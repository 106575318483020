import React, { useState, useContext } from "react";
import { ToastContext } from "../../App";
import ContentLoader from "react-content-loader";
import { Button } from "../../components/styled/Buttons";
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { sendContactUsMessage } from "../../controllers/userController";
import notify from "../../packages/toastify/Notify";

const MapLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={160}
    viewBox="0 0 350 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="300" height="20" />
    <rect x="80" y="40" rx="3" ry="3" width="250" height="20" />
  </ContentLoader>
);

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [click, setClick] = useState(0);
  const [message, setMessage] = useState("");
  const [len, setLen] = useState(0);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [errors, setErrors] = useState({});

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validate = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = "Name is required";
    if (!email.trim()) newErrors.email = "Email is required";
    else if (!validateEmail(email)) newErrors.email = "Email is invalid";
    if (!message.trim()) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (!e.target.value.trim()) {
      e.target.setCustomValidity("Name is required");
    } else {
      e.target.setCustomValidity("");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!e.target.value.trim()) {
      e.target.setCustomValidity("Email is required");
    } else if (!validateEmail(e.target.value)) {
      e.target.setCustomValidity("Email is not valid");
    } else {
      e.target.setCustomValidity("");
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (!e.target.value.trim()) {
      e.target.setCustomValidity("Message is required");
    } else {
      e.target.setCustomValidity("");
    }
  };

  const user = localStorage.getItem("dfs-user")
    ? JSON.parse(localStorage.getItem("dfs-user"))
    : {};
  const form_class =
    "w-auto h-auto shadow-xl px-4 py-4 mx-4 my-4 border-current shadow-2xl hover:shadow-3xl rounded";

  const { addToast } = useContext(ToastContext);
  const submit = (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (sendContactUsMessage(name, email, message)) {
      setName("");
      setEmail("");
      setMessage("");
      setErrors({});
    }
  };

  return (
    <div className="w-full md:w-3/4 xl:w-1/2">
      <div className="sm:w-full text-white rounded text-center bg-blue-green-0 mt-8 mb-4 py-2">
        Contact Us
      </div>
      <div className=" bg-white p-2 justify-items-center mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex">
            <div className={`${form_class} flex-grow`}>
              <form onSubmit={submit}>
                <div className="mb-4">
                  <label className="text-base" htmlFor="form2Example1">
                    Name
                  </label>
                  <input
                    type="text"
                    id="form2Example1"
                    value={name}
                    placeholder="Your name"
                    className="appearance-none rounded-md relative block w-full mt-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    onChange={handleNameChange}
                    required
                  />
                  {errors.name && (
                    <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                  )}
                </div>
                <div className="form-outline mb-4">
                  <label className="text-base mb-4" htmlFor="form2Example2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="form2Example2"
                    placeholder="Your email address"
                    value={email}
                    className="appearance-none rounded-md relative block w-full mt-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    onChange={handleEmailChange}
                    required
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    className="w-80 text-base mb-4"
                    htmlFor="form2Example2"
                  >
                    Message
                  </label>
                  <textarea
                    name="message"
                    placeholder="Write your Message"
                    className="appearance-none rounded-md relative block w-full mt-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    rows="5"
                    onChange={handleMessageChange}
                    value={message}
                    required
                  />
                  {errors.message && (
                    <p className="text-red-500 text-xs mt-1">{errors.message}</p>
                  )}
                </div>
                <Button.Blue
                  type="submit"
                  className="w-full mt-2 btn btn-primary btn-block mb-4"
                >
                  Send
                </Button.Blue>
              </form>
            </div>
          </div>
          <div className="flex">
            <div className={`${form_class} flex-grow`}>
              <p className="text-gray-600 mb-4 text-balance">
                <b>Have a question and need assistance?</b> <br />
                Reach out to us via email or contact form. We are eager to
                assist you.
              </p>
              <div className="flex flex-col">
                {!mapLoaded && <MapLoader />}
                <iframe
                  id="gmap_canvas"
                  className={`w-full h-40 frameborder=0 style=border:0 mb-4 ${
                    mapLoaded ? "block" : "hidden"
                  }`}
                  title="IIIT Hyderabad Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.310118232061!2d78.34723887501148!3d17.44486488345212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bce0a03f17ff82b%3A0x9d02e89b98deb496!2sIIIT-H%20%7C%20The%20International%20Institute%20of%20Information%20Technology%20-%20Hyderabad!5e0!3m2!1sen!2sin!4v1704786996204!5m2!1sen!2sin"
                  width="200"
                  height="200"
                  allowfullscreen=""
                  referrerpolicy="no-referrer-when-downgrade"
                  onLoad={handleMapLoad}
                />
              </div>
              <div className="flex flex-col">
                <ul className=" md:mb-0">
                  <li className="flex mb-2">
                    <div className="mt-2">
                      <p className="flex text-sm text-gray-600 dark:text-slate-400">
                        <FaMapMarkerAlt className="w-6 h-6 mt-4" />
                        <span className="mx-2 mt-2">
                          IIIT Hyderabad, Professor CR Rao Rd, Gachibowli,
                          Hyderabad, Telangana 500032
                        </span>
                      </p>

                      <p className="flex text-sm text-gray-600 dark:text-slate-400 mb-2">
                        <FaEnvelope className="w-5 h-5 mt-2" />
                        <a
                          href="mailto:dfsupport@ihub-data.iiit.ac.in"
                          className="mx-2 mt-2"
                        >
                          dfsupport@ihub-data.iiit.ac.in
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
