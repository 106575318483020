import React, { useState, useEffect, useContext, memo } from "react";
import { addDua } from "../../services/DUAService";
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from "../../packages/toasts/constants";
import MDEditor from "@uiw/react-markdown-editor";
import { FaSun, FaMoon } from "react-icons/fa";
import { getdomain } from "./../../services/DUAService";

const InputNode = memo(({ label, value, onChange, error, required }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {required ? (
        <span className="flex items-center">
          {label}: <span className="text-red-500 ml-1">*</span>
        </span>
      ) : (
        label
      )}
    </label>
    <input
      className={`block w-full rounded-md shadow-sm border ${
        error ? "border-red-500" : "border-gray-300"
      } focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={label}
    />
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
));

const SelectNode = memo(
  ({ label, value, options, onChange, error, required }) => (
    <div className="mb-1">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}:{required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <select
        className={`block w-full rounded-md shadow-sm border ${
          error ? "border-red-500" : "border-gray-300"
        } focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">Select {label}</option>
        {options && options.length > 0 ? (
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        ) : (
          <option value="">No domains available</option>
        )}
      </select>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  )
);

const AddDuaForm = ({ onDuaAdded, domainId }) => {
  const [mdData, setMdData] = useState("Write here your Data User Agreement");
  const [colorMode, setColorMode] = useState("light");
  const [domains, setDomains] = useState([]);
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.documentElement.setAttribute("data-color-mode", colorMode);
  }, [colorMode]);

  const [formData, setFormData] = useState({
    dua: "",
    duaDomainName: domainId || "",
  });

  const fetchDomains = async () => {
    try {
      const response = await getdomain();
      if (response && response.data && Array.isArray(response.data.domains)) {
        const activeDomains = response.data.domains.filter(
          (domain) => domain.isActive
        );
        setDomains(activeDomains);
      } else {
        console.error("No domains found in the response.");
        setDomains([]);
      }
    } catch (error) {
      console.error("Error fetching domains:", error);
      setDomains([]);
    }
  };

  useEffect(() => {
    fetchDomains();
  }, []);

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
    setErrors({ ...errors, [key]: "" });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.dua.trim()) {
      newErrors.dua = "DUA Title is required.";
    }
    if (!formData.duaDomainName.trim()) {
      newErrors.duaDomainName = "Domain Name is required.";
    }
    if (!mdData.trim() || mdData === "Write here your Data User Agreement") {
      newErrors.mdData = "DUA Description is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    setLoading(true);
    try {
      const response = await addDua({ formData, mdData });
      addToast({
        message: response.message || "DUA added successfully!",
        variant: TOAST_VARIANTS.SUCCESS,
      });

      setFormData({
        dua: "",
        duaDomainName: "",
      });
      setMdData("Write here your Data User Agreement");
      onDuaAdded();
    } catch (error) {
      addToast({
        message: error.message || "Failed to add DUA!",
        variant: TOAST_VARIANTS.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8 bg-gray-100 rounded-lg mb-2">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col gap-4">
          <SelectNode
            label="Domain Name"
            value={formData.duaDomainName}
            options={domains.map((domain) => ({
              label: domain.domain_name,
              value: domain.domain_id,
            }))}
            onChange={(val) => handleInputChange("duaDomainName", val)}
            error={errors.duaDomainName}
            required={true}
          />

          <InputNode
            label="Enter DUA Title"
            value={formData.dua}
            onChange={(val) => handleInputChange("dua", val)}
            error={errors.dua}
            required={true}
          />
          <span className="mb-[-4]">
            Enter the Data User Agreement Description:
            <span className="text-red-500 ml-1">*</span>
          </span>
          <div className="relative">
            <MDEditor
              data-color-mode={colorMode}
              value={mdData}
              onChange={setMdData}
              height="400px"
              enablePreview={true}
            />
            {errors.mdData && (
              <p className="text-red-500 text-sm mt-1">{errors.mdData}</p>
            )}
            <div className="mx-8 absolute top-2 right-6 flex items-center">
              {colorMode === "light" ? (
                <FaMoon
                  onClick={() => setColorMode("dark")}
                  className="h-4 w-4 cursor-pointer text-gray-700"
                  title="Switch to Dark Mode"
                />
              ) : (
                <FaSun
                  onClick={() => setColorMode("light")}
                  className="h-4 w-4 cursor-pointer text-yellow-500"
                  title="Switch to Light Mode"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className={`inline-flex items-center px-4 py-2 rounded-md text-white font-medium ${
              loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            }`}
          >
            {loading ? "Adding..." : "Add DUA"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddDuaForm;
