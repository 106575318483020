import React, {useEffect, useState} from "react";
import Table from "../styled/Table";
import { getVideos } from "../../services/VideoService";
import DownloadManager from "../download/DownloadManager";

const VideosTable = ({parentVideoId, parentVideoName}) => {
  const [videos, setVideos] = useState([]);
  const [isDownloadManagerOpen, setDownloadManagerOpen] = useState(false);
  const [datasetIdDownload, setDatasetIdDownload] = useState(null);
  const [versionId, setVersionId] = useState(null);

  const loadVideos = async () => {
    try {
      const response = await getVideos(parentVideoId);
      console.log(response);
      setVideos(response.videos || []);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    console.log("selected parentVideoId", parentVideoId);
    loadVideos();
  }, []); 

  const columns = [
    {
      key: "User",  
      label: "Owner Name",
      sortable: true,
      render: (User) => `${User?.firstname || ''} ${User?.lastname || ''}`.trim(), // Combine first and last name
    },
    {
      key: "model_name",
      label: "Model Name",
      sortable: true,
    },
    {
      key: "createdAt",
      label: "Creation Date",
      sortable: true,
      render: (value) => new Date(value).toLocaleDateString("en-GB"),
    },
  ];

  const actions = [
    {
      label: "Download Video",
      className: "px-3 py-2 rounded-lg text-sm text-blue-500 bg-white border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => {
        setDownloadManagerOpen(true);
        setDatasetIdDownload(parentVideoId);
        setVersionId(item.video_id);
      },
    }
  ];

  return (
    <div className="flex flex-col w-full bg-white mb-2">
      <Table 
        data={videos}
        columns={columns}
        actions={actions}
        pageSize={5}
        title={`${parentVideoName} anotations`}
      />
      {isDownloadManagerOpen && (
        <div className="z-50">
            <DownloadManager
                databaseId={datasetIdDownload}
                versionId={versionId}
                isModalOpen={isDownloadManagerOpen}
                onClose={() => setDownloadManagerOpen(false)}
            />
        </div>
      )}
    </div>
  );
};

export default VideosTable;