import React, { useState, useEffect, useRef } from "react";
import DownloadComponent from "./DownloadComponent";
import axios from "axios";
import creds from "../../creds";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import DownloadMonitor from "./DownloadMonitor";
import "../../assets/designs/modalStyle.css"
import { useDispatch } from "react-redux";
import { setDownloadIds } from "../../redux/downloadSlice";

const backendDU = creds.backendDU;

Modal.setAppElement("#root");

const DownloadSinglefile = ({
  databaseId,
  versionId,
  isModalOpen,
  onClose,
  selectedFile,
}) => {
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const hasLoggedDownloadEvent = useRef(false);

  const downloadStatus = useSelector((state) => state.download.downloadStatus);
  const dispatch = useDispatch();

  const downloadRefs = useRef({});

  const fetchFiles = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${backendDU}download/files/${databaseId}/${versionId}`
      );

      if (response.data.files && response.data.files.length > 0) {
        const newDownloads = response.data.files.map((file) => ({
          id: file.file_id || Date.now(),
          fileName: file.file_name,
          fileSize: file.file_size,
          fileType: file.file_type,
        }));

        setDownloads((prevDownloads) => {
          const existingFileNames = prevDownloads.map((d) => d.fileName);
          const uniqueNewDownloads = newDownloads.filter(
            (download) => !existingFileNames.includes(download.fileName)
          );
          return [...prevDownloads, ...uniqueNewDownloads];
        });
      } else {
        setError("No files found for the given database and version");
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to fetch files");
      console.error("Error fetching files:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (file) => {
    const ref = downloadRefs.current[file.id];
    if (ref && typeof ref.startDownload === "function") {
      try {
        await ref.startDownload();
      } catch (err) {
        console.error(`Failed to download file: ${file.fileName}`, err);
      }
    }
  };

  const handleDownloadComplete = (downloadId) => {
    setDownloads((prevDownloads) =>
      prevDownloads.filter((download) => download.id !== downloadId)
    );
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchFiles();
      dispatch(setDownloadIds({ databaseId, versionId }));
      if (!hasLoggedDownloadEvent.current) {
        hasLoggedDownloadEvent.current = true;
      }
    } else {
      hasLoggedDownloadEvent.current = false;
    }
  }, [isModalOpen, databaseId, versionId, dispatch]);

  const selectedDownload = downloads.find(
    (download) => download.fileName === selectedFile
  );

  const handleClose = () => {
    if (downloadStatus != "Downloading...") {
      onClose();
    }
    if (downloadStatus === "Downloading...") {
      const confirmClose = window.confirm(
        "Do you really want to cancel the download?"
      );
      if (!confirmClose) {
        return;
      }
    }
    onClose();
  };

  return (
    <>
      <DownloadMonitor downloadStatus={downloadStatus} />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        // className="relative mx-auto max-h-[30vh] max-w-[60vw] overflow-y-auto bg-white shadow-2xl rounded-md"
        // overlayClassName="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center"
        // just added these styles to the Modal component so that it will behave like download manager now it not behaving like download manager so I put these styles we can remove these styles if we want in future
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 },
          content: {
            maxHeight: "30vh",
            maxWidth: "50vw",
            margin: "auto",
            padding: "20px",
            zIndex: 1001,
            display: "flex",
            flexDirection: "column",
          },
        }}
        shouldCloseOnOverlayClick={false}
      >
        <div className="flex items-center justify-between p-3 bg-gray-100 border-b border-gray-300">
          <h2 className="text-lg font-bold">Download</h2>
          <button onClick={handleClose} className="text-2xl font-bold">
            &times;
          </button>
        </div>

        <div className="p-4 max-h-80 overflow-y-auto">
          {loading ? (
            <div className="text-center">Loading...</div>
          ) : selectedDownload ? (
            <DownloadComponent
              key={selectedDownload.id}
              ref={(el) => (downloadRefs.current[selectedDownload.id] = el)}
              fileName={selectedDownload.fileName}
              onComplete={handleDownloadComplete}
            />
          ) : (
            <div className="text-center text-gray-600">
              No file found with the given name.
            </div>
          )}
          {error && (
            <div className="text-center text-red-600 mt-2">{error}</div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default DownloadSinglefile;
