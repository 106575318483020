import { createSlice } from '@reduxjs/toolkit';
import { USER_STATE } from '../withAuth';

const getInitialTab = (userRole) => {
  if (userRole === USER_STATE.ADMIN) return 1;
  if (userRole === USER_STATE.SIGNED_IN) return 1;
  if (userRole === USER_STATE.UPLOADER) return 1;
  if (userRole === USER_STATE.ANNOTATOR) return 1;
  return null;
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    tab: null,
    collapsed: false,
    tabParams: {},
  },
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setTabParams: (state, action) => { // To add parameters(optional): e.g. tabparams: {13: {domainId: "3744e75c-094c-453e-82f1-a8787710c288"}}
      state.tabParams = action.payload;
    },
    toggleCollapsed: (state) => {
      state.collapsed = !state.collapsed;
    },
    setCollapsed: (state, action) => {
      state.collapsed = action.payload;
    },
    initializeTab: (state, action) => {
      state.tab = getInitialTab(action.payload);
    },
  },
});

export const { setTab, setCollapsed, toggleCollapsed, initializeTab, setTabParams } = commonSlice.actions;

export default commonSlice.reducer;