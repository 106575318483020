import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import newBannermob from '../../assets/images/landingPageImages/mobileBanner.webp';
import newBanner from '../../assets/images/landingPageImages/desktopBanner.webp';

export const Banner = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={150}
          viewBox="0 0 100% 150px"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" width="100%" height="150px" />
        </ContentLoader>
      ) : (
        <div className="">
          <img
                  src={newBanner}
                  alt="DFS Banner"
                  className="w-full h-full hidden sm:hidden md:block"
            />
            <img
              src={newBannermob}
              alt="DFS Banner"
              className="w-full h-full block md:hidden sm:block lg:hidden"
            />
        </div>    
      )}
    </>
  );
};

export default Banner;
