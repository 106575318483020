import React, { useState, useEffect, useContext } from "react";
import { FaEdit, FaEye, FaEyeSlash } from "react-icons/fa";
import notify from "../../packages/toastify/Notify";
import { updateUserDetailsPassword } from "../../services/AuthService";
import { PASSWORD_REGEX } from "../../packages/utils/regexPatterns";

const PasswordUpdate = () => {
  const [IsCollaps, SetIsCollaps] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [oldPassword, setoldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const onCollaps = () => {
    SetIsCollaps(!IsCollaps);
  };

  const updatePasswordSave = async (e) => {
    e.preventDefault();
    if (!oldPassword) {
      setPasswordError("Old password is required.");
      return;
    }
    if (!newPassword || !confirmNewPassword) {
      setPasswordError(
        "Please enter the updated new password and confirm new password."
      );
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setPasswordError(
        "The confirmation password does not match the new password."
      );
      return;
    }

    if (!PASSWORD_REGEX.test(newPassword)) {
      setPasswordError(
        `The new password and confirmation must meet these requirements:\n` +
          `- Minimum length: 8 characters\n` +
          `- Include at least 1 special character (e.g., @, #, $)\n` +
          `- Include at least 1 uppercase letter (A-Z)\n` +
          `- Include at least 1 lowercase letter (a-z)\n` +
          `- Include at least 1 number (0-9)`
      );
      return;
    }
    if (oldPassword === newPassword && confirmNewPassword) {
      setPasswordError(
        "Old password and new password is same. Please enter a new password."
      );
      return;
    }

    try {
      const response = await updateUserDetailsPassword(
        oldPassword,
        newPassword
      );

      setoldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      SetIsCollaps(false);
      notify("Passwords have been successfully saved.", "success");
      setPasswordError("");
      setShowOldPassword(false);
      setShowNewPassword(false);
      setShowConfirmNewPassword(false);
    } catch (error) {
      if (error.response && error.response.data) {
        notify(
          error.response.data.message || "Failed to update the password.",
          "errors"
        );
      } else {
        setPasswordError(
          "An error occurred while updating the password. Please try again."
        );
      }
    }
  };

  return (
    <>
      <div className="flex flex-row flex-wrap">
        <div className="pr-3 py-3 border-b border-gray-200 text-sm w-4/5 sm:w-3/5 md:w-1/5 lg:w-1/5 xl:w-1/5 2xl:w-1/5 ">
          Update Password
        </div>
        <div className="px-3 py-3 border-b border-gray-200 text-sm w-1/5 sm:w-1/5 md:w-1/12 lg:w-1/12 xl:w-1/12 2xl:w-1/12">
          <span className="text-gray-900 whitespace-no-wrap">:</span>
        </div>
        <div className="px-3 py-3 border-b border-gray-200 text-sm w-4/5 sm:w-4/5 md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5">
          <input
            disabled
            placeholder="********"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            // className="shadow-sm rounded-md w-4/6 px-3 py-2 my-1 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div className="px-3 py-3 border-b border-gray-200 text-sm w-1/5 sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5 2xl:w-1/5 flex flex-row items-center justify-center">
          <button onClick={onCollaps}>
            <FaEdit className="text-gray-500 text-base" />
          </button>
        </div>
      </div>
      {IsCollaps && (
        <form>
          {[
            {
              label: "Old Password",
              value: oldPassword,
              setValue: setoldPassword,
              showPassword: showOldPassword,
              setShowPassword: setShowOldPassword,
            },
            {
              label: "New Password",
              value: newPassword,
              setValue: setNewPassword,
              showPassword: showNewPassword,
              setShowPassword: setShowNewPassword,
            },
            {
              label: "Confirm New Password",
              value: confirmNewPassword,
              setValue: setConfirmNewPassword,
              showPassword: showConfirmNewPassword,
              setShowPassword: setShowConfirmNewPassword,
            },
          ].map((field, index) => (
            <div key={index} className="flex flex-row flex-wrap">
              <div className="pr-3 py-3 border-b text-sm w-4/5 sm:w-3/5 md:w-1/5">
                {field.label}
              </div>
              <div className="px-3 py-3 border-b border-gray-200 text-sm w-1/5 sm:w-1/5 md:w-1/12 lg:w-1/12 xl:w-1/12 2xl:w-1/12">
                <span className="text-gray-900 whitespace-no-wrap">:</span>
              </div>
              <div className="relative px-3 py-3 border-b text-sm w-4/5 sm:w-4/5 md:w-2/5">
                <input
                  placeholder=""
                  type={field.showPassword ? "text" : "password"}
                  className="block w-full px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={(e) => field.setValue(e.target.value)}
                  value={field.value}
                />
                <button
                  type="button"
                  onClick={() => field.setShowPassword(!field.showPassword)}
                  className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm"
                >
                  {field.showPassword ? (
                    <FaEyeSlash className="h-5 w-5 text-gray-500" />
                  ) : (
                    <FaEye className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              </div>
            </div>
          ))}

          {passwordError && (
            <pre className="text-red-600 text-sm">{passwordError}</pre>
          )}
          <div className="flex items-center mt-4">
            <button
              type="submit"
              onClick={updatePasswordSave}
              className="bg-indigo-600 px-3 py-1 text-sm font-semibold text-white rounded-md"
            >
              Save
            </button>
          </div>
        </form>
      )}
    </>
  );
};
export default PasswordUpdate;
