import React, { useEffect, useState } from "react";
import AddDomainForm from "../../components/domains/AddDomainForm";
import DomainTable from "../../components/domains/DomainTable";
import { deleteDomain } from "../../controllers/domainController";
import { editDomain, getDomains } from "../../services/DomainService";
import EditDomainModal from "../../components/domains/EditDomainModal";
import { InternalHeading } from "../../components/styled/GlobalStyles";

const AdminAddDomain =() => {
    const [domains, setDomains] = useState([]);
    const [domainToDelete, setDomainToDelete] = useState(null);  
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
  
    const fetchDomains = async () => {
      const response = await getDomains();
      setDomains(response.data.domains || []);
    };
  
    const handleDelete = async () => {
      try {
        await deleteDomain(domainToDelete);
        setDomains(domains.filter((d) => d.domain_id !== domainToDelete.domain_id));
        fetchDomains();
      } catch (error) {
        console.error("Failed to delete domain");
      }
    };

    useEffect(() => {
      if (domainToDelete) {
        handleDelete();
      }
    }, [domainToDelete]);
  
    useEffect(() => {
      fetchDomains();
    }, []);
    
    const handleEdit = (domain) => {
          setSelectedDomain(domain);
          setShowEditModal(true);
      };
  
    const handleSaveEdit = async (updatedDomain) => {
          await editDomain(updatedDomain, selectedDomain.domain_id);
          setShowEditModal(false);
          fetchDomains();
      };
  
    return (
      <div>
        <div className="flex flex-wrap w-full px-4 bg-gray-200 mb-2 rounded-lg">
            <h1 className={InternalHeading}>
              Manage Domains
            </h1>
        </div> 

        <AddDomainForm onDomainAdded={fetchDomains} />
        <DomainTable
          domains={domains}
          onDelete={(domain) => {
            setDomainToDelete(domain);
          }}
          onEdit={handleEdit}
        />
        
        {showEditModal && (
                  <EditDomainModal
                    selectedDomain={selectedDomain}
                    show={showEditModal}
                    setShow={setShowEditModal}
                    onEdit={handleSaveEdit}
                  />
              )}
      </div>
    );
  };
export default AdminAddDomain;