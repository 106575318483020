import { url, authUrl } from "../creds";
import axiosInstance from "./AxiosInstance";
import { store } from "../redux/store";

export const getUserRole = () => {
  try {
    const state = store.getState(); // Access the store directly
    const { roleId, roleName } = state.auth.roleRes || {};
    return roleId && roleName ? { roleId, roleName } : null;
  } catch (error) {
    console.error('Error parsing user role:', error);
    return null;
  }
};

export const getSessionId = () => {
  try {
    const state = store.getState(); // Access the store directly
    const sessionId = state.auth.sessionId;

    return sessionId ? JSON.parse(sessionId) : null;
  } catch (error) {
    console.error('Error parsing session ID:', error);
    return null;
  }
};

export const fetchUserDetails = async () => {
  try {
    return axiosInstance.get(`${url}authorization/user-details`, {
      withCredentials: true,
    });
  } catch (error) {
    console.error("Error getting user details: ", error )
  }
}

export const updateUserDetails = async (field, updatedValue) => {
  try {
    if (field === "firstname" || field === "lastname") {
      const resp1 = await axiosInstance.put(`${authUrl}update-user-details`, 
        { [field]: updatedValue}, 
        { withCredentials: true}
      );
      
      if (resp1.status === 200) {
        const resp2 = await axiosInstance.post(`${url}authorization/update-user-details`, 
          { [field]: updatedValue }, 
          { withCredentials: true }
      );
        return resp2.data;
      }
    }else {
        const resp = await axiosInstance.post(`${url}authorization/update-user-details`, 
          { [field]: updatedValue }, 
          { withCredentials: true }
      );
        return resp.data;
    }
  } catch (error) {
    console.error("Error updating user details:", error);
    throw error;
  }
};

export const updateUserDetailsPassword = async (oldPassword, newPassword) => {
  try {
    return axiosInstance.post(`${authUrl}update-password`, {
      appName: 'dfs',
      oldPassword,
      newPassword,
    },{withCredentials: true});
  } catch (error) {
    console.error("Error getting user details: ", error )
  }
}
