import React, { useState,useEffect} from "react";
import Table from "../styled/Table";
import { MarkdownModal } from "../../components/dua/ViewDuaModal";
import { EditDuaModal } from "../../components/dua/EditDuaModal";
import { deletedua } from "../../services/DUAService";
import notify from "../../packages/toastify/Notify";

const DuaTable = ({ data, fetchDua }) => {
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDua, setSelectedDua] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const columns = [
    {
      key: "authorName",
      label: "Author Name",
      sortable: true,
    },
    {
      key: "duaTitle",
      label: "DUA Title",
      sortable: true,
    },
    {
      key: "duaDomainName",
      label: "Domain Name",
      sortable: true,
    },
    {
      key: "createdDate",
      label: "Creation Date",
      sortable: true,
      render: (value) => new Date(value).toLocaleDateString("en-GB"),
    },
    {
      key: "duaStatus",
      label: "Status",
      sortable: true,
      render: (value) => (
        <p
          className={`px-3 py-2 rounded-lg text-sm text-center font-medium ${
            value.toUpperCase() === "APPROVED"
              ? "text-green-800 bg-green-300 border-green-500" // Green for "APPROVED"
              : value.toUpperCase() === "REJECTED" 
              ? "text-red-800 bg-red-300 border-red-500" // Red for "REJECTED"
              : "text-blue-800 bg-blue-300 border-blue-500" // Blue for "CREATED"
          }`}
        >
          {value.toUpperCase() === "REJECTED" ? "Rejected" : value.toUpperCase()== "PENDING" ? "Unapproved" : "Approved"}
        </p>
      ),
    },
  ];

    const handleDelete = async (item) => {
      if (isDeleting) return; 
      setIsDeleting(true);
      try {
        const response = await deletedua(item);
        if (response.status === 200) {
          notify("DUA deleted successfully!", "success");
          await fetchDua(); 
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 403:
              notify(`${err.response.data.message}`, "error");
              break;
            case 500:
              notify(`${err.response.data.message}`, "error");
              break;
            default:
              notify("Something went wrong. Please try again.", "error");
          }
        }
      } finally {
        setIsDeleting(false);
      }
    };
  
  const actions = [
    {
      label: "View",
      className:
        "px-3 py-2 rounded-lg text-sm text-blue-500 bg-white border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => {
        setSelectedDua(item);
        setIsViewModalOpen(true);
      },
    },
    {
      label: "Edit",
      className:
        "px-6 py-2 rounded-lg text-sm text-green-500 bg-white border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-green-500 transition",
      handler: (item) => {
        if (item.isUsed) {
          notify("Edit not allowed for used DUA!", "error");
          return;
        }
        setSelectedDua(item);
        setIsEditModalOpen(true);
      },
    },

    {
      label: "Delete",
      className:
        "px-6 py-2 rounded-lg text-sm text-red-500 bg-white border border-red-500 hover:bg-red-300 hover:text-red-700 focus:ring-2 focus:ring-red-500",
      handler: (item) => {
        setSelectedDua(item);
        handleDelete(item);
      },
    },
  ];

  return (
    <div className="flex flex-col w-full bg-white mb-2">
      <Table
        data={data}
        columns={columns}
        pageSize={5}
        actions={actions}
        title="Data User Agreement List"
      />

      <MarkdownModal
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        title={selectedDua?.duaTitle || "DUA Details"}
        markdownContent={selectedDua?.duaDescription || ""}
      />

      <EditDuaModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        dua={selectedDua}
        onSave={async (updatedDua) => {
          await fetchDua(); // Fetch updated DUAs from backend
          notify("DUA updated successfully!", "success");
          setSelectedDua(null);
          setIsEditModalOpen(false);
        }}
      />
    </div>
  );
};

export default DuaTable;
