import { url, authUrl } from "../creds";
import axios from "axios";
import axiosInstance from "./AxiosInstance";

export const homeDownloadsCount = async () => {
    try {
      return axiosInstance.get(`${url}stats/home-page-analytics`, {
        withCredentials: true,
      });
    } catch (error) {
      console.error("Error getting user details: ", error )
    }
  }
  
  export const homeDomain = async () => {
    try {
      return axiosInstance.get(`${url}domain/get-domain-dataset`, {
        withCredentials: true,
      });
    } catch (error) {
      console.error("Error getting user details: ", error )
    }
  }