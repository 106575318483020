import { configureStore, combineReducers } from '@reduxjs/toolkit' // Import combineReducers
import authReducer from './AuthController'
import commonReducer from './commonSlice'
import paymentReducer from './paymentSlice'
import formReducer from './formSlice';
import datasetReducer from './datasetSlice';
import downloadReducer from './downloadSlice';
import uploadReducer from './uploadSlice';
import profileReducer from './profileSlice';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  payment: paymentReducer,
  form: formReducer,
  dataset: datasetReducer,
  download: downloadReducer,
  upload: uploadReducer,
  profile: profileReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };