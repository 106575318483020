import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { DUUrl } from "../../creds";
import { download_config } from "../../config/config";
import axiosInstance from "../../services/AxiosInstance";
import { setProfileImage } from "../../redux/profileSlice";

const ImageDownloader = ({ versionId }) => {
  const dispatch = useDispatch();
  const databaseId = download_config.DATABASE_PROFILE_ID;
  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return;

    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(
          `${DUUrl}download/files/${databaseId}/${versionId}`
        );
        const files = response.data.files;
        if (files && files.length > 0) {
          const latestFile = files.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )[0];
          const latestFileName = latestFile.file_name;

          const fileResponse = await axiosInstance.get(
            `${DUUrl}download/v1/download-file`,
            {
              headers: {
                fileName: latestFileName,
              },
              withCredentials: true,
              responseType: "blob",
            }
          );
          const imageUrl = URL.createObjectURL(fileResponse.data);
          dispatch(setProfileImage({ imageUrl }));
        }
        hasFetched.current = true;
      } catch (error) {
        console.error("Failed to fetch files", error);
      }
    };
    fetchFiles();
  }, [databaseId, dispatch, versionId]);
  return null;
};
export default ImageDownloader;
