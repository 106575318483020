import { useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";
import { DUUrl } from "../../creds";
import { upload_config } from "../../config/config";
import * as tus from "tus-js-client";

const ImageUploader = ({
    file,
    onSuccess,
    onError,
    versionId,
    customPath,
    fileCategory,
    databaseId,
}) => {

    const hasUploaded = useRef(false);

    useEffect(() => {
        if (!file || hasUploaded.current) return;

        hasUploaded.current = true;

        const compressImage = async () => {
            try {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1024,
                    useWebWorker: true,
                };

                const compressedFile = await imageCompression(file, options);
                await startUpload(compressedFile);
            } catch (error) {
                console.error("Image compression failed:", error);
                onError?.(error);
            }
        };

        const startUpload = async (compressedFile) => {
            return new Promise((resolve, reject) => {
                const upload = new tus.Upload(compressedFile, {
                    endpoint: `${DUUrl}upload/v1/tus`,
                    chunkSize: upload_config.CHUNK_SIZE,
                    retryDelays: [0, 3000, 5000],
                    headers: {
                        databaseId: databaseId || upload_config.DATABASE_PROFILE_ID,
                        versionId,
                        customPath,
                        appName: 'DFS',
                        fileCategory: fileCategory || upload_config.FILE_CATEGORY,
                    },
                    metadata: {
                        filename: compressedFile.name,
                        filetype: compressedFile.type,
                    },
                    onBeforeRequest: (req) => {
                        const xhr = req.getUnderlyingObject();
                        xhr.withCredentials = true;
                    },
                    onError: (error) => {
                        console.error("Upload failed:", error);
                        onError?.(error);
                        reject(error);
                    },
                    onSuccess: () => {
                        onSuccess?.();
                        resolve();
                    },
                });

                upload.start();
            });
        };

        compressImage();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return null;
}

export default ImageUploader;