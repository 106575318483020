import React, { useState, useEffect } from "react";
import { getPublicDatasetCataloguesList } from "../../services/DatasetCatalogueService"; // Import the function from your service.js
import { BiSortUp, BiSortDown, BiSort } from "react-icons/bi";

const DatasetCatalogue = () => {
  const [page, setPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [datasetCatalogue, setDatasetCatalogue] = useState([]); // State to store fetched datasets
  const [loading, setLoading] = useState(true); // State to track loading state

  // Function to handle sorting
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchDatasetCatalogues = async () => {
      try {
        const datasets = await getPublicDatasetCataloguesList();
        console.log(datasets); // Log the entire datasets array or object
        console.log(datasets.map(item => item.datasetName)); // Log dataset names if datasets is an array
        setDatasetCatalogue(datasets);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching dataset catalogue:", error);
        setLoading(false); // Set loading to false even in case of error
      }
    };
  
    fetchDatasetCatalogues();
  }, []);
  

  // Sort the data based on the sort config
  const sortedData =
    sortConfig.key
      ? [...datasetCatalogue].sort((a, b) => {
          const aValue = a[sortConfig.key]?.toString().toLowerCase() || "";
          const bValue = b[sortConfig.key]?.toString().toLowerCase() || "";

          if (aValue < bValue) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        })
      : datasetCatalogue;

  // Filter the data based only on the dataset name or domain
  const filteredData = sortedData.filter(
    (item) =>
      item.datasetName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.domainName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate total number of filtered entries
  const totalEntries = filteredData.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  // Get the current page of data from filtered data
  const paginatedData = filteredData.slice(
    page * entriesPerPage,
    (page + 1) * entriesPerPage
  );

  return (
    <>
      <div className="flex flex-col w-full px-8 bg-white mb-2">
        <div className="flex flex-col mt-4 mb-4 rounded-lg">
          <div className="flex flex-wrap">
            <h1 className="mt-2 mb-4 lg:text-4xl md:text-3xl sm:text-2xl text-2xl font-extrabold tracking-tight flex-1">
              Dataset Catalogue
            </h1>
            <div className="flex-col">
              <div className="mt-4 mb-4">
                <input
                  type="text"
                  placeholder="Search by Dataset Name or Domain Name"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setPage(0);
                  }}
                  className="text-base px-4 py-2 border border-gray-300 rounded-lg w-full"
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-100 rounded p-3">
            <p className="text-base">
              <b>Disclaimer:</b> The datasets listed in the India Data Org
              CATALOGUE are sourced from various third-party providers and do
              not belong to or are owned by India Data Org. We are sharing
              these datasets solely for educational and informational purposes
              to facilitate research, development, and knowledge dissemination.
              We do not guarantee the accuracy, completeness, or reliability of
              the content contained within these datasets, and any use of the
              datasets is at the user's own risk.
            </p>
            <p className="text-base">
              By accessing or using these datasets, you agree that the India Data Org
              is not liable for any errors, omissions, or
              inaccuracies in the data, or for any consequences arising from
              their use. Users are advised to review the terms and conditions,
              data usage policies, and licenses applicable to each dataset from
              their original sources before using them for any purpose.
            </p>
            <p className="text-base">
              If you have any questions or concerns regarding the datasets,
              please contact the respective data provider directly.
            </p>
          </div>

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-2 overflow-x-auto pt-3">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      className="px-5 py-3 border-b-2 border-regal-blue bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                      onClick={() => requestSort("datasetName")}
                    >
                      <div className="flex items-center justify-between">
                        Dataset Name
                        <span className="ml-2">
                          {sortConfig.key === "datasetName" ? (
                            sortConfig.direction === "ascending" ? (
                              <BiSortUp />
                            ) : (
                              <BiSortDown />
                            )
                          ) : (
                            <BiSort />
                          )}
                        </span>
                      </div>
                    </th>
                    <th
                      className="px-5 py-3 border-b-2 border-regal-blue bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                      onClick={() => requestSort("domainName")}
                    >
                      <div className="flex items-center justify-between">
                        Domain
                        <span className="ml-2">
                          {sortConfig.key === "domainName" ? (
                            sortConfig.direction === "ascending" ? (
                              <BiSortUp />
                            ) : (
                              <BiSortDown />
                            )
                          ) : (
                            <BiSort />
                          )}
                        </span>
                      </div>
                    </th>

                    <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Description
                    </th>
                    <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Content Type
                    </th>
                    <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Dataset Usage
                    </th>
                    <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      External Links
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    paginatedData.map((item, index) => (
                      <tr key={index} className="border-b border-gray-200 mt-5">
                        <td className="px-3 py-3 bg-white text-base">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item.datasetName}
                          </p>
                        </td>
                        <td className="px-3 py-3 bg-white text-base">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item.domainName}
                          </p>
                        </td>
                        <td className="px-3 py-3 bg-white text-base">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item.description}
                          </p>
                        </td>
                        <td className="px-3 py-3 bg-white text-base">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item.contentType}
                          </p>
                        </td>
                        <td className="px-3 py-3 bg-white text-base">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item.datasetUsage}
                          </p>
                        </td>
                        <td className="px-3 py-3 bg-white text-base">
                          <a
                            href={item.sourceLink}
                            target="__blank"
                            className="text-blue-500 hover:underline"
                          >
                            <button className="font-medium rounded-lg text-sm px-3 py-2 text-center cursor-pointer text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800 py-2 px-3 bg-blue-800 text-white hover:underline dark:text-blue-500 font-semibold">
                              Click Here
                            </button>
                          </a>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <div className="px-3 py-3 bg-white border-t flex flex-col xs:flex-row items-center text-center xs:justify-between">
                <span className="text-xs xs:text-sm text-gray-900">
                  Showing {page * entriesPerPage + 1} to{" "}
                  {Math.min((page + 1) * entriesPerPage, totalEntries)} of{" "}
                  {totalEntries} entries
                </span>
                <div className="flex mt-2 xs:mt-0">
                  <button
                    className="px-4 py-2 bg-blue-600 text-white rounded disabled:bg-gray-400"
                    onClick={() => setPage(Math.max(0, page - 1))}
                    disabled={page === 0}
                  >
                    Prev
                  </button>
                  <button
                    className="px-4 py-2 bg-blue-600 text-white rounded ml-2 disabled:bg-gray-400"
                    onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatasetCatalogue;
