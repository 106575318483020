import React, { useEffect, useState } from "react";
import AddDatasetCatalogueForm from "../../components/datasetsCatalogue/AddDatasetCatalogueForm";
import DatasetCatalogueTable from "../../components/datasetsCatalogue/DatasetCatalogueTable";
import { getDatasetCataloguesList } from "../../services/DatasetCatalogueService";
import { InternalHeading } from "../../components/styled/GlobalStyles";

const AdminAddDatasetCatalogue = () => {
  const [datasetCatalogueData, setDatasetCatalogueData] = useState([]);

  const fetchgetDatasetCataloguesList = async () => {
    const response = await getDatasetCataloguesList();
    setDatasetCatalogueData(response.data || []);
  };

  useEffect(() => {
    fetchgetDatasetCataloguesList();
  }, []);

  return (
    <div>
      <div className="flex flex-wrap w-full px-4 bg-gray-200 mb-2 rounded-lg">
        <h1 className={InternalHeading}>
          Manage Dataset Catalogue
        </h1>
      </div>
      <AddDatasetCatalogueForm onDatasetCatalogueAdded={fetchgetDatasetCataloguesList} />
      <DatasetCatalogueTable 
        datasetCatalogue={datasetCatalogueData}
        onDatasetCatalogueUpdated={fetchgetDatasetCataloguesList} 
      />
    </div>
  );
};

export default AdminAddDatasetCatalogue;