import React from "react";
import { FaDownload, FaUsers, FaGlobe, FaBuilding } from "react-icons/fa";

const DatasetStats = ({ stats }) => {
  if ((stats?.totalUniqueCountries || 0) === 0 && (stats?.totalDownloads || 0) > 0) {
    stats.uniqueCountries = ["India"];
    stats.totalUniqueCountries = 1;
  }

  const downloadCSV = () => {
    const csvContent = [
      ["Key", "Value"],
      ...Object.entries(stats || {}).map(([key, value]) => {
        let formattedKey = key;
        if (key === "uniqueCountries") formattedKey = "Unique Countries";
        if (key === "datasetId") formattedKey = "Dataset Id";
        if (key === "datasetName") formattedKey = "Dataset Name";
        if (key === "uniqueOrganisations") formattedKey = "Unique Organization";
        if (key === "totalDownloads") formattedKey = "Total Downloads";
        if (key === "totalUniqueUsers") formattedKey = "Total Unique Users";
        if (key === "totalUniqueCountries") formattedKey = "Total Unique Countries";
        if (key === "totalUniqueOrganisations") formattedKey = "Total Unique Organization";
        
        if (key === "uniqueCountries" || key === "uniqueOrganisations") {
          return [formattedKey, value.join(", \n")];
        }
        return [formattedKey, value];
      })
    ]
    .map(e => e.map(v => `"${v}"`).join(" ,")) // Quote each value to preserve newlines in CSV format
    .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "dataset_stats.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const statCards = [
    { title: "Total Downloads", value: stats?.totalDownloads || 0, icon: <FaDownload className="text-blue-600" /> },
    { title: "Total Users", value: stats?.totalUniqueUsers || 0, icon: <FaUsers className="text-blue-600" /> },
    { title: "Total Countries", value: stats?.totalUniqueCountries || 0, icon: <FaGlobe className="text-blue-600" /> },
    { title: "Total Organizations", value: stats?.totalUniqueOrganisations || 0, icon: <FaBuilding className="text-blue-600" /> },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
      {statCards.map((stat, index) => (
        <div
          key={index} 
          className="bg-white shadow-lg rounded-lg p-4 flex flex-col items-center justify-center text-center"
        >
          <div className="text-3xl">{stat.icon}</div>
          <h2 className="text-lg font-medium mt-2">{stat.title}</h2>
          <p className="text-2xl font-bold text-indigo-600 mt-1">{stat.value}</p>
        </div>
      ))}

      <div className="bg-white shadow-lg rounded-lg p-4 flex flex-col items-center justify-center text-center group relative">
        <button
          onClick={downloadCSV}
          className="bg-blue-500 text-white py-2 px-4 rounded flex  hover:bg-blue-600"
        >
          <span className="mr-2">&#9776;</span>
          Download Statistics
        </button>
        <span className="absolute bottom-full mb-2 hidden group-hover:block w-max bg-black text-white text-xs rounded py-1 px-2">
          Click here to Download Statistics
        </span>
      </div>
    </div>
  );
};

export default DatasetStats;
