import React from "react";
import { useNavigate } from "react-router-dom";

const SignUpVerificationLinkSent = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="text-center bg-white shadow-md rounded-lg p-6 mx-auto my-auto">
        <h1 className="text-2xl font-bold mb-4">Thanks for Signing Up!</h1>
        <h2 className="text-xl mb-2">
          A verification link has been sent to your email.
        </h2>
        <p className="text-lg">Please verify your email to continue.</p>
      </div>
    </>
  );
};

export default SignUpVerificationLinkSent;