import React, { useState, useContext } from "react";
import { handleExpiredPasswordReset } from "../../controllers/authController";
import { Button } from "../../components/styled/Buttons";
import PasswordRenewalSuccessful from "./PasswordRenewalSuccessful";
import notify from "../../packages/toastify/Notify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const formClass = "px-10 py-6 mx-auto my-6 w-full max-w-md rounded-lg bg-white shadow-md sm:w-1/3";


export default function PasswordExpiredPage({ email }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showResetForm, setShowResetForm] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNPassword, setShowNPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const validateNewPassword = (password) => {
    const passwordRegex =
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/;
    return passwordRegex.test(password)
      ? ""
      : "Password must be at least 8, max 20 characters long, include uppercase, lowercase, number, and special character.";
  };

  const handleNewPasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    const errorMessage = validateNewPassword(password);
    if (errorMessage) {
      setError(errorMessage);
      setIsPasswordValid(false); // Mark as invalid
    } else {
      setError("");
      setIsPasswordValid(true); // Mark as valid
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword === oldPassword) {
      notify("New password must be different from the old password", "warning");
      return;
    }
    if (newPassword !== confirmPassword) {
      notify("New password and confirm password do not match", "warning");
      return;
    }

    const resultAction = await handleExpiredPasswordReset(
      email,
      oldPassword,
      newPassword,
      "dfs"
    );

  };

  return (
    
    <div className="max-w-5xl rounded-lg my-8">
      <div className="bg-gray-100 flex flex-col justify-center px-10 sm:px-12 lg:px-20">
        <div className="mx-auto w-full sm:max-w-3xl">
          <div className="sm:w-full text-white rounded text-center bg-blue-green-0 mt-1 mb-4 py-3 text-lg font-semibold">
            Reset Password
          </div>
          <div className="bg-white py-14 px-12 sm:rounded-lg sm:px-16">
            {isPasswordResetSuccessful ? (
              <PasswordRenewalSuccessful />
            ) : showResetForm ? (
              <form onSubmit={handleSubmit}>
                <div className="space-y-8">
                  <div className="space-y-2">
                    <label
                      htmlFor="oldPassword"
                      className="text-base font-medium"
                    >
                      Old Password
                    </label>
                    <div className="relative">
                      <input
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        id="oldPassword"
                        name="oldPassword"
                        type={showPassword ? "text" : "password"}
                        placeholder="Old Password"
                        required
                        className="appearance-none rounded-md block w-full px-3 py-2 pr-12 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-3 flex items-center"
                      >
                        {showPassword ? (
                          <FaEyeSlash className="h-5 w-5 text-gray-500" />
                        ) : (
                          <FaEye className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label
                      htmlFor="newPassword"
                      className="text-base font-medium"
                    >
                      New Password
                    </label>
                    <div className="relative">
                      <input
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        id="newPassword"
                        name="newPassword"
                        type={showNPassword ? "text" : "password"}
                        placeholder="New Password"
                        required
                        className="appearance-none rounded-md block w-full px-3 py-2 pr-12 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        onClick={() => setShowNPassword(!showNPassword)}
                        className="absolute inset-y-0 right-3 flex items-center "
                      >
                        {showNPassword ? (
                          <FaEyeSlash className="h-5 w-5 text-gray-500" />
                        ) : (
                          <FaEye className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                  {error && <p className="text-red-600 text-sm">{error}</p>}

                  <div className="space-y-2">
                    <label
                      htmlFor="confirmPassword"
                      className="text-base font-medium"
                    >
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        id="confirmPassword"
                        name="confirmPassword"
                        type={showCPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        required
                        className="appearance-none rounded-md block w-full px-3 py-2 pr-12 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        onClick={() => setShowCPassword(!showCPassword)}
                        className="absolute inset-y-0 right-3 flex items-center "
                      >
                        {showCPassword ? (
                          <FaEyeSlash className="h-5 w-5 text-gray-500" />
                        ) : (
                          <FaEye className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>

                  <Button.Blue
                    type="submit"
                    className="flex w-full justify-center mt-6 py-2"
                  >
                    Reset Password
                  </Button.Blue>
                </div>
              </form>
            ) : (
              <div className="text-center mt-4">
                Your password has expired! <br />
                <button
                  onClick={() => setShowResetForm(true)}
                  className="font-semibold text-indigo-600 hover:text-indigo-500 hover:underline"
                >
                  Click here to update your password
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
