import React, { useState, useEffect, useRef } from "react";
import { BiSort, BiSortUp, BiSortDown } from "react-icons/bi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { RiArrowDropDownLine } from "react-icons/ri";
import { InternalTableHeading } from "../../components/styled/GlobalStyles";

const Table = ({
  data,
  columns,
  pageSize = 5,
  actions,
  expandableField,
  title = "",
  showDomainDropdown = false,
  domains = [],
  selectedDomain,
  setSelectedDomain
}) => {
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [expandedRows, setExpandedRows] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedDomains, setSelectedDomains] = useState(selectedDomain || []);

  const dropdownRef = useRef(null);

  // Sorting logic
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Sort data
  const sortedData = [...data].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue = a[sortConfig.key]?.toString().toLowerCase() || "";
    const bValue = b[sortConfig.key]?.toString().toLowerCase() || "";
    return sortConfig.direction === "ascending"
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  // Filter data
  const filteredData = sortedData.filter((item) => {
    if (!searchQuery && selectedDomains.length === 0) return true;

    // Filter based on selected domains
    const matchesDomain = selectedDomains.length
      ? selectedDomains.includes(item.domain)
      : true;

    return matchesDomain && Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Pagination
  const totalEntries = filteredData.length;
  const totalPages = Math.ceil(totalEntries / pageSize);
  const paginatedData = filteredData.slice(
    page * pageSize,
    (page + 1) * pageSize
  );

  const toggleExpandRow = (index) => {
    setExpandedRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleDomainChange = (domain) => {
    setSelectedDomains((prev) =>
      prev.includes(domain) ? prev.filter((item) => item !== domain) : [...prev, domain]
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col w-full bg-gray-200 p-4 rounded-lg">
      {/* Search Bar */}
      <div className="mb-4 flex items-center justify-between">
        <h1 className={InternalTableHeading}>{title}</h1>
        <div className="flex gap-4 w-full max-w-md">
          {showDomainDropdown && (
            <div className="relative" ref={dropdownRef}>
              <button
                className={`bg-blue-800 text-white px-4 py-1 rounded-md flex items-center whitespace-nowrap ${
                  dropdownVisible ? "bg-blue-900" : "bg-blue-800"
                }`}
                onClick={handleDropdownToggle}
              >
                Domain
                <RiArrowDropDownLine
                  className={`ml-1 transition-transform duration-300 ${
                    dropdownVisible ? "rotate-180" : ""
                  }`}
                  size={34}
                />
              </button>
              {dropdownVisible && (
                <div className="absolute bg-white border border-gray-300 shadow rounded-md mt-1 max-h-40 overflow-y-auto min-w-[200px]">
                  {domains.sort().map((domain, index) => (
                    <label key={index} className="flex items-center px-1 py-1 text-sm" >
                      <input
                        type="checkbox"
                        checked={selectedDomains.includes(domain)}
                        onChange={() => handleDomainChange(domain)}
                        className="mr-2"
                      />
                      {domain}
                    </label>
                  ))}
                </div>
              )}
            </div>
          )}
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setPage(0);
            }}
            className="px-4 py-2 border rounded-lg w-full"
          />
        </div>
      </div>

      <div className="overflow-x-auto bg-white shadow rounded-lg">
        {totalEntries === 0 ? (
          <div className="p-4 text-center text-gray-800">No result found</div>
        ) : (
          <table className="min-w-full bg-white z-10">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className="px-4 py-3 border-b-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                    onClick={() => column.sortable && requestSort(column.key)}
                  >
                    <div className="flex items-center justify-between">
                      {column.label}
                      {column.sortable && (
                        <span className="ml-2">
                          {sortConfig.key === column.key ? (
                            sortConfig.direction === "ascending" ? (
                              <BiSortUp />
                            ) : (
                              <BiSortDown />
                            )
                          ) : (
                            <BiSort />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
                {actions && <th className="px-4 py-3 border-b-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr key={index} className="border-b">
                  {columns.map((column) => (
                    <td key={column.key} className="px-4 py-3">
                      {column.type === 'dropdown' ? (
                        <Select
                          options={column.options}
                          value={column.value ? column.value(item) : item[column.key]}
                          onChange={(selectedOption) => column.onChange(selectedOption, item)}
                          isMulti={column.isMulti}
                          closeMenuOnSelect={!column.isMulti}
                          components={column.isMulti ? makeAnimated() : undefined}
                          className="min-w-[200px]"
                        />
                      ) : column.key === expandableField ? (
                        <>
                          <p>
                            {item[column.key].length > 100 ? (
                              <>
                                {expandedRows[index]
                                  ? item[column.key]
                                  : item[column.key].slice(0, 100) + "..."}
                                <button
                                  className="text-blue-500 underline ml-2"
                                  onClick={() => toggleExpandRow(index)}
                                >
                                  {expandedRows[index] ? "Show Less" : "Show More"}
                                </button>
                              </>
                            ) : (
                              item[column.key]
                            )}
                          </p>
                        </>
                      ) : column.render ? (
                        column.render(item[column.key], item)
                      ) : (
                        item[column.key]
                      )}
                    </td>
                  ))}
                  {actions && actions.length > 0 && (
                    <td className="px-4 py-3 flex gap-2">
                      {actions.map((action, actionIndex) => (
                        <div key={actionIndex} className="group relative">
                          {(!action.hidden || !action.hidden(item)) && (
                            <>
                              <button
                                className={action.className}
                                onClick={() => action.handler(item)}
                                disabled={action.isDisabled && action.isDisabled(item)}
                              >
                                {action.label}
                                {typeof action.icon === 'function' ? action.icon(item) : action.icon}
                              </button>
                              {/* Simple Tooltip */}
                              {action.tooltip && 
                              <div className="absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 
                                            transition-opacity duration-200 bottom-full left-1/2 -translate-x-1/2 mb-1 
                                            px-2 py-1 text-xs text-white bg-gray-800 rounded whitespace-nowrap">
                                {action.tooltip}
                              </div>}
                            </>
                          )}
                        </div>
                      ))}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {totalPages > 1 && (
          <div className="px-4 py-3 bg-white border-t flex justify-between items-center">
            <span className="text-sm text-gray-700">
              Showing {page * pageSize + 1} to {Math.min((page + 1) * pageSize, totalEntries)} of{" "}
              {totalEntries} entries
            </span>
            <div className="flex items-center gap-2">
              <button
                className="px-4 py-2 text-white bg-indigo-600 rounded-lg disabled:opacity-50"
                onClick={() => setPage(page - 1)}
                disabled={page === 0}
              >
                Prev
              </button>
              <span className="text-sm">{page + 1} of {totalPages}</span>
              <button
                className="px-4 py-2 text-white bg-indigo-600 rounded-lg disabled:opacity-50"
                onClick={() => setPage(page + 1)}
                disabled={page >= totalPages - 1}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
