import { useSelector, useDispatch } from 'react-redux';
import MultiStepForm from '../styled/MultiStepForm';
import AddDatasetForm from './AddDatasetForm';
import AddVersionForm from './AddVersionForm';
import UploadDetails from './AddFilesForm';
import { useEffect } from 'react';
import { setCurrentPage, resetForm } from '../../redux/formSlice';
const MyVersionCreate = () => {
  const formData = useSelector((state) => state.form);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPage(1));
    dispatch(resetForm());
  }, [])
  const formPages = [
    AddVersionForm,
    UploadDetails
  ];

  return (
    <MultiStepForm 
      formPages={formPages}
      formData={formData}
    />
  );
};

export default MyVersionCreate;