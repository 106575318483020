import notify from "../packages/toastify/Notify";
import { sendContactUsMessageService } from "../services/ContactUsService";

export const sendContactUsMessage = async (name, email, message) => {
    try {
        const response = await sendContactUsMessageService(name, email, message);
        if (response.status === 200) {
            notify("Message sent successfully", "success");
            return true;
        }
        else return false;
    } catch (err) {
        if (err.response) {
            switch (err.status) {
            case 404:
                notify(`${err.response.data.message}`, "error");
                break;
            case 500:
                notify(`${err.response.data.message}`, "error");
                break;
            case 503:
                notify(`${err.response.data.message}`, "error");
                break;
            default:
                notify(`Something went wrong. Please try again.`, "error");
            }
        }
        return err
    }
};