import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDatasetDetails, nextPage } from "../../redux/formSlice";
import { Modal } from "../styled/Modal";
import { handleFetchDomains, handleFetchEmailSuggestions, handleFetchDuas } from "../../controllers/datasetController";
import { handleCreateDataset } from "../../controllers/datasetController";
import { createVendorPayout, validateKycAccount } from "../../services/PaymentService";
import notify from "../../packages/toastify/Notify";
import { MarkdownModal } from "../../components/dua/ViewDuaModal";

const AddDatasetForm = ({ setCurrentStep }) => {
  const dispatch = useDispatch();
  const datasetDetails = useSelector((state) => state.form.datasetDetails);
  const [descriptionLines, setDescriptionLines] = useState(datasetDetails.datasetDescription || [""]);
  const [isBankDetailsModalOpen, setIsBankDetailsModalOpen] = useState(false);
  const [isKycProcessingModalOpen, setIsKycProcessingModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [ownerEmailSuggestions, setOwnerEmailSuggestions] = useState([]);
  const [communicationEmailSuggestions, setCommunicationEmailSuggestions] = useState([]);
  const [showOwnerSuggestions, setShowOwnerSuggestions] = useState(false);
  const [showCommunicationSuggestions, setShowCommunicationSuggestions] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [markdownContent, setMarkdownContent] = useState("");
  const [bankDetails, setBankDetails] = useState({
    accountNumber: "",
    ifscCode: "",
    accountHolderName: "",
    bankName: "",
    branchName: "",
    mobileNumber: "",
    payment_method: "Beneficiary"
  });
  const [domainLst, setDomainLst] = useState([]);
  const [duaLst, setDuaLst] = useState([]);
  const [defaultEmail, setDefaultEmail] = useState();
  const [duaDescription, setduaDescription] = useState("");
  const [defaultName, setDefaultName] = useState();
  const [defaultOwnerEmail, setDefaultOwnerEmail] = useState()
  const [defaultOwnerName, setDefaultOwnerName] = useState();
  const userName = useSelector((state) => state.auth?.userName || '');
  const firstName = useSelector((state) => state.auth?.firstName || '');
  const lastName = useSelector((state) => state.auth?.lastName || '');
  const currentPage = useSelector((state) => state.form.currentPage);
  const filled = useSelector((state) => state.form.datasetDetails.filled);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const suggestionListRef = useRef(null);
  const InvalidFormStyle =
    "bg-red-100 border border-red-500 text-red-900 placeholder-red-700 text-xs rounded-md focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400";

  const validEmail = (email) => {
    return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email));
  };

  const validHtmlTag = (value) => {
    return (/<\/?[^>]+>/.test(value)) 
  };
  
  const validateForm = () => {
    const newErrors = { ...errors };
    if (!datasetDetails.ownerEmail) newErrors.ownerEmail = "Owner Email is required";
    if (!datasetDetails.datasetOverview) newErrors.datasetOverview = "Dataset Overview is required."
    if (!datasetDetails.domain) newErrors.domain = "Domain is required";
    if (!datasetDetails.dua) newErrors.dua = "DUA is required";
    if (!datasetDetails.datasetName) newErrors.datasetName = "Dataset Name is required";
    if (!datasetDetails.datasetUsage) newErrors.datasetUsage = "Dataset Usage is required.";
    if (!datasetDetails.contentType) newErrors.contentType = "Content Type is required";
    if (!datasetDetails.datasetCategory) newErrors.datasetCategory = "Dataset Category is required";
    if (descriptionLines.some(line => !line.trim())) newErrors.descriptionLines = "All description lines must be filled";
    else if (validHtmlTag(descriptionLines)) newErrors.descriptionLines = "Should not contain HTML tags.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Add separate validation functions

  const handleFieldValidation = (name, value) => {
    const newErrors = { ...errors };
    switch (name) {
      case "ownerEmail":
        if (!value) newErrors.ownerEmail = "Owner Email is required";
        else if (!validEmail(value)) newErrors.ownerEmail = "Not a valid email";
        else if (!(ownerEmailSuggestions.includes(value) || value === defaultOwnerEmail)) newErrors.ownerEmail = "Not a registered user.";
        else delete newErrors.ownerEmail;
        break;

      case "datasetOverview":
        if (!value) newErrors.datasetOverview = "Dataset Overview is required.";
        else if (value.length < 10) newErrors.datasetOverview = "Dataset Overview must be greater than 10 characters long.";
        else if (value.length > 150) newErrors.datasetOverview = "Dataset Overview must be less than 150 characters long.";
        else if (validHtmlTag(value)) {
          if (newErrors.datasetOverview) newErrors.datasetOverview += " Should not contain HTML tags.";
          else newErrors.datasetOverview = "Should not contain HTML tags.";
        }
        else delete newErrors.datasetOverview;
        break;

      case "communicationEmail":
        if (!value) newErrors.communicationEmail = "Coordinator Email is required";
        else if (!validEmail(value)) newErrors.communicationEmail = "Not a valid email";
        else if (!(communicationEmailSuggestions.includes(value) || value === defaultEmail)) newErrors.communicationEmail = "Not a registered user.";
        else delete newErrors.communicationEmail;
        break;

      case "domain":
        if (!value) newErrors.domain = "Domain is required";
        else delete newErrors.domain;
        break;

      case "dua":
        if (!value) newErrors.dua = "DUA is required";
        else delete newErrors.dua;
        break;

      case "datasetName":
        if (!value) newErrors.datasetName = "Dataset Name is required";
        else if (validHtmlTag(value)) newErrors.datasetName = "Should not contain HTML tags.";
        else delete newErrors.datasetName;
        break;

      case "datasetUsage":
        if (!value) newErrors.datasetUsage = "Dataset Usage is required.";
        else if (value.length < 10) newErrors.datasetUsage = "Dataset Usage must be greater than 10 characters long.";
        else if (value.length > 500) newErrors.datasetUsage = "Dataset Usage must be less than 500 characters long.";
        else if (validHtmlTag(value)) {
          if (newErrors.datasetUsage) newErrors.datasetUsage += " Should not contain HTML tags.";
          else newErrors.datasetUsage = "Should not contain HTML tags.";
        }
        else delete newErrors.datasetUsage;
        break;

      case "contentType":
        if (!value) newErrors.contentType = "Content Type is required";
        else if (value.length < 10) newErrors.contentType = "Content Type must be greater than 10 characters long.";
        else if (value.length > 150) newErrors.contentType = "Content Type must be less than 150 characters long.";
        else if (validHtmlTag(value)) {
          if (newErrors.contentType) newErrors.contentType += " Should not contain HTML tags.";
          else newErrors.contentType = "Should not contain HTML tags.";
        }
        else delete newErrors.contentType;
        break;

      case "datasetCategory":
        if (!value) newErrors.datasetCategory = "Dataset Category is required";
        else delete newErrors.datasetCategory;
        break;

      case "descriptionLines": 
        if (!value) newErrors.descriptionLines = "Description is required.";
        else if (!Array.isArray(value)) newErrors.descriptionLines = "Description must be a list.";
        else {
          delete newErrors.descriptionLines;
          for (const line of value) {
            if (typeof line !== 'string' || line.length < 10 || validHtmlTag(line)) {
              newErrors.descriptionLines = "Each line must be at least 10 characters long and not contain HTML tags.";
              break;
            }
          }
        }
        break;
        
      default:
        break;
    }
    setErrors(newErrors);
  };

  useEffect(() => {
    const newErrors = { ...errors };
    const descriptionLinesContainsHTML = (validHtmlTag(datasetDetails.descriptionLines))
    if (datasetDetails.communicationEmail && (validEmail(datasetDetails.communicationEmail)) && (communicationEmailSuggestions.includes(datasetDetails.communicationEmail))) delete newErrors.communicationEmail
    if (datasetDetails.ownerEmail && (validEmail(datasetDetails.ownerEmail)) && (ownerEmailSuggestions.includes(datasetDetails.ownerEmail))) delete newErrors.ownerEmail
    if (descriptionLines.some(line => !line.trim()) && !descriptionLinesContainsHTML) delete newErrors.descriptionLines;
    setErrors(newErrors);
  }, [datasetDetails, descriptionLines]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedDetails = { ...datasetDetails, [name]: value };

    if (name === "domain") {
      const selectedDomain = domainLst.find(domain => domain.domain_id === value);
      if (selectedDomain) {
        updatedDetails.domain = selectedDomain.domain_id;
        updatedDetails.domainName = selectedDomain.domain_name;
      }
    }

    if (name === "dua") {
      const selectedDua = duaLst.find(dua => dua.dua_id === value);
      if (selectedDua) {
        updatedDetails.dua = selectedDua.dua_id;
        updatedDetails.duaName = selectedDua.dua_title;
        updatedDetails.duaDescription = selectedDua.md_data;

        setduaDescription(updatedDetails.duaDescription);
        // setMarkdownContent(selectedDua.md_data);
        // setIsViewModalOpen(true);
      }
    }


    dispatch(setDatasetDetails(updatedDetails));
  };

  const handleViewDUADetails = () => {
    if (duaDescription) {
      setMarkdownContent(duaDescription);
      setIsViewModalOpen(true);
    }
  };

  const handleChangeWithValidation = (e) => {
    const { name, value } = e.target;    
    handleChange(e);
    handleFieldValidation(name, value);
  };

  

  useEffect(() => {
    const initialize = async () => {
      try {
        handleFetchDomains(true)
          .then(domainList => {
            if (domainList && domainList.domains) {
              const filteredDomains = domainList.domains.filter(domain => 
                domain.duas && domain.duas.length > 0
              );
              setDomainLst(filteredDomains);
            }
          })
          .catch(error => {
            console.error('Error fetching domains:', error);
          });
      } catch (error) {
        console.error("Error initializing:", error);
      }
    };
    localStorage.removeItem('datasetId');

    initialize();
  }, [userName, firstName, lastName]); // Removed datasetDetails from dependencies

  useEffect(() => {
    if (datasetDetails.domain) {
      const selectedDomain = domainLst.find(domain => 
        domain.domain_id === datasetDetails.domain
      );

      if (selectedDomain && selectedDomain.duas) {
        setDuaLst(selectedDomain.duas);
      } else {
        setDuaLst([]); // Reset DUA list if no domain selected or no DUAs available
      }
    }
  }, [datasetDetails.domain, domainLst]);


  const fetchEmailSuggestions = async (query, emailType) => {
    try {
      const response = await handleFetchEmailSuggestions(query);
      if (response.error === false && response.message) {
        const emailList = response.message.map(item => item.username);
        if (emailType === "ownerEmail") {
          setOwnerEmailSuggestions(emailList);
        } else if (emailType === "communicationEmail") {
          setCommunicationEmailSuggestions(emailList);
        }
      } else {
        if (emailType === "ownerEmail") {
          setOwnerEmailSuggestions([]);
        } else if (emailType === "communicationEmail") {
          setCommunicationEmailSuggestions([]);
        }
      }
    } catch (error) {
      console.error("Error fetching email suggestions:", error);
      if (emailType === "ownerEmail") {
        setOwnerEmailSuggestions([]);
      } else if (emailType === "communicationEmail") {
        setCommunicationEmailSuggestions([]);
      }
    }
  };

  const handleEmailSuggestions = (query, emailType) => {
    if (query.length > 2) {
      fetchEmailSuggestions(query, emailType);
      if (emailType === "ownerEmail") {
        setShowOwnerSuggestions(true);
      } else if (emailType === "communicationEmail") {
        setShowCommunicationSuggestions(true);
      }
    } else {
      if (emailType === "ownerEmail") {
        setShowOwnerSuggestions(false);
      } else if (emailType === "communicationEmail") {
        setShowCommunicationSuggestions(false);
      }
    }
  };

  const handleEmailBlur = (emailType, defaultEmail1, defaultName1) => {
    if (!datasetDetails[emailType]?.trim()) {
      const updatedDetails = {
        ...datasetDetails,
        [emailType]: defaultEmail1,
      };
      if (emailType === "ownerEmail") {
        updatedDetails.ownerName = defaultName1;
      } else if (emailType === "communicationEmail") {
        updatedDetails.cordinatorName = defaultName1;
      }
      dispatch(setDatasetDetails(updatedDetails));
    }
  };

  const handleOwnerEmailBlur = () => handleEmailBlur("ownerEmail", defaultOwnerEmail, defaultOwnerName);
  const handleCommunicationEmailBlur = () => handleEmailBlur("communicationEmail", defaultEmail, defaultName);

  const handleEmailChange = (e, emailType) => {
    handleChangeWithValidation(e);
    handleEmailSuggestions(e.target.value, emailType);
  };

  const handleCombinedSuggestionClick = async (email, emailType) => {
    try {
      const updatedDetails = { ...datasetDetails };
      const response = await handleFetchEmailSuggestions(email, false);
      if (response.message && response.message[0]) {
        const fullName = `${response.message[0].firstname} ${response.message[0].lastname}`;
        const userId = response.message[0].user_id;
        if (emailType === "ownerEmail") {
          updatedDetails.ownerEmail = email;
          updatedDetails.ownerName = fullName;
          updatedDetails.ownerId = userId;
        } else if (emailType === "communicationEmail") {
          updatedDetails.communicationEmail = email;
          updatedDetails.cordinatorName = fullName;
          updatedDetails.coordinatorId = userId;
        }
      }
      dispatch(setDatasetDetails(updatedDetails));
      setShowOwnerSuggestions(false);
      setShowCommunicationSuggestions(false);
      setActiveSuggestionIndex(-1); // Reset active index
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleDescriptionChange = (index, value) => {
    const newLines = [...descriptionLines];
    newLines[index] = value;
    setDescriptionLines(newLines);
    handleFieldValidation("descriptionLines", newLines);
  };

  const addDescriptionLine = () => {
    setDescriptionLines([...descriptionLines, ""]);
  };

  const removeDescriptionLine = (indexToRemove) => {
    setDescriptionLines((prevLines) =>
      prevLines.filter((_, index) => index !== indexToRemove)
    );
  };


  const handleCategoryChange = (e) => {
    handleChange(e);
    handleChangeWithValidation(e);
    if (e.target.value === "paid") {
      setIsBankDetailsModalOpen(true);
    } else {
      setIsBankDetailsModalOpen(false);
    }
  };

  const handleBankDetailsChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  };

  const handleSaveBankDetails = async () => {
    try {
      const transformedBankDetails = {
        bank_name: bankDetails.bankName,
        account_number: bankDetails.accountNumber,
        account_holder_name: bankDetails.accountHolderName,
        ifsc_code: bankDetails.ifscCode,
        branch_name: bankDetails.branchName,
        mobile_number: bankDetails.mobileNumber,
        payment_method: bankDetails.payment_method,
      };
      setIsKycProcessingModalOpen(true);
      console.log("Creating vendor payout...");
      const response = await createVendorPayout(JSON.stringify(transformedBankDetails));
      if (response?.payout?.status === "created") {
        console.log("Vendor payout created successfully. Validating KYC account...");

        // Show KYC processing modal
        // setIsKycProcessingModalOpen(true);

        const kycResponse = await validateKycAccount();
        console.log("KYC account validation response:", kycResponse);

        // Close KYC modal after processing is complete
        setIsKycProcessingModalOpen(false);
      } else {
        console.error("Vendor payout creation failed or status is not 'created'.");
      }

      setIsBankDetailsModalOpen(false);
    } catch (error) {
      console.error("Error during bank details processing:", error.message);
      // Close KYC modal in case of an error
      setIsKycProcessingModalOpen(false);
    }
  };


  const handleNext = async () => {
    if (validateForm()) {
      try {
        if (!filled) {
          console.log("Form is valid. Proceed to the next step.");
          const updatedDatasetDetails = {
            ...datasetDetails,
            datasetDescription: descriptionLines,
          };
          const respDatasetCreation = await handleCreateDataset(updatedDatasetDetails);
          if (respDatasetCreation) {
            dispatch(
              setDatasetDetails({
                ...updatedDatasetDetails,
                datasetId: respDatasetCreation.dataset.dataset_id,
                filled: true
              })
            );
          }
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        dispatch(nextPage());
        setCurrentStep(currentPage + 1);
      } catch (error) {
        console.error("Failed to send details to the backend", error);
      }
    } else {
      notify("Please fill all required fields correctly.", "error");
    }
  };

  const handleKeyDown = (e, emailType) => {
    if (emailType === "ownerEmail" && showOwnerSuggestions && ownerEmailSuggestions.length > 0) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex < ownerEmailSuggestions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : ownerEmailSuggestions.length - 1
        );
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (activeSuggestionIndex >= 0 && activeSuggestionIndex < ownerEmailSuggestions.length) {
          const selectedEmail = ownerEmailSuggestions[activeSuggestionIndex];
          handleCombinedSuggestionClick(selectedEmail, emailType);
        }
      } else if (e.key === "Escape") {
        setShowOwnerSuggestions(false);
        setActiveSuggestionIndex(-1);
      }
    } else if (emailType === "communicationEmail" && showCommunicationSuggestions && communicationEmailSuggestions.length > 0) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex < communicationEmailSuggestions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : communicationEmailSuggestions.length - 1
        );
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (activeSuggestionIndex >= 0 && activeSuggestionIndex < communicationEmailSuggestions.length) {
          const selectedEmail = communicationEmailSuggestions[activeSuggestionIndex];
          handleCombinedSuggestionClick(selectedEmail, emailType);
        }
      } else if (e.key === "Escape") {
        setShowCommunicationSuggestions(false);
        setActiveSuggestionIndex(-1);
      }
    }
  };

  return (
    <>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          {filled &&
            <div className="text-red-600 text-center font-medium mb-4">
              Page already filled. You can edit later under "My datasets" page in "My dashboard"
            </div>}
          <div className="mt-10 mx-4 grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label className="block text-sm/6 font-medium text-gray-900">
                Domain <span className="text-red-500">{"*"}</span>
              </label>
              <select
                disabled={filled}
                name="domain"
                value={datasetDetails.domain || ""}
                onChange={handleChangeWithValidation}
                required
                className={
                  errors.domain
                    ? InvalidFormStyle
                    :"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                }
                 >
                <option value="">Select Domain</option>
                {domainLst.map((domain) => (
                  <option key={domain.domain_id} value={domain.domain_id}>
                    {domain.domain_name}
                  </option>
                ))}
              </select>
              {errors.domain && <p className="text-red-500 text-xs mt-1">{errors.domain}</p>}
            </div>
            <div className="sm:col-span-3">
              <label className="block text-sm/6 font-medium text-gray-900">
                DUA
              </label>
              <select
                name="dua"
                value={datasetDetails.dua || ""}
                onChange={handleChangeWithValidation}
                className={
                  errors.dua
                    ? InvalidFormStyle
                    : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                }
                disabled={!datasetDetails.domain || errors.domain || filled}
              >
                <option value="">Select DUA</option>
                {duaLst.map((dua) => (
                  <option key={dua.dua_id} value={dua.dua_id}>
                    {dua.dua_title}
                  </option>
                ))}
              </select>
              {duaDescription!="" && (
                <button
                  type="button"
                  className="text-blue-500 hover:text-blue-700"
                  onClick={handleViewDUADetails}
                  title="View DUA Details"
                >
                  <p className="text-blue-500 text-xs mt-1">View the selected Dua</p>
                </button>
              )}
              {!datasetDetails.domain && (
                <p className="text-blue-500 text-xs mt-1">Please select a domain first.</p>
              )}
              {(errors.dua || errors.domain) && <p className="text-red-500 text-xs mt-1">{errors.dua}</p>}
            </div>
            <div className="sm:col-span-3">
              <label className="block text-sm/6 font-medium text-gray-900">
                Owner Email <span className="text-red-500">{"*"} </span>  <span className="text-blue-500 text-xs mt-1">(Is Editable)</span>
              </label>
              <input
                disabled={filled}
                name="ownerEmail"
                value={datasetDetails.ownerEmail}
                onChange={(e) => handleEmailChange(e, "ownerEmail")}
                onBlur={handleOwnerEmailBlur}
                onKeyDown={(e) => handleKeyDown(e, "ownerEmail")}
                placeholder="Owner Email"
                required
                className={
                  errors.ownerEmail
                    ? InvalidFormStyle
                    : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                }
              />
              {showOwnerSuggestions && ownerEmailSuggestions.length > 0 && (
                <ul className="border border-gray-300 rounded-md mt-1 bg-white" ref={suggestionListRef}>
                  {ownerEmailSuggestions?.map((email, index) => (
                    <li
                      disabled={filled}
                      key={index}
                      onClick={() => handleCombinedSuggestionClick(email, "ownerEmail")}
                      className={`px-4 py-2 cursor-pointer hover:bg-gray-200 ${index === activeSuggestionIndex ? "bg-gray-200" : ""
                        }`}
                    >
                      {email}
                    </li>
                  ))}
                </ul>
              )}
              {errors.ownerEmail && <p className="text-red-500 text-xs mt-1">{errors.ownerEmail}</p>}
            </div>
            <div className="sm:col-span-3">
              <label className="block text-sm/6 font-medium text-gray-900">
                Owner Name
              </label>
              <input
                name="ownerName"
                value={datasetDetails.ownerName}
                disabled
                // onChange={handleChange}
                placeholder="Owner Name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
              />
              {errors.ownerName && <p className="text-red-500 text-xs mt-1">{errors.ownerName}</p>}
            </div>

            <div className="sm:col-span-4">
              <label className="block text-sm/6 font-medium text-gray-900">
                Dataset Name <span className="text-red-500">{"*"}</span>
              </label>
              <input
                disabled={filled}
                name="datasetName"
                value={datasetDetails.datasetName}
                onChange={handleChangeWithValidation}
                placeholder="Dataset Name"
                required
                className={
                  errors.datasetName
                    ? InvalidFormStyle
                    : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                }
              />
              {errors.datasetName && <p className="text-red-500 text-xs mt-1">{errors.datasetName}</p>}

            </div>
            <div className="sm:col-span-6">
              <label className="block text-sm/6 font-medium text-gray-900">
                Dataset Overview <span className="text-red-500">{"*"}</span>
              </label>
              <input
                disabled={filled}
                name="datasetOverview"
                value={datasetDetails.datasetOverview}
                onChange={handleChangeWithValidation}
                placeholder="Dataset Overview"
                required
                className={
                  errors.datasetOverview
                    ? InvalidFormStyle
                    : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                }
              />
              {errors.datasetOverview && <p className="text-red-500 text-xs mt-1">{errors.datasetOverview}</p>}
            </div>
            <div className="sm:col-span-6">
              <label className="block text-sm/6 font-medium text-gray-900">
                Dataset Usage <span className="text-red-500">{"*"}</span>
              </label>
              <input
                disabled={filled}
                name="datasetUsage"
                value={datasetDetails.datasetUsage}
                onChange={handleChangeWithValidation}
                placeholder="Dataset Usage"
                required
                className={
                  errors.datasetUsage
                    ? InvalidFormStyle
                    : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                }
              />
              {errors.datasetUsage && <p className="text-red-500 text-xs mt-1">{errors.datasetUsage}</p>}
            </div>
            <div className="sm:col-span-6">
              <label className="block text-sm/6 font-medium text-gray-900">
                Content Type <span className="text-red-500">{"*"}</span>
              </label>
              <input
                disabled={filled}
                name="contentType"
                value={datasetDetails.contentType}
                onChange={handleChangeWithValidation}
                placeholder="Content Type"
                required
                className={
                  errors.contentType
                    ? InvalidFormStyle
                    : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                }
              />
              {errors.contentType && <p className="text-red-500 text-xs mt-1">{errors.contentType}</p>}
            </div>
            <div className="sm:col-span-6">
              <label className="block text-sm/6 font-medium text-gray-900">
                Dataset Description <span className="text-red-500">{"*"}</span>
              </label>
              <div className="space-y-2">
                {descriptionLines.map((line, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <textarea
                      disabled={filled}
                      type="text"
                      value={line}
                      required
                      onChange={(e) =>handleDescriptionChange(index,e.target.value.replaceAll(";", "").replaceAll("'", ""))}
                      placeholder="Dataset Description"
                      rows="2"
                      className={
                        (!line.trim() && descriptionLines.length > 1) || errors.descriptionLines
                          ? InvalidFormStyle
                          : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      }
                    ></textarea>
                    <div className="flex space-x-2">
                      {index === descriptionLines.length - 1 && (
                        <button
                          disabled={filled}
                          type="button"
                          onClick={addDescriptionLine}
                          className="px-6 py-1 bg-blue-500 text-xl text-white rounded hover:bg-blue-600"
                        >
                          +
                        </button>
                      )}
                      {descriptionLines.length > 1 && (
                        <button
                          disabled={filled}
                          type="button"
                          onClick={() => removeDescriptionLine(index)}
                          className="px-6 py-1 bg-red-500 text-xl text-white rounded hover:bg-red-600"
                        >
                          -
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                {errors.descriptionLines && <p className="text-red-500 text-xs mt-1">{errors.descriptionLines}</p>}

              </div>
            </div>
            <div className="sm:col-span-6">
              <p className="block text-sm/6 font-medium text-gray-900">
                Select Dataset Category <span className="text-red-500">{"*"}</span>
              </p>
              <div className="flex items-center space-x-4">
                <label className="inline-flex items-center">
                  <input
                    disabled={filled}
                    type="radio"
                    name="datasetCategory"
                    value="public"
                    required
                    checked={datasetDetails.datasetCategory === "public"}
                    onChange={handleCategoryChange}
                    className="form-radio"
                  />
                  <span className="ml-2">Public</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    disabled={filled}
                    type="radio"
                    name="datasetCategory"
                    value="private"
                    checked={datasetDetails.datasetCategory === "private"}
                    onChange={handleCategoryChange}
                    className="form-radio"
                  />
                  <span className="ml-2">Private</span>
                </label>
                {/* This is commented for the future purpose it will be used when Payment integration is comepleted */}
                {/* <label className="inline-flex items-center">
                  <input
                    disabled={filled}
                    type="radio"
                    name="datasetCategory"
                    value="paid"
                    checked={datasetDetails.datasetCategory === "paid"}
                    onChange={handleCategoryChange}
                    className="form-radio"
                  />
                  <span className="ml-2">Paid</span>
                </label> */}
                {errors.datasetCategory && <p className="text-red-500 text-xs mt-1">{errors.datasetCategory}</p>}

              </div>
            </div>
            {datasetDetails.datasetCategory === "private" && (
              <>
                <div className="space-y-4 sm:col-span-3">
                  <label className="block text-sm/6 font-medium text-gray-900">
                    Communication/Coordinator Email  <span className="text-blue-500 text-xs mt-1">(Is Editable)</span>
                  </label>
                  <input
                    disabled={filled}
                    name="communicationEmail"
                    value={datasetDetails.communicationEmail}
                    onChange={(e) => handleEmailChange(e, "communicationEmail")}
                    onBlur={handleCommunicationEmailBlur}
                    onKeyDown={(e) => handleKeyDown(e, "communicationEmail")}
                    placeholder="Dataset Coordinator Email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  />
                  {showCommunicationSuggestions && communicationEmailSuggestions.length > 0 && (
                    <ul className="border border-gray-300 rounded-md mt-1 bg-white" ref={suggestionListRef}>
                      {communicationEmailSuggestions?.map((email, index) => (
                        <li
                          disabled={filled}
                          key={index}
                          onClick={() => handleCombinedSuggestionClick(email, "communicationEmail")}
                          className={`px-4 py-2 cursor-pointer hover:bg-gray-200 ${index === activeSuggestionIndex ? "bg-gray-200" : ""
                            }`}
                        >
                          {email}
                        </li>
                      ))}
                    </ul>
                  )}
                  {errors.communicationEmail && <p className="text-red-500 text-xs mt-1">{errors.communicationEmail}</p>}

                </div>
                <div className="space-y-4 sm:col-span-3 ">
                  <label className="block text-sm/6 font-medium text-gray-900">
                    Coordinator Name
                  </label>
                  <input
                    name="cordinatorName"
                    value={datasetDetails.cordinatorName}
                    disabled
                    placeholder="Dataset Coordinator Name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm" />
                </div>
              </>
            )}
            {datasetDetails.datasetCategory === "paid" && (
              <Modal
                isOpen={isBankDetailsModalOpen}
                onCancel={() => setIsBankDetailsModalOpen(false)}
                onSave={handleSaveBankDetails}
                title="Add your Bank Account Details"
              >
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm/6 font-medium text-gray-900">
                      Account Number <span className="text-red-500">{"*"}</span>
                    </label>
                    <input
                      name="accountNumber"
                      value={bankDetails.accountNumber}
                      onChange={handleBankDetailsChange}
                      placeholder="Account Number"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm/6 font-medium text-gray-900">
                      IFSC Code <span className="text-red-500">{"*"}</span>
                    </label>
                    <input
                      name="ifscCode"
                      value={bankDetails.ifscCode}
                      onChange={handleBankDetailsChange}
                      placeholder="IFSC Code"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm/6 font-medium text-gray-900">
                      Account Holder's Name <span className="text-red-500">{"*"}</span>
                    </label>
                    <input
                      name="accountHolderName"
                      value={bankDetails.accountHolderName}
                      onChange={handleBankDetailsChange}
                      placeholder="Account Holder's Name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm/6 font-medium text-gray-900">
                      Bank Name <span className="text-red-500">{"*"}</span>
                    </label>
                    <input
                      name="bankName"
                      value={bankDetails.bankName}
                      onChange={handleBankDetailsChange}
                      placeholder="Bank Name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm/6 font-medium text-gray-900">
                      Branch Name <span className="text-red-500">{"*"}</span>
                    </label>
                    <input
                      name="branchName"
                      value={bankDetails.branchName}
                      onChange={handleBankDetailsChange}
                      placeholder="Branch Name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm/6 font-medium text-gray-900">
                      Mobile Number <span className="text-red-500">{"*"}</span>
                    </label>
                    <input
                      name="mobileNumber"
                      value={bankDetails.mobileNumber}
                      onChange={handleBankDetailsChange}
                      placeholder="Mobile Number"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      required
                    />
                  </div>
                </div>
              </Modal>
            )}
            {isKycProcessingModalOpen && (
              <Modal
                isOpen={isKycProcessingModalOpen}
                onCancel={null}
                onSave={null}
                title="Processing KYC"
              >
                <div className="space-y-4">
                  <p className="text-center">KYC is being processed. Please wait...</p>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            onClick={handleNext}
            className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Next
          </button>
        </div>
      </div>
          
      <MarkdownModal
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        title="DUA Description"
        markdownContent={markdownContent}
      />

    </>
  );
};

export default AddDatasetForm;
