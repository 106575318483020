import { TOAST_VARIANTS } from '../../src/packages/utils/constants';
import notify from '../packages/toastify/Notify';
import { getDomains, getDuas, createDataset, createDatasetVersion, fetchEmailSuggestions, getDatasetAnalytics } from '../services/DatasetService';


export const handleFetchDomains = async (withDuas = false, addToast) => {
  try {
    const domains = await getDomains(withDuas);
    return domains;
  } catch (error) {
    addToast({
      message: `Failed to fetch domains: ${error.message}`,
      variant: TOAST_VARIANTS.ERROR
    });
    throw error;
  }
};


export const handleFetchDuas = async (domain_id, addToast) => {
    try {
      const duas = await getDuas(domain_id);
      return duas;
    } catch (error) {
      addToast({
        message: `Failed to fetch duas: ${error.message}`,
        variant: TOAST_VARIANTS.ERROR
      });
      throw error;
    }
};

export const handleCreateDataset = async (datasetData) => {
  try {
    const newDataset = await createDataset(datasetData);
    notify('Dataset created successfully','success');
    return newDataset;
  } catch (error) {
    notify(`Failed to create dataset: ${error.message}`, 'errors');
    throw error;
  }
};


export const handleCreateDatasetVersion = async (datasetVersionData) => {
  try {
    const newDataset = await createDatasetVersion(datasetVersionData);
    notify('Dataset version created successfully', 'success');
    return newDataset;
  } catch (error) {
    notify(`Failed to create dataset version: ${error.message}`, 'errors');
    throw error;
  }
};

export const handleFetchEmailSuggestions = async (query, partial) => {
  try {
    const response = await fetchEmailSuggestions(query, partial);
    return response.data;
  } catch (error) {
    console.error("Error fetching email suggestions:", error);
    throw error;
  }
};

export const handleFetchDatasetAnalytics = async (datasetId) => {
  try {
    const response = await getDatasetAnalytics(datasetId);
    if (response.status === 200)
      return response;
  } catch (err) {
    if (err.response) {
      console.error('Error in OTP verify API call:', err.message || err);
      switch (err.status) {
        case 404:
          notify(`${err.response.data.message}`, "error");
          break;
        case 500:
          notify(`${err.response.data.message}`, "error");
          break;
        default:
          notify(`Something went wrong. Please try again.`, "error");
      }
    }
    return err; 
  }
};