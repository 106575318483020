import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { InternalHeading } from "../../components/styled/GlobalStyles";
const MultiStepForm = ({ 
  formPages, 
  formData,
  className = "p-6 bg-white shadow-md rounded-lg" 
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const currentPage = useSelector((state) => state.form.currentPage);

  const totalSteps = formPages.length;

  const renderPage = () => {
    const PageComponent = formPages[currentStep - 1];
    return PageComponent ? (
      <PageComponent 
        formData={formData} 
        setCurrentStep={setCurrentStep}
      />
    ) : formPages[0];
  };

  return (
    <>
      <div className="flex flex-wrap w-full px-4 bg-gray-200 mb-2 rounded-lg">  
        <h1 className={InternalHeading}>Upload Dataset</h1>
      </div>
    <div className={className}>
      {/* Dynamic Progress Bar with Circles */}
      <div className="flex items-center justify-between mb-4">
        {formPages.map((_, index) => (
          <React.Fragment key={index}>
            <div
              className={`w-16 h-16 flex items-center justify-center rounded-full border-2 ${
                currentStep > index 
                  ? 'bg-blue-600 text-2xl text-white' 
                  : 'bg-white text-2xl text-blue-600'
              }`}
            >
              {index + 1}
            </div>
            {index < totalSteps - 1 && (
              <div
                className={`flex-1 h-2 ${
                  currentStep > index + 1 ? 'bg-blue-600' : 'bg-gray-300'
                }`}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="p-4 bg-gray-50 rounded-lg shadow-inner">
        {renderPage()}
      </div>
    </div>
    </>
  );
};

export default MultiStepForm;