import React, { useState, useEffect, useRef } from "react";
import DownloadComponent from "./DownloadComponent";
import axiosInstance from "../../services/AxiosInstance";
import creds from "../../creds";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import DownloadMonitor from "./DownloadMonitor";
import { setDownloadStatus, setDownloadIds } from "../../redux/downloadSlice";

const backendDU = creds.backendDU;

Modal.setAppElement("#root");

const DownloadManager = ({ databaseId, versionId, isModalOpen, onClose }) => {
  // const { status } = useDownload();
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const hasLoggedDownloadEvent = useRef(false);
  const [isDownloadAllActive, setIsDownloadAllActive] = useState(false);
  const [ isSinglefileDownload, setIsSinglefileDownload ] = useState(false);
  const dispatch = useDispatch();

  const downloadStatus = useSelector((state) => state.download.downloadStatus);

  const downloadRefs = useRef({});

  const fetchFiles = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get(
        `${backendDU}download/files/${databaseId}/${versionId}`
      );

      if (response.data.files && response.data.files.length > 0) {
        const newDownloads = response.data.files.map((file) => ({
          id: file.file_id || Date.now(),
          fileName: file.file_name,
          fileSize: file.file_size,
          fileType: file.file_type,
          status: "Ready",
        }));

        setDownloads((prevDownloads) => {
          const existingFileNames = prevDownloads.map((d) => d.fileName);
          const uniqueNewDownloads = newDownloads.filter(
            (download) => !existingFileNames.includes(download.fileName)
          );
          return [...prevDownloads, ...uniqueNewDownloads];
        });
      } else {
        setError("No files found for the given database and version");
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to fetch files");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadComplete = (downloadId, status) => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) =>
        download.id === downloadId ? { ...download, status } : download
      )
    );

    if (isDownloadAllActive) {
      const completedIndex = downloads.findIndex((d) => d.id === downloadId);

      if (completedIndex >= 0 && completedIndex < downloads.length - 1) {
        const nextDownload = downloads[completedIndex + 1];
        const nextRef = downloadRefs.current[nextDownload.id];

        if (nextRef && typeof nextRef.startDownload === "function") {
          nextRef.startDownload();
        }
      }
    }
  };

  useEffect(() => {
  if (downloadStatus === "Downloading...") {
    setIsSinglefileDownload(true);
  }
}, [downloadStatus]);

  const handleDownloadAll = async () => {
    setIsDownloadAllActive(true); // Set Download All active
    for (let i = 0; i < downloads.length; i++) {
      const currentDownload = downloads[i];
      const ref = downloadRefs.current[currentDownload.id];

      if (ref && typeof ref.startDownload === "function") {
        try {
          await ref.startDownload();

          await new Promise((resolve, reject) => {
            const interval = setInterval(() => {
              if (currentDownload.status === "Completed") {
                clearInterval(interval);
                resolve();
              } else if (currentDownload.status === "Error") {
                clearInterval(interval);
                reject(
                  new Error(
                    `Failed to download file: ${currentDownload.fileName}`
                  )
                );
              }
            }, 500);
          });
        } catch (err) {
          console.error(
            `Error while downloading file: ${currentDownload.fileName}`,
            err
          );
        }
      } else {
        console.error(
          `Invalid reference for file: ${currentDownload.fileName}`
        );
      }
    }
    setIsDownloadAllActive(false);
  };

  useEffect(() => {
    if (isDownloadAllActive) {
      const allCompleted = downloads.every(
        (download) => download.status === "Completed"
      );

      if (allCompleted) {
        const lastFile = downloads[downloads.length - 1];
        // Ensure the last file's status is "Completed"
        if (lastFile && lastFile.status === "Completed") {

          // Delay closure
          const timeout = setTimeout(() => {
            onClose();
            setIsDownloadAllActive(false);
          }, 10000);

          return () => {
            clearTimeout(timeout); // Clean up timeout if needed
          };
        }
      }
    }
  }, [downloads, isDownloadAllActive, onClose]);

  useEffect(() => {
    if (isModalOpen) {
      fetchFiles();
      dispatch(setDownloadIds({ databaseId, versionId }));
      if (!hasLoggedDownloadEvent.current) {
        hasLoggedDownloadEvent.current = true;
      }
    } else {
      hasLoggedDownloadEvent.current = false;
    }
  }, [isModalOpen, databaseId, versionId, dispatch]);

  const handleClose = () => {
    if(downloadStatus != "Downloading...") {
      onClose();
      dispatch(setDownloadStatus("Ready"));
    }
    if (downloadStatus === "Downloading...") {
      const confirmClose = window.confirm("Do you really want to cancel the download?");
      if (!confirmClose) {
        return;
      }
    }
    onClose();
    dispatch(setDownloadStatus("Ready"));
  };

  return (
    <>
    <DownloadMonitor downloadStatus={downloadStatus} />
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 },
        content: {
          maxHeight: "80vh",
          maxWidth: "700px",
          margin: "auto",
          padding: "20px",
          zIndex: 1001,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <div className="flex flex-col items-start px-4 py-3 bg-gray-100 border-b border-gray-200">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-2xl font-bold">Downloads</h2>
          <button
            onClick={handleClose}
            className="text-2xl font-bold text-gray-600 hover:text-gray-800"
          >
            &times;
          </button>
        </div>
        <span className="text-blue-500 mt-2">
          If you are downloading a large file, Please stay on this page to
          ensure it completes.
        </span>
      </div>
      <div className="p-4 overflow-y-auto flex-grow">
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          downloads.map((download) => (
            <DownloadComponent
              ref={(el) => (downloadRefs.current[download.id] = el)}
              downloadId={download.id}
              fileName={download.fileName}
              fileSize={download.fileSize}
              fileType={download.fileType}
              onComplete={handleDownloadComplete}
            />
          ))
        )}
        {error && (
          <div className="mt-2 text-sm text-red-600 text-center">{error}</div>
        )}
      </div>
      <div className="fixed-footer flex items-center justify-end p-4 border-t border-gray-200">
        <button
          onClick={handleDownloadAll}
          className={`px-4 py-2 font-semibold rounded-md ${
            isDownloadAllActive || isSinglefileDownload
              ? "bg-gray-400 text-gray-700 cursor-not-allowed"
              : "bg-blue-800 text-white hover:underline"
          }`}
          disabled={isDownloadAllActive}
        >
          {isDownloadAllActive || isSinglefileDownload ? "Download All" : "Download All"}
        </button>
      </div>
    </Modal>
    </>
  );
};
export default DownloadManager;
