import { Link } from 'react-router-dom'
import React from "react";

const SignUpVerificationFail = () => {
  return (
    <>
      <div className="text-center bg-white shadow-md rounded-lg p-6 mx-auto my-auto">
        <h1 className="text-2xl font-bold mb-4">
          Email Verification OTP expired, email verification failed !
        </h1>
        <div className="text-xl mb-2"></div>
        <Link to="/sign-in" className="text-indigo-600 hover:bg-indigo-200 mx-1">
          Click here to Signin again
        </Link>
      </div>
    </>
  )
}

export default SignUpVerificationFail;