// src/utils/regexPatterns.js

// Regex to validate email format
export const EMAIL_REGEX = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

// Regex to validate password (e.g., minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character)
export const PASSWORD_REGEX = RegExp(
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/
);


// Regex to validate names (e.g., letters only, with spaces or hyphens allowed)
export const NAME_REGEX = RegExp(/^[A-Za-z\s]*$/);

// Regex to validate institution or designation names (allowing alphanumeric characters and some special characters)
export const INSTITUTION_DESIGNATION_REGEX = RegExp(
  /^(?!^[0-9\s]+$)(?!^[@$!%*?&#.,\-'/\s]+$)[A-Za-z0-9@$!%*?&#.,\-'/\s]+$/
);
