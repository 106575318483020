import React, { useState, useEffect, useRef, useContext } from "react";
import { FaEdit, FaSave } from "react-icons/fa";
import PasswordUpdate from "./PasswordUpdate";
import {
  fetchUserDetails,
  updateUserDetails,
} from "../../services/AuthService";
import notify from "../../packages/toastify/Notify";
import { InternalHeading } from "../../components/styled/GlobalStyles";
const MyProfile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [editingField, setEditingField] = useState(null); // Tracks the field being edited
  const [updatedValue, setUpdatedValue] = useState(""); // Tracks the value being updated
  const [errorMessage, setErrorMessage] = useState("");
  const containerRef = useRef();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const getUserDetails = async () => {
    try {
      const response = await fetchUserDetails();
      const userData = response.data.message.userProfileRecord;
      setUserDetails(userData);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setEditingField(null); // Exit editing mode
        setUpdatedValue(""); // Reset the updated value
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setEditingField(null); // Exit editing mode
      setUpdatedValue(""); // Reset the updated value
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEdit = (field, value) => {
    setEditingField(field);
    setUpdatedValue(value);
    setErrorMessage("");
  };

  const handleSave = async (field) => {
    // Validation for firstname and lastname
    if (field === "firstname") {
      const nameRegex = /^[A-Za-z]+$/; // Regex to allow only alphabets, no spaces, minimum length 3
      if (!nameRegex.test(updatedValue)) {
        notify(
          "Please enter a valid First Name. It should contain only alphabets and no spaces or numbers.",
          "errors"
        );
        return;
      }
    }
    if (field === "lastname") {
      const nameRegex = /^[A-Za-z]/; // Regex to allow only alphabets, minimum length 3
      if (!nameRegex.test(updatedValue)) {
        notify(
          "Please enter a valid Last Name. It should contain only alphabets and no numbers..",
          "errors"
        );
        return;
      }
    }
    if (
      field === "user_organization_mailid" &&
      !emailRegex.test(updatedValue)
    ) {
      notify("Please enter a valid email address.", "errors");
      return;
    }
    try {
      await updateUserDetails(field, updatedValue); // Update local state
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [field]: updatedValue,
      }));

      setEditingField(null);
      setUpdatedValue("");
      setErrorMessage("");
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const displayNames = {
    firstname: "First Name",
    lastname: "Last Name",
    username: "User Mail ID",
    organization_name: "Organization Name",
    designation: "Designation",
    joined_date: "Joined Date",
    role_id: "User Role",
    role_name: "Role Name",
    createdAt: "Joined Date",
    updatedAt: "Updated At",
    user_type: "User Type",
    user_tag_line: "User Tag Line",
    user_pronouns: "Pronouns",
    user_organization_mailid: "Organization Mail ID",
    user_privileged_access: "Privileged Access",
  };

  return (
    <>
    <div className="col-span-12 flex justify-between items-center px-4 bg-gray-200 rounded-lg mb-2">
      <h1 className={InternalHeading}>My Profile</h1>
      </div>
      <div className="flex flex-row px-4">
        <div className="w-full sm:w-1/1 md:w-2/3 lg:w-2/3">
          <div className="w-full text-left align-middle">
            {userDetails && (
              <>
                {Object.entries(userDetails).map(
                  ([key, value], index) =>
                    key !== "user_id" &&
                    key !== "isActive" &&
                    key !== "user_profile_url" &&
                    key !== "user_profile_image" &&
                    key !== "role_id" &&
                    key !== "role_key" &&
                    key !== "user_role" &&
                    key !== "updatedAt" &&
                    key !== "role_description" &&
                    key !== "user_privileged_access" &&
                    key !== "user_organization_mail_verification_status" && (
                      <div key={index} className="flex flex-row flex-wrap">
                        <div className="pr-3 py-3 border-b border-gray-200 text-sm w-4/5 sm:w-3/5 md:w-1/5 lg:w-1/5 xl:w-1/5 2xl:w-1/5">
                          <span className="text-gray-900 whitespace-no-wrap">
                            {displayNames[key] || key}
                          </span>
                        </div>
                        <div className="px-3 py-3 border-b border-gray-200 text-sm w-1/5 sm:w-1/5 md:w-1/12 lg:w-1/12 xl:w-1/12 2xl:w-1/12 sm:text-auto text-center">
                          <span className="text-gray-900 whitespace-no-wrap">
                            :
                          </span>
                        </div>
                        <div className="md:px-3 py-3 border-b border-gray-200 text-sm w-4/5 sm:w-4/5 md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5">
                          {editingField === key ? (
                            key !== "username" && key !== "user_role" ? (
                              <input
                                type="text"
                                className={`border rounded p-1 w-full ${
                                  key === "user_organization_mailid" &&
                                  !emailRegex.test(updatedValue) &&
                                  updatedValue
                                    ? "border-red-500"
                                    : ""
                                }`}
                                value={updatedValue}
                                onChange={(e) =>
                                  setUpdatedValue(e.target.value)
                                }
                              />
                            ) : (
                              <span className="text-gray-900 whitespace-no-wrap">
                                {key === "createdAt" || key === "updatedAt"
                                  ? new Date(value).toLocaleString()
                                  : value}
                              </span>
                            )
                          ) : (
                            <span className="text-gray-900 whitespace-no-wrap">
                              {value === null
                                ? "Please update here"
                                : key === "createdAt" || key === "updatedAt"
                                ? new Date(value).toLocaleString()
                                : value}
                            </span>
                          )}
                        </div>
                        <div className="px-3 py-3 border-b border-gray-200 text-sm w-1/5 sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5 2xl:w-1/5 flex flex-row items-center justify-center">
                          {key !== "username" &&
                            key !== "user_role" &&
                            key !== "user_type" &&
                            key !== "role_name" &&
                            key !== "createdAt" &&
                            (editingField === key ? (
                              <FaSave
                                className="text-green-500 cursor-pointer text-base"
                                onClick={() => handleSave(key)}
                              />
                            ) : (
                              <FaEdit
                                className="text-gray-500 cursor-pointer text-base"
                                onClick={() => handleEdit(key, value)}
                              />
                            ))}
                        </div>
                      </div>
                    )
                )}
              </>
            )}
            {/* password update component start */}
            <PasswordUpdate />
            {/* password update component ends */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
