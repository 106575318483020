import axios from "axios";
import creds from "../../src/creds";
import axiosInstance from './AxiosInstance';


const backendAuth = creds.backendAuth;
const backendCore = creds.backendCore;

export const registerUserFirstPart = async (formFields) => {
  const { firstName, lastName, userName, password, appName } = formFields;
  return axios.post(backendAuth + "register", {
    firstName,
    lastName,
    userName,
    password,
    appName,
  });
};

export const registerUserSecondPart = async (formFields, userId) => {
  const { firstName, lastName, userName, organization, designation, userRole } = formFields;
  return axios.post(backendCore + "authorization/register", {
    firstName,
    lastName,
    userName,
    organization,
    designation,
    userRole,
    userId,
  });
};

export const verifyEmail = async (notification_type, emailVerificationToken) => {
    return axios.post(`${backendAuth}otp/verify`, {
        otp: emailVerificationToken,       // Adding otp to the body
        notificationType: notification_type // Adding notificationType to the body
      }
    );
};

export const login = async (userName, password, appName) => {
  return axiosInstance.post(`${backendAuth}login`, { userName, password, appName },
    { withCredentials: true }
  );
};

export const getForgotPasswordOtp = async (email,notification_type,appName) => {
  return axios.post(`${backendAuth}otp/send`, { userName: email,notificationType:notification_type.FORGOT_PASSWORD, appName:appName});
};

export const forgotPassword = async (email,newPassword,appName) => {
  return axios.post(`${backendAuth}forgot-password`, { userName: email, newPassword:newPassword, appName:appName});
};


export const expiredPasswordReset = async (email, oldPassword, newPassword,appName) => {
  return axios.post(`${backendAuth}reset-password`, {userName: email, oldPassword: oldPassword, newPassword: newPassword, appName: appName}); 
};


export const fetchUserRole = async () => {
  return axiosInstance.get(`${backendCore}authorization/fetch-user-role`
  ,{
     withCredentials:true,
   })
};

export const logout = (sessionId, userId, userName) => {
  return axiosInstance.post(`${backendAuth}logout`,{ userId: userId,  userName: userName, appName: "dfs" },
    {
      headers: {
        'session-id': sessionId
      },
      withCredentials:true,
    }
  );
};
 



