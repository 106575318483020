import React, { useState, useEffect } from "react";
import { Button } from "../../components/styled/Buttons";
import axios from "axios";
import ihubLogo from "../../assets/images/logo/ihub.png";
import CryptoJS from "crypto-js";
import creds from "../../creds";

const backendCore = creds.backendCore;

const AdminPayment = () => {
  const [amount, setAmount] = useState("");
  const [orders, setOrders] = useState([]);
  const [payments, setPayments] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingPayments, setLoadingPayments] = useState(true);
  const [activeTab, setActiveTab] = useState("payments");

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const handlePayment = async () => {
    try {
      //----- this is for order creation in payment tab ----//
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_URL}orders`,
        //--- "http://<Payment url>/api/v1/orders" ----//
        { amount, currency: "INR" },
        {
          headers: {
            "access-token": process.env.REACT_APP_ACCESS_TOKEN,
            client_UUID: process.env.REACT_APP_CLIENT_UUID,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { data } = response;
      if (data && data.data[0] && data.data[0].transaction_order_id) {
        openRazorpay(data.data[0].transaction_order_id);
      } else {
        alert("Error creating order. Please try again.");
      }
    } catch (error) {
      console.error("Payment Error:", error);
      alert("Error creating order. Please try again.");
    }
  };
  //----- this is hardecoded value of the web hook which we are not gonna use in production this is just for test ----//
  const generateSignature = (payload) => {
    const secret = "QpKJkipzWns@8T8";
    const hash = CryptoJS.createHmac("sha256", secret);
    hash.update(payload);
    return hash.digest("hex");
  };

  const openRazorpay = (order_id) => {
    if (typeof window.Razorpay === "undefined") {
      alert("Razorpay SDK failed to load. Please try again later.");
      return;
    }

    //----- Right now these are the hardecoded values that are the buyer details we need to update for production build ----//
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: amount * 100,
      currency: "INR",
      name: "IHUB DATA",
      description: "Your Purchase",
      image: ihubLogo,
      order_id: order_id,
      handler: (response) => {
        verifyPayment(
          response.razorpay_payment_id,
          response.razorpay_order_id,
          response.razorpay_signature
        );
      },
      prefill: {
        name: "Ravi Kumar",
        email: "ravi.kumar@gamil.com",
        contact: "9553664942",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#2563EB",
      },
    };

    //response.error.metadata this is to get the error handling in payment
    // this is for window option to open razopay instance
    const rzp1 = new window.Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(`Payment failed: ${response.error.description}`);

      const paymentId = response.error.metadata.payment_id;
      const orderId = response.error.metadata.order_id;

      const data = JSON.stringify({
        payload: {
          payment: {
            entity: {
              id: paymentId,
              order_id: orderId,
              amount: amount,
              currency: "INR",
              status: "failed",
              method: "card",
              created_at: Math.floor(Date.now() / 1000),
            },
          },
        },
      });
      // --- this web hook we are doing this mimic the Razor pay web hook which will be done by razor pay only ---//
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PAYMENT_URL}payments/webhook`,
        headers: {
          "Content-Type": "application/json",
          "x-payment-signature":
            "4bc0a279a0625141d206ee0c232a261da69fd3ef0ff962ed01b79f63a8d56682",
        },
        withCredentials: true,
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          console.log("Backend response:", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error calling backend API:", error);
        });
    });

    rzp1.open();
  };
  //---- To verify the paymet from razor pay we need to call this api where we also update the core databse table also with payment status ----//
  const verifyPayment = async (paymentId, orderId, signature) => {
    const data = JSON.stringify({
      payment_id: paymentId,
      order_id: orderId,
      signature: signature,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_PAYMENT_URL}payments/verification`,
      headers: {
        "Content-Type": "application/json",
        client_UUID: process.env.REACT_APP_CLIENT_UUID,
      },
      withCredentials: true,
      data: data,
    };

    try {
      const response = await axios(config);
      const { message } = response.data;
      console.log("Verification Response:", message);
      alert(message);
      fetchOrders();
      fetchPayments();
    } catch (error) {
      console.error("Verification Error:", error);
      alert("Payment verification failed. Please try again.");
    }
  };

  //------ this is just to show the orders that done upto this call ----//
  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${backendCore}payment/get-orders`, {
        headers: {
          "Content-Type": "application/json",
          client_UUID: process.env.REACT_APP_CLIENT_UUID,
        },
        withCredentials: true,
      });

      const { count, rows } = response.data.data || { count: 0, rows: [] };
      setOrders(rows);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoadingOrders(false);
    }
  };

  //----- This is to update payments in table that are donw upto now---//
  const fetchPayments = async () => {
    try {
      const response = await axios.get(`${backendCore}payment/get-transactions`, {
        headers: {
          "Content-Type": "application/json",
          client_UUID: process.env.REACT_APP_CLIENT_UUID,
        },
        withCredentials: true,
      });
      setPayments(response.data.data.rows || []);
    } catch (error) {
      console.error("Error fetching payments:", error);
    } finally {
      setLoadingPayments(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchPayments();
    fetchOrders();
  }, []);

  return (
    <div className="p-4">
      <div className="mt-8">
        <div className="flex space-x-4 mb-4">
          <button
            className={`px-4 py-2 ${activeTab === "payments"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
              }`}
            onClick={() => handleTabChange("payments")}
          >
            Payments
          </button>
          <button
            className={`px-4 py-2 ${activeTab === "orders" ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
            onClick={() => handleTabChange("orders")}
          >
            Orders
          </button>
        </div>

        {activeTab === "payments" ? (
          <div>
            <h3 className="text-xl font-semibold mb-4">Payments</h3>
            {loadingPayments ? (
              <p>Loading payments...</p>
            ) : (
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border px-4 py-2">Payment ID</th>
                    <th className="border px-4 py-2">Order ID</th>
                    <th className="border px-4 py-2">Amount</th>
                    <th className="border px-4 py-2">Status</th>
                    <th className="border px-4 py-2">Transaction ID</th>
                    <th className="border px-4 py-2">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {payments
                    .slice()
                    .reverse()
                    .map((payment) => (
                      <tr key={`${payment.order_id}-${payment.payment_id}`}>
                        <td className="border px-4 py-2 text-center">
                          {payment.payment_id}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {payment.order_id}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {payment.amount}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {payment.status}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {payment.pg_payment_id || "null"}
                        </td>
                        <td className="border px-4 py-2">
                          {new Date(payment.created_at).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        ) : (
          <div>
            <h3 className="text-xl font-semibold mb-4">Orders</h3>
            {loadingOrders ? (
              <p>Loading orders...</p>
            ) : (
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border px-4 py-2">Order ID</th>
                    <th className="border px-4 py-2">Amount</th>
                    <th className="border px-4 py-2">Receipt</th>
                    <th className="border px-4 py-2">Status</th>
                    <th className="border px-4 py-2">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .slice()
                    .reverse()
                    .map((order) => (
                      <tr key={order.id}>
                        <td className="border px-4 py-2 text-center">
                          {order.order_id}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {order.amount}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {order.receipt}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {order.status}
                        </td>
                        <td className="border px-4 py-2">
                          {new Date(order.created_at).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPayment;