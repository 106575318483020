import axios from "axios";
import axiosInstance from './AxiosInstance';

import creds from "../../src/creds";
import { getUserRole } from "./AuthService";

const backendCore = creds.backendCore;

// const userRole = JSON.parse(localStorage.getItem('userRole'));

export const getduas = async () => {
    try {
        return await axiosInstance.get(`${backendCore}dua/list-dua`, {
          headers: {
              'Permission-Name': `list_dua`,
              'Role-Id': getUserRole().roleId,
          },
          withCredentials:true,
        });
    } catch (error) {
        throw error.message;
    }
};

export const getAllDuas = async () => {
    try {
        return await axiosInstance.get(`${backendCore}dua/list-all-dua`, {
          headers: {
              'Permission-Name': `list_all_dua`,
              'Role-Id': getUserRole()?.roleId,
          },
          withCredentials:true,
        });
    } catch (error) {
        throw error.message;
    }
};

export const getdomain = async () => {
    try {
        return await axios.get(`${backendCore}domain/get-domains`);
    } catch (error) {
        throw error.message;
    }
};

export const addDua = async (duaData) => {
    const duaJSON = {
        duaTitle: duaData.formData.dua,
        domainId: duaData.formData.duaDomainName,
        mdData: duaData.mdData,
    };
    try {
        const response = await axiosInstance.post(`${backendCore}dua/create-dua`, duaJSON, {
            headers: {
                'Permission-Name': `create_dua`,
                'Role-Id': getUserRole().roleId,
            },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.message || 'Something went wrong';
            throw new Error(errorMessage);
        }
        throw new Error(error.message || 'Network Error');
    }
};

export const deletedua = async (dua) => {
    let data = {isActive: false};
    return await axiosInstance.delete(`${backendCore}dua/delete-dua/${dua.duaId}`, {
        headers: {
            'Permission-Name': `delete_dua`,
            'Role-Id': getUserRole()?.roleId,
        },
        withCredentials:true,
    });
};

export const editdua = async (duaData, prevduaId) => {
    try {
        const updatedduaJSON = {
            duaTitle: duaData.duaTitle,
            mdData: duaData.duaDescription,
            lvl: duaData.lvl,
            linkedId: duaData.linkedId,
        };
        return await axiosInstance.put(`${backendCore}dua/update-dua/${prevduaId}`, updatedduaJSON, {
            headers: {
                'Permission-Name': `update_dua`,
                'Role-Id': getUserRole().roleId,
            },
            withCredentials: true,
        });
    } catch (error) {
        throw error.message;
    }
};

export const updateDuaStatus = async ({duaId, status, lvl}) => {
    try {
        let updatedDuaJSON = {}
        console.log(duaId, status, lvl)
        if (status)
            updatedDuaJSON.duaStatus = status;
        // if (lvl)
            updatedDuaJSON.lvl = lvl;
        
        return await axiosInstance.put(`${backendCore}dua/verify-dua/${duaId}`, updatedDuaJSON, {
            headers: {
                'Permission-Name': 'verify_dua',
                'Role-Id': getUserRole().roleId,
            },
            withCredentials: true,
        });
    } catch (error) {
        throw error.message;
    }
};

export const getDuaAgreement = async (datasetId) => {
    try {
        const response = await axiosInstance.get(`${backendCore}dua/get-dua-agreement/${datasetId}`, {
        headers: {
            'Permission-Name': `update_dua`,
            'Role-Id': getUserRole().roleId,
        },
        withCredentials: true,
      });
  
      return response.data;
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  };