import React, {useEffect, useState} from "react";
import Table from "../../components/styled/Table";
import { getVideos } from "../../services/VideoService";
import VideosTable from "../../components/videos/VideoTable";
import { getUserRole } from "../../services/AuthService";

const MyVideoList = () => {
  const [videos, setVideos] = useState([]);
  const [showAnotations, setShowAnotations] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoName, setSelectedVideoName] = useState(null);
  
  const loadVideos = async () => {
    try {
      const response = await getVideos();
      console.log(response);
      setVideos(response.videos || []);
    } catch (err) {
      console.error(err);
      setVideos([]);
    }
  };
  useEffect(() => {
    loadVideos();
  }, []); 

  const columns = [
    {
      key: "User",  // Changed from "User.firstname"
      label: "Owner Name",
      sortable: true,
      render: (User) => `${User?.firstname || ''} ${User?.lastname || ''}`.trim(), // Combine first and last name
    },
    {
      key: "parent_video_name",
      label: "Video Name",
      sortable: true,
    },
    {
      key: "createdAt",
      label: "Creation Date",
      sortable: true,
      render: (value) => new Date(value).toLocaleDateString("en-GB"),
    },
  ];

  const actions = [
    {
      label: "View versions",
      className: "px-3 py-2 rounded-lg text-sm text-blue-500 bg-white border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => {
        setSelectedVideo(item.video_id);
        setSelectedVideoName(item.parent_video_name);
        setShowAnotations(true);
      },
      
    },
  ];
  if(getUserRole().roleName !== 'Video Uploader' && getUserRole().roleName !== 'Annotator' && getUserRole().roleName !== 'Admin')
    return <div>You do not have video upload and view access.</div>
    
  return (
    <div className="flex flex-col w-full bg-white mb-2">
      {videos.length !== 0 ?
        <Table 
          data={videos}
          columns={columns}
          actions={actions}
          pageSize={5}
          title="Videos Management"
        /> : <div className="text-center text-gray-500">No videos uploaded</div>
      }
      
      {showAnotations && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-3/4 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <button 
                onClick={() => setShowAnotations(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <span className="text-2xl">&times;</span>
              </button>
            </div>
            <VideosTable parentVideoId={selectedVideo} parentVideoName={selectedVideoName}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyVideoList;