import axios from 'axios';
import creds, { url } from "../creds";
import { getUserRole } from './AuthService';
import axiosInstance from './AxiosInstance';

export const createVideo = async (videoData) => {
  try {
    const response = await axiosInstance.post(`${url}video/create-video`, videoData, 
      {
        headers: {
        'Permission-Name': `upload_video`,
        'Role-Id': getUserRole().roleId,
        }, 
        withCredentials: true
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Dataset creation failed: ${errorMessage}`);
    }    
    throw new Error(`Failed to create dataset: ${error.message}`);
  }
};

export const getVideos = async (parentVideoId) => {
  try {
    const response = await axiosInstance.get(`${url}video/get-videos${parentVideoId ? `?parentVideoId=${parentVideoId}` : ''}`, 
      {
        headers: {
          'Permission-Name': `list_own_videos_and_upload_annotated_videos`,
          'Role-Id': getUserRole().roleId,
        }, 
        withCredentials: true
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Failed to fetch videos: ${errorMessage}`);
    }    
    throw new Error(`Failed to fetch videos: ${error.message}`);
  }
};

export const getAllVideos = async (parentVideoId) => {
  try {
    const response = await axiosInstance.get(`${url}video/get-all-videos${parentVideoId ? `?parentVideoId=${parentVideoId}` : ''}`, 
      {
        headers: {
          'Permission-Name': `list_all_videos_and_upload_annotated_videos`,
          'Role-Id': getUserRole().roleId,
        }, 
        withCredentials: true
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Failed to fetch videos: ${errorMessage}`);
    }    
    throw new Error(`Failed to fetch videos: ${error.message}`);
  }
};

export const fetchVideoCountLimit = async () => {
  try {
    const response = await axiosInstance.get(`${url}video/get-limit`, 
      {
        headers: {
          'Permission-Name': `video_get_limit`,
          'Role-Id': getUserRole().roleId,
        }, 
        withCredentials: true
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Failed to fetch video count limit: ${errorMessage}`);
    }    
    throw new Error(`Failed to fetch video count limit: ${error.message}`);
  }
}

export const fetchAllVideoCountLimit = async () => {
  try {
    const response = await axiosInstance.get(`${url}video/get-all-limit`, 
      {
        headers: {
          'Permission-Name': `video_get_all_limit`,
          'Role-Id': getUserRole().roleId,
        }, 
        withCredentials: true
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Failed to fetch video count limit: ${errorMessage}`);
    }    
    throw new Error(`Failed to fetch video count limit: ${error.message}`);
  }
}

export const decreaseLimit = async (updatedData) => {
  try {
    const response = await axiosInstance.put(`${url}video/decrease-limit`, updatedData, {
      headers: {
        'permission-name': 'video_decrease_limit',
        'role-id': getUserRole().roleId
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error("Error updating video upload limit:", error);
    throw new Error('Error updating video upload limit');
  }
};

export const updateLimit = async (userId, updatedData) => {
  try {
    const response = await axiosInstance.put(`${url}video/update-limit?userId=${userId}`, updatedData, {
      headers: {
        'permission-name': 'video_update_limit',
        'role-id': getUserRole().roleId
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error("Error updating video upload limit:", error);
    throw new Error('Error updating video upload limit');
  }
}