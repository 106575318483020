const creds = {
  // For PROD server
  backendAuth: "https://india-data.org/auth/",
  backendCore: "https://india-data.org/core/",
  backendDU: "https://india-data.org/du/",
  backendPG: "https://india-data.org/payment/",

  // For QA server
  // backendAuth: "https://indiadata-qa.iiit.ac.in/auth/",
  // backendCore: "https://indiadata-qa.iiit.ac.in/core/",
  // backendDU: "https://indiadata-qa.iiit.ac.in/du/",
  // backendPG: "https://indiadata-qa.iiit.ac.in/payment/",

  // For Dev server
  // backendAuth: "https://datafoundation-dev.iiit.ac.in/auth/",
  // backendCore: "https://datafoundation-dev.iiit.ac.in/core/",
  // backendDU: "https://datafoundation-dev.iiit.ac.in/du/",
  // backendPG: "https://datafoundation-dev.iiit.ac.in/payment/",

  // For local development
  // backendCore: "http://localhost:3001/core/",
  // backendAuth: "http://localhost:3002/auth/",
  // backendDU: "http://localhost:3003/du/",
  // backendPG: "http://localhost:3004/pg/",
};

export const url = creds.backendCore;
export const authUrl = creds.backendAuth;
export const DUUrl = creds.backendDU;
export default creds;