//libs
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
// import { getUserRole } from './services/AuthService';
import { Heading } from './components/styled/Text';
import {store} from './redux/store';


const getUser = () => {
  const state = store.getState(); // Access the store directly
  const { roleId, roleName } = state.auth.roleRes || {};
  // console.log('getUserRole',  JSON.stringify(roleId));
  // const { roleId, roleName } = getUserRole();  // if(!user?.token || new Date() > new Date(user.validTill)){
  //   if(user){ localStorage.removeItem('dfs-user'); }
  //   return null;
  // }
  return roleName;
};

const withAuth = (Component) =>
  forwardRef((props, ref) => {
    
    const user = getUser();
    if(!user){
      return <Heading size={1}>Please <Link to="/sign-in" className="text-indigo-600 hover:bg-indigo-200">Sign In</Link> to view this page</Heading>
    }
    return (
      <Component
        {...props}
        user={user}
        ref={ref}
      />
    );
  });

const USER_STATE = {
  ANY : 'ANY',
  ADMIN : 'ADMIN',
  SIGNED_IN : 'SIGNED_IN',
  UPLOADER : 'UPLOADER',
  ANNOTATOR : 'ANNOTATOR',
  // can add other roles
}
  
const getUserLoginState = (user, _getUser = false) => {
  let targetUser = user;
  if(!_getUser){
    targetUser = getUser();
  }
  if(targetUser) {
    if(targetUser === "Admin") return USER_STATE.ADMIN;
    else if(targetUser === "Video Uploader") return USER_STATE.UPLOADER;
    else if(targetUser === "Annotator") return USER_STATE.ANNOTATOR;
    return USER_STATE.SIGNED_IN;
  }
  return USER_STATE.ANY;
}
  
export { withAuth, getUser, USER_STATE, getUserLoginState };
