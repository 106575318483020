import { registerUserFirstPart, registerUserSecondPart, verifyEmail, forgotPassword, getForgotPasswordOtp, expiredPasswordReset } from '../services/AuthenticationService';
import notify from "../packages/toastify/Notify";

export const handleSignUp = async (formFields, setEmailSent, navigate) => {
  let userId;
  let response1, response2;
  try {
    response1 = await registerUserFirstPart(formFields);
    if (response1.status === 201)
      userId = response1.data.data;
  } catch (err) {
    console.error('Error in first API call:', err.message || err.status);
    switch (err.status) {
      case 400:
        notify(`${err.response.data.message}`, "error");
        break;
      case 403:
        notify(`User is already registered. Please login and verify your email`, "error");
        break;
      case 409:
        notify(`${err.response.data.message}`, "error");
        break;
      case 500:
        notify(`${err.response.data.message}`, "error");
        break;
      default:
        notify(`An error occurred: ${err.response.data.message}`, "error");
    }
  }
  setEmailSent(false)
  try {
    if (response1.status === 201) {
      response2 = await registerUserSecondPart(formFields, userId);
      if (response2.status === 201) {
        const message = response2.data.message;
        notify(`${message}`, "success");
        setEmailSent(true)
        navigate("/verification-link-sent");
      }
    }
  } catch (err) {
    console.error('Error in second API call:', err.message || err);
    switch (err.status) {
      case 400:
        notify(`${err.response.data.message}`, "error");
        break;
      case 401:
        notify(`${err.response.data.message}`, "error");
        break;
      case 403:
        notify(`${err.response.data.message}`, "error");
        break;
      case 409:
        notify(`${err.response.data.message}`, "error");
        break;
      default:
        notify(`An error occurred: ${err.response.data.message}`, "error");
    }
    setEmailSent(false)
    navigate("/sign-up");
  }
};

export const handleEmailVerification = async (
  email,                     // The email address
  token,                     // The email verification token (OTP)
  setIsLoading,              // Function to set loading state
  setIsSuccess               // Function to set success state
) => {
  setIsLoading(true);         // Start loading before making API call
  try {
    const response = await verifyEmail(email, token);
    setIsLoading(false);
    if (response.status === 200) {
      setIsSuccess(true);
    }
  } catch (err) {
    if (err.response) {
      console.error('Error in otp verify API call:', err.message || err);
      switch (err.status) {
        case 400:
          notify(`${err.response.data.message}`, "error");
          break;
        case 404:
          notify(`${err.response.data.message}`, "error");
          break;
        case 500:
          notify(`${err.response.data.message}`, "error");
          break;
        default:
          notify(`${err.response.data.message}`, "error");
      }
    }
    setIsLoading(false);
    setIsSuccess(false); // Indicate that the verification failed
  }
};

export const getResetPasswordLinkHandler = async (email, notification_type, appName) => {
  try {
    const response = await getForgotPasswordOtp(email, notification_type, appName);
    return response;
  } catch (err) {
    if (err.response) {
      switch (err.status) {
        case 404:
          notify(`${err.response.data.message}`, "error");
          break;
        case 500:
          notify(`${err.response.data.message}`, "error");
          break;
        case 503:
          notify(`Service Unavailable: ${err.response.data.message}`, "error");
          break;
        default:
          notify(`Something went wrong. Please try again.`, "error");
      }
    }
    throw err; // Fixed error throwing
  }
};

export const resetPassword = async (email, newPassword, appName) => {
  try {
    const response = await forgotPassword(email, newPassword, appName);
    if (response.status === 200) {
      const message = response.data.message;
      notify(`${message}`, "success");
    }
    return response
  } catch (err) {
    if (err.response) {
      switch (err.status) {
        case 400:
          notify(`${err.response.data.message}`, "error");
          break;
        case 404:
          notify(`${err.response.data.message}`, "error");
          break;
        case 500:
          notify(`${err.response.data.message}`, "error");
          break;
        default:
          notify(`Something went wrong. Please try again.`, "error");
      }
    }
    return err
  }  
}

export const handleExpiredPasswordReset = async (email, oldPassword, newPassword, appName) => {
  try {
    const response = await expiredPasswordReset(email, oldPassword, newPassword, appName);
    if (response.status === 200) {
      const message = response.data.message;
      notify(`${message}`, "success");
    }
    return response;
  } catch (err) {
    console.log(err.message)
    if (err.response) {
      switch (err.status) {
        case 400:
          notify(`${err.response.data.message}`, "error");
          break;
        case 401:
          notify(`Your old password is incorrect`, "error");
          break;
        case 404:
          notify(`User not found`, "error");
          break;
        case 500:
          notify(`${err.response.data.message}`, "error");
          break;
        default:
          notify(`Something went wrong. Please try again.`, "error");
      }
    }
    return err
  } 
}




