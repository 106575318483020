import React, { useState, useEffect } from "react";
import { Button } from "../../components/styled/Buttons";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { signIn, clearLoginError } from '../../redux/AuthController';
import PasswordExpiredPage from "./PasswordExpiredPage"; // Correct import
import notify from "../../packages/toastify/Notify";

export default function SignInForm() {
  const [userName, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [appName] = useState("dfs");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginError } = useSelector(state => state.auth);
  const isLoggedIn = useSelector((state) =>  state.auth?.isLoggedIn);

  useEffect(() => {
    if (loginError) {
      switch (loginError.status) {
        case 401:
          notify('Incorrect Password',("error"));
          break;
        case 403:
          if(loginError.message === "Email is not verified. Please verify your email") {
            notify('Please verify your email to login',("error"));
          }
          else {
          setPasswordExpired(true);
          }
          break;
        case 404:
          notify('Username is not registered in system',("error"));
          break;

        default:
          notify('Something went wrong',`${loginError.message}`);
          break;
      }
    }
    return () => {
      dispatch(clearLoginError());
    };
  }, [loginError, dispatch]);

  if(isLoggedIn){
    return <Navigate to="/my-dashboard" />
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value) {
      setEmailError("");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailBlur = () => {
    if (!userName) {
      setEmailError("Email address is required");
    } else if (!validateEmail(userName)) {
      setEmailError("Please enter valid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordError("Password should not be empty");
    } else {
      setPasswordError("");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(userName)) {
      setEmailError("Please enter valid email address");
      return;
    }
    const resultAction = await dispatch(signIn({ userName, password, appName }));
    if (signIn.fulfilled.match(resultAction)) {
      dispatch(clearLoginError());
      navigate('/my-dashboard');
    }
  }

  const navigateToResetPassword = () => {
    if (!userName || !validateEmail(userName)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    navigate(`/forgot-password?email=${encodeURIComponent(userName)}`);
  };

  return (
    <div className="max-w-3xl w-full rounded-l my-8">
      {passwordExpired ? (
        <PasswordExpiredPage email={userName} />
      ) : (
        <div className="bg-gray-100 flex flex-col justify-center px-6 sm:px-8 lg:px-10">
          <div className="mx-auto w-full sm:max-w-lg">
            <div className="sm:w-full text-white rounded text-center bg-blue-green-0 mt-1 mb-4 py-2">
              Sign In
            </div>
            <div className="bg-white py-10 px-6 sm:rounded-lg sm:px-10">
              <form onSubmit={onSubmit}>
                <div className="space-y-6">
                  <div className="space-y-2">
                    <label htmlFor="form2Example1" className="text-base">
                      Email address
                    </label>
                    <input
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                      id="form2Example1"
                      name="email"
                      type="email"
                      placeholder="Email"
                      autoComplete="email"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                    {emailError && (
                      <p className="text-red-600 text-sm">{emailError}</p>
                    )}
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="form2Example2" className=" text-base">
                      Password
                    </label>
                    <div className="relative">
                      <input
                        value={password}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="form2Example2"
                        placeholder="Password"
                        className={`text-md rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${passwordError ? "mb-2" : "mb-1 "}`}
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        onBlur={handlePasswordBlur}                        
                        onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); onSubmit(e); } }}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      >
                        {showPassword ? (
                          <FaEyeSlash className="h-5 w-5 text-gray-500" />
                        ) : (
                          <FaEye className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                    {passwordError && (
                      <p className="text-red-600 text-sm">{passwordError}</p>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between mt-4 mb-4 space-x-4">
                  <div>
                    <input
                      type="checkbox"
                      value=""
                      id="form2Example31"
                      name="rememberMe"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="form2Example31"
                      className="ml-2 text-sm text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>
                  <div className="text-sm">
                    <button
                      type="button"
                      onClick={navigateToResetPassword}
                      className="font-semibold text-indigo-600 no-underline hover:text-indigo-500 hover:underline"
                    >
                      Forgot password?
                    </button>
                  </div>
                </div>

                <Button.Blue type="submit" className="flex w-full justify-center">
                  Sign in
                </Button.Blue>

                <div className="text-center m-2">
                  <p>
                    Not a member?
                    <a
                      href="/sign-up"
                      className="font-semibold text-indigo-600 no-underline hover:text-indigo-500 hover:underline"
                    >
                      Register
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
