import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineUpload } from "react-icons/ai";
import { FaEye, FaPlus, FaAngleDown, FaEyeSlash } from "react-icons/fa";
import { DatasetDetails } from "../../components";
import DatasetVersionsTable from "../../components/datasets/DatasetVersionsTable";
import { fetchDatasetDetails } from "../../services/DatasetService";
import MyVersionCreate from "../../components/datasets/MyVersionCreate";
import { toggleCompleted } from "../../redux/formSlice";
import { InternalHeading } from "../../components/styled/GlobalStyles";
const ActionButton = ({ icon, tooltip, onClick, hoverColor }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative">
      <button
        className={`p-2 border rounded ${hoverColor} transition-colors duration-200`}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={onClick}
      >
        {icon}
      </button>
      {showTooltip && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 text-sm text-white bg-gray-900 rounded-md whitespace-nowrap z-10">
          {tooltip}
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 border-4 border-transparent border-t-gray-900"></div>
        </div>
      )}
    </div>
  );
};

const MyDatasetsList = () => {
  const [datasetId, setDatasetId] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDatasetDetailPage, setShowDatasetDetailPage] = useState(false);
  const [showVersions, setShowVersions] = useState(false);
  const [showCreateVersion, setShowCreateVersion] = useState(false); // New state for create version visibility
  const [userDatasets, setUserDatasets] = useState([]);
  const completed =  useSelector((state) => state.form.completed);
  const dispatch = useDispatch();
  const isUploading = useSelector((state) => state.upload.isUploading);

useEffect(() => {
    if (completed === true && showCreateVersion === false) {
      dispatch(toggleCompleted())
      localStorage.removeItem('datasetId');
      setShowCreateVersion(false);
    }
  }, [completed, dispatch]);
  
  useEffect(() => {
    const getUserDatasets = async () => {
      try {
        const data = await fetchDatasetDetails({
          verified: false,
          authentication: true,
        });
        if (data.error) {
          console.log("Error fetching dataset details");
        } else {
          setUserDatasets(data.datasets);
          console.log("datasets", data.datasets);
        }
      } catch (err) {
        console.log("Error fetching dataset details");
      }
    };
    getUserDatasets();
  }, []);

  const handleDetailsToggle = (dataset) => {
    setDatasetId(dataset.dataset_id);
    setShowDatasetDetailPage(!showDatasetDetailPage);
    setShowVersions(false);
    setShowCreateVersion(false);
  };
  
  const handleVersionsToggle = (dataset) => {
    setDatasetId(dataset.dataset_id);
    setShowVersions(!showVersions);
    setShowDatasetDetailPage(false);
    setShowCreateVersion(false);
  };
  
  const handleAddVersion = (dataset) => {
    setDatasetId(dataset.dataset_id);
    localStorage.setItem("datasetId", dataset.dataset_id);
    setShowCreateVersion(!showCreateVersion);
    setShowDatasetDetailPage(false);
    setShowVersions(false);
  };

  return (
    <div className="dataset-container">
      <div className="col-span-12 flex justify-between items-center px-4 bg-gray-200 rounded-lg mb-2">
      <h2 className={InternalHeading}>My Datasets</h2>
      </div>
      <div className="overflow-auto max-h-80vh rounded-md">
        <table className="min-w-full border-2 border-gray-100 ">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-3 py-3 text-left">Dataset Name</th>
              <th className="px-3 py-3 text-left">Description</th>
              <th className="px-3 py-3 text-left">Domain</th>
              <th className="px-3 py-3 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {userDatasets?.map((dataset, index) => (
              <React.Fragment key={index}>
                <tr className={`bg-gray-100 ${isUploading ? 'cursor-not-allowed opacity-50' : ''}`} title={isUploading ? `Navigation disabled - Upload in progress` : ''} >
                  <td className="px-3 py-3 border-b">{dataset.dataset_name}</td>
                  <td className="px-3 py-3 border-b">
                    {dataset.dataset_description}
                  </td>
                  <td className="px-3 py-3 border-b">{dataset.domain_name}</td>
                  <td className="px-3 py-3 border-b">
                    <div className={`flex gap-2 ${isUploading ? 'cursor-not-allowed pointer-events-none' : ''}`}>
                      <ActionButton
                        icon={
                          showDatasetDetailPage &&
                          dataset.dataset_id === datasetId ? (
                            <FaEyeSlash />
                          ) : (
                            <FaEye />
                          )
                        }
                        tooltip="View Details"
                        onClick={() => handleDetailsToggle(dataset)}
                        hoverColor="hover:bg-yellow-500"
                      />

                      <ActionButton
                        icon={<FaPlus />}
                        tooltip="Add Version"
                        onClick={() => handleAddVersion(dataset)}
                        hoverColor="hover:bg-green-500"
                      />

                      <ActionButton
                        icon={<FaAngleDown />}
                        tooltip="View Versions"
                        onClick={() => handleVersionsToggle(dataset)}
                        hoverColor="hover:bg-blue-500"
                      />
                    </div>
                  </td>
                </tr>
                {showDatasetDetailPage && dataset.dataset_id === datasetId && (
                  <tr>
                    <td colSpan="5" className="px-3 py-3">
                      <DatasetDetails datasetId={datasetId} editable={true} />
                    </td>
                  </tr>
                )}
                {showVersions && dataset.dataset_id === datasetId && (
                  <tr>
                    <td colSpan="5" className="px-3 py-3">
                      <DatasetVersionsTable datasetId={datasetId}/>
                    </td>
                  </tr>
                )}
                {showCreateVersion && dataset.dataset_id === datasetId && (
                  <tr>
                    <td colSpan="5" className="px-3 py-3">
                      <MyVersionCreate />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default MyDatasetsList;
