import React, { useState, useEffect, useRef } from 'react';

const data = [
  { name: 'CSV' },
  { name: 'TSV' },
  { name: 'JSON' },
  { name: 'XML' },
  { name: 'YAML' },
  { name: 'HTML' },
  { name: 'Markdown' },
  { name: 'XLS' },
  { name: 'XLSX' },
  { name: 'ODS' },
  { name: 'SQL' },
  { name: 'MDB' },
  { name: 'ACCDB' },
  { name: 'JPEG' },
  { name: 'PNG' },
  { name: 'GIF' },
  { name: 'BMP' },
  { name: 'TIFF' },
  { name: 'SVG' },
  { name: 'MP3' },
  { name: 'WAV' },
  { name: 'AAC' },
  { name: 'FLAC' },
  { name: 'MP4' },
  { name: 'AVI' },
  { name: 'MKV' },
  { name: 'MOV' },
  { name: 'WMV' },
  { name: 'ZIP' },
  { name: 'RAR' },
  { name: 'TAR' },
  { name: 'GZ' },
  { name: '7Z' },
  { name: 'HDF5' },
  { name: 'NETCDF' },
  { name: 'Parquet' },
  { name: 'ORC' },
  { name: 'PDF' },
  { name: 'DOC' },
  { name: 'DOCX' },
  { name: 'PPT' },
  { name: 'PPTX' },
  { name: 'RTF' }
];

const AutocompleteSelect = ({ value, handleChange, errors, disabled }) => {
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef();
  const InvalidFormStyle =
    "bg-red-100 border border-red-500 text-red-900 placeholder-red-700 text-xs rounded-md focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400";



  useEffect(() => {
    const handler = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const filterSuggestions = (value) => {
    return data.filter(item =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    handleChange(e);  // Update Redux state
    if (value) {
      const filteredSuggestions = filterSuggestions(value);
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.name);
    handleChange({ target: { name: 'fileType', value: suggestion.name } });  // Update Redux state
    setShowSuggestions(false);
  };

  return (
    <div className="relative sm:col-span-3" ref={suggestionsRef}>
      <label htmlFor="fileType" className="block text-sm/6 font-medium text-gray-900">
        File Type <span className="text-red-500">{"*"}</span>
      </label>
      <div className="mt-2">
        <input
          disabled={disabled}
          type="text"
          id="fileType"
          name="fileType"
          value={inputValue}
          onChange={handleInputChange}
          className={
            errors.fileType
              ? InvalidFormStyle
              :"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
          }
          placeholder="Type to search..."
        />
        {showSuggestions && (
          <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
            {suggestions.length > 0 ? (
              suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="p-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.name}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">No suggestions</div>
            )}
          </div>
        )}
        {errors.fileType && <p className="text-red-500 text-xs mt-1">{errors.fileType}</p>}
      </div>
    </div>
  );
};

export default AutocompleteSelect;
