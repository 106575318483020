import axios from 'axios';
import creds, { url, authUrl, DUUrl } from "../creds";
import { getUserRole } from './AuthService';
import axiosInstance from './AxiosInstance';

export const createDataset = async (datasetData) => {
  try {
    const response = await axiosInstance.post(`${url}dataset/create-dataset`, datasetData, { withCredentials: true });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Dataset creation failed: ${errorMessage}`);
    }
    throw new Error(`Failed to create dataset: ${error.message}`);
  }
};

export const createDatasetVersion = async (datasetVersionData) => {
  try {
    const response = await axiosInstance.post(`${url}dataset/create-version`, datasetVersionData, { withCredentials: true });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Dataset version creation failed: ${errorMessage}`);
    }
    throw new Error(`Failed to create dataset: version ${error.message}`);
  }
};

export const fetchDatasetDetails = async ({ datasetId, user, domainId, verified = true, authentication = true, open } = {}) => {
  try {
    const queryParams = new URLSearchParams({
      ...(datasetId && { datasetId }),
      ...(user && { user }),
      ...(domainId && { domain: domainId })
    }).toString();

    const endpoint = verified ? 'get-datasets-verified' : open ? 'get-datasets-open' : 'get-datasets';
    const response = await axiosInstance.get(
      `${url}dataset/${endpoint}${queryParams ? `?${queryParams}` : ''}`, { 
          withCredentials: authentication, 
          headers: { 
              'Role-Id': getUserRole?.()?.roleId,
          }
      }
    );

    return response.data;
  } catch (error) {
    throw new Error('Error fetching dataset details');
  }
};
export const editDatasetDetails = async (datasetId, updatedData) => {
  try {
    if (updatedData.accessType) {
      let publicValue, isPaidValue;
      switch (updatedData.accessType) {
        case 'Public':
          publicValue = 1;
          isPaidValue = 0;
          break;
        case 'Private':
          publicValue = 0;
          isPaidValue = 0;
          break;
        case 'Paid':
          publicValue = 1;
          isPaidValue = 1;
          break;
      }
      updatedData = { public: publicValue, is_paid: isPaidValue };
    }
    const response = await axiosInstance.put(`${url}dataset/update-dataset?datasetId=${datasetId}`, updatedData, {
      headers: {
        'permission-name': 'update_own_dataset_metadata',
        'role-id': getUserRole().roleId
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error("Error updating dataset:", error);
    throw error;
  }
};

export const fetchVersionDetails = async (params = {}) => {
  try {
    const { datasetVersionId, datasetId, open = false } = params;
    const queryParams = new URLSearchParams();

    if (datasetVersionId) queryParams.append('datasetVersionId', datasetVersionId);
    if (datasetId) queryParams.append('datasetId', datasetId);

    const base_url = `${url}dataset/get-versions` + (open ? `-open` : ``);
    const get_url = `${base_url}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;

    const response = await axiosInstance.get(get_url, { withCredentials: true });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching dataset versions');
  }
};

export const fetchVersionsAdmin = async (params = {}) => {
  try {
    const { datasetVersionId, datasetId } = params;
    const queryParams = new URLSearchParams();

    if (datasetVersionId) queryParams.append('datasetVersionId', datasetVersionId);
    if (datasetId) queryParams.append('datasetId', datasetId);

    const get_url = `${url}admin/get-dataset-versions${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;

    const response = await axiosInstance.get(get_url, {
      headers: {
        'Permission-Name': `update_domain`,
        'Role-Id': getUserRole().roleId,
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching dataset versions');
  }
};

export const editVersionDetails = async (datasetVersionId, updatedData) => {
  try {
    const response = await axiosInstance.put(`${url}dataset/update-version?datasetVersionId=${datasetVersionId}`, updatedData, {
      headers: {
        'permission-name': 'update_own_version_metadata',
        'role-id': getUserRole().roleId
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error("Error updating dataset version:", error);
    throw new Error('Error updating dataset details');
  }
};

export const getDomains = async (withDuas) => {
  try {
    const response = withDuas ? await axiosInstance.get(`${url}domain/get-domains`) : await  axios.get(`${url}domain/get-domains`);
    return response.data;
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const getDuas = async (domainId) => {
  try {
    const userRole = JSON.parse(localStorage.getItem('userRole'));
    const { roleId, roleName } = userRole || {};
    const response = await axiosInstance.get(`${url}dua/list-dua-by-domain?domainId=${domainId}`, {
      headers: {
        'permission-name': 'list_dua',
        'role-id': roleId
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const fetchEmailSuggestions = (query, partial = true) => {
  if (partial)
    return axiosInstance.get(`${authUrl}query-users?searchq=${query}`, { withCredentials: true });
  else
    return axiosInstance.get(`${authUrl}query-users?userEmail=${query}`, { withCredentials: true });
};

export const verifyDatasetVersion = async (versionId, status) => {
  try {
    const response = await axiosInstance.put(
      `${url}admin/verify-dataset-version?datasetVersionId=${versionId}`,
      { verification: status },
      {
        headers: {
          'Permission-Name': 'update_domain',
          'Role-Id': getUserRole().roleId,
        },
        withCredentials: true
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in verifyDatasetVersion:", error);
    throw error;
  }
};

export const fetchDatasets = (userId) => {
  return new Promise((resolve, reject) => {
    const backendCore = creds.backendCore;
    const endpoint = userId
      ? `${backendCore}dataset/get-datasets-verified?userId=${userId}`
      : `${backendCore}dataset/get-datasets-verified`;

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          reject("Failed to fetch datasets");
        } else {
          return response.json();
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const fetchDomainDatasets = (domainName) => {
  domainName = domainName.replace(/-/g, " ");
  return new Promise((resolve, reject) => {
    const backendCore = creds.backendCore;
    const endpoint = `${backendCore}dataset/get-datasets-verified?domainName=${domainName}`

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          reject("Failed to fetch datasets");
        } else {
          return response.json();
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const createDatasetRequest = async (requestData) => {
  try {
    const response = await axiosInstance.post(
      `${url}access/create-dataset-request`,
      requestData,
      {
        headers: {
          'Permission-Name': 'create_dataset_request',
          'Role-Id': getUserRole().roleId,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      throw new Error(`Dataset request creation failed: ${errorMessage}`);
    }
    throw new Error(`Failed to create dataset request: ${error.message}`);
  }
};

export const getAccessRequestStatus = async (params = {}) => {
  try {
    const userRole = JSON.parse(localStorage.getItem('userRole'));
    const { roleId } = userRole || {};

    const queryParams = new URLSearchParams(params).toString();
    const urlWithParams = `${url}access/get-dataset-request${queryParams ? `?${queryParams}` : ''}`;

    const response = await axiosInstance.get(urlWithParams, {
      headers: {
        'permission-name': 'create_dataset_request',
        'role-id': getUserRole().roleId,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const updateDatasetRequest = async (requestId, requestStatus) => {
  try {
    const response = await axiosInstance.put(
      `access/update-dataset-request`,
      { requestId, request_status: requestStatus },
      {
        headers: {
          'permission-name': 'create_dataset_request',
          'role-id': getUserRole().roleId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating dataset request:", error.response || error);
    throw new Error(
      error.response?.data?.message || "Error updating dataset request"
    );
  }
};

export const getReminderNotification = async (requesterID) => {
  try {
    const userRole = JSON.parse(localStorage.getItem('userRole'));
    const { roleId } = userRole || {};

    const urlWithParams = `${url}access/remind-notification/${requesterID}`;

    const response = await axiosInstance.get(urlWithParams, {
      headers: {
        'role-id': roleId,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const getDatasetAnalytics = async (datasetId) => {
  try {
    return await axios.get(`${url}stats/datasets-analytics/${datasetId}`)    
  } catch (error) {
    console.error("Error fetching dataset analytics:", error);
    throw new Error('Error fetching dataset analytics');
  }
};

export const deleteDatasetVersion = async (datasetId, datasetVersionId) => {
  try {
    if (!datasetVersionId) {
      throw new Error('Dataset Version ID is required');
    }
    const delete_url = `${url}admin/delete-version?datasetVersionId=${datasetVersionId}`;
    const deleteFilesUrl = `${DUUrl}files/delete-file?database_id=${datasetId}&version_id=${datasetVersionId}`;
    
    const response = await axiosInstance.delete(delete_url, {
      headers: {
        'Permission-Name': 'delete_version',
        'Role-Id': getUserRole().roleId,
      },
      withCredentials: true
    });
    
    if(response.data.error) {
      throw new Error(deleteFilesResponse.data.message);
    }
    
    const deleteFilesResponse = await axiosInstance.delete(deleteFilesUrl, {
      withCredentials: true
    });
    console.log("Delete response", deleteFilesResponse);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || 'Error deleting dataset version');
    } else if (error.request) {
      throw new Error('No response received from server');
    } else {
      throw new Error('Error deleting dataset version');
    }
  }
};

export const deleteFile = async (fileName) => {
  try {
    if (!fileName) {
      throw new Error('File name is required');
    }
    const deleteFilesUrl = `${DUUrl}files/delete-file?file_name=${fileName}`;
    
    const deleteFilesResponse = await axiosInstance.delete(deleteFilesUrl, {
      withCredentials: true
    });
    console.log("Delete response", deleteFilesResponse);
    return deleteFilesResponse.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || 'Error deleting File');
    } else if (error.request) {
      throw new Error('No response received from server');
    } else {
      throw new Error('Error deleting File');
    }
  }
};