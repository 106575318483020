import { USER_STATE } from "../../withAuth";
import icprPdf from "../../assets/pdf/icpr_2024_rip_flyer.pdf"
export const NAV_CONFIG = [
  {
    name: "Home",
    pathname: "/",
    permissions: [USER_STATE.ANY],
    options: [],
  },
  {
    name: "My Dashboard",
    permissions: [USER_STATE.SIGNED_IN],
    pathname: "/my-dashboard",
    options: [],
  },
  {
    name: "Datasets",
    permissions: [USER_STATE.ANY],
    options: [
      {
        //This is needed to test locally
        // name: "All datasets",
        // pathname: "/datasets-listing",
      },
    ],
  },
  //This is needed to test locally
  //  The TOOLS menu button is currently commented out and is reserved for future use.
  // {
  //   name: "Tools",
  //   permissions: USER_STATE.ANY,
  //   options: [
  //     {
  //       name: "ML Challenges",
  //       pathname: "/mlc",
  //       redirect: true,
  //     },
  //     {
  //       name: "Histopathological Viewer",
  //       pathname: "/hv",
  //       redirect: true,
  //     },
  //     {
  //       name: "Special Viewers IDD Data Explorer",
  //       pathname: " ",
  //       redirect: true,
  //     },
  //   ],
  // },
  {
    name: "Challenges",
    permissions: [USER_STATE.ANY],
    options: [
      //  The ML/ EDA menu button is currently commented out and is reserved for future use.
      // {
      //   name: "ML / EDA Challenges",
      //   pathname: "/mlc ",
      // },
      {
        name: "Rider Intention Prediction(RIP) ",
        pathname: icprPdf,
        redirect: true,
        type: "file", // File to download or open
        target: "_blank", // Opens in new tab
        rel: "noopener noreferrer", // Security attribute
      },
      {
        name: "Safe-Segmentation",
        pathname:
          "https://girish-lab.github.io/Safe-Segmentation-Competition-ICPR-24/",
        redirect: true,
        type: "external", // File to download or open
        target: "_blank", // Opens in new tab
        rel: "noopener noreferrer", // Security attribute
      },
    ],
  },
  {
    name: "About",
    permissions: [USER_STATE.ANY],
    options: [
      {
        name: "About Us",
        pathname: "/about-us",
      },
      {
        name: "Team",
        pathname: "/team",
      },
      {
        name: "Privacy Policy",
        pathname: "/privacy-policy",
      },
      {
        name: "Terms and Conditions",
        pathname: "/terms-conditions",
      },
      {
        name: "Cancellation and Refund",
        pathname: "/cancellations-refunds",
      },
      {
        name: "Shipping and Delivery",
        pathname: "/shipping-delivery",
      },
      {
        name: "Contact Us",
        pathname: "/contact-us",
      },
    ],
  },
];
