import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import MDEditor from "@uiw/react-markdown-editor";
import { FaShoppingCart, FaDownload, FaUnlock, FaLock } from "react-icons/fa";
import { IoChatboxEllipsesSharp } from "react-icons/io5";
import { BsChatQuoteFill } from "react-icons/bs";
import { FaArrowUpRightDots } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import creds from "../../creds";
import RazorPaymentInt from "../payment/RazorPaymentInt";
import { selectIsLoggedIn } from "../../redux/AuthController";
import { fetchDatasetDetails, getAccessRequestStatus } from '../../services/DatasetService';
import DownloadManager from "../download/DownloadManager";
import { Modal } from "../styled/Modal";
import { getDuaAgreement } from "../../services/DUAService";
import { createDatasetRequest } from "../../services/DatasetService";
import { ToastContext } from "../../App";

const backendCore = creds.backendCore;
const DatasetDetailsCard = ({ datasetId }) => {
    const [dataset, setDataset] = useState({});
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [versionId, setVersionId] = useState(null);
    const [datasetIdDownload, setDatasetIdDownload] = useState(null);
    const [isDownloadManagerOpen, setDownloadManagerOpen] = useState(false);
    const [isRequestSent, setIsRequestSent] = useState(false);
    const [agreementText, setAgreementText] = useState('');
    const [comment, setComment] = React.useState("");
    const [commentError, setCommentError] = useState(false);
    const [coordinatorId, setCoordinatorId] = useState(null);
    const [ownerId, setOwnerId] = useState(null);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const { addToast } = useContext(ToastContext);

    useEffect(() => {
        const fetchDataset = async () => {
            try {
                const data = await fetchDatasetDetails({ datasetId, verified: false, open: true });
                if (data.error) {
                    setError("Error fetching dataset details");
                } else {
                    setDataset(data.datasets[0]);
                    const fetchedDataset = data.datasets[0];
                    setVersionId(fetchedDataset.latest_version_id);
                    setDatasetIdDownload(fetchedDataset.dataset_id);
                    setCoordinatorId(fetchedDataset.coordinator_id);
                    setOwnerId(fetchedDataset.owner_id);
                }
            } catch (err) {
                setError("Error fetching dataset details");
            }
        };

        fetchDataset();
    }, [datasetId]);

    const handleIconClick = async () => {
        if (!isLoggedIn) {
            setIsLoginModalOpen(true);
            return;
        }

        try {
            const agreementData = await getDuaAgreement(datasetId);
            setAgreementText(agreementData.data.md_data);

            const isOwner = agreementData.data.isOwner; 

            if (isOwner) {
                setDownloadManagerOpen(true);
            } else {
                const accessRequestStatus = await getAccessRequestStatus({ datasetId });
                console.log("Access Request Status:", accessRequestStatus);

                switch (accessRequestStatus.requests.request_status) {
                    case "approved":
                        setDownloadManagerOpen(true);
                        break;
                    case "rejected":
                        setIsModalOpen(true);
                        break;
                    case "requested":
                        setIsRequestSent(accessRequestStatus.requests.request_status === "requested");
                        setIsModalOpen(true);
                        break;
                    default:
                        setIsModalOpen(true);
                        break;
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIsModalOpen(true);
            } else {
                console.error("Error handling icon click:", error);
                throw error;
            }
        }
    };

    const handleTermsAccept = () => {
        if (isTermsAccepted) {
            if (dataset.public) {
                setIsModalOpen(false);
                setDownloadManagerOpen(true);
            } else {
                setIsRequestSent(true);
                // setIsModalOpen(false);
            }
        }
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleTermsChange = (e) => {
        setIsTermsAccepted(e.target.checked);
    };

    const handleCloseLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    const handleRequestAccess = async () => {
        if (!comment.trim()) {
            setCommentError(true);
            return;
        }
        setCommentError(false);

        const requestData = {
            datasetId: datasetIdDownload,
            coordinatorId: coordinatorId,
            ownerId: ownerId,
            requester_message: comment,
        };

        try {
            const response = await createDatasetRequest(requestData);
            if (response.status === true) {
                // alert("Request for access has been sent successfully.");
                setIsModalOpen(false);
                addToast(response.message || "Request for access has been sent successfully.", { appearance: "success" });
                console.log("request created successfully");
            } else {
                addToast(response.message || "Failed to create request. Please try again later.", { appearance: "error" });
                console.log("first request failed");
            }
        } catch (error) {
            addToast(error.message || "Error creating dataset request. Please try again later.", { appearance: "error" });
            console.error("Error creating dataset request:", error.message);
        }
    };

    return (
        <div className="grid">
            <div className="text-center rounded shadow-lg py-4 mb-4 hover:shadow-lg transition-shadow duration-300">
                <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    <span style={{ fontWeight: 500 }}>{dataset.dataset_name}</span>
                </h1>
            </div>
            <div className="flex justify-between items-start mb-4 flex-wrap">
                <div className="flex space-x-2 mb-2 sm:mb-0">
                    <span
                        className="flex bg-indigo-400 hover:bg-indigo-700 text-white font-bold items-center text-center m-1 p-1 rounded overflow-hidden shadow-2xl hover:shadow-3xl"
                        data-tooltip-id={`File-Size-${dataset.dataset_id}`}
                        data-tip
                    >
                        {(dataset.dataset_size / 1073741824).toFixed(2)} GB
                    </span>
                    <Tooltip id={`File-Size-${dataset.dataset_id}`} content="File-Size" />
                    <button
                        className={`flex ${dataset.is_paid
                            ? "bg-blue-800 pr-2"
                            : dataset.public
                                ? "bg-green-600"
                                : "bg-red-500 hover:bg-red-700"
                            } text-white font-bold items-center text-center m-1 p-1 rounded overflow-hidden shadow-2xl hover:shadow-3xl`}
                    >
                        {dataset.is_paid ? (
                            <span className="ml-2">₹ {dataset.final_price}</span>
                        ) : dataset.public ? (
                            <div className="flex items-center">
                                <FaUnlock size="16" />
                                <span className="p-2 text-sm">Public</span>
                            </div>
                        ) : (
                            <div className="flex items-center">
                                <FaLock size="16" />
                                <span className="p-2 text-sm">Private</span>
                            </div>
                        )}
                    </button>
                </div>
                <div className="flex space-x-2">
                    <>
                        {dataset.is_paid ? (
                            <>
                                <button
                                    className="font-bold py-2 px-4 rounded-full m-1"
                                    data-tooltip-id={`buy-dataset-${dataset.dataset_id}`}
                                    data-tip
                                >
                                    <FaShoppingCart size="28" className="text-blue-500" />
                                </button>
                                <Tooltip
                                    id={`buy-dataset-${dataset.dataset_id}`}
                                    content="Buy Dataset"
                                />
                            </>
                        ) : (
                            <>
                                <button
                                    className="font-bold py-2 px-4 rounded-full m-1"
                                    data-tooltip-id={`download-dataset-${dataset.dataset_id}`}
                                    data-tip
                                    onClick={handleIconClick}
                                >
                                    <FaDownload size="28" className="text-blue-500" />
                                </button>
                                <Tooltip
                                    id={`download-dataset-${dataset.dataset_id}`}
                                    content="Download Dataset"
                                />
                            </>
                        )}
                    </>
                     <button
                        className="font-bold py-2 px-4 rounded-full m-1"
                        data-tooltip-id={dataset.dataset_id + "_discussion"}
                        data-tip
                        data-for={dataset.dataset_id + "_discussion"}
                        // onClick={() => {
                        //     window.location.href = `/dataset-discussions/${dataset.dataset_id}`;
                        // }}
                    >
                        <IoChatboxEllipsesSharp size="28" className="text-gray-400" />
                    </button>
                    <Tooltip
                        id={dataset.dataset_id + "_discussion"}
                        content="Dataset Discussion"
                    /> 

                    <button
                        className="font-bold py-2 px-4 rounded-full m-1 text-gray"
                        data-tooltip-id={`Citation-${dataset.dataset_id}`}
                        data-tip
                    >
                        <BsChatQuoteFill size="28" className="text-gray-400" />
                    </button>
                    <Tooltip id={`Citation-${dataset.dataset_id}`} content="Citation" /> 

                     <button
                        className="font-bold py-2 px-4 rounded-full m-1 text-gray"
                        data-tooltip-id={`Download-count-${dataset.dataset_id}`}
                        data-tip
                    >
                        <FaArrowUpRightDots size="28" className="text-gray-400" />
                    </button>
                    <Tooltip id={`Download-count-${dataset.dataset_id}`} content="Download-count" />
                </div>
            </div>

            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    title="Terms and Conditions"
                >
                    <div className="p-4">
                        <p className="mb-4">
                            Please read and accept our terms and conditions before downloading.
                        </p>
                        <div className="mb-4">
                            <p className="font-medium">Agreement</p>
                            <div
                                className="agreement-box mt-2 p-4 bg-gray-100 border rounded overflow-y-auto"
                                style={{ maxHeight: "200px" }}
                            >
                                <MDEditor.Markdown
                                    source={agreementText}
                                    style={{
                                        backgroundColor: "transparent",
                                        fontSize: "16px",
                                        lineHeight: "1.5",
                                        color: "black",
                                    }}
                                />
                            </div>
                        </div>
                        {!dataset.public && !isRequestSent && (
                            <div className="mt-4">
                                <label
                                    htmlFor="comment"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Add a comment: <span className="text-red-500">*</span>
                                </label>
                                <textarea
                                    id="comment"
                                    rows="3"
                                    className="mt-1 p-2 w-full border rounded"
                                    placeholder="Please provide your reason to download this Dataset."
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                                {commentError && (
                                    <p className="text-red-500 text-sm mt-1">
                                        Comment is required.
                                    </p>
                                )}
                            </div>
                        )}

                        <div className="flex items-center mb-4">
                            <input
                                type="checkbox"
                                id="terms"
                                checked={isTermsAccepted}
                                onChange={handleTermsChange}
                            />
                            <label htmlFor="terms" className="ml-2">
                                I accept the terms and conditions.
                            </label>
                        </div>

                        <div className="flex space-x-4">
                            {dataset.public ? (
                                <button
                                    className={`bg-blue-500 text-white px-4 py-2 rounded ${!isTermsAccepted && "opacity-50 cursor-not-allowed"
                                        }`}
                                    onClick={handleTermsAccept}
                                    disabled={!isTermsAccepted}
                                >
                                    Proceed
                                </button>
                            ) : (
                                <>
                                    {!isRequestSent ? (
                                        <button
                                            className={`bg-blue-500 text-white px-4 py-2 rounded ${(!isTermsAccepted || !comment.trim()) &&
                                                "opacity-50 cursor-not-allowed"
                                                }`}
                                            onClick={handleRequestAccess}
                                            disabled={!isTermsAccepted || !comment.trim()}
                                        >
                                            Request Access
                                        </button>
                                    ) : (
                                        <p className="text-sm text-gray-600">
                                            Request sent, waiting for owner to accept.
                                        </p>
                                    )}
                                </>
                            )}
                            <button
                                className="bg-gray-300 text-black px-4 py-2 rounded"
                                onClick={handleCloseModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            )}

            {isLoginModalOpen && (
                <Modal
                    isOpen={isLoginModalOpen}
                    onClose={handleCloseLoginModal}
                    title="Login Required"
                >
                    <p>
                        {dataset.public
                            ? "You need to log in to download this dataset."
                            : "You need to log in to request access to this dataset."}
                    </p>
                    <div className="flex justify-end space-x-4 mt-4">
                        <button
                            className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            onClick={() => {
                                window.location.href = "/sign-in";
                            }}
                        >
                            Login
                        </button>
                        <button
                            className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                            onClick={handleCloseLoginModal}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>
            )}

            {isDownloadManagerOpen && (
                <div className="z-50">
                    <DownloadManager
                        databaseId={datasetIdDownload}
                        versionId={versionId}
                        isModalOpen={isDownloadManagerOpen}
                        onClose={() => setDownloadManagerOpen(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default DatasetDetailsCard;
