import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVersionDetails, nextPage, prevPage } from '../../redux/formSlice';
// import { v4 as uuidv4 } from 'uuid';
import AutocompleteSelect from './AutocompleteFiletype';
import { handleCreateDatasetVersion } from '../../controllers/datasetController';
import notify from "../../packages/toastify/Notify";

const AddVersionForm = ({ setCurrentStep }) => {
  const dispatch = useDispatch();
  const datasetDetails = useSelector((state) => state.form.datasetDetails);
  const versionDetails = useSelector((state) => state.form.versionDetails);
  const [descriptionLines, setDescriptionLines] = useState(versionDetails.versionDescription || ['']);
  const [errors, setErrors] = useState({});
  const currentPage = useSelector((state) => state.form.currentPage);
  const filled = useSelector((state) => state.form.versionDetails.filled);
  const InvalidFormStyle =
    "bg-red-100 border border-red-500 text-red-900 placeholder-red-700 text-xs rounded-md focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400";

  const validHtmlTag = (value) => {
    return (/<\/?[^>]+>/.test(value)) 
  };

  const validateForm = () => {
    const newErrors = { ...errors };
    if (!versionDetails.versionId) newErrors.versionId = "Version Name is required";
    if (!versionDetails.fileType) newErrors.fileType = "File Type is required";
    if (!versionDetails.versionOverview) newErrors.versionOverview = "Version Overview is required";
    if (descriptionLines.some(line => !line.trim())) newErrors.descriptionLines = "All description lines must be filled";
    else if (validHtmlTag(descriptionLines)) newErrors.descriptionLines = "Should not contain HTML tags.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const newErrors = { ...errors };
    const descriptionLinesContainsHTML = descriptionLines.some(line => validHtmlTag(line));
    if (descriptionLines.some(line => !line.trim()) && !descriptionLinesContainsHTML) delete newErrors.descriptionLines;
    setErrors(newErrors);
  }, [versionDetails, descriptionLines]);

  const handleFieldValidation = (name, value) => {
    const newErrors = { ...errors };
    switch (name) {
      case "versionOverview":
        if (!value) newErrors.versionOverview = "Version Overview is required.";
        else if (value.length < 10) newErrors.versionOverview = "Version Overview must be greater than 10 characters long.";
        else if (value.length > 150) newErrors.versionOverview = "Version Overview must be less than 150 characters long.";
        else if (validHtmlTag(value)) {
          if (newErrors.versionOverview) newErrors.versionOverview += " Should not contain HTML tags.";
          else newErrors.versionOverview = "Should not contain HTML tags.";
        }
        else delete newErrors.versionOverview;
        break;

      case "versionId":
        if (!value) newErrors.versionId = "Version Name is required";
        else if (validHtmlTag(value)) newErrors.versionId = "Should not contain HTML tags.";
        else delete newErrors.versionId;
        break;

      case "fileType":
        if (!value) newErrors.fileType = "File Type is required";
        else if (validHtmlTag(value)) newErrors.fileType = "Should not contain HTML tags.";
        else delete newErrors.fileType;
        break;

        case "descriptionLines": 
        if (!value) newErrors.descriptionLines = "Description is required.";
        else if (!Array.isArray(value)) newErrors.descriptionLines = "Description must be a list.";
        else {
          delete newErrors.descriptionLines;
          for (const line of value) {
            if (typeof line !== 'string' || line.length < 10 || validHtmlTag(line)) {
              newErrors.descriptionLines = "Each line must be at least 10 characters long and not contain HTML tags.";
              break;
            }
          }
        }
        break;

      default:
        break;
    }
    setErrors(newErrors);
  };

  const handleChange = (e) => {
    dispatch(setVersionDetails({ ...versionDetails, [e.target.name]: e.target.value }));
  };

  const handleChangeWithValidation = (e) => {
    const { name, value } = e.target;
    handleChange(e);
    handleFieldValidation(name, value);
  };

  const handleDescriptionChange = (index, value) => {
    const newLines = [...descriptionLines];
    newLines[index] = value;
    setDescriptionLines(newLines);
    handleFieldValidation("descriptionLines", newLines);
  };

  const addDescriptionLine = () => {
    setDescriptionLines([...descriptionLines, '']);
  };

  const removeDescriptionLine = (indexToRemove) => {
    setDescriptionLines(prevLines => prevLines.filter((_, index) => index !== indexToRemove));
  };

  useEffect(() => { // Update Redux store whenever descriptionLines changes 
    dispatch(setVersionDetails({ ...versionDetails, versionDescription: descriptionLines }));
  }, [descriptionLines]);

  const handleNext = async () => {
    if (validateForm()) {
      try {
        if (!filled) {
          const updatedVersionDetails = {
            ...versionDetails,
            datasetId: localStorage.getItem('datasetId') || datasetDetails.datasetId,
          };

          const respDatasetVersionCreation = await handleCreateDatasetVersion(updatedVersionDetails);
          console.log(respDatasetVersionCreation)
          if (respDatasetVersionCreation) {
            dispatch(setVersionDetails({
              ...updatedVersionDetails,
              datasetVersionId: respDatasetVersionCreation.version.dataset_version_id,
              versionDescription: descriptionLines,
              filled: true
            }));
            console.log("Form is valid. Proceed to the next step.");
          }
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setCurrentStep(currentPage + 1);
        dispatch(nextPage());
      } catch (error) {
        console.error('Failed to send details to the backend', error);
      }
    } else {
      notify("Please fill all required fields correctly.", "errors");
    }
  };


  const handlePrev = () => {
    setCurrentStep(1);
    dispatch(prevPage());
  };

  return (
    <>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          {filled ? <div className="text-red-600 text-center font-medium mb-4">
            Page already filled. You can edit later under "My datasets" page in "My dashboard"
          </div>
            : <h2 className="text-base/7 font-semibold text-gray-900 text-center ">Fill Version Details</h2>
          }
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="versionId" className="block text-sm/6 font-medium text-gray-900">
                Version Name <span className="text-red-500">{"*"}</span>
              </label>
              <div className="mt-2">
                <input
                  disabled={filled}
                  id="versionId"
                  name="versionId"
                  type="text"
                  value={versionDetails.versionId || ''}
                  onChange={handleChangeWithValidation}
                  placeholder="Version Name"
                  className={
                    errors.versionId
                      ? InvalidFormStyle
                      : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  }
                />
                {errors.versionId && <p className="text-red-500 text-xs mt-1">{errors.versionId}</p>}
              </div>
            </div>

            <AutocompleteSelect
              disabled={filled}
              value={versionDetails.fileType || ''}
              handleChange={handleChangeWithValidation}
              errors={errors}
            />

            <div className="sm:col-span-6">
              <label htmlFor="versionOverview" className="block text-sm/6 font-medium text-gray-900">
                Version Overview <span className="text-red-500">{"*"}</span>
              </label>
              <div className="mt-2">
                <input
                  disabled={filled}
                  id="versionOverview"
                  name="versionOverview"
                  type="text"
                  value={versionDetails.versionOverview}
                  onChange={handleChangeWithValidation}
                  placeholder="Version Overview"
                  required
                  className={
                    errors.versionOverview
                      ? InvalidFormStyle
                      : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  }
                />
                {errors.versionOverview && <p className="text-red-500 text-xs mt-1">{errors.versionOverview}</p>}
              </div>
            </div>
            <div className="sm:col-span-6">
              <label className="block text-sm/6 font-medium text-gray-900">
              Version Description <span className="text-red-500">{"*"}</span>
              </label>
              <div className="space-y-2">
                {descriptionLines.map((line, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <textarea
                      disabled={filled}
                      type="text"
                      value={line}
                      required
                      onChange={(e) => handleDescriptionChange(index, e.target.value.replaceAll(';', '').replaceAll("'", ""))}
                      placeholder="Version Description"
                      rows="2"
                      className={
                        !line.trim() && descriptionLines.length > 1 || errors.descriptionLines
                          ? InvalidFormStyle
                          : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      }
                      ></textarea>
                    <div className="flex space-x-2">
                      {index === descriptionLines.length - 1 && (
                        <button
                          disabled={filled}
                          type="button"
                          onClick={addDescriptionLine}
                          className="px-6 py-1 bg-blue-500 text-xl text-white rounded hover:bg-blue-600"
                        >
                          +
                        </button>
                      )}
                      {descriptionLines.length > 1 && (
                        <button
                          disabled={filled}
                          type="button"
                          onClick={() => removeDescriptionLine(index)}
                          className="px-6 py-1 bg-red-500 text-xl text-white rounded hover:bg-red-600"
                        >
                          -
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                {errors.descriptionLines && <p className="text-red-500 text-xs mt-1">{errors.descriptionLines}</p>}
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="doi" className="block text-sm/6 font-medium text-gray-900">
                Digital Object Identifier (DOI)
              </label>
              <div className="mt-2">
                <input
                  disabled={filled}
                  id="doi"
                  name="doi"
                  type="text"
                  value={versionDetails.doi || ''}
                  onChange={handleChange}
                  placeholder="DOI (Optional)"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <div className="mt-6 flex items-center justify-between gap-x-6">
          {/* <button onClick={handlePrev} className="px-4 py-2 bg-gray-300 rounded">Previous</button> */}
          <button onClick={handleNext} className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save & Proceed</button>
        </div>
      </div>
    </>
  );
};

export default AddVersionForm;
