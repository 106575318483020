import axios from "axios";
import axiosInstance from './AxiosInstance';
import creds from "../../src/creds";
import { getUserRole } from "./AuthService";

const backendCore = creds.backendCore;
const authentication = creds.backendAuth;

export const fetchAllUsers = async () => {
    try{
        const response = await axiosInstance.get(`${backendCore}admin/list-all-users`, {
          headers: {
              'Permission-Name': `list_all_users`,
              'Role-Id': getUserRole().roleId,
          },
          withCredentials:true,
        });
        return response.data.records || [];
    } catch (error) {
        throw error.message;
    }
};

export const fetchAllRoles = async () => {
    try{
        const response = await axios.get(`${backendCore}authorization/list-all-roles`);
        return response.data.message.rolesJson || [];
    } catch (error) {
        throw error.message;
    }
};

export const updateUserRole = async (userId, updateRoleId) => {
    try{
        const response = await axiosInstance.put(`${backendCore}admin/update-user-role/${userId}`, {"updatedRoleId":updateRoleId}, {
          headers: {
              'Permission-Name': `update_user_role`,
              'Role-Id': getUserRole().roleId,
          },
          withCredentials:true,
        });
        return response.data;
    } catch (error) {
        throw error.message;
    }
};

export const deleteUser = async (userId) => {
    try {
        const authDeleteResponse = await axiosInstance.delete(`${authentication}delete-user/${userId}`, {
                withCredentials: true,
            });
        if (authDeleteResponse.data.error === false) {
            const response = await axiosInstance.delete(`${backendCore}admin/delete-user/${userId}`, {
                headers: {
                    'Permission-Name': `delete_user`,
                    'Role-Id': getUserRole().roleId,
                },
                withCredentials: true,
            });
            return response.data;
        }

        return authDeleteResponse.message;
    } catch (error) {
        throw error.message;
    }
};

export const fetchDeactivatedUsers = async () => {
  try{
        const response = await axiosInstance.get(`${backendCore}admin/list-deactivated-users`, {
          headers: {
              'Permission-Name': `list_deactivated_users`,
              'Role-Id': getUserRole().roleId,
          },
          withCredentials:true,
        });
        return response.data.records || [];
    } catch (error) {
        throw error.message;
    }
};

export const activateUser = async (userId) => {
  try {
        const authActivateResponse = await axiosInstance.put(`${authentication}activate-user/${userId}`, {}, {
                withCredentials: true,
            });
        if (authActivateResponse.data.error === false) {
            const response = await axiosInstance.put(`${backendCore}admin/activate-user/${userId}`,{}, {
                headers: {
                    'Permission-Name': `activate_user`,
                    'Role-Id': getUserRole().roleId,
                },
                withCredentials: true,
            });
            return response.data;
        }
        return authActivateResponse.message;
    } catch (error) {
        throw error.message;
    }
};