import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import "./Lock.css";

const colors = require("tailwindcss/colors");

const resolveTailwindColor = (colorClass) => {
  const [baseColor, shade] = colorClass.split("-");
  return colors[baseColor]?.[shade] || colorClass;
};

const CircleLoad = ({
  colorClass = "stroke-blue-500",
  locked = false,
  text1,
  text2,
  Icon,
  iconColor = "blue-500",
  tooltipText = "",
}) => {
  const [percent, setPercent] = useState(100);
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const [offset, setOffset] = useState(circumference);
  const resolvedIconColor = resolveTailwindColor(iconColor);

  useEffect(() => {
    const targetOffset = ((100 - percent) / 100) * circumference;
    const timeout = setTimeout(() => {
      setOffset(targetOffset);
    }, 100);
    return () => clearTimeout(timeout);
  }, [circumference, percent]);

  return (
    <div className="text-center text-white">
      <div
        className="relative w-44 h-44 mx-auto"
        data-tooltip-id="circle-tooltip"
        data-tooltip-content={tooltipText || undefined}
        data-pct={percent}
      >
        <p
          className="font-medium"
          style={{
            color: "black",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            marginBottom: "0rem",
          }}
        >
          {text1}
        </p>
        <svg className="w-full h-full" viewBox="0 0 200 200">
          <circle
            className={`transition-all duration-1000 ${colorClass}`}
            strokeWidth="16"
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx="100"
            cy="70"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            style={{
              transition: "stroke-dashoffset 1s ease-out",
            }}
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="container flex flex-col items-center">
            {Icon && <Icon color={resolvedIconColor} size={50} />}
            <h1
              className="font-medium"
              style={{ color: "black", fontWeight: "bold" }}
            >
              {text2}
            </h1>
          </div>
        </div>
      </div>
      {tooltipText && <Tooltip id="circle-tooltip" place="right" />}
    </div>
  );
};

export default CircleLoad;
