import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Table from '../styled/Table';
import { fetchVersionDetails } from '../../services/DatasetService';
import { FaEye, FaDownload, FaEyeSlash, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from "../../redux/AuthController";
import DownloadManager from "../download/DownloadManager";
import { createDatasetRequest } from '../../services/DatasetService';
import MDEditor from "@uiw/react-markdown-editor";
import { Modal } from '../styled/Modal';
import { ToastContext } from '../../App';
import { getDuaAgreement } from '../../services/DUAService';
import { getAccessRequestStatus, fetchDatasetDetails } from '../../services/DatasetService';
import { setDatasetDetails, setVersionDetails } from "../../redux/formSlice";
import FileUploadModal from './FileUploadModal';
import { setDatasetState } from '../../redux/datasetSlice';

const DatasetVersionsTable = ({ datasetId, open = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const datasetDetails = useSelector((state) => state.form.datasetDetails);
  const versionDetails = useSelector((state) => state.form.versionDetails);
  const [dataset, setDataset] = useState({});
  const [versions, setVersions] = useState([]);
  const [versionId, setVersionId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [isDownloadManagerOpen, setDownloadManagerOpen] = useState(false);
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const [comment, setComment] = React.useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datasetIdDownload, setDatasetIdDownload] = useState(null);
  const [commentError, setCommentError] = useState(false);
  const [coordinatorId, setCoordinatorId] = useState(null);
  const [agreementText, setAgreementText] = useState('');
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [ownerId, setOwnerId] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    const fetchDataset = async () => {
      try {
        const data = await fetchDatasetDetails({ datasetId, verified: false, open });
        if (data.error) {
          setError("Error fetching dataset details");
        } else {
          setDataset(data.datasets[0]);
          const fetchedDataset = data.datasets[0];
          setVersionId(fetchedDataset.latest_version_id);
          setDatasetIdDownload(fetchedDataset.dataset_id);
          setCoordinatorId(fetchedDataset.coordinator_id);
          setOwnerId(fetchedDataset.owner_id);
        }
      } catch (err) {
        setError("Error fetching dataset details");
      }
    };

    fetchDataset();
  }, [datasetId]);
  const loadVersions = async () => {
    try {
      setLoading(true);
      const response = await fetchVersionDetails({ datasetId, open });
      const sortedVersions = response.datasets || [];
      sortedVersions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setVersions(sortedVersions);
    } catch (err) {
      setError('Failed to load dataset versions');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadVersions();
  }, [datasetId, open]);
  

  const columns = [
    {
      key: "createdAt",
      label: "Created",
      sortable: true,
      render: (value) => formatDate(value),
    },
    {
      key: "version_name",
      label: "Version",
      sortable: true,
    },
    {
      key: "version_overview",
      label: "Overview",
      sortable: false,
    },
    {
      key: "version_file_format",
      label: "Format",
      sortable: true,
    },
    {
      key: "verification",
      label: "Status",
      sortable: true,
      render: (value) => (
        <p
          className={`px-3 py-2 rounded-lg text-sm text-center font-medium ${value.toUpperCase() === "VERIFIED"
            ? "text-green-800 bg-green-300 border-green-500"
            : value.toUpperCase() === "REJECTED"
              ? "text-red-800 bg-red-300 border-red-500"
              : value.toUpperCase() === "PENDING"
                ? "text-blue-800 bg-blue-300 border-blue-500"
                : value.toUpperCase() === "INCOMPLETE"
                  ? "text-yellow-800 bg-yellow-200 border-yellow-500"
                  : value.toUpperCase() === "UNVERIFIED"
                    ? "text-orange-800 bg-orange-300 border-orange-500"
                    : "text-gray-800 bg-gray-300 border-gray-500"
            }`}
        >
          {value.toUpperCase()}
        </p>

      ),
    },
  ];

  const handleViewDetails = async (version) => {
    try {
      setSelectedVersionId(version.dataset_version_id);
      const agreementData = await getDuaAgreement(datasetId);
      const isOwner = agreementData.data.isOwner;
      const agreementTextUser= agreementData.data.md_data;

      dispatch(setDatasetState({ isDatasetOwner: isOwner, agreementText:agreementTextUser }));

      if (dataset.public) {
        navigate(`/dataset-version/${datasetId}/${version.dataset_version_id}`, {
          state: {
            editable: true,
            isOwner: true,
            agreementText: agreementText,
          },
        });
        return;
      }

      if (isOwner) {
        navigate(`/dataset-version/${datasetId}/${version.dataset_version_id}`, {
          state: {
            editable: true,
            isOwner: isModalOpen,
            agreementText: agreementText,
          },
        });
        return;
      }

      const accessRequestStatus = await getAccessRequestStatus({ datasetId });

      if (accessRequestStatus.requests.request_status === "approved") {
        navigate(`/dataset-version/${datasetId}/${version.dataset_version_id}`, {
          state: {
            editable: true,
            isOwner: isOwner,
            agreementText: agreementText,
          },
        });
      } else {
        console.log("Access request not approved or user is not the owner");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Dataset or agreement not found.");
      } else {
        console.error("Error handling view details:", error);
      }
    }
  };

  const handleDownload = async (version) => {
    try {
      setSelectedVersionId(version.dataset_version_id);

      const agreementData = await getDuaAgreement(datasetId);
      setAgreementText(agreementData.data.md_data);

      const isOwner = agreementData.data.isOwner;

      if (isOwner) {
        setDownloadManagerOpen(true);
      } else {
        const accessRequestStatus = await getAccessRequestStatus({ datasetId });

        switch (accessRequestStatus.requests.request_status) {
          case "approved":
            setDownloadManagerOpen(true);
            break;
          case "rejected":
            setIsModalOpen(true);
            break;
          case "requested":
            setIsRequestSent(accessRequestStatus.requests.request_status === "requested");
            setIsModalOpen(true);
            break;
          default:
            setIsModalOpen(true);
            break;
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsModalOpen(true);
      } else {
        console.error("Error handling download:", error);
        throw error;
      }
    }
  };

  const handleAddFiles = async (version) => {
    setSelectedVersionId(version.dataset_version_id);
    dispatch(setDatasetDetails({ datasetId }));
    dispatch(setVersionDetails({ datasetVersionId: version.dataset_version_id }));
    setIsUploadModalOpen(true);
  }
  const renderActions = (version) => {
    if (!isLoggedIn) {
      return <span className="text-red-500 ">Login Required</span>;
    }

    const canView = async () => {
      try {
        if (dataset.public) {
          return true;
        }

        const agreementData = await getDuaAgreement(datasetId);
        const isOwner = agreementData.data.isOwner;

        if (isOwner) {
          return true;
        }

        const accessRequestStatus = await getAccessRequestStatus({ datasetId });
        return accessRequestStatus.requests.request_status === "approved";
      } catch (error) {
        console.error("Error checking view permissions:", error);
        return false;
      }
    };


    return (
      <div className="flex gap-2">
        <button
          onClick={() => handleViewDetails(version)}
          className={`p-2 border rounded transition-colors duration-200 ${canView
              ? "text-blue-500 hover:bg-blue-800 hover:text-white"
              : "text-gray-500 cursor-not-allowed"
            }`}
          title="View Details"
        >
          {<FaEye />}
        </button>
        <button
          onClick={() => handleDownload(version)}
          className="p-2 border rounded hover:bg-green-500 hover:text-white transition-colors duration-200"
          title="Download Version"
        >
          <FaDownload />
        </button>
        {
          version.verification === "incomplete" && 
            <button
          onClick={() => handleAddFiles(version)}
          className="p-2 border rounded hover:bg-green-500 hover:text-white transition-colors duration-200"
          title="Download Version"
        >
          <FaPlus />
        </button>
        }
        {isDownloadManagerOpen && selectedVersionId && (
          <div className="z-50">
            <DownloadManager
              databaseId={datasetId}
              versionId={selectedVersionId}
              isModalOpen={isDownloadManagerOpen}
              onClose={() => setDownloadManagerOpen(false)}
            />
          </div>
        )}
      </div>
    );
  };

    const handleRequestAccess = async () => {
      if (!comment.trim()) {
        setCommentError(true);
        return;
      }
      setCommentError(false);

      const requestData = {
        datasetId: datasetIdDownload,
        coordinatorId: coordinatorId,
        ownerId: ownerId,
        requester_message: comment,
      };

      try {
        const response = await createDatasetRequest(requestData);
        if (response.status === true) {
          setIsModalOpen(false);
          addToast(response.message || "Request for access has been sent successfully.", { appearance: "success" });
        } else {
          addToast(response.message || "Failed to create request. Please try again later.", { appearance: "error" });
        }
      } catch (error) {
        addToast(error.message || "Error creating dataset request. Please try again later.", { appearance: "error" });
        console.error("Error creating dataset request:", error.message);
      }
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const handleTermsChange = (e) => {
      setIsTermsAccepted(e.target.checked);
    };

    const handleTermsAccept = () => {
      if (isTermsAccepted) {
        if (dataset.public) {
          setIsModalOpen(false);
          setDownloadManagerOpen(true);
        } else {
          setIsRequestSent(true);
          // setIsModalOpen(false);
        }
      }
    };

    if (loading) return <div className="text-center py-4">Loading versions...</div>;
    if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

    const formatDate = (dateString) => {
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-GB', options).replace(/ /g, '-');
    };

    return (
      <>
        <Table
          data={versions.map((version) => ({
            ...version,
            actions: renderActions(version),
          }))}
          columns={[
            ...columns,
            {
              key: "actions",
              label: "Actions",
              sortable: false,
            },
          ]}
          pageSize={5}
          expandableField="version_description"
          title="Dataset Versions"
        />

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            title="Terms and Conditions"
          >
            <div className="p-4">
              <p className="mb-4">
                Please read and accept our terms and conditions before downloading.
              </p>
              <div className="mb-4">
                <p className="font-medium">Agreement</p>
                <div
                  className="agreement-box mt-2 p-4 bg-gray-100 border rounded overflow-y-auto"
                  style={{ maxHeight: "200px" }}
                >
                  <MDEditor.Markdown
                    source={agreementText}
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "16px",
                      lineHeight: "1.5",
                      color: "black",
                    }}
                  />
                </div>
              </div>
              {!dataset.public && !isRequestSent && (
                <div className="mt-4">
                  <label
                    htmlFor="comment"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Add a comment: <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    id="comment"
                    rows="3"
                    className="mt-1 p-2 w-full border rounded"
                    placeholder="Please provide your reason to download this Dataset."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  {commentError && (
                    <p className="text-red-500 text-sm mt-1">
                      Comment is required.
                    </p>
                  )}
                </div>
              )}

              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="terms"
                  checked={isTermsAccepted}
                  onChange={handleTermsChange}
                />
                <label htmlFor="terms" className="ml-2">
                  I accept the terms and conditions.
                </label>
              </div>

              <div className="flex space-x-4">
                {dataset.public ? (
                  <button
                    className={`bg-blue-500 text-white px-4 py-2 rounded ${!isTermsAccepted && "opacity-50 cursor-not-allowed"
                      }`}
                    onClick={handleTermsAccept}
                    disabled={!isTermsAccepted}
                  >
                    Proceed
                  </button>
                ) : (
                  <>
                    {!isRequestSent ? (
                      <button
                        className={`bg-blue-500 text-white px-4 py-2 rounded ${(!isTermsAccepted || !comment.trim()) &&
                          "opacity-50 cursor-not-allowed"
                          }`}
                        onClick={handleRequestAccess}
                        disabled={!isTermsAccepted || !comment.trim()}
                      >
                        Request Access
                      </button>
                    ) : (
                      <p className="text-sm text-gray-600">
                        Request sent, waiting for owner to accept.
                      </p>
                    )}
                  </>
                )}
                <button
                  className="bg-gray-300 text-black px-4 py-2 rounded"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        )}
        {(
          <FileUploadModal 
            isOpen={isUploadModalOpen}
            onClose={() => {setIsUploadModalOpen(false); loadVersions();}}
            datasetId={datasetDetails.datasetId}
            datasetVersionId={versionDetails.datasetVersionId}
          />
        )}
      </>
    );
  };

export default DatasetVersionsTable;
