import React, { useState, useEffect } from "react";
import { FaDownload, FaRupeeSign } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import { BsChatQuoteFill } from "react-icons/bs";
import BarGraph from "../../components/graphs/BarGraph";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserRole } from "../../services/AuthService";
import { selectIsLoggedIn } from "../../redux/AuthController";
import {
  getDatasetAccessStats,
  getOrderStats,
  getRevenueStats,
} from "../../services/AdmindashboardService";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  parseISO,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { InternalHeading } from "../../components/styled/GlobalStyles";

const AdminDashboardNew = () => {
  const [selectedOption, setSelectedOption] = useState(
    "Top Downloaded Datasets"
  );
  const [graphData, setGraphData] = useState({ xdata: [], ydata: [] });
  const isLoggedIn = selectIsLoggedIn();

  const role = getUserRole().roleName;

  const handleOrdersClick = () => {
    console.log("Orders Clicked");
  };

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [datasetStats, setDatasetStats] = useState({
    publicAndPaid: 0,
    publicNotPaid: 0,
    private: 0,
    totalDatasetDownloadCount: 0,
    topDownloads: [],
  });

  const [orderStats, setOrderStats] = useState({
    totalOrderCount: 0,
    totalRevenue: 0,
    datasets: [],
  });

  const [datasets, setDatasets] = useState({
    "Top Sold Datasets": [],
    "Top Downloaded Datasets": [],
  });

  const [fromDate, setFromDate] = useState(() => {
    // Default date: January 1, 2021
    return new Date("2021-01-01");
  });
  const [toDate, setToDate] = useState(() => new Date());

  const fetchDatasetAccessStats = async () => {
    try {
      const fromDateString = fromDate.toISOString().split("T")[0];
      const toDateString = toDate.toISOString().split("T")[0];
      const response = await getDatasetAccessStats(
        fromDateString,
        toDateString
      );

      const topDownloads = response.data.records.topDownloads
        .slice(0, 3)
        .map((dataset) => dataset.datasetName);

      setDatasetStats({
        publicAndPaid: response.data.records.publicAndPaid,
        publicNotPaid: response.data.records.publicNotPaid,
        private: response.data.records.private,
        totalDatasetDownloadCount:
          response.data.records.totalDatasetDownloadCount,
        topDownloads: response.data.records.topDownloads,
      });
      setDatasets((prevDatasets) => ({
        ...prevDatasets,
        "Top Downloaded Datasets": topDownloads,
      }));
    } catch (error) {
      console.error("Error fetching dataset access stats:", error);
    }
  };

  const fetchOrderStats = async () => {
    try {
      const fromDateString = fromDate.toISOString().split("T")[0];
      const toDateString = toDate.toISOString().split("T")[0];
      const response = await getOrderStats(fromDateString, toDateString);

      const topSoldDatasets = response.data.records.datasets.map(
        (dataset) => dataset.datasetName
      );

      setOrderStats({
        totalOrderCount: response.data.records.totalOrderCount,
        totalRevenue: response.data.records.totalRevenue,
        datasets: response.data.records.datasets,
      });

      setDatasets((prevDatasets) => ({
        ...prevDatasets,
        "Top Sold Datasets": topSoldDatasets,
      }));
    } catch (error) {
      console.error("Error fetching order stats:", error);
    }
  };

  const fetchRevenueStats = async () => {
    try {
      const fromDateString = fromDate.toISOString().split("T")[0];
      const toDateString = toDate.toISOString().split("T")[0];

      const diffInDays = (toDate - fromDate) / (1000 * 60 * 60 * 24);
      let groupBy = "daywise";
      if (diffInDays <= 40) {
        groupBy = "daywise";
      } else if (diffInDays > 40 && diffInDays <= 366) {
        groupBy = "monthwise";
      } else {
        groupBy = "yearwise";
      }

      console.log("Fetching revenue stats:", {
        groupBy,
        fromDateString,
        toDateString,
      });

      const response = await getRevenueStats(
        fromDateString,
        toDateString,
        groupBy
      );

      let xdata = [];
      let ydata = [];

      if (response && response.data.records) {
        const records = response.data.records;

        if (groupBy === "daywise") {
          const weekData = {};
          eachDayOfInterval({
            start: parseISO(fromDateString),
            end: parseISO(toDateString),
          }).forEach((day) => {
            const weekStart = startOfWeek(day, { weekStartsOn: 1 });
            const weekEnd = endOfWeek(day, { weekStartsOn: 1 });
            const weekLabel = `${format(weekStart, "MMM d")} - ${format(
              weekEnd,
              "MMM d"
            )}`;
            if (!weekData[weekLabel]) {
              weekData[weekLabel] = 0;
            }
          });
          records.forEach((record) => {
            const recordDate = parseISO(record.groupKey);
            const weekStart = startOfWeek(recordDate, { weekStartsOn: 1 });
            const weekEnd = endOfWeek(recordDate, { weekStartsOn: 1 });
            const weekLabel = `${format(weekStart, "MMM d")} - ${format(
              weekEnd,
              "MMM d"
            )}`;
            weekData[weekLabel] += record.totalDownloadCount;
          });
          xdata = Object.keys(weekData);
          ydata = Object.values(weekData);
        } else if (groupBy === "monthwise") {
          const monthData = {};
          eachMonthOfInterval({
            start: parseISO(fromDateString),
            end: parseISO(toDateString),
          }).forEach((month) => {
            const monthLabel = format(month, "MMMM yyyy");
            if (!monthData[monthLabel]) {
              monthData[monthLabel] = 0;
            }
          });

          records.forEach((record) => {
            const [year, month] = record.groupKey.split("-");
            const date = new Date(year, month - 1);
            const monthLabel = format(date, "MMMM yyyy");

            if (!monthData[monthLabel]) {
              monthData[monthLabel] = 0;
            }
            monthData[monthLabel] += record.totalDownloadCount;
          });

          xdata = Object.keys(monthData);
          ydata = Object.values(monthData);
        } else if (groupBy === "yearwise") {
          const yearData = {};
          const currentYear = new Date().getFullYear();
          const startYear = new Date(fromDate).getFullYear();
          const endYear = new Date(toDate).getFullYear();
          for (let year = startYear; year <= endYear; year++) {
            yearData[year] = 0;
          }
          records.forEach((record) => {
            const year = record.groupKey;
            if (yearData.hasOwnProperty(year)) {
              yearData[year] += record.totalDownloadCount;
            }
          });
          xdata = Object.keys(yearData);
          ydata = Object.values(yearData);
          console.log("Year Data:", yearData);
        }

        console.log("Processed XData:", xdata);
        console.log("Processed YData:", ydata);

        setGraphData({ xdata, ydata: Array.from(ydata) });
      }
    } catch (error) {
      console.error("Error fetching Revenue stats:", error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchDatasetAccessStats();
      fetchOrderStats();
      fetchRevenueStats();
    }
  }, [isLoggedIn, fromDate, toDate]);

  return (
    <>
      {/* Dashboard Header */}
      <div className="col-span-12 flex justify-between items-center py-2 px-4 bg-gray-200 rounded-lg ">
        <h1 className={InternalHeading}>
          {role === "Admin" ? "Admin" : "User"} Dashboard
        </h1>
        <div className="flex gap-4">
          <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            placeholderText="From Date"
            className="px-2 py-1 shadow text-sm placeholder-gray-500 border rounded"
            dateFormat="dd/MMM/yyyy"
            minDate={new Date("2021-01-01")}
            maxDate={new Date()}
          />
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            placeholderText="To Date"
            className="px-2 py-1 shadow text-sm placeholder-gray-500 border rounded"
            dateFormat="dd/MMM/yyyy"
            minDate={fromDate || new Date("2021-01-01")}
            maxDate={new Date()}  
          />
        </div>
      </div>
      <div className="min-h-screen grid grid-cols-12 gap-4 p-4">
        {/* Statistics and Graph Sections */}
        <div className="col-span-9 flex flex-col gap-4 h-full">
          {/* Statistics Section */}
          <div className="grid grid-cols-4 gap-4">
            <div className="flex items-center justify-between p-4 border rounded-lg shadow-md">
              <FaDownload
                size={35}
                className="bg-blue-200 text-blue-500 p-2 rounded-full"
              />
              <div className="text-center">
                <p className="text-lg font-bold">
                  {datasetStats.totalDatasetDownloadCount}
                </p>
                <p className="text-sm font-semibold text-gray-400">Downloads</p>
              </div>
            </div>
            <div
              className="flex items-center bg-gray-200 justify-between p-4 border rounded-lg shadow-md"
              onClick={handleOrdersClick}
              // title="Coming Soon"
            >
              <IoMdCart
                size={35}
                className="bg-orange-200 text-orange-500 p-2 rounded-full"
              />
              <div className="text-center">
                <p className="text-lg font-bold">
                  {orderStats.totalOrderCount}
                </p>
                <p className="text-sm font-semibold text-gray-400">Orders</p>
              </div>
            </div>
            <div
              className="flex items-center bg-gray-200 justify-between p-4 border rounded-lg shadow-md"
              title="Coming Soon"
            >
              <FaRupeeSign
                size={35}
                className="bg-green-200 text-green-500 p-2 rounded-full"
              />
              <div className="text-center">
                <p className="text-lg font-bold">{orderStats.totalRevenue}</p>
                <p className="text-sm font-semibold text-gray-400">Revenue</p>
              </div>
            </div>
            <div
              className="flex items-center bg-gray-200 justify-between p-4 border rounded-lg shadow-md"
              title="Coming Soon"
            >
              <BsChatQuoteFill
                size={35}
                className="bg-red-200 text-red-500 p-2 rounded-full"
              />
              <div className="text-center">
                <p className="text-lg font-bold">0</p>
                <p className="text-sm font-semibold text-gray-400">Citations</p>
              </div>
            </div>
          </div>

          {/* Graph Section */}
          <div className="p-4 border rounded-lg shadow-md flex-grow">
            <BarGraph
              key={`${graphData.xdata.join("-")}-${graphData.ydata.join("-")}`}
              xdata={graphData.xdata}
              ydata={graphData.ydata}
            />
          </div>
        </div>

        {/* Our Datasets Section */}
        <div className="col-span-3 flex flex-col border p-4 shadow-md bg-white h-full">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold">Datasets</h2>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center">
              <div className="bg-blue-800 w-10 h-10 rounded-full flex items-center justify-center shadow-md">
                <span className="text-white text-lg">
                  {datasetStats.publicNotPaid}
                </span>
              </div>
              <p className="text-sm font-semibold pl-3 text-black">
                Public Datasets
              </p>
            </div>
            <div className="flex items-center">
              <div className="bg-blue-800 w-10 h-10 rounded-full flex items-center justify-center shadow-md">
                <span className="text-white text-lg">
                  {datasetStats.private}
                </span>
              </div>
              <p className="text-sm font-semibold pl-3 text-black">
                Private Datasets
              </p>
            </div>
            <div className="flex items-center">
              <div className="bg-gray-400 w-10 h-10 rounded-full flex items-center justify-center shadow-md">
                <span className="text-white text-lg">
                  {datasetStats.publicAndPaid}
                </span>
              </div>
              <p className="text-sm font-semibold pl-3 text-black">
                Paid Datasets
              </p>
            </div>
          </div>
          <div className="w-full">
            <div className="w-full flex items-center gap-4">
              <select
                value={selectedOption}
                onChange={handleChange}
                className="mt-16 w-full px-2 py-1 rounded-lg text-center border bg-white shadow-md"
              >
                {Object.keys(datasets).map((option) => (
                  <option
                    key={option}
                    value={option}
                    className="bg-white text-black"
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <ul className="w-full flex flex-col gap-2 mt-2">
              {datasets[selectedOption]?.length > 0 ? (
                datasets[selectedOption].map((dataset, index) => (
                  <li
                    key={index}
                    className="text-black text-left whitespace-nowrap pl-6"
                  >
                    {dataset}
                  </li>
                ))
              ) : (
                <li className="text-gray-500 text-left pl-6">Coming Soon</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboardNew;
