import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PasswordRenewalSuccessful = () => {
  const navigate = useNavigate();
  const [isNavigated, setIsNavigated] = useState(false);

  const handleSignInClick = () => {
    setIsNavigated(true);
    navigate('/sign-in', { replace: true });
    window.location.reload();
  };

  return (
    <div className="text-center">
      <h2>Password Reset Successful</h2>
      <p>Your password has been successfully updated.</p>
      <button 
        onClick={handleSignInClick} 
        className="font-semibold text-indigo-600 no-underline hover:text-indigo-500 hover:underline"
      >
        Sign In
      </button>
      {isNavigated && <div>Redirecting...</div>}
    </div>
  );
};

export default PasswordRenewalSuccessful;
