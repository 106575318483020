import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from "../../packages/toasts/constants";

const InputNode = ({ label, value, onChange, type = "text" }) => (
  <div className="mb-4">
    <label className="font-medium text-lg mb-2 block">{label}:</label>
    {type === "textarea" ? (
      <textarea
        className="w-full border border-gray-300 p-2 rounded"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={label}
      />
    ) : (
      <input
        type={type}
        className="w-full border border-gray-300 p-2 rounded"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={label}
      />
    )}
  </div>
);

const EditDomainModal = ({ show, setShow, onEdit, selectedDomain }) => {
  const [domain, setDomain] = useState("");
  const [domainDescription, setDomainDescription] = useState("");
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    if (selectedDomain) {
      setDomain(selectedDomain.domain_name || "");
      setDomainDescription(selectedDomain.domain_description || "");
    }
  }, [selectedDomain]);

  const handleUpdate = () => {
    if (!domain) {
      addToast({
        message: "Domain name cannot be empty.",
        variant: TOAST_VARIANTS.ERROR,
      });
      return;
    }

    const updatedData = {
      domain_name: domain,
      domain_description: domainDescription,
    };

    onEdit(updatedData);
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Edit Domain</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShow(false)}
          >
            ✕
          </button>
        </div>
        <form>
          <InputNode
            label="Domain Name"
            value={domain}
            onChange={setDomain}
          />
          <InputNode
            label="Domain Description"
            value={domainDescription}
            onChange={setDomainDescription}
            type="textarea"
          />
        </form>
        <div className="flex justify-end mt-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            onClick={handleUpdate}
          >
            Update
          </button>
          <button
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditDomainModal;