import React from "react";

import Banner from "./Banner";
import DFSDatasetDomain from "./DFSDatasetDomain";
import DFSAchievements from "./DFSAchievements";
import DFSPartner from "./DFSPartners";

const Home = () => {
  return (
    <div className="bg-white w-full">
      <Banner />
      <DFSDatasetDomain />
      <DFSAchievements />
      <DFSPartner />
    </div>
  );
};

export default Home;
