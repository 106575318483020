import React, {  useState } from "react";
import {  useNavigate } from "react-router-dom";
import { Button } from '../styled/Buttons.jsx'
import axios from 'axios'
import { url } from '../../creds.js'

const ResendVerificationLink = () => {
  const [email, setEmail] = useState('')
  const [issuccess, setIsSuccess] = useState(true)
  const navigate = useNavigate()

  const handleResendVerification = async () => {
    axios
      .get(url + `resend-email-verification?email=${email}`)
      .then((res) => {
        console.log(JSON.stringify(res, null, 2))
        setIsSuccess(true)
      })
      .catch((err) => {
        setIsSuccess(false)
      })
    if (issuccess) navigate('/verification-link-sent')
    else navigate('/verification-fail')
  }

  return (
    <div className="text-center bg-white shadow-md rounded-lg p-6 mx-auto my-auto">
      <h2 className="text-2xl font-bold mb-4">
        Your Email verification is Pending!
      </h2>
      <p className="text-lg mb-2">.</p>
      <div className="mt-4">
        <label htmlFor="email" className="m-2 text-base">
          Provide email address to resend the verification link
        </label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          id="form2Example1"
          name="email"
          type="email"
          placeholder="Email"
          autoComplete="email"
          required
          className="m-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
        />
      </div>
      <div className="mt-4">
        <Button.Blue
          type="submit"
          className="justify-center  w-1/2 m-4"
          onClick={handleResendVerification}
        >
          Resend Verification Email
        </Button.Blue>
      </div>
    </div>
  )
}

export default ResendVerificationLink;
