import React, { useEffect, useState, useRef } from "react";
import Table from "../../components/styled/Table";
import { fetchDatasets } from "../../services/DatasetService";
import { useNavigate } from "react-router-dom";

const DatasetTable = () => {
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [domains, setDomains] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const navigate = useNavigate();

  const fetchDataset = async () => {
    setLoading(true);
    try {
      const res = await fetchDatasets(); 
      const formattedData = res.domainDatasets.domains.flatMap((domain) =>
        domain.datasets.map((dataset) => ({
          dataset_id: dataset.dataset_id,
          datasetName: dataset.dataset_name,
          domain: domain.domainName,
          dataset_overview: dataset.dataset_overview,
          description: dataset.dataset_description,
          contentType: dataset.content_type,
          usage: dataset.dataset_usage,
        }))
      );
      setDatasets(formattedData);
      setDomains(res.domainDatasets.domains.map((domain) => domain.domainName));
    } catch (err) {
      setError(err.message || "Failed to fetch datasets");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataset();
  }, []);

  const handleDomainSelection = (event) => {
    const value = event.target.value;
    setSelectedDomains((prev) =>
      prev.includes(value) ? prev.filter((domain) => domain !== value) : [...prev, value]
    );
    setSearchTerm(""); 
  };

  const filteredDatasets = datasets.filter(
    (dataset) =>
      selectedDomains.length === 0 || selectedDomains.includes(dataset.domain)
  );

  const columns = [
    {
      key: "datasetName",
      label: "Dataset Name",
      sortable: true,
    },
    {
      key: "domain",
      label: "Domain",
      sortable: true,
    },
    {
      key: "dataset_overview",
      label: "Dataset Overview",
      sortable: false,
    },
    // {
    //   key: "description",
    //   label: "Description",
    //   sortable: false,
    // },
    {
      key: "contentType",
      label: "Content Type",
      sortable: false,
    },
    {
      key: "usage",
      label: "Usage",
      sortable: false,
    },
  ];

  const actions = [
    {
      label: "Details",
      className: "px-3 py-2 rounded-lg text-sm text-green-700 bg-white border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-blue-500",
      handler: (dataset) => {
        if (dataset?.dataset_id) {
          navigate(`/dataset-details/${dataset.dataset_id}`);
        } else {
          console.error("Dataset ID not found in the row data.");
        }
      },
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  const noData = filteredDatasets.length === 0;

  return (
    <div className="flex flex-col w-full bg-white mb-2 relative overflow-visible min-h-[400px]">
      {noData ? (
        <>
          <table className="table-auto w-full border-collapse">
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index} className="border-b text-left p-2 font-normal">
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <div className="text-center text-gray-500 mt-4 text-xl font-bold py-10 rounded-lg w-full">
            No dataset found with this name.
          </div>
        </>
      ) : (
        <Table
          data={filteredDatasets}
          columns={columns}
          pageSize={10}
          actions={actions}
          title="All Datasets"
          showDomainDropdown={true}
          domains={domains}
          selectedDomain={selectedDomain}
          setSelectedDomain={setSelectedDomain}
        />
      )}
    </div>
  );
};

export default DatasetTable;
