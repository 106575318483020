import React from "react";

const InvalidFormStyle =
  "bg-red-100 border border-red-500 text-red-900 placeholder-red-700 text-xs rounded-md focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400";

const SignUpInputField = ({
  fieldName,
  labelName,
  inpType,
  touched,
  setTouched,
  errors,
  inputField,
  setInputField,
  placeholder,
  autoComplete,
  required
}) => {
  const handleChange = (e) => {
    setInputField(e.target.value);
    setTouched(true); // Set touched to true on input change
  };

  return (
    <div className="mb-4">
      <label htmlFor={fieldName}>
        {labelName} {required && <span className="text-red-500">*</span>}
      </label>

      <input
        type={inpType}
        id={fieldName}
        className={
           errors 
            ? InvalidFormStyle
            : "appearance-none rounded-md block w-full border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
        }
        value={inputField}
        onBlur={() => setTouched(true)}
        onChange={handleChange}
        onInput={handleChange} // Add onInput event for autofill
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
      />
      {errors && (
        <p className="mt-2 text-xs text-red-600 dark:text-red-500">{errors}</p>
      )}
    </div>
  );
};

export default SignUpInputField;

