import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import { useSelectedDomain } from "../DomainContext/DomainContext";
import { handleFetchDomains } from "../../controllers/datasetController";

export const NavItemWithDropdown = ({
  navItemConfig,
  pathname,
  isDropDown,
  onDomainSelect,
}) => {
  const { selectedDomain, updateSelectedDomain } = useSelectedDomain();
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [domainList, setDomainLst] = useState([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const isActive = navItemConfig.options.some(
    (navItemOption) => navItemOption.pathname === pathname
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  // fetch domain name
  const fetchDropdownData = async () => {
    if (navItemConfig.name === "Datasets" && dynamicOptions.length === 0) {
      try {
        const domainList = await handleFetchDomains(); // Assuming this returns a promise
        // console.log(domainList);
        if (domainList && domainList.domains) {
          const sortedDomains = domainList.domains.sort((a, b) =>
            a.domain_name.localeCompare(b.domain_name)
          );

          const domainNames = sortedDomains.map((domain) => ({
            name: domain.domain_name,
            domainId: domain.domain_id,
            pathname: `/datasets-listing/${domain.domain_name
              .toLowerCase()
              .replace(/\s+/g, "-")}`,
          }));
          const allDatasetsOption = {
            name: "All datasets",
            pathname: "/datasets-listing",
          };
          const extarnalLink = {
            name: "External Link",
            pathname: "/dataset-catalogue",
          };
          // console.log(`Domains: ${JSON.stringify(domainNames)}`);
          const finalDomainList = [
            allDatasetsOption,
            ...domainNames,
            extarnalLink,
          ];
          setDomainLst(finalDomainList); // Save domains for rendering
          setDynamicOptions(finalDomainList); // Set options dynamically
        } else {
          console.error("Domain list is empty or undefined");
        }
      } catch (error) {
        console.error("Failed to fetch dataset options:", error);
      }
    }

    setShowDropdown((prev) => !prev);
  };

  const handleDomainSelect = (domainName) => {
    if (!navItemConfig || navItemConfig.name !== "Datasets") return; // Ensure this runs only for Datasets
    updateSelectedDomain(domainName); // Update selected domain context

  };

  return (
    <button
      className={
        isActive
          ? "text-red-600 font-semibold hover:text-blue-800 focus:outline-0"
          : "text-black font-semibold  hover:text-blue-800 hover:underline focus:outline-0"
      }

      onClick={fetchDropdownData}
    >
      <span className="flex items-center hover:text-blue-800 hover:underline">
        <span>{navItemConfig.name}</span> <BiChevronDown />
      </span>
      {showDropdown && (
        <div
          ref={dropdownRef}
          className={`bg-white pl-3 flex flex-col items-start gap-2 pb-3 mt-2 pt-2 ${
            isDropDown ? "" : "absolute top-14 pr-4"
          }`}
          style={{
            maxHeight: "calc(5 * 2.3rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {(dynamicOptions.length > 0
            ? dynamicOptions
            : navItemConfig.options
          ).map((navItemOptionConfig, index) => {
            const handleClick = () =>
              handleDomainSelect(navItemOptionConfig.name);
            const className =
              pathname === navItemOptionConfig.pathname
                ? "text-red-600 font-semibold focus:outline-0"
                : "text-black text-left  hover:text-blue-800 hover:underline";
            return navItemOptionConfig.redirect ? (
              <a
                className={className}
                href={navItemOptionConfig.pathname}
                key={index}
                target={navItemOptionConfig.target || "_self"}
                rel={navItemOptionConfig.rel || ""}
              >
                {navItemOptionConfig.name}
              </a>
            ) : (
              <Link
                className={className}
                to={navItemOptionConfig.pathname}
                key={index}
                onClick={handleClick}
              >
                {navItemOptionConfig.name}
              </Link>
            );
          })}
        </div>
      )}
    </button>
  );
};
