import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUploadDetails, prevPage, resetForm, nextPage } from '../../redux/formSlice';
import { Modal } from '../styled/Modal';
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from '../../packages/utils/constants';
import UploadComponent from '../upload/UploadComponent';
import TusUploader from '../upload/TusUploader';
import { editDatasetDetails, editVersionDetails } from '../../services/DatasetService';
import AlertBox from '../styled/AlertBox';
import axios from 'axios';
import { authUrl } from '../../creds';
import { auth_config } from '../../config/config';
import DatasetDetails from './DatasetDetailsBody';
import DatasetVersionDetails from './DatasetVersionDetails';
import {tokenDetails } from '../../services/AxiosInstance';
import { setUploading } from '../../redux/uploadSlice';
import { set } from 'date-fns';

const UploadDetails = ({ 
  setCurrentStep, 
  hasDatasetFiles: propsHasDatasetFiles = false, 
  hasReadmeFiles: propsHasReadmeFiles = false, 
  totalUploadSize: propsTotalUploadSize = 0,
  onComplete 
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const datasetDetails = useSelector((state) => state.form.datasetDetails); 
  const versionDetails = useSelector((state) => state.form.versionDetails);
  const currentPage = useSelector((state) => state.form.currentPage); 
  const userId = useSelector(state => state.auth.userId);
  const sessionId = useSelector(state => state.auth.sessionId);
  const [totalUploadSize, setTotalUploadSize] = useState(propsTotalUploadSize);
  const [hasDatasetFiles, setHasDatasetFiles] = useState(propsHasDatasetFiles);
  const [hasReadmeFiles, setHasReadmeFiles] = useState(propsHasReadmeFiles);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [hasAnyFilesInProgress, setHasFilesInProgress] = useState(false);
  const [tokenRenewalTimer, setTokenRenewalTimer] = useState(null);
  const acceptedFileTypesReadme = ['.pdf', '.doc', '.docx', '.txt', '.md']; 
  const [fileStates, setFileStates] = useState({});
  const [readmeInProgress, setReadmeInProgress] = useState(false);

  useEffect(() => {
    setHasFilesInProgress(Object.values(fileStates).some( state => state.newFiles.length > 0 || state.isUploadInProgress ) || readmeInProgress);
  }, [fileStates, readmeInProgress]);

  useEffect(() => {
    return () =>
      dispatch(setUploading(false));
  }, []);
  const handleFileStatesChange = (uploaderId) => (newStates) => {
    setFileStates(prevStates => ({
      ...prevStates,
      [uploaderId]: newStates
    }));
  };
  const renewAccessToken = async () => {
    try {
        console.log("Renewing token...");
        const renewTokenJSON = {
            userId: userId,
            appName: "dfs",
        };
        await axios.post(
            `${authUrl}renew-token`,
            renewTokenJSON,
            {
                headers: {'Session-Id': sessionId},
                withCredentials: true,
            }
        );
    } catch (renewError) {
      const event = new CustomEvent('sessionExpired', { detail: { userName: tokenDetails.userName } });
      window.dispatchEvent(event);    
    }
  };
  useEffect(() => {
    let timer;
    if (hasAnyFilesInProgress) {
        console.log("Timer started");
        renewAccessToken();
        timer = setInterval(renewAccessToken, auth_config.ACCESS_TOKEN_EXPIRY - auth_config.TOKEN_RENEW_THRESHOLD);
        setTokenRenewalTimer(timer);
        dispatch(setUploading(true));
    } else {
        console.log("Timer stopped");
        if (tokenRenewalTimer) {
            clearInterval(tokenRenewalTimer);
            setTokenRenewalTimer(null);
        }
        dispatch(setUploading(false));
    }
    return () => {
        if (timer) {
            clearInterval(timer);
            setTokenRenewalTimer(null);
        }
    };
  }, [hasAnyFilesInProgress]);



  const handleUploadSize = (size) => {
    setTotalUploadSize(prevSize => prevSize + size);
  };

  const handlePrev = () => {
    setCurrentStep(currentPage-1);
    dispatch(prevPage());
  };

  const handlePreview = () => {
    if (totalUploadSize <= 0) {
      addToast({ 
        message: 'Please upload at least one dataset file before proceeding', 
        variant: TOAST_VARIANTS.ERROR 
      });
      return;
    }
    if (!hasReadmeFiles) {
      addToast({ 
        message: 'Please upload readme file before proceeding', 
        variant: TOAST_VARIANTS.ERROR 
      });
      return;
    }
    if (hasAnyFilesInProgress) {  
      addToast({
        message: 'Please wait for the files to finish uploading',
        variant: TOAST_VARIANTS.ERROR
      });
      return;
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = async () => {
    try {
      const datasetId = datasetDetails.datasetId || localStorage.getItem('datasetId');
      const updatedData = {
        dataset_size: totalUploadSize
      };
      await editDatasetDetails(datasetId, updatedData);
      const updaetedVersionData = {
        verification: 'pending',
      };
      await editVersionDetails(versionDetails.datasetVersionId, updaetedVersionData);
      dispatch(resetForm());
      setIsModalOpen(false);
      onComplete?.();
      setIsAlertOpen(true); 
    } catch (error) {
      // addToast({ message: `Failed to create dataset: ${error.message}`, variant: 'error' });
      console.log("errro responseeee",error)
      if(error.response.status !==401)
        alert(`Failed to create dataset: ${error.message}`);
      setIsModalOpen(false);
    }
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle closing the alert and navigating
  const handleAlertClose = () => {
    setIsAlertOpen(false);
    navigate('/'); 
  };

  return (
    <>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base/7 font-semibold text-gray-900">Upload Files</h2>
          <div className="mt-2">
            {propsHasReadmeFiles ? 
              <p className="mt-1 text-sm/2 text-gray-600">Readme Files already uploaded<br/> </p> :
              <>
                <p className="mt-1 text-sm/6 text-gray-600">Add readme file <span className="text-red-500">{"*"}</span></p>
                <TusUploader 
                  fileCategory={"README"}
                  onStart={() => {setReadmeInProgress(true)}}
                  onSuccess={() => {setHasReadmeFiles(true); setReadmeInProgress(false)}}
                  onRemove={() => {setHasReadmeFiles(false); setReadmeInProgress(false)}}
                  databaseId={localStorage.getItem('datasetId') || datasetDetails.datasetId}
                  versionId={versionDetails.datasetVersionId}
                  acceptedFileTypes={acceptedFileTypesReadme.join(',')}
                  />
              </>
            }
            <p className="mt-1 text-sm/6 text-gray-600">Add dataset files <span className="text-red-500">{"*"}</span></p>
            <UploadComponent 
              databaseId={localStorage.getItem('datasetId') || datasetDetails.datasetId}
              versionId={versionDetails.datasetVersionId}
              onUploadComplete={() => setHasDatasetFiles(true)}

              onUploadSize={handleUploadSize}
              onFileStatesChange={handleFileStatesChange('datasets')}
            />            
            <p className="mt-1 text-sm/6 text-gray-600">Add sample version</p>
            <UploadComponent 
              fileCategory='SAMPLE-VERSION' 
              databaseId={localStorage.getItem('datasetId') || datasetDetails.datasetId}
              versionId={versionDetails.datasetVersionId}
              onFileStatesChange={handleFileStatesChange('samples')}
            />
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <div className="mt-6 flex items-center  justify-between gap-x-6">
          {/* <button onClick={handlePrev} className="px-4 py-2 bg-gray-300 rounded">Previous</button> */}
          <button 
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: (hasAnyFilesInProgress || (totalUploadSize <= 0) || !hasReadmeFiles) ? '#e0e0e0' : '#007bff',
              color: (hasAnyFilesInProgress || (totalUploadSize <= 0) || !hasReadmeFiles) ? '#999' : 'white',
              cursor: (hasAnyFilesInProgress || (totalUploadSize <= 0) || !hasReadmeFiles) ? 'not-allowed' : 'pointer',
            }}
            onClick={handlePreview}
          >
            Preview Details
          </button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onCancel={handleCancelModal}
        size='xl'
      >
          <DatasetDetails datasetId={datasetDetails.datasetId}/>
          <br />
          <DatasetVersionDetails datasetVersionId={versionDetails.datasetVersionId}/>
      </Modal>
      {isAlertOpen && (
        <AlertBox
          message="Files uploaded successfully"
          onClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default UploadDetails;