import React, { useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import Tus from '@uppy/tus';
import Url from '@uppy/url';
import styled from 'styled-components';
import axios from 'axios';
import axiosInstance, {tokenDetails,failedRequestsQueue} from '../../services/AxiosInstance';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/url/dist/style.css';
// import SessionManager from './SessionManager';
import { DUUrl, authUrl } from '../../creds';
import { useSelector } from 'react-redux';
import { upload_config } from '../../config/config';

const UploadComponent = ({ 
  maxFiles = null, 
  fileCategory = 'DATASET-FILE',   
  onUploadComplete, 
  onFilesRemoved, 
  databaseId, 
  versionId, 
  onUploadSize,  
  allowedFileTypes = null,
  onFileStatesChange
}) => {
  const userId = useSelector(state => state.auth.userId);
  const sessionId = useSelector(state => state.auth.sessionId);
  const [uppy, setUppy] = useState(null);
  const [uploadConfig, setUploadConfig] = useState({
    databaseId: databaseId || localStorage.getItem('datasetId'),
    versionId: versionId || localStorage.getItem('datasetVersionId'),
    appName: 'DFS',
    fileCategory: fileCategory
  });
  const getNoteMessage = () => {
    let message = '';
    if (maxFiles) {
      message += maxFiles === 1 ? 'Single file upload only' : `Maximum ${maxFiles} files allowed`;
    }
    if (allowedFileTypes) {
      message += message ? '. ' : '';
      message += `Allowed types: ${allowedFileTypes.join(', ')}`;
    }
    return message;
  };
  const handleTokenRefresh = async (error) => {
    try {
      console.log("Renewing token...");
      const renewTokenJSON = {
        userId: userId,
        appName: "dfs",
      };
      await axios.post(
        `${authUrl}renew-token`,
        renewTokenJSON,
        {
          headers: {'Session-Id': sessionId},
          withCredentials: true,
        }
      );
      return;
    } catch (renewError) {
      const event = new CustomEvent('sessionExpired', { detail: { userName: tokenDetails.userName } });
      window.dispatchEvent(event);
      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({ config: error.config, resolve, reject });
      });
    }
  };
  const handleUploadSuccess = () => {
    if (onUploadComplete) {
      onUploadComplete();
    }
  };
  // Call when all files are removed
  const handleRemoveAllFiles = () => {
    if (onFilesRemoved) {
      onFilesRemoved();
    }
  };

  const updateFileStates = (uppyInstance) => {
    console.log('Updating file states...');
    if (uppyInstance && onFileStatesChange) {
      const fileStates = uppyInstance.getObjectOfFilesPerState();
      onFileStatesChange(fileStates);
      console.log('File states updated:', fileStates);
    }
  };

  const deleteFileFromMinio = async (fileId) => {
    try {
      console.log('Attempting to delete file:', fileId);
      const response = await axiosInstance.delete(`${DUUrl}upload/v1/tus/${fileId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Tus-Resumable': '1.0.0',  // Add this required Tus header
          ...uploadConfig
        }
      });
      console.log('File deleted successfully from MinIO');
    } catch (error) {
      console.error('Error deleting file from MinIO:', error.response || error);
      throw new Error('Failed to delete file from storage');
    }
  };
  useEffect(() => {
    console.log(databaseId, versionId);
  }, [databaseId, versionId])
  
  // Initialize or update Uppy instance when config changes
  useEffect(() => {
    // Cleanup previous instance
    if (uppy) {
      uppy.close();
    }
      
    const uppyInstance = new Uppy({
      id: 'uppy'+fileCategory,
      hideInformer: true,
      debug: true,
      autoProceed: false,  
      restrictions: {
        ...(maxFiles && { maxNumberOfFiles: maxFiles }),
        ...(allowedFileTypes && { allowedFileTypes: allowedFileTypes }),
      },
      note: maxFiles === 1 
      ? 'Single file upload only' 
      : `Maximum ${maxFiles} files allowed${allowedFileTypes ? 
          `. Allowed types: ${allowedFileTypes.join(', ')}` : ''}`
      
    }).use(Tus, {
      limit: upload_config.CONCURRENT_UPLOADS_LIMIT,
      endpoint: DUUrl + "upload/v1/tus",
      chunkSize: upload_config.CHUNK_SIZE,
      retryDelays: [0, 1000, 3000, 5000],
      metadata: {
        filename: file => file.name,
        filetype: file => file.type,
      },
      // cookiesRule: 'include',
      withCredentials: true,
      headers: uploadConfig,
      removeFingerprintOnSuccess: true,
      // uncomment for upload via url setup
      // }).use(Url, {
      //   companionUrl: "http://localhost:3020",
      //   companionAllowedHosts: /.*/,
      });

      // Event listeners
      uppyInstance.on('error', (error) => {
        console.error('Uppy error:', error.message, error.details);
      });

      uppyInstance.on('file-added', (file) => {
        updateFileStates(uppyInstance);
      });

      uppyInstance.on('upload-started', (file) => {
        console.log('Upload started:', file.name);
        updateFileStates(uppyInstance);
      });

      uppyInstance.on('upload-success', (file, response) => {
        console.log('File uploaded successfully:', file.name, response);
        handleUploadSuccess();
        onUploadSize?.(file.size);
        updateFileStates(uppyInstance);
      });

      uppyInstance.on('upload-error', async (file, error) => {
        if (error.message.includes('response code: 498') && error.message.includes('Invalid or expired access token')) {
          await handleTokenRefresh(uppyInstance);
          await uppyInstance.retryUpload(file.id);
        }
        console.error("Upload error:", error.message);
        updateFileStates(uppyInstance);
      }); 

      uppyInstance.on('upload-progress', (file, progress) => {
        console.log('Upload progress:', file.name, progress.bytesUploaded, progress.bytesTotal);
        updateFileStates(uppyInstance);
      });

      uppyInstance.on('file-removed', async (file) => {
        try {
          const fileId = file.response?.uploadURL?.split('/').pop();
          
          if (fileId && !file.isRemoved) {
            file.isRemoved = true;
            await deleteFileFromMinio(fileId);
          }
            if (file.progress?.uploadStarted && file.progress?.uploadComplete) {
              onUploadSize?.(-file.size);
            }  
          if (onFilesRemoved) {
            onFilesRemoved(file);
          }
        } catch (error) {
          console.error('Error handling file removal:', error);
        }
        updateFileStates(uppyInstance);
      });
      
      uppyInstance.on('reset-progress', () => {
        updateFileStates(uppyInstance);
      });

      setUppy(uppyInstance);

    return () => {
      if (uppy) {
        uppy.close();
      }
    };
  }, [uploadConfig, maxFiles]);

  if (!uppy) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ transform: 'translateZ(50px)'}}>
      {/* <SessionManager operation_type="upload" application="dfs"/> */}
      <Dashboard
        uppy={uppy}
        // plugins={['Url']} // uncomment For upload via URL setup
        height="480px"
        width="900px"
        hideUploadButton={false}
        showRemoveButtonAfterComplete={true}
        hideCancelButton={false}
        showProgressDetails={true}
        doneButtonHandler={null}
        proudlyDisplayPoweredByUppy={false}
        note={getNoteMessage()} 
      />
    </div>
  );
};

export default UploadComponent;