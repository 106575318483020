import React, { useEffect } from "react";

const DownloadMonitor = ({ downloadStatus }) => {

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (downloadStatus === "Downloading...") {
        event.preventDefault();
        event.returnValue = "Download is in progress. Do you really want to leave?";
      }
    };

    const handleBackButton = (event) => {
      if (downloadStatus === "Downloading...") {
        event.preventDefault();
        window.history.pushState(null, "", window.location.href);
        alert("Download is in progress. Please wait until it completes.");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [downloadStatus]);

  return null;
};

export default DownloadMonitor;
