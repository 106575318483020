import { USER_STATE } from "../../withAuth";

import {
  FaUserCog,
  FaUsers,
  FaDatabase,
  FaUser,
  FaPlus,
  FaRegPaperPlane,
  FaVideo,
  FaEdit,
  FaShieldAlt,
  FaChartBar,
  FaCheckCircle,
  FaPlusSquare,
  FaMoneyBillWave,
} from "react-icons/fa";

// Import for Specific Admin Control
import AdminDashBoard from "../../views/adminPanel/AdminDashBoard";
import AdminVerfyDataset from "../../views/adminPanel/AdminVerfyDataset";
import AdminVerfyDUA from "../../views/adminPanel/AdminvVerfyDUA";
import AdminAddDomain from "../../views/adminPanel/AdminAddDomain";
import AdminAccControl from "../../views/adminPanel/AdminAccessControl";
import AdminVendorKYC from "../../views/adminPanel/AdminVendorKYC";
import AdminVendorPay from "../../views/adminPanel/AdminVendorPay";
import AdminPayment from "../../views/adminPanel/AdminPayment";
import AdminAddDatasetCatalogue from "../../views/adminPanel/AdminAddDatasetCatalogue";
import AdminDashboardNew from "../../views/adminPanel/AdminDashboardNew";

// Import for Specific Default User Control

// Import for Specific Video Uploader User Control

// Import for Specific Video Annotator User Control

// Common Component Import for All User Controls
import MyDownloadUserAgreement from "../../views/myDashboard/MyDownloadUserAgreement";
import MyDatasetCreate from "../../views/myDashboard/MyDatasetCreate";
import MyDatasetsList from "../../views/myDashboard/MyDatasetsList";
import MyProfile from "../../views/myDashboard/MyProfile";
import MyGroups from "../../views/myDashboard/MyGroups";
import MyDatasetRequests from "../../views/myDashboard/MyDatasetRequests";
import MyVideoCreate from "../../views/myDashboard/MyVideoCreate";
import MyVideoAnotate from "../../views/myDashboard/MyVideoAnotate";
import MyVideoList from "../../views/myDashboard/MyVideoList";

export const SIDE_BAR_ITEM_CONFIG = [
  {
    id: 1,
    href: "#myStats",
    label: "My Stats",
    icon: FaChartBar,
    permissions: [USER_STATE.ANY],
    component: AdminDashBoard,
    tooltipId: "myStats",
    tooltip: "My Stats",
  },
  {
    id: 2,
    href: "#verificationOfDatasets",
    label: "Manage Datasets",
    icon: FaCheckCircle,
    permissions: [USER_STATE.ADMIN],
    component: AdminVerfyDataset,
    tooltipId: "verificationOfDatasets",
    tooltip: "Manage Datasets",
  },
  {
    id: 3,
    href: "#verificationOfDUA",
    label: "Manage DUA",
    icon: FaCheckCircle,
    permissions: [USER_STATE.ADMIN],
    component: AdminVerfyDUA,
    tooltipId: "verificationOfDUA",
    tooltip: "Manage DUA",
  },
  {
    id: 4,
    href: "#addDomain",
    label: "Manage Domain",
    icon: FaPlusSquare,
    permissions: [USER_STATE.ADMIN],
    component: AdminAddDomain,
    tooltipId: "addDomain",
    tooltip: "Manage Domain",
  },
  {
    id: 5,
    href: "#userAccessControl",
    label: "Manage Access Control",
    icon: FaUserCog,
    permissions: [USER_STATE.ADMIN],
    component: AdminAccControl,
    tooltipId: "userAccessControl",
    tooltip: "Manage Access Control",
  },
  {
    id: 19,
    href: "#adminAddDatasetCatalogue",
    label: "Manage Dataset Catalogue",
    icon: FaDatabase,
    permissions: [USER_STATE.ADMIN],
    component: AdminAddDatasetCatalogue,
    tooltipId: "adminAddDatasetCatalogue",
    tooltip: "Manage Dataset Catalogue",
  },
  
  // {
  //   id: 6,
  //   href: "#customers",
  //   label: "Vendors KYC",
  //   icon: FaUsers,
  //   permissions: USER_STATE.ADMIN,
  //   component: AdminVendorKYC,
  // },
  // {
  //   id: 7,
  //   href: "#vendorPayout",
  //   label: "Vendors Payout",
  //   icon: FaMoneyBillWave,
  //   permissions: USER_STATE.ADMIN,
  //   component: AdminVendorPay,
  // },
  // {
  //   id: 8,
  //   href: "#payment",
  //   label: "Payment",
  //   icon: FaMoneyBillWave,
  //   permissions: USER_STATE.ADMIN,
  //   component: AdminPayment,
  // },
  {
    id: 10,
    href: "#myDatasets",
    label: "My Datasets",
    icon: FaDatabase,
    permissions: [USER_STATE.ANY],
    component: MyDatasetsList,
    tooltipId: "myDatasets",
    tooltip: "My Datasets",
  },
  {
    id: 11,
    href: "#Create",
    label: "Upload Dataset",
    icon: FaPlus,
    permissions: [USER_STATE.ANY],
    component: MyDatasetCreate,
    tooltipId: "Create",
    tooltip: "Upload Dataset",
  },
  {
    id: 13,
    href: "#myAgreements",
    label: "My Agreements",
    icon: FaShieldAlt,
    permissions: [USER_STATE.ANY],
    component: MyDownloadUserAgreement,
    tooltipId: "myAgreements",
    tooltip: "My Agreement",
  },
  {
    id: 14,
    href: "#myDatasetRequests",
    label: "My Dataset Request",
    icon: FaRegPaperPlane,
    permissions: [USER_STATE.ANY],
    component: MyDatasetRequests,
    tooltipId: "myDatasetRequests",
    tooltip: "My Dataset Requests",
  },
  {
    id: 20,
    href: "#myVideoList",
    label: "My Videos",
    icon: FaDatabase,
    permissions: [USER_STATE.UPLOADER, USER_STATE.ANNOTATOR, USER_STATE.ADMIN],
    component: MyVideoList,
  },
  {
    id: 15,
    href: "#myVideoCreate",
    label: "My Video Create",
    icon: FaVideo,
    permissions: [USER_STATE.UPLOADER, USER_STATE.ANNOTATOR, USER_STATE.ADMIN],
    component: MyVideoCreate,
    tooltipId: "myVideoCreate",
    tooltip: "My Video Create",
    // disabled: true
  },

  {
    id: 16,
    href: "#myVideoAnotate",
    label: "My Video Annotate",
    icon: FaEdit,
    permissions: [USER_STATE.ANNOTATOR, USER_STATE.ADMIN],
    component: MyVideoAnotate,
    tooltipId: "myVideoAnotate",
    tooltip: "My Video Annotate",
    // disabled: true
  },

  // {
  //   id: 17,
  //   href: "#myGroups",
  //   label: "My Groups",
  //   icon: FaUsers,
  //   permissions: USER_STATE.ANY,
  //   // component: MyGroups,
  //   tooltipId: "myGroups",
  //   tooltip: "Coming soon",
  //   disabled: true
  // },

  {
    id: 18,
    href: "#myProfile",
    label: "My Profile",
    icon: FaUser,
    permissions: [USER_STATE.ANY],
    component: MyProfile,
    tooltipId: "MyProfile",
    tooltip: "My Profile",
  },
];

export const SIDE_BAR_ITEM_MAP = SIDE_BAR_ITEM_CONFIG.reduce((map, item) => {
  if (item.component) {
    map[item.id] = item.component;
  }
  return map;
}, {});
