import { useState, useRef, useCallback, useEffect } from "react";
import { TOAST_VARIANTS } from "../utils/constants";

const DEFAULT_TOAST_DURATION = 5000;
const DEFAULT_INTERVAL_DURATION = 25;
const DEFAULT_VARIANT = TOAST_VARIANTS.INFO;

export const useToast = () => {
  const [toastData, setToastData] = useState([]);

  const addToast = useCallback(
    ({
      message,
      variant = DEFAULT_VARIANT,
      duration = DEFAULT_TOAST_DURATION,
    }) => {
      setToastData((currentToastData) => [
        ...currentToastData.filter((toast) => toast.message !== message),
        {
          variant,
          message,
          duration,
          pending: duration,
        },
      ]);
    },
    [setToastData]
  );

  const deleteToast = useCallback(
    ({ message }) => {
      setToastData((currentToastData) =>
        currentToastData.filter((toast) => toast.message !== message)
      );
    },
    [setToastData]
  );

  const intervalRef = useRef();
  useEffect(() => {
    intervalRef.current = setInterval(
      () =>
        setToastData((currentToastData) =>
          currentToastData
            .map((toast) =>
              toast.pending > 0
                ? {
                    ...toast,
                    pending: toast.pending - DEFAULT_INTERVAL_DURATION,
                  }
                : null
            )
            .filter((a) => a)
        ),
      DEFAULT_INTERVAL_DURATION
    );
    return () => clearInterval(intervalRef.current);
  }, []);

  return { toastData, addToast, deleteToast };
};
