import React, { useState, useContext, memo } from "react";
import { addDomain } from "../../services/DomainService";
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from "../../packages/toasts/constants";
import { useDispatch } from "react-redux";
import { setTab, setTabParams } from "../../redux/commonSlice";
import { InternalTableHeading } from "../styled/GlobalStyles";

const InputNode = memo(({ label, value, onChange, type }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}:<span className="text-red-500">{"*"}</span>
    </label>
    {type === "text" ? (
      <textarea
        className="block w-full rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={label}
      />
    ) : (
      <input
        className="block w-full rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={label}
      />
    )}
  </div>
));

const AddDomainForm = ({ onDomainAdded }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    domain: "",
    domain_description: "",
  });

  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.domain || !formData.domain_description) {
      addToast({
        message: "Please fill all required fields.",
        variant: TOAST_VARIANTS.ERROR,
      });
      setLoading(false);
      return;
    }
    try {
      const response = await addDomain(formData);
      addToast({
        message: response.message || "Domain added successfully!",
        variant: TOAST_VARIANTS.SUCCESS,
      });
      onDomainAdded(); // Refresh the domain list
      dispatch(setTabParams({ 13: { domainId: response.data.domain.domain_id } }));
      dispatch(setTab(13)); // Switch to dua tab
    } catch (error) {
      addToast({
        message: error.response.data.message || "Failed to add domain!",
        variant: TOAST_VARIANTS.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8 bg-gray-100 rounded-lg mb-2">
      <h1 className={InternalTableHeading}>
        Add New Domain
      </h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col gap-4">
          <InputNode
            label="Domain Name"
            placeholder="Domain Name"
            value={formData.domain}
            onChange={(val) => handleInputChange("domain", val)}
            required
          />
          <InputNode
            label="Domain Description"
            type="text"
            placeholder="Domain Description"
            value={formData.domain_description}
            onChange={(val) => handleInputChange("domain_description", val)}
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className="rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
          >
            {loading ? "Adding..." : "Add Domain"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddDomainForm;
