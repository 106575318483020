import axios from 'axios';

const paymentUrl = process.env.REACT_APP_PAYMENT_URL;
console.log("payment URL: ",paymentUrl)

export const requestPostApiCall = async (urlEndPoint, payload) => {
  try {
    const headers = {
      'Accept': 'application/json',
      'access-token': process.env.REACT_APP_ACCESS_TOKEN,
      'client_UUID': process.env.REACT_APP_CLIENT_UUID,
      'x-payment-signature': payload.signature
    };

    const response = await axios({
      url: `${paymentUrl}${urlEndPoint}`,
      data: payload,
      method: 'POST',
      headers: headers,
    });

    return response.data;
  } catch (error) {
    const errorData = error.response ? error.response.data : {
      status: 'error',
      message: 'Unknown error occurred.',
      code: 500,
    };
    throw errorData;
  }
};

export const requestGetApiCall = async (urlEndPoint) => {
  try {
    const headers = {
      'Accept': 'application/json',
      'access-token': process.env.REACT_APP_ACCESS_TOKEN,
      'client_UUID': process.env.REACT_APP_CLIENT_UUID,
    };

    const response = await axios({
      url: `${paymentUrl}${urlEndPoint}`,
      method: 'GET',
      headers: headers,
    });

    return response.data;
  } catch (error) {
    const errorData = error.response ? error.response.data : {
      status: 'error',
      message: 'Unknown error occurred.',
      code: 500,
    };
    throw errorData;
  }
};
