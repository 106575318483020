import React, { useState, useEffect } from "react";
import { getResetPasswordLinkHandler } from "../../controllers/authController";
import { notification_type } from "../../config/config";
import { useLocation } from "react-router-dom";
import notify from "../../packages/toastify/Notify";

const formClass = "px-4 py-8 mx-4 my-4 border border-solid border-gray-300 rounded-lg bg-white shadow-md sm:w-1/3";

const ForgotPassword = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialEmail = params.get("email");
  const [email, setEmail] = useState(initialEmail || "");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  const [appName] = useState("dfs");

  const fetchResetLink = async () => {
    try {
      const result = await getResetPasswordLinkHandler(email, notification_type, appName);
      if (result.status === 200) {
        setEmailSent(true);
        notify("A link to reset password is sent to the registered email address", "success");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const handleSendResetLink = () => {
    if (!email) {
      setError("Please provide an email address.");
      return;
    }
    setError("");
    setLoading(true);
    fetchResetLink();
  };

  if (emailSent) {
    return (
      <div className={formClass}>
        <div className="text-center">
          <p>
            A link to reset password is sent to the registered email address "{email}". <br />
            If you no longer have access to the registered email address, please contact support at{" "}
            <a href="mailto:dfsupport@ihub-data.iiit.ac.in">dfsupport@ihub-data.iiit.ac.in</a>.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={formClass}>
      {loading ? (
        <div className="text-center">
          <p>Sending reset password link...</p>
        </div>
      ) : error ? (
        <div className="text-center">
          <p className="text-red-500">{error} !. If you haven't received reset password link please request a new link after 10 minutes,!</p>
        </div>
      ) : null}
      <div className="text-center">
        <button
          type="button"
          onClick={handleSendResetLink}
          className="font-semibold text-indigo-600 no-underline hover:text-indigo-500 hover:underline"
        >
          Send Reset Link
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;