import React, { useState, useEffect, useRef } from 'react';
import { FaEdit, FaSave } from 'react-icons/fa';

const DisplayDetails = ({
  data,
  isEditable,
  onEdit,
  onSave,
  title,
  inputTypes = {},
  fieldsToDisplay = [],
  customComponents = {},
}) => {
  const [editingFields, setEditingFields] = useState({});
  const [originalValues, setOriginalValues] = useState({});
  const textAreaRefs = useRef({});

  const inputClass =
    'w-full text-sm font-sans border border-gray-200 rounded py-2 px-3 shadow-sm text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500';
  const nonEditableInputClass =
    'w-full text-sm font-sans border border-gray-100 rounded py-2 px-3 shadow-sm text-gray-700 leading-tight focus:outline-none focus:bg-white';

  useEffect(() => {
    Object.keys(textAreaRefs.current).forEach((key) => {
      const textarea = textAreaRefs.current[key];
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    });
  }, [data]);

  const handleInputResize = (key) => {
    const textarea = textAreaRefs.current[key];
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const formatValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return 'null';
    }
    return value;
  };

  const handleValueChange = (key, value) => {
    // Only convert to null if the value is empty or only whitespace
    const finalValue = !value || (typeof value === 'string' && value.trim() === '') ? null : value;
    onEdit(key, finalValue);
  };

  const handleSave = async (key) => {
    if (data[key] !== originalValues[key]) {
      await onSave(key);
    }
    setEditingFields((prev) => ({ ...prev, [key]: false }));
  };

  const renderField = (key, value, tag) => {
    const editable = isEditable(key);
    const editing = editingFields[key];
    const inputType = inputTypes[key]?.type || 'text';
    const CustomComponent = customComponents[key];
    const fieldClass = inputType === 'textarea' ? 'w-full' : 'w-full sm:w-1/2 md:w-1/3';

    if (CustomComponent) {
      return (
        <div key={key} className={`p-2 ${fieldClass}`}>
          <label className="block text-gray-700 font-sans text-sm font-bold mb-2">
            {tag}:
          </label>
          <div className="flex items-center">
            <CustomComponent
              value={value}
              onChange={(newValue) => handleValueChange(key, newValue)}
              editing={editing}
              onSave={() => handleSave(key)}
              onEdit={() => {
                setOriginalValues((prev) => ({ ...prev, [key]: data[key] }));
                setEditingFields((prev) => ({ ...prev, [key]: true }));
              }}
              editable={editable}
            />
          </div>
        </div>
      );
    }

    let input;
    const displayValue = formatValue(value);

    switch (inputType) {
      case 'select':
  input = (
    editable && editing ? (
      <select
        value={value || ''}
        onChange={(e) => handleValueChange(key, e.target.value)}
        className={editing ? inputClass : nonEditableInputClass}
      >
        {inputTypes[key]?.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <span className={nonEditableInputClass}>{displayValue}</span>
    )
  );
  break;

      case 'textarea':
        input = (
          <textarea
            ref={(el) => (textAreaRefs.current[key] = el)}
            value={value || ''}
            onChange={(e) => {
              handleValueChange(key, e.target.value);
              handleInputResize(key);
            }}
            readOnly={!editing}
            className={`${editing ? inputClass : nonEditableInputClass} resize-none overflow-hidden`}
            rows={1}
            placeholder="null"
          />
        );
        break;

      default:
        input = (
          <input
            type={inputType}
            value={value || ''}
            onChange={(e) => handleValueChange(key, e.target.value)}
            readOnly={!editing}
            className={editing ? inputClass : nonEditableInputClass}
            placeholder="null"
          />
        );
    }


    return (
      <div key={key} className={`p-2 ${fieldClass}`}>
        <label className="block text-gray-700 font-sans text-sm font-bold mb-2">
          {tag}:
        </label>
        <div className="flex items-center">
          {input}
          {editable && renderEditButton(key, editing)}
        </div>
      </div>
    );
  };

  const renderEditButton = (key, editing) => {
    return editing ? (
      <button
        onClick={() => handleSave(key)}
        className="ml-4 text-gray-500"
        title="Save"
      >
        <FaSave />
      </button>
    ) : (
      <button
        onClick={() => {
          setOriginalValues((prev) => ({ ...prev, [key]: data[key] }));
          setEditingFields((prev) => ({ ...prev, [key]: true }));
        }}
        className="ml-4 text-gray-500"
        title="Edit"
      >
        <FaEdit />
      </button>
    );
  };

  return (
    <div className="bg-white p-4 border">
      <div className="items-center justify-between pb-6">
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl md-0">
          <span style={{ fontWeight: 500 }}>{title}</span>
        </p>
      </div>
      <div className="flex flex-wrap">
        {fieldsToDisplay.map(({ key, tag }) => renderField(key, data[key], tag))}
      </div>
    </div>
  );
};

export default DisplayDetails;
