import { TOAST_VARIANTS } from '../../src/packages/utils/constants';
import { createVideo } from '../services/VideoService';

export const handleCreateVideo = async (videoData, addToast) => {
  try {
    const newDataset = await createVideo(videoData);
    
    addToast({
      message: 'Video created successfully',
      variant: TOAST_VARIANTS.SUCCESS
    });

    return newDataset;
  } catch (error) {
    // Show error toast notification
    addToast({
      message: `Failed to create video: ${error.message}`,
      variant: TOAST_VARIANTS.ERROR
    });
    
    throw error;
  }
};