import React, {useEffect, useState} from "react";
import Table from "../../components/styled/Table";
import { deleteDatasetVersion, fetchVersionsAdmin } from "../../services/DatasetService";
import { verifyDatasetVersion } from "../../services/DatasetService";
import { DatasetDetails, DatasetVersionDetails, FileDetailsTable } from "../../components";
import { getDuaAgreement } from "../../services/DUAService";
import { 
  FaEye, 
  FaCheck, 
  FaTimes, 
  FaTrash 
} from "react-icons/fa";


const AdminVerifyDataset = () => {
  const [datasetVersions, setDatasetVersions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmName, setDeleteConfirmName] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);  
  const [agreementText, setAgreementText] = useState('');

  const loadVersions = async () => {
    try {
      const response = await fetchVersionsAdmin();
      const versions = (response.datasets || []).map(version => ({
        ...version,
        owner_name: version.owner ? `${version.owner.first_name} ${version.owner.last_name}`.trim() : ''
      }));
  
      setDatasetVersions(versions);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    loadVersions();
  }, []); 

  const handleVerification = async (item, status) => {
    try {
      await verifyDatasetVersion(item.dataset_version_id, status);
      setDatasetVersions(prevVersions => 
        prevVersions.map(version => 
          version.dataset_version_id === item.dataset_version_id
            ? { ...version, verification: status }
            : version
        )
      );
    } catch (error) {
      console.error(`Error ${status} dataset:`, error);
    }
  };
  
  const handleDelete = async (datasetId, versionId) => {
    try {
      await deleteDatasetVersion(datasetId, versionId);
      // Update state or show success message
      loadVersions();
    } catch (error) {
      // Show error message
      console.error(error.message);
    }
  };

  const columns = [
    {
      key: "owner_name",
      label: "Owner Name",
      sortable: true,
      render: (value) => (<p className="w-[150px] truncate">{value}</p>),
    },
    {
      key: "domain_name",
      label: "Domain Name",
      sortable: true,
      render: (value) =>(<p className="w-[150px] truncate">{value}</p>)
    },
    {
      key: "dataset_name",
      label: "Dataset Name",
      sortable: true,
      render: (value) =>(<p className="w-[150px] truncate">{value}</p>)
    },
    {
      key: "version_name",
      label: "Version Name",
      sortable: true,
      render: (value) =>(<p className="w-[150px] truncate">{value}</p>)
    },
    {
      key: "createdAt",
      label: "Creation Date",
      sortable: true,
      render: (value) => new Date(value).toLocaleDateString("en-GB"),
    },
    {
      key: "verification",
      label: "Status",
      sortable: true,
      render: (value) => (
        <p
          className={`px-3 py-2 w-[120px] rounded-lg text-sm text-center font-medium ${
            value.toUpperCase() === "VERIFIED"
              ? "text-green-800 bg-green-300 border-green-500"
              : value.toUpperCase() === "REJECTED"
              ? "text-red-800 bg-red-300 border-red-500"
              : value.toUpperCase() === "PENDING"
              ? "text-blue-800 bg-blue-300 border-blue-500"
              : value.toUpperCase() === "INCOMPLETE"
              ? "text-yellow-800 bg-yellow-200 border-yellow-500"
              : value.toUpperCase() === "UNVERIFIED"
              ? "text-orange-800 bg-orange-300 border-orange-500"
              : "text-gray-800 bg-gray-300 border-gray-500"
          }`}
        >
          {value.toUpperCase()}
        </p>

      ),
    },
  ];

  const actions = [
    {
      tooltip: "View",
      icon: <FaEye />,
      className: "p-2 rounded-lg text-blue-500 bg-white border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: async (item) => {
        setSelectedVersion(item.dataset_version_id);
        setSelectedDataset(item.dataset_id)
        setShowModal(true);

        const agreementData = await getDuaAgreement(item.dataset_id);
        setAgreementText(agreementData.data.md_data);
      },
    },
    {
      tooltip: "Approve",
      icon: <FaCheck />,
      className: "p-2 rounded-lg text-green-500 bg-white border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => handleVerification(item, "verified"),
      isDisabled: (item) => item.verification?.toUpperCase() === "VERIFIED",
      hidden: (item) => item.verification?.toUpperCase() === "INCOMPLETE",
    },
    {
      tooltip: "Reject",
      icon: <FaTimes />,
      className: "p-2 rounded-lg text-red-500 bg-white border border-red-500 hover:bg-red-300 hover:text-red-700 focus:ring-2 focus:ring-red-500",
      handler: (item) => handleVerification(item, "rejected"), 
      isDisabled: (item) => item.verification?.toUpperCase() === "REJECTED",
      hidden: (item) => item.verification?.toUpperCase() === "INCOMPLETE",
    },
    {
      tooltip: "Delete",
      icon: <FaTrash />,
      className: "p-2 rounded-lg text-red-500 bg-white border border-red-500 hover:bg-red-300 hover:text-red-700 focus:ring-2 focus:ring-red-500",
      handler: (item) => {
        setItemToDelete(item);
        setShowDeleteModal(true);
        setDeleteConfirmName('');
      },
      hidden: (item) => item.verification?.toUpperCase() === "VERIFIED" || item.verification?.toUpperCase() === "INCOMPLETE",
    },
  ];

  return (
    <div className="flex flex-col w-full bg-white mb-2">
      <Table 
        data={datasetVersions}
        columns={columns}
        actions={actions}
        pageSize={10}
        title="Datasets Management"
      />
      
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[999]">
          <div className="bg-white rounded-lg p-6 w-3/4 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Dataset Version Details</h2>
              <button 
                onClick={() => setShowModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <span className="text-2xl">&times;</span>
              </button>
            </div>
            <DatasetDetails datasetId={selectedDataset} editable={false} />
            <br/>
            <DatasetVersionDetails datasetVersionId={selectedVersion} editable={false} />
            <br/>
            <FileDetailsTable datasetId={selectedDataset} datasetVersionId={selectedVersion} agreementText={agreementText}/>


          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
          <div className="bg-white rounded-lg p-6 w-1/3">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Confirm Delete</h2>
              <button 
                onClick={() => setShowDeleteModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <span className="text-2xl">&times;</span>
              </button>
            </div>
            <p className="mb-4">Please enter the dataset name to confirm deletion:</p>
            <p className="mb-2 font-bold">{itemToDelete?.dataset_name}</p>
            <input
              type="text"
              value={deleteConfirmName}
              onChange={(e) => setDeleteConfirmName(e.target.value)}
              className="w-full p-2 border rounded mb-4"
              placeholder="Enter dataset name"
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 rounded-lg text-gray-500 bg-white border border-gray-500 hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (deleteConfirmName === itemToDelete?.dataset_name) {
                    handleDelete(itemToDelete.dataset_id, itemToDelete.dataset_version_id);
                    setShowDeleteModal(false);
                  } else {
                    alert('Dataset name does not match');
                  }
                }}
                disabled={deleteConfirmName !== itemToDelete?.dataset_name}
                className={`px-4 py-2 rounded-lg text-white ${
                  deleteConfirmName === itemToDelete?.dataset_name
                    ? 'bg-red-500 hover:bg-red-600'
                    : 'bg-gray-300 cursor-not-allowed'
                }`}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminVerifyDataset;