import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminPanelContent from "./MyProfileContent";
import Sidebar from "../../components/sidebar/Sidebar";
import { setTab, toggleCollapsed } from "../../redux/commonSlice";
import { SIDE_BAR_ITEM_CONFIG } from "../../components/sidebar/SidebarConfig";
import { useLocation } from "react-router-dom";
import { selectIsLoggedIn } from "../../redux/AuthController";

const MyProfilePanel = () => {
  const activeTab = useSelector((state)=> state.common.tab);
  const collapsed = useSelector((state) => state.common.collapsed);
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = selectIsLoggedIn();
  const handleToggleCollapsed = () => {
    dispatch(toggleCollapsed());
  }
  
  useEffect(() => {
    if (location.hash === "#myProfile") {
      dispatch(setTab(18)); // Optional: Sync Redux if needed
      console.log("Navigate to dashboard MyProfile component");
      // Implement logic to display the appropriate MyProfile section
    }
  }, [location.hash, dispatch]);
  if(!isLoggedIn) return <div>Log in to access this page</div>;
  return (
    <div className="hello w-full h-full sm:p-2 md:p-6">
      <div
        className={`my-4 sm:mx-4 mx-4 md:mx-auto ${collapsed
            ? "flex flex-row justify-items-end"
            : "flex flex-row justify-center"
          }`}
      >
        {/* <AdminPanelSidebar */}
        <Sidebar
          tabConfig={SIDE_BAR_ITEM_CONFIG}
          collapsed={collapsed}
          setCollapsed={handleToggleCollapsed}
        />
        <div
          className={`${collapsed
              ? "w-full sm:w-1/1 md:w-w-full lg:w-full"
              : "w-full sm:w-1/1 md:w-3/4 lg:w-full"
            }`}
        >
          <div className="p-3 bg-white h-full border-gray-100 border-2 h-100 ml-3 rounded-1xl">
            <AdminPanelContent activeTab={activeTab} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfilePanel;
