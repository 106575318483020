import React, { useState, useEffect } from "react";

import ContentLoader from "react-content-loader";
import { FlexSection, Heading, SubHeading, HomePageSubHeading, ParaFont } from "../../components/styled/GlobalStyles";
import TeamData from "./TeamDetails.json";

const teamImages = require.context("../../assets/images/teamImages", true);
const HeadingContentLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={150}
    viewBox="0 0 2000 400"
    backgroundColor="#e2e2e2"
    foregroundColor="#ecebeb"
  >
    <rect x="30" y="25" rx="10" ry="10" width="100%" height="40%" />
  </ContentLoader>
);

const DFSTeamLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={200}
    viewBox="0 0 500 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="20" rx="50%" ry="50%" width="70" height="70"/>
    <rect x="80" y="20" rx="3" ry="3" width="920" height="60"/>
    <rect x="80" y="100" rx="3" ry="3" width="920" height="30"/>
  </ContentLoader>
);
const DFSTeamInternal = ({ displayAlumni }) => {
  const title = displayAlumni ? "Our Alumni" : "Our Team Members";
    const alumniCheck = displayAlumni ? "Yes" : "No";
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, []);
  return (
    <>
      <div className="mx-auto px-4 bg-gray-100 pb-4 pt-2">
            {loading ? (
              <div className="pt-8 pb-8 transition-transform duration-300 ease-in-out flex justify-center items-center">
                <HeadingContentLoader />
              </div>
            ) : (
              <h1 className={`${Heading} py-2 sm:mx-8`}>
                {title}
              </h1>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 gap-4 sm:mx-8">
              {TeamData.map(
                (member) =>
                  member.alumni === alumniCheck && (


                    <div
                      key={member.id}
                      className="bg-white shadow-lg text-black font-sans mb-4 border-2 border-gray-300 rounded-md overflow-hidden hover:bg-gray-600 hover:text-white transition ease-in-out duration-300"
                    >
                      {loading ? (
                        <div className="mx-2 transition-transform duration-300 ease-in-out flex justify-center items-center">
                          <DFSTeamLoader />
                        </div>
                      ) : (
                        <div className="flex ">
                          <img
                            src={teamImages(`./${member.profileImage.split('/').pop()}`)}
                            alt={member.fullName}
                            className="w-36 h-36 object-cover p-4 rounded-full"
                          />
                          <div className="p-4">
                            <h3 className="text-xl font-sans font-semibold">
                              {member.fullName}
                            </h3>
                            <p className="text-sm font-sans hover:text-white">
                              {member.designation}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
      
                  )
              )}
              {/* <TeamData displayAlumni={true} /> */}
            </div>
          </div>
    </>
  );
};

export default DFSTeamInternal;
