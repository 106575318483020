import { React, useState, useEffect } from "react";
import {
  FaLinkedin,
  FaFacebook,
  FaYoutube,
  FaInstagramSquare,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ContentLoader from "react-content-loader";
import ihubLogoH from "../../assets/images/logo/ihubLogoH.png";
import mstLogo from "../../assets/images/logo/dstLogo.png";
import nmICPSLogo from "../../assets/images/logo/nmICPSLogo.png";
import iiitLogo from "../../assets/images/logo/iiitLogo.png";
import { Link } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";

const MapLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="300" height="20" />
    <rect x="80" y="40" rx="3" ry="3" width="250" height="20" />
  </ContentLoader>
);

const Footer = () => {
  const currentDateTime = new Date();
  const currentYear = currentDateTime.getFullYear();

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
      <div className="mx-auto p-2 bg-blue-800">
      <div className="grid grid-cols-1 sm:grid-cols-3 rounded p-6 sm:p-6 pb-1 sm:pb-1 gap-6 md:gap-8">
        <div className="sm:md-0 md:lg-4">
          <h5 className="text-white text-lg  font-sans font-bold mb-2">
            India Data
          </h5>
          <p className="text-white text-sm font-sans text-justify-left">
            India Data is a trusted custodian of India-centric datasets,
            dedicated to driving innovation through secure data sharing, robust
            curation, and advanced tools for researchers and businesses
            worldwide.
          </p>
        </div>
        <div className="sm:md-0 md:lg-4">
          <h5 className="text-white text-lg  font-sans font-bold mb-2">
            More Links
          </h5>
          <p className="text-white text-sm font-sans text-justify-left">
            <ul>
              <li><Link to="/about-us" className="text-sm hover:underline">About Us</Link></li>
              <li><Link to="/privacy-policy" className="text-sm hover:underline">Privacy Policy</Link></li>
              <li><Link to="/contact-us" className="text-sm hover:underline">Contact Us</Link></li>
            </ul>
          </p>
        </div>
        <div className="flex justify-left items-center">
          <div className="grid grid-cols-1 gap-2">
            <h5 className=" font-bold font-sans text-xl text-white">
              Powered By
            </h5>
            <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2">
              <div className="flex justify-center bg-white items-center">
                <img
                  className="img-fluid mx-auto md:h-12 lg:h-14"
                  src={mstLogo}
                  alt="logo"
                  responsive
                />
              </div>
              <div className="flex justify-center bg-white items-center">
                <img
                  className="img-fluid mx-auto md:h-12 lg:h-14"
                  src={nmICPSLogo}
                  alt="logo"
                  responsive
                />
              </div>
              <div className="flex justify-center bg-white items-center">
                <img
                  className="img-fluid mx-auto md:h-12 lg:h-14"
                  src={ihubLogoH}
                  alt="logo"
                  responsive
                />
              </div>
              <div className="flex justify-center bg-white items-center">
                <img
                  className="img-fluid mx-auto md:h-12 lg:h-14"
                  src={iiitLogo}
                  alt="logo"
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center py-1">
        <a
          href="https://www.linkedin.com/company/ihub-data/"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
        >
          <FaLinkedin className="text-white text-3xl" />
        </a>
        <a
          href="https://twitter.com/IHUB_Data"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
        >
          <FaXTwitter className="text-white text-3xl" />
        </a>
        <a
          href="https://www.facebook.com/IHub-Data-103031462109849"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
        >
          <FaFacebook className="text-white text-3xl" />
        </a>
        <a
          href="https://www.youtube.com/@INDIADATA-p5r"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
        >
          <FaYoutube className="text-white text-3xl" />
        </a>
        <a
          href="https://www.instagram.com/indiadata2025/"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
        >
          <FaInstagramSquare className="text-white text-3xl" />
        </a>
      </div>

      <div className="">
        <p className="text-center font-sans text-white">
          Copyright © {currentYear} | All rights reserved. IHub-Data,
          IIIT-Hyderabad
        </p>
      </div>
      {/* goto top code */}
      {isVisible && (
        <button
        className="fixed bottom-0 right-0 font-weight-bold z-40 bg-orange-400 p-3 font-extrabold rounded-full m-4"
        onClick={handleScrollToTop}
        >
          <IoIosArrowUp style={{ color: "white" }} className="text-2xl font-extrabold" />
        </button>
      )}
      </div>
  );
};

export default Footer;
