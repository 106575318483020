import { useState, useEffect } from "react";
import { fetchAllVideoCountLimit } from "../../services/VideoService";

const useFetchAllVideoLimits = (refresh) => {
  const [videolimits, setVideoLimits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getVideoLimits = async () => {
      try {
        const videolimitsData = await fetchAllVideoCountLimit();
        setVideoLimits(videolimitsData.limits);
        console.log(videolimits);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getVideoLimits();
  }, [refresh]);

  return { videolimits, loading, error };
};

export default useFetchAllVideoLimits;