import React, {useEffect} from "react";
import DatasetDetailsCard from "../../components/datasets/DatsetDetailsHeader";
import DatasetVersionsTable from "../../components/datasets/DatasetVersionsTable";
import { useParams, useNavigate } from 'react-router-dom';
import { DatasetDetails } from "../../components";
import DatasetStats from "../../components/datasets/DatasetStats";
import { handleFetchDatasetAnalytics } from "../../controllers/datasetController";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";

const DatasetDetailsPublic = () => {
    const { datasetId } = useParams();
    const [datasetStat, setDatasetStats] = React.useState(null);

    useEffect(() => {
        handleFetchDatasetAnalytics(datasetId).then(response => {
            if (response.status===200) {
                setDatasetStats(response?.data?.data);
            }
        });
    }, [datasetId]);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Goes back to the previous page in history
    };

    return (
        <div className="w-full h-full px-16">
            <div className="mx-6 my-4">
            <button onClick={handleBack} className="rounded-lg text-sm px-3 py-2 text-center cursor-pointer text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 py-2 px-3 bg-blue-800 text-white hover:underline font-semibold flex items-center justify-center">
                <MdOutlineKeyboardArrowLeft className="text-xl"/>
                Back
            </button> 
            </div>
            <div className="bg-white shadow-md mx-6 my-4 p-6 rounded-lg">
                <DatasetDetailsCard datasetId={datasetId} />
                <div className="mt-2">
                    <DatasetDetails datasetId={datasetId} editable={true} open={true} />
                    <div className="mt-12">
                    <DatasetVersionsTable datasetId={datasetId} editable={false} open={true}/>
                    </div>
                    <div className="mt-12">
                    <DatasetStats stats={datasetStat} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatasetDetailsPublic;
