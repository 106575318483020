import React from "react";
import DFSTeam from "./DFSTeamInternal"
const teamImages = require.context("../../assets/images/teamImages", true);

const Team = () => {
  
  return (
    <>
      <DFSTeam displayAlumni={false} />
      <DFSTeam displayAlumni={true} />

    </>
  );
};

export default Team;
