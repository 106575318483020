import React, { useState, useEffect } from 'react';
import MDEditor from "@uiw/react-markdown-editor";
import { FaSun, FaMoon } from "react-icons/fa";
import { editdua } from "../../services/DUAService";

export const EditDuaModal = ({ isOpen, onClose, dua, onSave }) => {
  const [editedDua, setEditedDua] = useState(dua);
  const [colorMode, setColorMode] = useState("light");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setEditedDua(dua);
  }, [dua]);

  useEffect(() => {
    document.documentElement.setAttribute("data-color-mode", colorMode);
  }, [colorMode]);

  const validateFields = () => {
    const errors = {};
    if (!editedDua?.duaTitle?.trim()) {
      errors.duaTitle = "DUA Title is required.";
    }
    if (!editedDua?.duaDescription?.trim()) {
      errors.duaDescription = "DUA Description is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateFields()) return;

    try {
      if (editedDua?.duaId) {
        await editdua(editedDua, editedDua.duaId); // Call API to save the edited DUA
        onSave(editedDua); // Pass the updated DUA back to the parent component
      }
    } catch (error) {
      console.error("Error saving edited DUA:", error);
    } finally {
      onClose(); // Close the modal after saving
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Edit DUA</h3>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            DUA Title:
          </label>
          <input
            className={`block w-full rounded-md shadow-sm border ${
              errors.duaTitle ? "border-red-500" : "border-gray-300"
            } focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2`}
            value={editedDua?.duaTitle || ""}
            onChange={(e) => {
              setEditedDua({ ...editedDua, duaTitle: e.target.value, dua_id: dua.dua_id });
              setErrors({ ...errors, duaTitle: "" });
            }}
          />
          {errors.duaTitle && (
            <p className="text-red-500 text-sm mt-1">{errors.duaTitle}</p>
          )}
        </div>
        <div className="relative mb-4">
          <MDEditor
            data-color-mode={colorMode}
            value={editedDua?.duaDescription || ""}
            onChange={(value) => {
              setEditedDua({ ...editedDua, duaDescription: value, dua_id: dua.dua_id });
              setErrors({ ...errors, duaDescription: "" });
            }}
            height="400px"
            enablePreview={true}
          />
          {errors.duaDescription && (
            <p className="text-red-500 text-sm mt-1">{errors.duaDescription}</p>
          )}
          <div className="absolute top-2 right-2 flex items-center">
            {colorMode === "light" ? (
              <FaMoon
                onClick={() => setColorMode("dark")}
                className="h-4 w-4 cursor-pointer text-gray-700"
                title="Switch to Dark Mode"
              />
            ) : (
              <FaSun
                onClick={() => setColorMode("light")}
                className="h-4 w-4 cursor-pointer text-yellow-500"
                title="Switch to Light Mode"
              />
            )}
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};