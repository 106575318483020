import React from "react";
import { Link } from 'react-router-dom'


const SignUpVerificationSuccess = () => {
  return (
    <>
      <div className="text-center bg-white shadow-md rounded-lg p-6 mx-auto my-auto">
        <div className="text-2xl font-bold mb-4">
          Thanks for verifying your email
        </div>
        <div className="text-xl mb-2"></div>
        <Link to="/sign-in" className="text-indigo-600 hover:bg-responseindigo-200 mx-1">
          Click here to Sign In
        </Link>
      </div>
    </>
  )
}
export default SignUpVerificationSuccess;