import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import DisplayDetails from '../styled/DisplayDetails';
import { fetchVersionDetails, editVersionDetails, getDomains, fetchDatasetDetails, editDatasetDetails } from '../../services/DatasetService';
import { useLocation } from 'react-router-dom';
import { selectIsLoggedIn } from "../../redux/AuthController";
import { getUserRole } from './../../services/AuthService';

const DatasetVersionDetails = ({ datasetVersionId: propsDatasetVersionId, editable: propsEditable }) => {
  const location = useLocation();
  const [datasetVersion, setDatasetVersion] = useState({});
  const [error, setError] = useState(null);
  const [domains, setDomains] = useState([]);
  const datasetVersionId = propsDatasetVersionId || location.state?.datasetVersionId;
  const editable = propsEditable ?? location.state?.editable;
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userId = useSelector(state => state.auth.userId);

  const formatDateToYMD = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
  };

  useEffect(() => {
    const fetchDatasetVersion = async () => {
      try {
        const data = await fetchVersionDetails({ datasetVersionId });
        if (data.error) {
          setError('Error fetching dataset version details');
        } else {
          const formattedData = {
            ...data.datasets[0],
            createdAt: formatDateToYMD(data.datasets[0].createdAt),
            updatedAt: formatDateToYMD(data.datasets[0].updatedAt),
            owner_name: `${data.datasets[0].uploader.first_name} ${data.datasets[0].uploader.last_name}`,
          };
          setDatasetVersion(formattedData);
        }
      } catch (err) {
        setError('Error fetching dataset version details');
      }
    };

    const fetchDomains = async () => {
      try {
        const domainData = await getDomains();
        if (domainData && domainData.domains) {
          const domainNames = domainData.domains.map(domain => ({
            value: domain.domain_id,
            label: domain.domain_name,
          }));
          setDomains(domainNames);
        }
      } catch (err) {
        setError('Error fetching domain list');
      }
    };

    fetchDatasetVersion();
    fetchDomains();
  }, [datasetVersionId]);

  const isEditable = (field) => {
    if (field === 'owner_name') return false; 
  
    if (!isLoggedIn) return false;
  
    const roleId = getUserRole().roleName;
    const isOwner = datasetVersion.owner?.user_id === userId;

    const userEditableFields = ['version_description', 'version_name', 'version_overview','version_file_format'];
  
    if (roleId === 'Admin') {
      return userEditableFields.includes(field);
    }
  
    if (isOwner) {
      return userEditableFields.includes(field);
    }
  
    return false;
  };
  
  const handleEdit = (key, value) => {
    if (key === "createdAt" && !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      setError("Date must be in yyyy-mm-dd format");
      return;
    }
    setDatasetVersion(prevData => ({ ...prevData, [key]: value }));
  };

  const handleSave = async (key) => {
    try {
      const valueToSave = datasetVersion[key];

      if (key === "createdAt" && !/^\d{4}-\d{2}-\d{2}$/.test(valueToSave)) {
        setError("Date must be in yyyy-mm-dd format");
        return;
      }

      const updatedField = key === 'domain_name' ? { domain_id: valueToSave } : { [key]: valueToSave };
      if (key === "domain_name") {
        await editDatasetDetails(datasetVersion['dataset_id'], updatedField);
      } else {
        await editVersionDetails(datasetVersion['dataset_version_id'], updatedField);
      }

      const updatedDataset = await fetchDatasetDetails({
        datasetId: datasetVersion['dataset_id'],
      });

      setDatasetVersion({
        ...updatedDataset.datasets[0],
        domain_name: domains.find(domain => domain.value === updatedDataset.datasets[0].domain_id)?.label || "",
      });
    } catch (err) {
      console.error("Error editing field: ", err);
    }
  };

  const inputTypes = {
    domain_name: {
      type: 'select',
      options: domains.map(domain => ({
        value: domain.value, // domain_id
        label: domain.label, // domain_name
      })),
    },
    version_overview: { type: 'textarea' },
    version_description: { type: 'textarea' },
  };

  const fieldsToDisplay = [
    { key: 'dataset_name', tag: 'Dataset Name' },
    { key: 'domain_name', tag: 'Domain Name' },
    { key: 'createdAt', tag: 'Created Date' },
    { key: 'owner_name', tag: 'Version Uploader Name' },
    { key: 'version_name', tag: 'Version Name' },
    { key: 'version_file_format', tag: 'File Format' },
    { key: 'version_overview', tag: 'Overview' },
    { key: 'version_description', tag: 'Description' },
  ];

  const getNestedValue = (key, data) => {
    return key.split('.').reduce((acc, curr) => acc && acc[curr], data);
  };

  if (error) return <div>{error}</div>;

  return (
    <DisplayDetails
      data={datasetVersion}
      isEditable={isEditable}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Dataset Version Details"
      inputTypes={inputTypes}
      fieldsToDisplay={fieldsToDisplay}
      getNestedValue={getNestedValue}
    />
  );
};

export default DatasetVersionDetails;
