import React, { useState, useContext } from 'react';
import UploadComponent from '../upload/UploadComponent';
import { v4 as uuidv4 } from 'uuid';
import { ToastContext } from "../../App";
import { handleCreateVideo } from '../../controllers/videoController';
import { set } from 'date-fns';

const AnotatedVideoCreate = ({parentVideoId, parentVideoName, onSubmit}) => {
  const [modelName, setModelName] = useState('');
  const [error, setError] = useState('');
  const [videoId, setVideoId] = useState(uuidv4());
  const [uploadKey, setUploadKey] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const { addToast } = useContext(ToastContext);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!modelName.trim()) {
        setError('Video name is required');
        return;
      }
      if (!fileUploaded) {
        setError('Please upload a video file');
        return;
      }
      const videoData = {
        videoId: videoId,
        parentVideoId: parentVideoId,
        parentVideoName: parentVideoName,
        modelName: modelName
      }
      const resp = await handleCreateVideo(videoData, addToast);
      console.log(resp);
      setUploadKey(prev => prev + 1);
      setVideoId(uuidv4())
      setModelName('');
      onSubmit();
    } catch (error) {
      console.error(error);
    }        // Handle form submission here
  };

  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="mt-10">
          <div className="sm:col-span-6">
            <label htmlFor="modelName" className="block text-sm font-medium text-gray-900">
              Model Name
            </label>
            <div className="mt-2">
              <input
                id="modelName"
                type="text"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
                placeholder="Enter model name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
              />
              {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
            </div>
          </div>
          
          <div className="mt-6">
          </div>
          <UploadComponent 
            maxFiles={1}
            onUploadComplete={() => {setFileUploaded(true)}}
            onFilesRemoved={() => {setFileUploaded(false)}}
            key={uploadKey} 
            fileCategory='VIDEO-ANOTATED' 
            databaseId={parentVideoId} 
            versionId={videoId} 
            allowedFileTypes={[
              'video/*',               // All video formats
              'video/mp4',            // MP4 videos
              'video/webm',           // WebM videos
              'video/quicktime',      // MOV videos
              'video/x-msvideo',      // AVI videos
              'video/x-matroska'      // MKV videos
            ]}
          />

        </div>
      </div>
      <button
        onClick={handleSubmit}
        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Submit
      </button>
    </div>
  );
};

export default AnotatedVideoCreate;
