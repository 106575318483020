import { useState, useRef, useEffect, useContext } from "react";
import axiosInstance from "../../services/AxiosInstance";
import axios from "axios";
import streamSaver from "streamsaver";
import creds from "../../creds";
import { download_config } from "../../config/config";
import { setDownloadStatus } from "../../redux/downloadSlice";
import { useDispatch } from "react-redux";
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from '../../packages/utils/constants';
import { useSelector } from "react-redux";
import { logDownloadEvent } from "../../services/DownloadService";

const backendDU = creds.backendDU;

const {CHUNK_SIZE, MAX_RETRIES, RETRY_DELAY} = download_config;

export const useDownload = ({ fileName, onComplete }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [error, setError] = useState(null);
  const [pauseToggle, setPauseToggle] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [status, setStatus] = useState("Ready");
  const [currentDownloadIndex, setCurrentDownloadIndex] = useState(0);
  const [downloads, setDownloads] = useState([]); 
  const [downloadId, setDownloadId] = useState(null);
  const { addToast } = useContext(ToastContext);

  const { databaseId, versionId } = useSelector((state) => state.download);

  const dispatch = useDispatch();


  const downloadRef = useRef({
    downloadedChunks: 0,
    isPaused: false,
    writer: null,
    abortController: null,
    needNewStream: true,
    isCancelled: false,
  });

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (downloadRef.current.abortController) {
        downloadRef.current.abortController.abort();
      }
      if (downloadRef.current.writer) {
        downloadRef.current.writer.abort();
      }
    };
  }, []);

  const extractOriginalFilename = (filename) => {
    const match = filename.match(/\d{17}_(.+)$/);
    return match ? match[1] : filename;
  };

  // useEffect(() => {
  //   if(error) {
  //     addToast({ 
  //       message: error, 
  //       variant: TOAST_VARIANTS.ERROR 
  //     });
  //   }
  // }, [error]); // Commenting this, because we might need this in future to fix error related issues.
  useEffect(() => {
    if (isCompleted) {
      // Call onComplete for the current file
      onComplete(downloadId, "Completed");
  
      // Reset states for the next file download
      setIsDownloading(false);
      setIsCompleted(false);
      setError(null);
      setProgress(0);
      setTotalSize(0);
      setStatus("Completed")
      dispatch(setDownloadStatus("Completed"));
  
      // Reset downloadRef for new file download
      downloadRef.current = {
        ...downloadRef.current,
        downloadedChunks: 0,
        isPaused: false,
        needNewStream: true,
        isCancelled: false
      };
  
      // Trigger next download if there's another one
      const nextDownload = downloads[currentDownloadIndex + 1]; // Track the next file index
      if (nextDownload) {
        setCurrentDownloadIndex((prevIndex) => prevIndex + 1); // Update index to the next file
        handleDownloadClick(nextDownload); // Start the next download
      }
    }
  }, [isCompleted, fileName, currentDownloadIndex, downloads, onComplete, downloadId]);
  
  
  const downloadChunkWithRetry = async (
    fileId,
    chunkIndex,
    retries = MAX_RETRIES
  ) => {
    if (!downloadRef.current.abortController) {
      downloadRef.current.abortController = new AbortController();
    }

    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        const response = await axiosInstance.get(
          `${backendDU}download/v1/download-file`,
          {
            headers: {
              fileName: fileId,
              range: `bytes=${chunkIndex * CHUNK_SIZE}-`,
            },
            withCredentials: true,
            responseType: 'blob',
            signal: downloadRef.current.abortController.signal,
            timeout: 5 * 60 * 1000
          }
        );

        if (response.status === 416) return { status: 416 };

        const contentRange = response.headers['content-range'];
        if (contentRange) {
          const [, range, size] = contentRange.match(/bytes (\d+-\d+)\/(\d+)/);
          const [start, end] = range.split("-").map(Number);
          const totalSize = Number(size);

          setTotalSize(totalSize);
          const downloadedBytes = end - start + 1;
          const newProgress =
            ((chunkIndex * CHUNK_SIZE + downloadedBytes) / totalSize) *
            100;
          setProgress(newProgress.toFixed(2));
        }

        return { status: 200, blob: response.data };
      } catch (error) {
        if (axios.isCancel(error)) return { status: "aborted" };
        if (error.response?.status === 416) return { status: 416 };
        if (attempt === retries - 1) throw error;
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      }
    }
  };

  const writeToDisk = async () => {
    try {
      if (downloadRef.current.needNewStream) {
        const fileStream = streamSaver.createWriteStream(
          extractOriginalFilename(fileName)
        );
        downloadRef.current.writer = fileStream.getWriter();
        downloadRef.current.needNewStream = false;
      }

      let attempts_left = MAX_RETRIES;
      while (!downloadRef.current.isPaused && !downloadRef.current.isCancelled && attempts_left) {
        try {
          const result = await downloadChunkWithRetry(
            fileName,
            downloadRef.current.downloadedChunks
          );

          if (result.status === 416 || result.status === "aborted") break;

          if (result.blob) {
            const arrayBuffer = await result.blob.arrayBuffer();
            await downloadRef.current.writer.write(new Uint8Array(arrayBuffer));
            downloadRef.current.downloadedChunks++;
          }
        } catch (error) {
          attempts_left-=1;
          console.error("Error downloading chunk:", error);
          if (!downloadRef.current.isPaused && !downloadRef.current.isCancelled) {
            if(attempts_left) {
              setError("An error occurred during download. Retrying...");
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            }
            else {
              console.error("Download failed");
              if(status === "Downloading...") {
                setError("Download paused due to an issue fetching the file. Please try again.");
                handlePauseResumeClick();
              }
            }
          }
        }
      }

      if (downloadRef.current.isCancelled) {
        throw new Error("Download canceled by the user.");
      }

      if (!downloadRef.current.isPaused && !downloadRef.current.isCancelled) {
        await downloadRef.current.writer.close();
        setIsDownloading(false);
        setStatus("Completed")
        logDownloadEvent(databaseId, versionId, fileName);
        dispatch(setDownloadStatus("Completed"));
        downloadRef.current.needNewStream = true;
        onComplete(downloadId, "Completed");
        setIsCompleted(true);
      }
    } catch (error) {
      console.error("File write interrupted:", error);
      if (downloadRef.current.writer) {
        await downloadRef.current.writer.abort();
      }

      setError("Download canceled by the user.");
      downloadRef.current.isCancelled = true;
      setIsDownloading(false);
      setProgress(0);
      // setStatus("Ready")
      // dispatch(setDownloadStatus("Ready"));
      setTotalSize(0);
      downloadRef.current.needNewStream = true;
    }
  };

  const handleDownloadClick = async () => {
    if (!fileName) {
      setError("Please enter a file name");
      return;
    }
    setIsDownloading(true);
    setIsCompleted(false);
    setError(null);
    setStatus("Downloading...")
    dispatch(setDownloadStatus("Downloading...")); 
    downloadRef.current = {
      ...downloadRef.current,
      downloadedChunks: 0,
      isPaused: false,
      needNewStream: true,
      isCancelled: false
    };
    setProgress(0);
    setTotalSize(0);

    writeToDisk();
  };

  const handlePauseResumeClick = () => {
    downloadRef.current.isPaused = !downloadRef.current.isPaused;
    setPauseToggle((prev) => !prev);

    if (downloadRef.current.isPaused) {
      downloadRef.current.abortController.abort();
      setStatus("Paused")
      dispatch(setDownloadStatus("Paused")); 
    } else {
      downloadRef.current.abortController = new AbortController();
      setStatus("Downloading...")
      dispatch(setDownloadStatus("Downloading..."));
      writeToDisk();
    }
  };

  const handleCancelClick = () => {
    downloadRef.current.isCancelled = true;
    setIsDownloading(false);
    setProgress(0);
    setTotalSize(0);
    setStatus("Ready")
    dispatch(setDownloadStatus("Ready"));

    if (downloadRef.current.writer) {
      downloadRef.current.writer.abort();
      downloadRef.current.writer = null;
    }

    if (downloadRef.current.abortController) {
      downloadRef.current.abortController.abort();
      downloadRef.current.abortController = null;
    }

    // Reset downloadRef
    downloadRef.current = {
      ...downloadRef.current,
      downloadedChunks: 0,
      isPaused: false,
      needNewStream: true,
    };

    setError("Download canceled by the user.");

    // Ensure the onComplete is triggered after cancel status is updated
    if (onComplete) {
      onComplete(); // Notify cancellation
    }
  };

  return {
    isDownloading,
    isCompleted,
    progress,
    totalSize,
    error,
    pauseToggle,
    isOnline,
    isCancelled: downloadRef.current.isCancelled,
    status,
    handleDownloadClick,
    handlePauseResumeClick,
    handleCancelClick,
  };
};
