// Libs
import React, { createContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { updateTokenDetails } from "./services/AxiosInstance";
// constants
import { TOAST_VARIANTS } from "./packages/utils/constants";
// styles
import "./App.css";
// Components
import { 
  Navbar, 
  Footer,
  DatasetVersionDetails, 
  ResetPassword,
  VerifyForgotPasswordLink, 
  ResendVerificationLink, 
  SignUpVerificationLinkSent, 
  SignUpVerificationSuccess, 
  VerifyEmailLink, 
  SignUpVerificationFail ,
  ForgotPassword,
  DatasetDetails,
  DatasetDetailsCard,
} from "./components";

// Views
import {
  Home,
  About,
  Team,
  PrivacyPolicy,
  TermsConditions,
  CancellationsRefunds,
  ShippingDelivery,
  DatasetCatalogue,
  SignInPage,
  SignUpPage,
  MyProfilePanel,
  ContactUs,
  DatasetsListing,
  DatasetDetailsPublic,
  VersionDetails,
} from "./views";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastComponent } from "./packages/toasts/Toast";
import { useToast } from "./packages/hooks/useToast"
import ScrollToTop from "./components/ScrollToTop";
import AdminPayment from "./views/adminPanel/AdminPayment";
import UserGuide from "./views/userGuide/UserGuide.jsx";
import DynamicDomainDataset from "./views/datasets/DynamicDomainDataset";
import { useNavigate } from "react-router-dom";
import SessionExpiredPopup from './components/SessionExpiredPopup/SessionExpiredPopup';

type ToastContextType = {
  addToast: (a: { message: string, variant: TOAST_VARIANTS }) => void;
}
export const ToastContext = createContext<ToastContextType>({ addToast: ({ message, variant }) => { } });
const App = () => {
  const userName= useSelector((state: any) => state.auth.userName);
  const userId = useSelector((state: any) => state.auth.userId);
  const sessionId = useSelector((state: any) => state.auth.sessionId);
  useEffect(() => {
      if (userName && userId && sessionId) {
          updateTokenDetails(userName, userId, sessionId);
      }
  }, [userId, sessionId]);
  const { toastData, addToast, deleteToast } = useToast();

  return (
    <Router >
      <ScrollToTop />
      <div className="App">
        <div className="navbar__bg" style={{ position: "sticky", top: 0 }}>
          <Navbar />
        </div>
        <ToastContext.Provider value={{ addToast }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/cancellations-refunds" element={<CancellationsRefunds />} />
            <Route path="/shipping-delivery" element={<ShippingDelivery />} />
            <Route path="/dataset-catalogue" element={<DatasetCatalogue />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/otp/verify/forgetpassword" element={<VerifyForgotPasswordLink />} />
            <Route path="/userverification" element={<VerifyEmailLink />} />
            <Route path="/verification-link-sent" element={<SignUpVerificationLinkSent />} />
            <Route path="/verification-success" element={<SignUpVerificationSuccess />} />
            <Route path="/verification-fail" element={<SignUpVerificationFail />} />
            <Route path="/resend-verification-link" element={<ResendVerificationLink />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/my-dashboard" element={<MyProfilePanel />} />
            <Route path="/dataset-version/:datasetId/:versionId" element={<VersionDetails />} />
            <Route path="/datasets-listing" element={<DatasetsListing />} />
            <Route path="#payment" element={<AdminPayment/>} />
            <Route path="/user-guide" element={<UserGuide />} />
            <Route path="/dataset-details/:datasetId" element={<DatasetDetailsPublic />} />
            <Route path="/dataset-version" element={<VersionDetails />} />
            <Route path="/datasets-listing/:domainName" element={<DynamicDomainDataset />} />

          </Routes>
          <SessionExpiredPopup />
        </ToastContext.Provider>
        <ToastComponent toastData={toastData} deleteToast={deleteToast} />
        <Footer />
      </div>
    </Router>
  );
}

export default App;