import React, { useState, useEffect, useContext, memo } from "react";
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from "../../packages/toasts/constants";
import { updateDatasetInCatalogue } from "../../services/DatasetCatalogueService";  // Import the service function

const InputNode = memo(({ label, value, onChange, type, required }) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
        {required && <span className="text-red-500"> *</span>}:
      </label>
      {type === "text" ? (
        <textarea
          className="block w-full rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={label}
        />
      ) : (
        <input
          type="text"
          className="block w-full rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={label}
          required={required}
        />
      )}
    </div>
));

const EditDatasetCatalogueModal = ({
  show,
  setShow,
  onEdit,
  selectedCatalogue,
}) => {
  const [formData, setFormData] = useState({
    datasetName: "",
    domainName: "",
    description: "",
    contentType: "",
    datasetUsage: "",
    sourceLink: "",
  });

  const { addToast } = useContext(ToastContext);

  // Fetch and set the details when selectedCatalogue is available
  useEffect(() => {
    if (selectedCatalogue) {
      setFormData({
        datasetName: selectedCatalogue.datasetName || "",
        domainName: selectedCatalogue.domainName || "",
        description: selectedCatalogue.description || "",
        contentType: selectedCatalogue.contentType || "",
        datasetUsage: selectedCatalogue.datasetUsage || "",
        sourceLink: selectedCatalogue.sourceLink || "",
      });
    }
  }, [selectedCatalogue]);

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleUpdate = async () => {
    if (!formData.datasetName || !formData.domainName) {
      addToast({
        message: "Dataset Name and Domain are required.",
        variant: TOAST_VARIANTS.ERROR,
      });
      return;
    }

    const updatedData = {
      datasetName: formData.datasetName,
      domainName: formData.domainName,
      contentType: formData.contentType,
      description: formData.description,
      datasetUsage: formData.datasetUsage,
      sourceLink: formData.sourceLink,
    };

    try {
      const result = await updateDatasetInCatalogue(selectedCatalogue.datasetCatalogueId, updatedData);

      if (!result.error) {
        addToast({
          message: "Dataset catalogue updated successfully.",
          variant: TOAST_VARIANTS.SUCCESS,
        });
        onEdit(result.data); // Pass the updated data to the parent
        setShow(false);
      } else {
        addToast({
          message: result.message || "Failed to update dataset catalogue.",
          variant: TOAST_VARIANTS.ERROR,
        });
      }
    } catch (error) {
      addToast({
        message: "An error occurred while updating the dataset catalogue.",
        variant: TOAST_VARIANTS.ERROR,
      });
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Edit Dataset Catalogue</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShow(false)}
          >
            ✕
          </button>
        </div>
        <form>
          <div className="grid grid-cols-2 gap-4">
            <InputNode
              label="Dataset Name"
              value={formData.datasetName}
              onChange={(val) => handleInputChange("datasetName", val)}
              required
            />
            <InputNode
              label="Domain Name"
              value={formData.domainName}
              onChange={(val) => handleInputChange("domainName", val)}
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <InputNode
              label="Dataset Content Type"
              value={formData.contentType}
              onChange={(val) => handleInputChange("contentType", val)}
            />
            <InputNode
              label="Dataset Usage"
              value={formData.datasetUsage}
              onChange={(val) => handleInputChange("datasetUsage", val)}
            />
          </div>
          <InputNode
            label="Dataset Description"
            value={formData.description}
            onChange={(val) => handleInputChange("description", val)}
          />
          <InputNode
            label="Dataset Source Link"
            value={formData.sourceLink}
            onChange={(val) => handleInputChange("sourceLink", val)}
            required
          />
        </form>
        <div className="flex justify-end mt-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            onClick={handleUpdate}
          >
            Update
          </button>
          <button
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditDatasetCatalogueModal;