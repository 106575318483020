// src/views/SignInView.jsx

import React from "react";
import SignInForm from "../../components/signinform/SignInForm";
const form_class = "w-full h-full flex justify-center items-center"; 

const SignInPage = () => (
  <div className={form_class}>
    <SignInForm />
  </div>
);

export default SignInPage;
