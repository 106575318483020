import React, { useEffect, useState } from "react";
import Table from "../../components/styled/Table";
import { getUserRole } from "../../services/AuthService";
import {
  updateDatasetRequest,
  getReminderNotification,
  getAccessRequestStatus,
} from "../../services/DatasetService";
import { useNavigate } from "react-router-dom";

const MyDatasetRequests = () => {
  const [requestsReceived, setRequestsReceived] = useState([]);
  const [requestsSent, setRequestsSent] = useState([]);
  const [loadingReceived, setLoadingReceived] = useState(true);
  const [loadingSent, setLoadingSent] = useState(true);
  const [errorReceived, setErrorReceived] = useState(null);
  const [errorSent, setErrorSent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const userRole = getUserRole().roleName;
  const navigate = useNavigate();

  useEffect(() => {
    fetchRequestsReceived();
    fetchRequestsSent();
  }, [userRole]);

  const fetchRequestsReceived = async () => {
    setLoadingReceived(true);
    setErrorReceived(null);

    try {
      let response;

      if (userRole === "Admin") {
        response = await getAccessRequestStatus();
      } else {
        response = await getAccessRequestStatus({ owner: true });
      }

      setRequestsReceived(response.requests || []);
    } catch (err) {
      setErrorReceived(
        err.message || "Failed to fetch received dataset requests"
      );
    } finally {
      setLoadingReceived(false);
    }
  };

  const fetchRequestsSent = async () => {
    setLoadingSent(true);
    setErrorSent(null);

    try {
      const response = await getAccessRequestStatus({ requester: true });
      setRequestsSent(response.requests || []);
    } catch (err) {
      setErrorSent(err.message || "Failed to fetch sent dataset requests");
    } finally {
      setLoadingSent(false);
    }
  };

  const columnsRequestsReceived = [
    { key: "requester_name", label: "Requester Name", sortable: true },
    { key: "dataset_name", label: "Dataset Name", sortable: true },
    { key: "requester_message", label: "Requester Message", sortable: true },
    {
      key: "created_at",
      label: "Requested Date",
      sortable: true,
      render: (value) => {
        const date = new Date(value);
        return isNaN(date) ? "Invalid Date" : date.toLocaleDateString("en-GB");
      },
    },
    {
      key: "request_status",
      label: "Status",
      sortable: true,
      render: (value) => (
        <p
          className={`px-3 py-2 rounded-lg text-sm text-center font-medium ${
            value.toUpperCase() === "APPROVED"
              ? "text-green-800 bg-green-300 border-green-500"
              : value.toUpperCase() === "REJECTED"
              ? "text-red-800 bg-red-300 border-red-500"
              : value.toUpperCase() === "PENDING"
              ? "text-blue-800 bg-blue-300 border-blue-500"
              : "text-gray-800 bg-gray-300 border-gray-500"
          }`}
        >
          {value.toUpperCase()}
        </p>
      ),
    },
  ];

  const columnsRequestsSent = [
    { key: "dataset_name", label: "Dataset Name", sortable: true },
    { key: "owner_name", label: "Owner Name", sortable: true },
    { key: "requester_message", label: "Your Message", sortable: true },
    {
      key: "created_at",
      label: "Request Date",
      sortable: true,
      render: (value) => {
        const date = new Date(value);
        return isNaN(date) ? "Invalid Date" : date.toLocaleDateString("en-GB");
      },
    },
    {
      key: "request_status",
      label: "Status",
      sortable: true,
      render: (value) => (
        <p
          className={`px-3 py-2 rounded-lg text-sm text-center font-medium ${
            value.toUpperCase() === "APPROVED"
              ? "text-green-800 bg-green-300 border-green-500"
              : value.toUpperCase() === "REJECTED"
              ? "text-red-800 bg-red-300 border-red-500"
              : value.toUpperCase() === "PENDING"
              ? "text-blue-800 bg-blue-300 border-blue-500"
              : "text-gray-800 bg-gray-300 border-gray-500"
          }`}
        >
          {value.toUpperCase()}
        </p>
      ),
    },
  ];

  const actionsReceived = [
    {
      label: "View",
      className:
        "px-3 py-2 rounded-lg text-sm text-blue-500 bg-blue-100 border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => {
        setSelectedRequest(item);
        setShowModal(true);
      },
    },
    {
      label: "Approve",
      className:
        "px-3 py-2 rounded-lg text-sm text-green-500 bg-green-100 border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-green-500",
      handler: async (item) => {
        try {
          await updateDatasetRequest(item.request_id, "approved");
          await fetchRequestsReceived();
          await fetchRequestsSent();
        } catch (err) {
          console.log(err.message || "Failed to approve the request.");
        }
      },
    },
    {
      label: "Reject",
      className:
        "px-3 py-2 rounded-lg text-sm text-red-500 bg-red-100 border border-red-500 hover:bg-red-300 hover:text-red-700 focus:ring-2 focus:ring-red-500",
      handler: async (item) => {
        try {
          await updateDatasetRequest(item.request_id, "rejected");
          await fetchRequestsReceived();
          await fetchRequestsSent();
        } catch (err) {
          console.log(err.message || "Failed to reject the request.");
        }
      },
    },
  ];

  const datasetRequestAction = [
    {
      label: "Download",
      className:
        "px-3 py-2 rounded-lg text-sm text-blue-500 bg-blue-100 border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: async (item) => {
        if (item?.dataset_id) {
          navigate(`/dataset-details/${item.dataset_id}`);
        } 
      },
      hidden: (item) => item.request_status.toUpperCase() === "REJECTED",
    },
  ];

  if (userRole === "Admin") {
    actionsReceived.push({
      label: "Reminder",
      className:
        "px-3 py-2 rounded-lg text-sm text-amber-800 bg-amber-100 border border-amber-500 hover:bg-amber-200 hover:text-amber-900 focus:ring-2 focus:ring-amber-500",
      handler: async (item) => {
        try {
          const response = await getReminderNotification(item.request_id);
          alert(`Reminder sent successfully: ${response.message}`);
        } catch (error) {
          console.error("Failed to send reminder:", error.message);
          alert("Failed to send reminder. Please try again.");
        }
      },
    });
  }

  return (
    <>
      <div className="flex flex-col w-full bg-white mb-2">
        <div className="pb-4">
          <Table
            data={requestsReceived}
            columns={columnsRequestsReceived}
            actions={actionsReceived}
            pageSize={5}
            title="Dataset Requests Received"
          />
        </div>
        <Table
          data={requestsSent}
          columns={columnsRequestsSent}
          actions={datasetRequestAction}
          pageSize={5}
          title="Dataset Requests Sent"
        />
      </div>

      {showModal && selectedRequest && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-3/4 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Dataset Request Details</h2>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <span className="text-2xl">&times;</span>
              </button>
            </div>
            <div>
              <p>
                <strong>Requester Name:</strong>{" "}
                {selectedRequest.requester_name}
              </p>
              <p>
                <strong>Requester Email:</strong>{" "}
                {selectedRequest.requester_email}
              </p>
              <p>
                <strong>Dataset Name:</strong> {selectedRequest.dataset_name}
              </p>
              <p>
                <strong>Message:</strong> {selectedRequest.requester_message}
              </p>
              <p>
                <strong>Requested Date:</strong>{" "}
                {new Date(selectedRequest.created_at).toLocaleDateString(
                  "en-GB"
                )}
              </p>
              <p>
                <strong>Status:</strong> {selectedRequest.request_status}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyDatasetRequests;
