import { Link } from "react-router-dom";
import { USER_STATE } from "../../withAuth";
import { NavItemWithDropdown } from "./NavItemWithDropdown";
import { Button } from "../styled/Buttons";


export const navItemRenderer = (
  navItemConfig,
  pathname,
  userLoginState,
  isDropDown = false,
  handleDomainSelect,
) => {
  if (Array.isArray(navItemConfig.permissions)) {
    if (userLoginState === USER_STATE.ANY) {
      if (!navItemConfig.permissions.includes(USER_STATE.ANY)) {
        return null;
      }
    } 
    else {
      if (!navItemConfig.permissions.some(permission => 
        permission === USER_STATE.ANY || 
        permission === userLoginState ||
        (permission === USER_STATE.SIGNED_IN && userLoginState !== USER_STATE.ANY)
      )) {
        return null;
      }
    }
  }
  
  return navItemConfig.options?.length ? (
    // <Link>SOON!</Link>
    <NavItemWithDropdown
      navItemConfig={navItemConfig}
      pathname={pathname}
      isDropDown={isDropDown}
      onDomainSelect={handleDomainSelect}
    />
  ) : (
    <Link
      className={
        pathname === navItemConfig.pathname
          ? "text-red-600 font-semibold"
          : "text-black-500 font-semibold hover:text-blue-800 hover:underline"
      }
      to={navItemConfig.pathname}
    >
      {navItemConfig.name}
    </Link>
  );
};

export const NavButton = ({ children }) => (
  <Button.Blue
    className="py-2 px-3 bg-blue-800 text-white hover:underline dark:text-blue-500 font-semibold"
    type="button"
  >
    {children}
  </Button.Blue>
);