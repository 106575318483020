// Modal.js
import React from 'react';

export const Modal = ({ isOpen, onClose, onCancel, onSave, title, children, size = 'default'}) => {
  if (!isOpen) return null;

  const sizeClasses = {
    sm: 'max-w-lg',
    default: 'max-w-3xl',
    lg: 'max-w-4xl',
    xl: 'max-w-6xl',
    full: 'max-w-full mx-4'
  };
  
  const modalWidth = sizeClasses[size] || sizeClasses.default;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[999]">
      <div className={`bg-white p-6 rounded-lg shadow-lg ${modalWidth} w-full max-h-[90vh] overflow-y-auto`}>
        <h2 className="text-2xl font-bold mb-6">{title || 'Details'}</h2>
        {children}
        {/* Conditionally render the buttons if onCancel or onSave is provided */}
        {(onCancel || onSave) && (
          <div className="flex justify-end space-x-4 mt-6">
            <button 
              className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600" 
              onClick={onCancel}
            >
              Cancel
            </button>
            <button 
              className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" 
              onClick={() => {
                if (onSave) {
                  onSave();
                } else {
                  onClose();
                }
              }}
            >
              {onSave ? 'Confirm' : 'Submit'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

