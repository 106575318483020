import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleEmailVerification } from '../../controllers/authController';
import config from '../../config/config';

const VerifyForgotPasswordLink = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("user");
  const emailVerificationToken = searchParams.get("verification_token");
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  
  useEffect(() => {
    if (email && emailVerificationToken) {
      handleEmailVerification(config.notification_type.FORGOT_PASSWORD, emailVerificationToken, setIsLoading, setIsSuccess);
    } else {
      setIsLoading(false);
      setIsSuccess(false);
    }
  }, [email, emailVerificationToken]);

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        navigate(`/reset-password?email=${email}`);
      } else {
        navigate("/verification-fail");
      }
    }
  }, [isLoading, isSuccess, navigate]);

  if (isLoading) return <p>Loading...</p>;

  return null;
};

export default VerifyForgotPasswordLink;
