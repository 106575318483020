import { useEffect, useState, useRef, useCallback } from "react";
import { DUUrl } from "../../creds";
import { download_config } from "../../config/config";
import axiosInstance from "../../services/AxiosInstance";
import { setProfileImage } from "../../redux/profileSlice";

const useImageDownloader = () => {
  // const hasFetched = useRef({});
  const [imageUrls, setImageUrls] = useState({});

  const fetchImage = useCallback(async (versionId, downloadDatabaseId, type = "profile") => {
    // if (hasFetched.current[versionId]) return;
    
    const databaseId = downloadDatabaseId || download_config.DATABASE_PROFILE_ID;
    try {
      const response = await axiosInstance.get(
        `${DUUrl}download/files/${databaseId}/${versionId}`
      );
      const files = response.data.files;
      
      if (files && files.length > 0) {
        const latestFile = files.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )[0];
        const latestFileName = latestFile.file_name;

        const fileResponse = await axiosInstance.get(
          `${DUUrl}download/v1/download-file`,
          {
            headers: { fileName: latestFileName },
            withCredentials: true,
            responseType: "blob",
          }
        );

        const imgUrl = URL.createObjectURL(fileResponse.data);
        setImageUrls((prev) => ({ ...prev, [versionId]: imgUrl }));
      }

      // hasFetched.current[versionId] = true;
    } catch (error) {
      console.error(`Failed to fetch image for version ${versionId}`, error);
    }
  }, []);

  return { fetchImage, imageUrls };
};

export default useImageDownloader;