import React, { useState, useEffect, useRef } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUser, getUserLoginState, USER_STATE } from "../../withAuth";
import { NAV_CONFIG } from "./navConfig";
import { navItemRenderer, NavButton } from "./navItems";
import SignOut from "../signout/SignOut";
import ihubLogoV from "../../assets/images/logo/iHub_IndiaData_logo.png";
import { PiBookOpenTextLight } from "react-icons/pi";
import { useSelector, useDispatch } from "react-redux";
import { setTab } from "../../redux/commonSlice";
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const user = getUser();
  const userLoginState = getUserLoginState(user);

  const location = useLocation();
  const pathname = location.pathname;
  const menuRef = useRef(null); // This ref should be assigned to the menu container
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstName = useSelector((state) => state.auth.firstName);
  const isUploading = useSelector((state) => state.upload.isUploading);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMyProfile = () => {
    // comment for testing purpose
    // console.log("Navigating navbar to MyProfile...");
    dispatch(setTab(18));
    setTimeout(() => navigate("/my-dashboard#myProfile"), 0);
    // navigate("/my-dashboard#myProfile")
  };

  return (
    <div className={`flex pl-4 pb-3 pt-3 pr-4 items-center justify-between xs:py-8 xs:px-8 bg-white shadow-[rgba(17,_17,_26,_0.3)_0px_0px_16px] ${isUploading ? 'cursor-not-allowed' : ''}`}>   
      {isUploading && (
        <div className="absolute inset-0 bg-white bg-opacity-50 z-10" title="Navigation disabled - Upload in progress"></div>
      )}   
      <div className="flex justify-start items-center flex-1">
        <Link className="mr-8 cursor-default">
          <img className="h-14" src={ihubLogoV} alt="logo" />
        </Link>
        <div className="hidden lg:flex flex-row whitespace-nowrap gap-6">
          {NAV_CONFIG.map((navItemConfig) =>
            navItemRenderer(navItemConfig, pathname, userLoginState)
          )}
        </div>
      </div>
      {userLoginState !== USER_STATE.ANY ? (
        <div className=" hidden lg:flex lg:justify-end lg:items-center">
          <Link onClick={handleMyProfile} className="text-black font-semibold mx-4 my-1 cursor-pointer whitespace-nowrap  hover:text-blue-800 hover:underline">
          Hi {firstName}
          </Link>
          <SignOut />
          <div className="">
            <p className="flex justify-center text-black text-center capitalize mx-4 my-1 cursor-pointer  hover:text-blue-800 hover:underline ">
              <Link to="/user-guide" className="text-center">
                <div className="flex justify-center">
                  <PiBookOpenTextLight className="text-3xl text-blue-700" />
                </div>
                <div>User Guide</div>
              </Link>
            </p>
          </div>
        </div>
      ) : (
        <div className=" hidden lg:flex justify-end items-center ">
          <p className="text-black font-semibold text-base capitalize mx-4 my-1 cursor-pointer  hover:text-blue-800 hover:underline ">
            <Link to="/sign-in"> Sign In </Link>
          </p>
          <NavButton>
            <Link className="" to="/sign-up" style={{ color: "white" }}>
              Sign Up
            </Link>
          </NavButton>
          <div className="">
            <p className="flex justify-center text-black text-center capitalize mx-4 my-1 cursor-pointer  hover:text-blue-800 hover:underline ">
              <Link to="/user-guide" className="text-center">
                <div className="flex justify-center">
                  <PiBookOpenTextLight className="text-3xl text-blue-700" />
                </div>
                <div>User Guide</div>
              </Link>
            </p>
          </div>
        </div>
      )}

      <div className="ml-3 my-3  cursor-pointer relative lg:hidden">
        {!toggleMenu ? (
          <RiMenu3Line
            color="#4682B4"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        ) : (
          <>
            <RiCloseLine
              color="#4682B4"
              size={27}
              onClick={() => setToggleMenu(false)}
            />
            <div
              ref={menuRef}
              className="absolute top-10  bg-white right-0 shadow rounded whitespace-nowrap pt-3"
            >
              <div className="flex flex-col gap-2 ml-4 mr-12">
                {NAV_CONFIG.map((navItemConfig) =>
                  navItemRenderer(navItemConfig, pathname, userLoginState, true)
                )}
                <div className="text-left mt-3">
                  {userLoginState !== USER_STATE.ANY ? (
                    <div className="-mr-4 mb-4 whitespace-nowrap">
                      <SignOut />
                      <div className="my-2">
                        <p className=" text-black text-center capitalize my-1 cursor-pointer  hover:text-blue-800 hover:underline ">
                          <Link to="/user-guide" className="text-left flex">
                            <PiBookOpenTextLight className="text-2xl text-blue-700" />
                            User Guide
                          </Link>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="text-left mb-4">
                      <p className="text-black font-semibold my-1 cursor-pointer mb-2">
                        <Link to="/sign-in"> Sign In </Link>
                      </p>
                      <Link
                        className="-mr-4 my-1 whitespace-nowrap"
                        to="/sign-up"
                      >
                        <NavButton> Sign Up </NavButton>
                      </Link>
                      <div className="my-2">
                        <p className=" text-black text-center capitalize my-1 cursor-pointer  hover:text-blue-800 hover:underline ">
                          <Link to="/user-guide" className="text-left flex">
                            <PiBookOpenTextLight className="text-2xl text-blue-700" />
                            User Guide
                          </Link>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
