import React from 'react';
import ReactDOM from 'react-dom/client'; 
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store'; 
import { PersistGate } from 'redux-persist/integration/react'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from "react-cookie-consent";
import { SelectedDomainProvider } from './components/DomainContext/DomainContext';
// import { Link } from 'react-router-dom';

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}> {/* Wrap with PersistGate */}
          <SelectedDomainProvider>
            <ToastContainer />
            <CookieConsent
              location="bottom"
              buttonText="I Understand"
              cookieName="myAwesomeCookieName2"
              style={{ background: "rgb(234 241 255)", color: "black" }}
              buttonStyle={{
                color: "white",
                background: "rgb(30 66 159 / var(--tw-bg-opacity, 1))",
                borderRadius: "8px",
                padding: "12px",
                fontStyle: "",
                fontWeight: "600",
                fontSize: "13px",
              }}
              expires={30}
            >
              We use cookies to enhance your browsing experience. To learn more or
              manage your preferences, visit our <a href="/privacy-policy" className='hover:text-blue-500'>Privacy Policy</a> and Cookie Policy.
            </CookieConsent>
            <App />
          </SelectedDomainProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error("No root element found in the DOM.");
}
