import React from "react";
import { FlexSection, Heading, ParaFont, OverviewFont } from "../../components/styled/GlobalStyles";

function PrivacyPolicy() {
  const privacyPolicyData = [
    {
      id: 1,
      HeadingText: "Privacy Policy for India Data",
      Paragraphs: [
        "India Data is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our platform, including any datasets and services offered. By using our services, you consent to the practices described in this policy.",
      ],
      ListItems: [],
    },
    {
      id: 2,
      HeadingText: "1. Information We Collect",
      Paragraphs: [
        "We may collect the following types of information:",
      ],
      ListItems: [
        "Personal Information: Name, email address, phone number, and any other information you provide when registering or using our services.",
        "Usage Data: Information about how you use our platform, including your IP address, browser type, access times, and pages viewed.",
        "Cookies and Tracking Technologies: We use cookies and similar technologies to enhance your experience on our platform.",
      ],
    },
    {
      id: 3,
      HeadingText: "2. How We Use Your Information",
      Paragraphs: [
        "India Data uses your information for the following purposes:",
      ],
      ListItems: [
        "To Provide Services: To manage your account and provide you with the datasets and services you request.",
        "To Improve Our Services: To understand user behaviour and preferences to enhance our platform's functionality.",
        "To Communicate with You: To send you updates, newsletters, and other information related to our services.",
      ],
    },
    {
      id: 4,
      HeadingText: "3. Disclosure of Your Information",
      Paragraphs: [
        "We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:",
      ],
      ListItems: [
        "Legal Compliance: We may disclose your information if required by law or in response to valid requests by public authorities.",
      ],
    },
    {
      id: 5,
      HeadingText: "4. Data Security",
      Paragraphs: [
        "We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.",
      ],
      ListItems: [],
    },
    {
      id: 6,
      HeadingText: "5. Your Rights",
      Paragraphs: [
        "Under applicable data protection laws, you have the following rights regarding your personal data:",
      ],
      ListItems: [
        "Right to Access: You can request a copy of the personal data we hold about you.",
        "Right to Rectification: You can request corrections to inaccurate or incomplete data.",
        "Right to Erasure: You can request deletion of your personal data under certain conditions.",
        "Right to Restrict Processing: You can request that we limit the processing of your data.",
        "Right to Data Portability: You can request that we transfer your personal data to another organization in a structured format.",
      ],
    },
    {
      id: 7,
      HeadingText: "6. Changes to This Privacy Policy",
      Paragraphs: [
        "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our services after changes are made constitutes acceptance of those changes.",
      ],
      ListItems: [],
    },
    {
      id: 8,
      HeadingText: "7. Contact Us",
      Paragraphs: [
        "If you have any questions about this Privacy Policy or our data practices, please contact us at dfsupport@ihub-data.iiit.ac.in.",
      ],
      ListItems: [],
    },
  ];

  return (
    <div className="mx-auto bg-white pt-8">
      {privacyPolicyData.map(({ id, HeadingText, Paragraphs, ListItems }) => (
        <div className={FlexSection} key={id}>
          <div className="flex flex-col gap-4 shadow-2xl hover:shadow-3xl rounded -mt-4">
            <div className="w-full mx-4 pr-8 text-justify mb-4 mt-4">
              <h1 className={Heading}>{HeadingText}</h1>
              {Paragraphs.map((paragraph, index) => (
                <p className={`${ParaFont}`} key={`p-${index}`}>
                  {paragraph}
                </p>
              ))}
              {ListItems.length > 0 && (
                <ul className="list-disc pl-8">
                  {ListItems.map((item, index) => (
                    <li className={OverviewFont} key={`li-${index}`}>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PrivacyPolicy;
