import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SignUpInputField from "./SignUpInputField";

const PasswordToggleField = ({
  fieldName,
  labelName,
  touched,
  setTouched,
  errors,
  inputField,
  setInputField,
  autoComplete,
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? "text" : "password";

  // Handle the change event
  const handleChange = (e) => {
    setInputField(e.target.value);
    setTouched(true); // Set touched to true on input change
  };

  return (
    <div className="relative">
      <SignUpInputField
        fieldName={fieldName}
        labelName={labelName}
        inpType={passwordInputType}
        touched={touched}
        setTouched={setTouched}
        errors={errors}
        required={true}
        inputField={inputField}
        setInputField={setInputField}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onInput={handleChange} // Add onInput event for autofill
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="text-gray-60 absolute inset-y-0 right-0 mt-8 flex text-sm leading-5"
      >
        {showPassword ? (
          <FaEyeSlash className="h-5 w-5 text-gray-500 mr-2" />
        ) : (
          <FaEye className="h-5 w-5 text-gray-500 mr-2" />
        )}
      </button>
    </div>
  );
};

export default PasswordToggleField;


