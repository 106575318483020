import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isDatasetOwner:null,
    agreementText: '',
};

const datasetSlice = createSlice({
    name: 'dataset',
    initialState,
    reducers: {
        setDatasetState: (state, action) => {
            state.isDatasetOwner = action.payload.isDatasetOwner;
            state.agreementText = action.payload.agreementText;
        },
        resetDatasetState: () => initialState,
    },
});

export  const { setDatasetState, resetDatasetState } = datasetSlice.actions;

export default datasetSlice.reducer;