import React, { useEffect, useState, useRef } from "react";
import Table from "../../components/styled/Table";
import { fetchDomainDatasets } from "../../services/DatasetService";
import { useNavigate, useParams } from "react-router-dom";

const DynamicDomainDataset = () => {
  const { domainName } = useParams();
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [domains, setDomains] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const dropdownRef = useRef(null);
  const columnRef = useRef(null);
  const navigate = useNavigate();
 // const decodedDomainName = decodeURIComponent(domainName); // Decode it
  const fetchDataset = async () => {
    setLoading(true);
    try {
      const res = await fetchDomainDatasets(domainName); // Fetch the datasets from the API
      
      const formattedData = res.domainDatasets.domains.flatMap((domain) =>
        domain.datasets.map((dataset) => ({
          dataset_id: dataset.dataset_id,
          datasetName: dataset.dataset_name,
          domain: domain.domainName,
          dataset_overview: dataset.dataset_overview,
          contentType: dataset.content_type,
          usage: dataset.dataset_usage,
        }))
      );
      setDatasets(formattedData);
      const domainNames = res.domainDatasets.domains.map(
        (domain) => domain.domainName
      );
      setDomains(domainNames);
    } catch (err) {
      setError(err.message || "Failed to fetch datasets");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataset();
  }, [domainName]);

  const handleDomainSelection = (event) => {
    const value = event.target.value;
    setSelectedDomains((prev) =>
      prev.includes(value)
        ? prev.filter((domain) => domain !== value)
        : [...prev, value]
    );
    setSearchTerm(""); // Reset search term when selecting a domain
  };

  const filteredDatasets = datasets.filter(
    (dataset) =>
      selectedDomains.length === 0 || selectedDomains.includes(dataset.domain)
  );

  const filteredDomains = domains.filter((domain) =>
    domain.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      key: "datasetName",
      label: "Dataset Name",
      sortable: true,
    },
    {
      key: "domain",
      label: "Domain",
      sortable: true,
    },
    {
      key: "dataset_overview",
      label: "Dataset Overview",
      sortable: false,
    },
    {
      key: "contentType",
      label: "Content Type",
      sortable: false,
    },
    {
      key: "usage",
      label: "Usage",
      sortable: false,
    },
  ];

  const actions = [
    {
      label: "Details",
      className:
        "px-3 py-2 rounded-lg text-sm text-green-700 bg-white border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-blue-500",
      handler: (dataset) => {
        if (dataset && dataset.dataset_id) {
          navigate(`/dataset-details/${dataset.dataset_id}`);
        } else {
          console.error("Dataset ID not found in the row data.");
        }
      },
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  const noData = filteredDatasets.length === 0;

  const toCamelCase = (str) =>
    str.replace(/(^\w|-\w)/g, (match) => match.replace("-", " ").toUpperCase());
  return (
      <div className="flex flex-col w-full bg-white mb-2 relative overflow-visible  min-h-[400px]">
    <div>
          <Table
            data={filteredDatasets}
            columns={columns}
            pageSize={10}
            actions={actions}
            title={domains}
            showDomainDropdown={false}
            domains={domains}
            selectedDomain={selectedDomain}
            setSelectedDomain={setSelectedDomain}
          />
      </div>
    </div>
  );
};
export default DynamicDomainDataset;
