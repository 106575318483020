import React from "react";
import { SIDE_BAR_ITEM_MAP } from "../../components/sidebar/SidebarConfig";
import AdminDashBoard from "../adminPanel/AdminDashBoard";

const MyProfileContent = ({ activeTab }) => {

    const ActiveComponent = SIDE_BAR_ITEM_MAP[activeTab] || (() => <AdminDashBoard />);
    
    return <ActiveComponent />;
};

export default MyProfileContent;
