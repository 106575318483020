import { useSelector, useDispatch } from 'react-redux';
import MultiStepForm from '../../components/styled/MultiStepForm';
import AddDatasetForm from '../../components/datasets/AddDatasetForm';
import AddVersionForm from '../../components/datasets/AddVersionForm';
import UploadDetails from '../../components/datasets/AddFilesForm';
import { useEffect, useState } from 'react';
import { setCurrentPage, resetForm, setDatasetDetails } from '../../redux/formSlice';


const MyDatasetCreate = () => {
  const formData = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.auth?.userName || '');
  const firstName = useSelector((state) => state.auth?.firstName || '');
  const lastName = useSelector((state) => state.auth?.lastName || '');
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    dispatch(resetForm());
    dispatch(setCurrentPage(1));
    dispatch(setDatasetDetails({
      ownerEmail: userName,
      ownerName: firstName + ' ' + lastName,
      communicationEmail: "dfsupport@ihub-data.iiit.ac.in",
      cordinatorName: "DataFoundation Admin"
    }));
    setIsReset(true);
  }, []);
  
  const formPages = [
    AddDatasetForm,
    AddVersionForm,
    UploadDetails
  ];

  return (
    <MultiStepForm 
      key={isReset}
      formPages={formPages}
      formData={formData}
    />
  );
};

export default MyDatasetCreate;