import { createSlice } from "@reduxjs/toolkit";

const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    isUploading: false,
  },
  reducers: {
    setUploading: (state, action) => {
      state.isUploading = action.payload;
    },
  }
});

export const { setUploading } = uploadSlice.actions;
export default uploadSlice.reducer;