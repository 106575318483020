import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarGraph = ({ xdata, ydata }) => {
  const sanitizedYData = Array.from(ydata);

  const chartData = {
    labels: xdata,
    datasets: [
      {
        label: " Datasets Downloaded",
        data: sanitizedYData,
        backgroundColor: "#60A5FA",
        borderColor: "#3B82F6",
        borderWidth: 3,
        tension: 0.4,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Dataset Download Statistics",
        font: {
          size: 14,
        }
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        min: Math.min(10, Math.max(0, Math.min(...sanitizedYData))),
        max: Math.max(...sanitizedYData) > 10 ? Math.max(...sanitizedYData) + 1 : 10,
        ticks: {
          precision: 0,
        },
      },
    },
    animation: {
      duration: 60,
      easing: "easeInOutQuad",
    },
  };
  

  return (
    <div style={{ width: "100%", height: "70%" }}>
      <Bar options={chartOptions} data={chartData} />
    </div>
  );
};

export default BarGraph;