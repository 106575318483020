import React, { useState, useContext, memo } from "react";
import { addDatasetInCatalogue } from "../../services/DatasetCatalogueService";
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from "../../packages/toasts/constants";
import { InternalTableHeading } from "../styled/GlobalStyles";

const InputNode = memo(({ label, value, onChange, type, required, error }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
      {required && <span className="text-red-500"> *</span>}:
    </label>
    {type === "text" ? (
      <textarea
        className={`block w-full rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2 ${
          error ? "border-red-500" : ""
        }`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={label}
        required={required}
      />
    ) : (
      <input
        type="text"
        className={`block w-full rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2 ${
          error ? "border-red-500" : ""
        }`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={label}
        required={required}
      />
    )}
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
));

const AddDatasetCatalogueForm = ({ onDatasetCatalogueAdded }) => {
  const [formData, setFormData] = useState({
    datasetName: "",
    domainName: "",
    description: "",
    contentType: "",
    datasetUsage: "",
    sourceLink: "",
  });

  const [errors, setErrors] = useState({});
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
    if (errors[key]) {
      setErrors({ ...errors, [key]: "" }); // Clear error on user input
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.datasetName.trim()) newErrors.datasetName = "Dataset name is required.";
    if (!formData.domainName.trim()) newErrors.domainName = "Domain name is required.";
    if (!formData.contentType.trim()) newErrors.contentType = "Content type is required.";
    if (!formData.datasetUsage.trim()) newErrors.datasetUsage = "Dataset usage is required.";
    if (!formData.description.trim()) newErrors.description = "Dataset description is required.";
    if (!formData.sourceLink.trim()) {
      newErrors.sourceLink = "Source link is required.";
    } else {
      const urlRegex = /^(https?:\/\/)?([\w\-]+\.)+[a-z]{2,}(\/[\w\-]*)*\/?$/i;
      if (!urlRegex.test(formData.sourceLink)) {
        newErrors.sourceLink = "Please enter a valid URL.";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    try {
      const response = await addDatasetInCatalogue(formData);
      addToast({
        message: response.message || "Dataset added successfully in Catalogue!",
        variant: TOAST_VARIANTS.SUCCESS,
      });
      onDatasetCatalogueAdded(); // Refresh the Dataset Catalogue list
      setFormData({
        datasetName: "",
        domainName: "",
        description: "",
        contentType: "",
        datasetUsage: "",
        sourceLink: "",
      });
    } catch (error) {
      addToast({
        message: error.message || "Failed to add Dataset in Catalogue!",
        variant: TOAST_VARIANTS.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8 bg-gray-100 rounded-lg mb-2">
      <h1 className={InternalTableHeading}>
        Add New Dataset in Catalogue
      </h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-4">
            <InputNode
              label="Dataset Name"
              value={formData.datasetName}
              onChange={(val) => handleInputChange("datasetName", val)}
              required
              error={errors.datasetName}
            />
            <InputNode
              label="Domain Name"
              value={formData.domainName}
              onChange={(val) => handleInputChange("domainName", val)}
              required
              error={errors.domainName}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <InputNode
              label="Dataset Content Type"
              value={formData.contentType}
              onChange={(val) => handleInputChange("contentType", val)}
              required
              error={errors.contentType}
            />
            <InputNode
              label="Dataset Usage"
              value={formData.datasetUsage}
              onChange={(val) => handleInputChange("datasetUsage", val)}
              required
              error={errors.datasetUsage}
            />
          </div>
          <InputNode
            label="Dataset Description"
            type="text"
            value={formData.description}
            onChange={(val) => handleInputChange("description", val)}
            required 
            error={errors.description}
          />
          <InputNode
            label="Dataset Source Link"
            value={formData.sourceLink}
            onChange={(val) => handleInputChange("sourceLink", val)}
            required
            error={errors.sourceLink}
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className="rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
          >
            {loading ? "Adding..." : "Add Dataset"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddDatasetCatalogueForm;
