import React, { useState, useEffect } from 'react';
import Table from "../../components/styled/Table";
import {getAllDuas, updateDuaStatus, getDuaByID} from "../../services/DUAService";
import { MarkdownModal } from '../../components/dua/ViewDuaModal';

const AdminVerifyDUA = () => {
  const [dua, setDua] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDua, setSelectedDua] = useState(null);

  const handleViewDua = async (item) => {
    setSelectedDua(item);
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchDua();
  }, []);
  const fetchDua = async () => {
    const response = await getAllDuas();
    console.log(response.data.duas)
    setDua(response.data.duas);
  };
  const handleStatusUpdate = async (duaId, status) => {
    try {
      await updateDuaStatus({duaId, status});
      // Update local state after successful API call
      setDua(prevDuas => 
        prevDuas.map(dua => 
          dua._id === duaId ? { ...dua, duaStatus: status } : dua
        )
      );
      fetchDua();
    } catch (error) {
      console.error(`Failed to ${status} DUA:`, error);
    }
  };


  const handleGlobalStatusUpdate = async (duaId, lvl) => {
    try {
      await updateDuaStatus({duaId, lvl});
      setDua(prevDuas => 
        prevDuas.map(dua => 
          dua._id === duaId ? { ...dua, isGlobal: !dua.isGlobal } : dua
        )
      );
      fetchDua();
    } catch (error) {
      console.error(`Failed to update global status:`, error);
    }
  };
  const columns = [
    {
      key: "authorName",
      label: "Author Name",
      sortable: true,
    },
    {
      key: "duaTitle",
      label: "DUA Title",
      sortable: true,
    },
    {
      key: "duaDomainName",
      label: "Domain Name",
      sortable: true,
    },
    {
      key: "createdDate",
      label: "Creation Date",
      sortable: true,
      render: (value) => new Date(value).toLocaleDateString("en-GB"),
    },
    {
      key: "duaStatus",
      label: "Status",
      sortable: true,
      render: (value, item) => {
        const status = item.lvl === 1 ? "GLOBAL" : value.toUpperCase();
        return (
          <p
            className={`px-3 py-2 rounded-lg text-sm text-center font-medium ${
              status === "GLOBAL"
                ? "text-purple-800 bg-purple-300 border-purple-500"
                : status === "APPROVED"
                ? "text-green-800 bg-green-300 border-green-500"
                : status === "REJECTED"
                ? "text-red-800 bg-red-300 border-red-500"
                : status === "PENDING"
                ? "text-blue-800 bg-blue-300 border-blue-500"
                : status === "INCOMPLETE"
                ? "text-yellow-800 bg-yellow-200 border-yellow-500"
                : status === "UNVERIFIED"
                ? "text-orange-800 bg-orange-300 border-orange-500"
                : "text-gray-800 bg-gray-300 border-gray-500"
            }`}
          >
            {status}
          </p>
        );
      },
    },
    {
      key: "lvl",
      label: "Global",
      sortable: false,
      render: (value, item) => (
        <input
          type="checkbox"
          checked={item.lvl}
          onChange={() => handleGlobalStatusUpdate(item.duaId, !(item.lvl ?? 0))}
          className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
        />
      ),
    },
  ];

  const actions = [
    {
      label: "View",
      className: "px-3 py-2 rounded-lg text-sm text-blue-500 bg-white border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => {
        setSelectedDua(item);
        setIsModalOpen(true);
      },
    },
    {
      label: "Approve",
      className: "px-3 py-2 rounded-lg text-sm text-green-500 bg-white border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => {
        if (item.duaStatus.toUpperCase() !== "APPROVED") {
          handleStatusUpdate(item.duaId, "APPROVED");
        }
      },
      isDisabled: (item) => item.duaStatus.toUpperCase() === "APPROVED",
    },
    {
      label: "Reject",
      className: "px-3 py-2 rounded-lg text-sm text-red-500 bg-white border border-red-500 hover:bg-red-300 hover:text-red-700 focus:ring-2 focus:ring-red-500",
      handler: (item) => {
        if (item.duaStatus.toUpperCase() !== "REJECTED") {
          handleStatusUpdate(item.duaId, "REJECTED");
        }
      },
      isDisabled: (item) => item.duaStatus.toUpperCase() === "REJECTED",
    },
  ];

  return (
    <div className="flex flex-col w-full bg-white mb-2">
      <div className="flex flex-wrap w-full px-4 bg-gray-200">
      </div>
      <Table 
        data={dua}
        columns={columns}
        pageSize={10}
        actions={actions}
        title="Data User Agreement Management"
      />
      <MarkdownModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedDua?.duaTitle || "DUA Details"}
        markdownContent={selectedDua?.duaDescription || ""}
      />
    </div>
  );
};

export default AdminVerifyDUA;