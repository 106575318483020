import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../controllers/authController";
import { Button } from "../../components/styled/Buttons";
import { useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const formClass = "px-10 py-6 mx-auto my-6 w-full max-w-md rounded-lg bg-white shadow-md sm:w-1/3";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');

  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [error, setError] = useState("");


  const validatePassword = (password) => {
    const passwordRegex =
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/;
    return passwordRegex.test(password)
      ? ""
      : "Password must be at least 8 or at most 20 characters without space, include uppercase, lowercase, number, and special character.";
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const validationError = validatePassword(password);
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    if (password !== cpassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    try {
      const response = resetPassword(email, password, "dfs");
      if (response.error) {
        setError("DATABASE_ERROR");
      } else {
        setPasswordReset(true);
      }
    } catch (err) {
      navigate("/login-for-passwordupdate-link");
    } finally {
      setLoading(false);
    }
  };

  if (passwordReset) {
    return (
      <div className={formClass}>
        <div className="text-center">
          <p>Password has been successfully reset.</p>
          <p>Please sign in.</p>
          <Button.Blue onClick={() => navigate("/sign-in")} className="w-full my-2 cursor-pointer">
            Sign In
          </Button.Blue>
        </div>
      </div>
    );
  }

  return (
    <div className={formClass}>
      <form onSubmit={submit}>
        <div className="py-2 mb-4 space-y-2 sm:rounded-lg">
          <label className="text-base" htmlFor="pass">Enter New Password</label>
          <div className="relative">
            <input
            type={showPassword ? "text" : "password"}
            id="pass"
            value={password}
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="text-gray-60 absolute inset-y-0 right-0 mt-2 flex items-center px-2 text-sm leading-5"              
            >
              {showPassword ? (
                <FaEyeSlash className="h-5 w-5 text-gray-500 mb-2 mr-2" />
              ) : (
                <FaEye className="h-5 w-5 text-gray-500 mb-2 mr-2" />
              )}
            </button>
          </div>
        </div>
        <div className="py-2 mb-4 space-y-2 sm:rounded-lg">
          <label className="text-base" htmlFor="conf">Confirm New Password</label>
          <div className="relative">
            <input
            type={showCPassword ? "text" : "password"}
            id="conf"
            value={cpassword}
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={(e) => setCPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={() => setShowCPassword(!showCPassword)}
              className="text-gray-60 absolute inset-y-0 right-0 mt-2 flex items-center px-2 text-sm leading-5"
            >
              {showCPassword ? (
                <FaEyeSlash className="h-5 w-5 text-gray-500 mb-2  mr-2" />
              ) : (
                <FaEye className="h-5 w-5 text-gray-500 mb-2 mr-2" />
              )}
            </button>
          </div>
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <Button.Blue type="submit" className="w-full my-2 cursor-pointer">
          {loading ? "Please wait..." : "Reset Password"}
        </Button.Blue>
      </form>
    </div>
  );
};

export default ResetPassword;


