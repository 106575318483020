import axios from 'axios';
import { url } from '../creds';
import axiosInstance from './AxiosInstance';
import creds from "../../src/creds";
import { getUserRole } from './AuthService';

const backendCore = creds.backendCore;

export const getDatasetAccessStats = async (fromDate, toDate) => {
    try {
        return await axiosInstance.get(`${backendCore}stats/dashboard-dataset-access-type-stats`, {
            params: {
                fromDate: fromDate,
                toDate: toDate
            },
            headers: {
                'Permission-Name': 'dashboard_dataset_access_type_stats',
                'Role-Id': getUserRole().roleId,
            },
            withCredentials: true,
        });
    } catch (error) {
        throw error.message;
    }
};

export const getOrderStats = async (fromDate, toDate) => {
    try {
        return await axiosInstance.get(`${backendCore}stats/dashboard-order-stats`, {
            params: {
                fromDate: fromDate,
                toDate: toDate
            },
            headers: {
                'Permission-Name': 'dashboard_order_stats',
                'Role-Id': getUserRole().roleId,
            },
            withCredentials: true,
        });
    } catch (error) {
        throw error.message;
    }
};

export const getRevenueStats = async (fromDate, toDate, groupBy) => {
    try {
      return await axiosInstance.get(`${backendCore}stats/dashboard-dataset-revenue-stats`, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          groupBy: groupBy,
        },
        headers: {
          "Permission-Name": "dashboard_dataset_revenue_stats",
          "Role-Id": getUserRole().roleId,
        },
        withCredentials: true,
      });
    } catch (error) {
      throw error.message;
    }
  };