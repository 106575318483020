import axiosInstance from './AxiosInstance';
import axios from 'axios';
import creds from "../../src/creds";
import { getUserRole } from "./AuthService";

const backendCore = creds.backendCore;

// Adding the Dataset into the Catalogue
export const addDatasetInCatalogue = async (formData) => {
    const DatasetInCatalogueJSON = {
        datasetName: formData.datasetName,
        domainName: formData.domainName,
        description: formData.description,
        contentType: formData.contentType,
        datasetUsage: formData.datasetUsage,
        sourceLink: formData.sourceLink,
    };
    try {
        const response = await axiosInstance.post(`${backendCore}dataset/create-dataset-catalogue`, DatasetInCatalogueJSON, {
            headers: {
                'Permission-Name': `create_dataset_catalogue`,
                'Role-Id': getUserRole().roleId,
            },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.message || 'Something went wrong';
            throw new Error(errorMessage);
        }
        throw new Error(error.message || 'Network Error');
    }
};

export const updateDatasetInCatalogue = async (catalogueId, formData) => {
    const updatedDatasetJSON = {
        datasetName: formData.datasetName,
        domainName: formData.domainName,
        description: formData.description,
        contentType: formData.contentType,
        datasetUsage: formData.datasetUsage,
        sourceLink: formData.sourceLink,
    };
    try {
        const response = await axiosInstance.put(`${backendCore}dataset/update-dataset-catalogue/${catalogueId}`, updatedDatasetJSON, {
            headers: {
                'Permission-Name': `update_dataset_catalogue`,
                'Role-Id': getUserRole().roleId,
            },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.message || 'Something went wrong';
            throw new Error(errorMessage);
        }
        throw new Error(error.message || 'Network Error');
    }
};

// Delete dataset catalogue
export const deleteDatasetFromCatalogue = async (catalogueId) => {
    try {
      const response = await axiosInstance.delete(
        `${backendCore}dataset/delete-dataset-catalogue/${catalogueId}`,
        {
          headers: {
            'Permission-Name': `delete_dataset_catalogue`,
            'Role-Id': getUserRole().roleId,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message || "Failed to delete" };
      }
    } catch (error) {
      console.error("Error deleting dataset:", error);
      return { success: false, message: "An error occurred" };
    }
};

  
export const getDatasetCataloguesList = async () => {
    try {
        // Fetch the list of dataset catalogues from the backend
        const listResponse = await axiosInstance.get(`${backendCore}dataset/list-dataset-catalogue`, {
            headers: {
                'Permission-Name': `list_dataset_catalogues`,
                'Role-Id': getUserRole().roleId,
            },
            withCredentials: true,
        });
        const datasets = listResponse.data;
        return datasets;
    } catch (error) {
        console.error("Error fetching dataset catalogue list:", error);
        throw error.message;
    }
};


export const getPublicDatasetCataloguesList = async () => {
    try {
      const response = await axios.get(`${backendCore}dataset/public-list-dataset-catalogue`); // Make the API call

      const datasets = response.data.data;
        return datasets;
    } catch (error) {
      console.error("Error fetching dataset catalogue list:", error);
      throw error;
    }
  };
  
  

