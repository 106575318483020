import React, { useState, useMemo } from "react";
import SignUpInputField from "../../components/signupform/SignUpInputField";
import PasswordToggleField from "../../components/signupform/PasswordToggleField";
import { handleSignUp } from "../../controllers/authController";
import { validate, checkForNullErrors } from "../../models/Validation"; // Import validation logic
import { useNavigate } from "react-router-dom";
import notify from "../../packages/toastify/Notify";

export default function SignUpPage() {
  const [emailSent, setEmailSent] = useState(false);
  const [firstNameInputField, setFirstNameInputField] = useState("");
  const [lastNameInputField, setLastNameInputField] = useState("");
  const [passwordInputField, setPasswordInputField] = useState("");
  const [emailInputField, setEmailInputField] = useState("");
  const [institutionInputField, setInstitutionInputField] = useState("");
  const [designationInputField, setDesignationInputField] = useState("");
  const [roleInputField] = useState("default_user");
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [institutionTouched, setInstitutionTouched] = useState(false);
  const [designationTouched, setDesignationTouched] = useState(false);
  const navigate = useNavigate()

  const errors = useMemo(
    () =>
      validate(
        emailTouched,
        emailInputField,
        passwordTouched,
        passwordInputField,
        firstNameInputField,
        firstNameTouched,
        lastNameTouched,
        lastNameInputField,
        institutionTouched,
        institutionInputField,
        designationTouched,
        designationInputField
      ),
    [
      emailTouched,
      emailInputField,
      passwordTouched,
      passwordInputField,
      firstNameInputField,
      firstNameTouched,
      lastNameTouched,
      lastNameInputField,
      institutionTouched,
      institutionInputField,
      designationTouched,
      designationInputField,
    ]
  );

  const handleSubmit = async (e) => {    
    e.preventDefault(); 
    
    const touchedFields = {
      firstname: firstNameTouched,
      lastname: lastNameTouched,
      email: emailTouched,
      password: passwordTouched,
      institution: institutionTouched,
      designation: designationTouched,
    };

    const error_message = checkForNullErrors(errors,touchedFields);
    if (error_message) {
      notify("Fill " + error_message + " correctly", "warning");  
      return 
    } else {
      const trimmedFields = {
        firstname: firstNameInputField.trim(),
        lastname: lastNameInputField.trim(),
        password: passwordInputField.trim(),
        email: emailInputField.trim(),
        institution: institutionInputField.trim(),
        designation: designationInputField.trim(),
        role: roleInputField,
      };
      
      const formFields = {
        firstName: trimmedFields.firstname,
        lastName: trimmedFields.lastname,
        userName: trimmedFields.email,
        password: trimmedFields.password,
        organization: trimmedFields.institution,
        designation: trimmedFields.designation,
        userRole: trimmedFields.role,
        appName: "dfs",
      };
      await handleSignUp(formFields, setEmailSent, navigate);
    }
  }

  return (
    <div className="wx-auto sm:w-2/5">
      <div className="w-full sm:w-full text-white rounded text-center bg-blue-green-0 mt-8 mb-4 py-2">Create your account</div>
      <div className="bg-white shadow-md rounded px-4 pt-4 pb-1 mb-8">
        <form onSubmit={handleSubmit} autocomplete="on">
          <SignUpInputField
            fieldName="first-name"
            labelName="First Name"
            required={true}
            inpType="text"
            touched={firstNameTouched}
            setTouched={setFirstNameTouched}
            errors={errors.firstname}
            inputField={firstNameInputField}
            setInputField={setFirstNameInputField}
            placeholder="First Name"
          />

          <SignUpInputField
            fieldName="last-name"
            labelName="Last Name"
            inpType="text"
            required={true}
            touched={lastNameTouched}
            setTouched={setLastNameTouched}
            errors={errors.lastname}
            inputField={lastNameInputField}
            setInputField={setLastNameInputField}
            placeholder="Last Name"
          />

          <SignUpInputField
            fieldName="email"
            labelName="Email"
            required={true}
            inpType="email"
            touched={emailTouched}
            setTouched={setEmailTouched}
            errors={errors.email}
            inputField={emailInputField}
            autoComplete="email"
            setInputField={setEmailInputField}
            placeholder="Email"
          />

          <PasswordToggleField
            fieldName="password"
            labelName="Password"
            required={true}
            touched={passwordTouched}
            setTouched={setPasswordTouched}
            errors={errors.password}
            inputField={passwordInputField}
            autoComplete="current-password"
            setInputField={setPasswordInputField}
            placeholder="Password"
          />

          <SignUpInputField
            fieldName="institution"
            labelName="Institution"
            required={true}
            inpType="text"
            touched={institutionTouched}
            setTouched={setInstitutionTouched}
            errors={errors.institution}
            inputField={institutionInputField}
            setInputField={setInstitutionInputField}
            placeholder="Institution"
          />

          <SignUpInputField
            fieldName="designation"
            labelName="Designation"
            required={true}
            inpType="text"
            touched={designationTouched}
            setTouched={setDesignationTouched}
            errors={errors.designation}
            inputField={designationInputField}
            setInputField={setDesignationInputField}
            placeholder="Designation"
          />
          <div className="text-center text-sm font-light text-gray-500 dark:text-gray-400 my-4"> All fields are mandatory. </div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={emailSent}
          >
            {emailSent ? "Sending..." : "Sign Up"} 
          </button>
          <div className="text-center">
            <p className="text-sm font-light text-gray-500 dark:text-gray-400 my-4">
              Already a member?
              <a
                className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                href="/sign-in"
              >
                Login
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

