import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit'
import { fetchUserRole, login, logout } from '../services/AuthenticationService'
import { resetForm } from './formSlice';

const initialState = {
    value: 0,
    loginLoading: false,
    loginRes: null,
    loginError: null,
    roleRes: null,
    roleLoading: false,
    roleError: null,
    logoutLoading: false,
    logoutError: null,
    isLoggedIn: false,
    userName: '',
    firstName: '',
    lastName: '',
    sessionId: '',
    userId: '',
}

export const signIn = createAsyncThunk(
    'signIn',
    async ({ userName, password, appName }, thunkAPI) => {
        const { dispatch, rejectWithValue } = thunkAPI;
        try {            
            const response = await login(userName, password, appName);
            if (response.status === 200) {
                const { firstName, lastName } = response.data;
                console.log(firstName, lastName);
                const sessionId = response.headers['session-id']; 
                const userId = response.headers['user-id']; 
                dispatch(setUserDetails({ userName, firstName, lastName, sessionId, userId }));
                const response1 = await dispatch(getUserRole()).unwrap();
                dispatch(setRole({ roleId: response1.roleId, roleName: response1.roleName }));
            }
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            const statusCode = error.response?.status;
            return rejectWithValue({ message: errorMessage, status: statusCode });
        }
    }
);

export const getUserRole = createAsyncThunk('getUserRole', async () => {
    try {
        const response = await fetchUserRole();
        return response.data.message;
    }
    catch (error) {
        throw error.message;
    }
});

export const logOut = createAsyncThunk(
    'logOut',
    async ({ sessionId, userId, userName }, { dispatch, rejectWithValue }) => {
        try {
            const response = await logout(sessionId, userId, userName);          
            dispatch(clearSession());  
            dispatch(resetForm());
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message; 
            const statusCode = error.response?.status; 
            return rejectWithValue({ message: errorMessage, status: statusCode });
        }
    }
);

export const clearLoginError = createAction('auth/clearLoginError');

export const authSlice = createSlice({
    name: 'auth',
    initialState,    
    reducers: {
        setRole: (state, action) => {
            const { roleId, roleName } = action.payload;
            state.roleRes = { roleId, roleName };
            localStorage.setItem('userRole', JSON.stringify({ roleId, roleName }));
        },
        setUserDetails: (state, action) => {
            const { userName, firstName, lastName, sessionId, userId } = action.payload;
            state.userName = userName;
            state.firstName = firstName;
            state.lastName = lastName;
            state.sessionId = sessionId;
            state.userId = userId;
        },
        clearSession: (state) => {
            state.loginRes = null;
            state.roleRes = null;
            state.userName = '';
            state.firstName = '';
            state.lastName = '';
            state.sessionId = '';
            state.userId = '';
            state.isLoggedIn = false;
            localStorage.clear()
        },
        clearLoginError: (state) => {
            state.loginError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loginLoading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loginLoading = false;
                state.loginRes = action.payload;    
                state.isLoggedIn = true;
                // state.userName = action.meta.arg.userName; // Remove this line
            })
            .addCase(signIn.rejected, (state, action) => {
                state.loginLoading = false;
                state.loginError = { message: action.payload?.message || 'Login failed', status: action.payload?.status, };
            })
            .addCase(getUserRole.pending, (state) => {
                state.roleLoading = true;
            })
            .addCase(getUserRole.fulfilled, (state, action) => {
                state.roleLoading = false;
                state.roleRes = action.payload
            })
            .addCase(getUserRole.rejected, (state, action) => {
                state.roleLoading = false;
                state.roleError = { message: action.payload?.message || 'Failed to fetch user role', status: action.payload?.status, };
            })
            .addCase(logOut.pending, (state) => {
                state.logoutLoading = true;
            })
            .addCase(logOut.fulfilled, (state) => {
                state.logoutLoading = false;
                state.logoutError = null;
                state.isLoggedIn = false;
            })
            .addCase(logOut.rejected, (state, action) => {
                state.logoutLoading = false;
                state.logoutError = action.payload;
            });

    }
})

export const selectIsLoggedIn = (state) => {
    return Boolean(localStorage.getItem('userRole')) || state?.auth?.isLoggedIn;
};

export const { setRole,  clearSession, setUserDetails } = authSlice.actions;

export default authSlice.reducer;



