import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import {HomePageSubHeading} from "../../components/styled/GlobalStyles.js"
import howToDownload from "../../assets/pdf/How-to-Download-Dataset.pdf"
import howToLogin from "../../assets/pdf/How-to-Login.pdf"
import howToCreateDua from "../../assets/pdf/How-to-create-n-manage-DUA.pdf"
import howToRegister from "../../assets/pdf/How-to-Register.pdf"
import howToReqPrivateDS from "../../assets/pdf/How-to-Request-Access-to-a-Private-Dataset.pdf"
import howToSearch from "../../assets/pdf/How-to-search-Dataset.pdf"
import howToUpload from "../../assets/pdf/How-to-upload-Dataset.pdf"
import whoIsUploader from "../../assets/pdf/Who-is-uploader,Author,-coordinator-and-Admin.pdf"
import howToResetPassword from "../../assets/pdf/How-to-reset-password.pdf"
import howToChangeProfile from "../../assets/pdf/How-to-Change-Profile-Details.pdf"

const HeadingContentLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={150}
    viewBox="0 0 2000 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="30" y="25" rx="10" ry="10" width="100%" height="40%" />
  </ContentLoader>
);

const ParagraphContentLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={100}
    viewBox="0 0 5000 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
);

const DFSFeatureLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={100}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="10" ry="10" width="100%" height="40%" />
  </ContentLoader>
);

const HowTo = () => {
    const [loading, setLoading] = useState(true);
  const [openIndex, setOpenIndex] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqs = [
    {
      question: "How to Register on Our Website",
      answer: `
      <p class="py-1"><b>Description</b>: Instructions on how to create an account on the platform, including filling out the registration form, verifying your email, and completing your profile.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToRegister}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;" href="https://youtu.be/p8Z9xc5BHjU" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    {
      question: "How to Login",
      answer: `
      <p class="py-1"><b>Description</b>: Instructions on how to login/ sign in an account on the platform, verifying your email ID and password.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToLogin}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    {
      question: "How to Reset Password",
      answer: `
      <p class="py-1"><b>Description</b>: Instructions on how to reset your account password if you’ve forgotten it or need to change it. This includes using the "Forgot Password" option and following the reset steps via email.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToResetPassword}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;" href="https://youtu.be/2K3ymdZGw-0" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    {
      question: "How to Change Profile Details",
      answer: `
      <p class="py-1"><b>Description</b>: Instructions on how to update your profile information, including your name, email, profile picture, and other personal details on the platform.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToChangeProfile}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;" href="https://youtu.be/Axres0Gjczw" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    {
      question: "How to Create a Data Use Agreement (DUA) on Your Datasets Website",
      answer: `
      <p class="py-1"><b>Description</b>: A Data Use Agreement (DUA) is a formal contract that must be agreed upon by a user before they can access and download data. The DUA outlines the terms and conditions under which data can be used, ensuring compliance with privacy, security, and legal requirements. Users must review and agree to the DUA before downloading the data.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToCreateDua}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;" href="https://youtu.be/tJZRn8L9hes" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    {
      question: "Who is Uploader, Author, Co-Ordinator, ADMIN",
      answer: `
      <p class="py-1"><b>Description</b>: Explanation of the different roles on the platform: Uploader, Author, Co-Ordinator, and Admin, including their responsibilities and permissions.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${whoIsUploader}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    {
      question: "How to Upload a Dataset",
      answer: `
      <p class="py-1"><b>Description</b>: A step-by-step guide on how users can upload datasets to your platform. This should include details about file formats, dataset size limits, and any guidelines for uploading (e.g., dataset descriptions, metadata requirements).</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToUpload}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;" href="https://youtu.be/NmklaDfbt20" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    {
      question: "How to Download a Dataset",
      answer: `
      <p class="py-1"><b>Description</b>: Instructions on how to find, access, and download datasets from the platform. This can include search tips, filtering options, and download formats.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToDownload}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;" href="https://youtu.be/VJVKUd7NBVk" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    {
      question: "How to Search for Datasets",
      answer: `
      <p class="py-1"><b>Description</b>: A guide on how users can search for datasets effectively using the platform’s search bar, filters, and categories.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToSearch}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    // Right now we hide this content but we can use in future 
    // {
    //   question: "How to Share a Dataset",
    //   answer: `
    //   <p class="py-1"><b>Description</b>: Information on how users can share datasets with others, either by linking, embedding, or using collaborative features within the platform (if available).</p>
    //   <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToLogin}" target="__blank" rel="noopener noreferrer">Read More</a></p>
    //   <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;"  target="__blank" rel="noopener noreferrer">Coming Soon</a></p>
    //   `
    // },
    // {
    //   question: "How to Access Premium Datasets",
    //   answer: `
    //   <p class="py-1"><b>Description</b>: A guide for users who want to explore premium (paid) datasets, including how to subscribe, purchase, or access them through their account.</p>
    //   <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" target="__blank" rel="noopener noreferrer">Coming Soon</a></p>
    //   <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;"  target="__blank" rel="noopener noreferrer">Coming Soon</a></p>
    //   `
    // },
    {
      question: "How to Request Access to Private Datasets",
      answer: `
      <p class="py-1"><b>Description</b>: A Private Dataset is a dataset that is not publicly available and requires special permission to access. To gain access, you must submit a request, and upon approval, you will be granted permission to view or download the dataset.</p>
      <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" href="${howToReqPrivateDS}" target="__blank" rel="noopener noreferrer">Read More</a></p>
      `
    },
    // Right now we hide this content but we can use in future 
    // {
    //   question: "How to Manage Your Account",
    //   answer: `
    //   <p class="py-1"><b>Description</b>: A section that explains how users can manage their profile, view download/upload history, or adjust privacy and account settings.</p>
    //   <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" target="__blank" rel="noopener noreferrer">Coming Soon</a></p>
    //   <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;"  target="__blank" rel="noopener noreferrer">Coming Soon</a></p>
    //   `
    // },
    // {
    //   question: "How to Access Premium Datasets",
    //   answer: `
    //   <p class="py-1"><b>Coming soon</b></p>
    //   `
    // },
    // {
    //   question: "How to Collaborate on Datasets-My Groups",
    //   answer: `
    //   <p class="py-1"><b>Description</b>: Explain how users can collaborate on datasets, either within teams or with the broader community, and the tools they can use for collaboration (e.g., comments, private sharing).</p>
    //   <p class="py-1">User Manual: <a class="" style="color: blue; text-decoration: underline;" target="__blank" rel="noopener noreferrer">Coming Soon</a></p>
    //   <p class="py-1">YouTube link: <a class="" style="color: blue; text-decoration: underline;"  target="__blank" rel="noopener noreferrer">Coming Soon</a></p>
    //   `
    // },
    {
      question: "How to contact ADMIN",
      answer: `
      <p class="py-1">For any concerns or issues or help contact Mail : <a style="color: blue; text-decoration: underline; cursor: pointer;" mainto="dfsupport@ihub-data.iiit.ac.in">dfsupport@ihub-data.iiit.ac.in</p>
      `
    }
  ];

  return (
    <>
    <div className="bg-gray-50 mx-auto">
      {loading ? (
        <div className="transition-transform duration-300 ease-in-out flex justify-center items-center">
          <HeadingContentLoader />
        </div>
      ) : (
        <h1 className={HomePageSubHeading}>
          User Guide
        </h1>
      )}

      {loading ? (
        <div className="mx-8 transition-transform duration-300 ease-in-out flex justify-center items-center">
          <ParagraphContentLoader />
        </div>
      ) : (
        <p className="lg:text-xl md:text-lg sm:text-base text-base font-sans font-xl text-center px-10 md:pt-6 md:pb-10 sm:pt-3 sm:pb-6">
          User's platform supports dataset and model registration, publication,
          and sharing, fostering collaboration while respecting data
          sensitivity, advancing research in ML and Data Analytics Challenges.
        </p>
      )}
      <div className="md:mx-20 sm:mx-15 mx-10 mt-5 rounded-xl">
        <div className="w-full sm:w-auto mx-auto pb-16">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="border-b hover:scale-105 transition-transform duration-300 ease-in-out"
            >
              {loading ? (
                <div className="transition-transform duration-300 ease-in-out flex justify-center items-center">
                  <DFSFeatureLoader />
                </div>
              ) : (
                <>
                  <button
                    onClick={() => toggleAccordion(index)}
                    className="w-full py-3 px-4 flex items-center justify-between bg-white rounded-t-lg font-sans shadow-md font-semibold text-black"
                  >
                    <span>{faq.question}</span>
                    <svg
                      className={`h-6 w-6 transform rotate-${
                        openIndex === index ? "180" : "0"
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {openIndex === index && (
                    <div
                    className="px-4 py-2 text-gray-800 font-sans rounded-b-lg bg-white shadow-md"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  )
}

export default HowTo;
