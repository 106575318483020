import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { AiOutlineCamera } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import ContentLoader from "react-content-loader";
import defaultImage from "../../assets/images/profileImages/0-defaultProfile.webp";
import { useDispatch, useSelector } from "react-redux";
import {
  setTab,
  toggleCollapsed,
  initializeTab,
} from "../../redux/commonSlice";
import { ImSpinner6 } from "react-icons/im";
import { getUserLoginState, USER_STATE } from "../../withAuth";
import { setProfileImage } from "../../redux/profileSlice";
import ImageUploader from "../upload/ImageUploader";
import notify from "../../packages/toastify/Notify";
import ImageDownloader from "../download/ImageDownloader";

const Sidebar = ({ tabConfig }) => {
  const activeTab = useSelector((state) => state.common.tab);
  const collapsed = useSelector((state) => state.common.collapsed);
  const isUploading = useSelector((state) => state.upload.isUploading);
  const profileImage = useSelector((state) => state.profile.profileImage);
  const [imageVersion, setImageVersion] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [ProfileImg, setProfileImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const userId = useSelector((state) => state.auth.userId);

  const dispatch = useDispatch();

  const handleTabClick = (tabId, event) => {
    event.preventDefault();
    dispatch(setTab(tabId));
  };

  const toggleCollapse = () => {
    dispatch(toggleCollapsed());
  };

  const imgLoader = () => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    imgLoader();

    // Get user role and initialize the tab
    const userRole = getUserLoginState();
    dispatch(initializeTab(userRole));
  }, [dispatch]);

  const filteredTabConfig = tabConfig.filter(
    (item) =>
      item.permissions.includes(USER_STATE.ANY) ||
      item.permissions.includes(getUserLoginState())
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsUploadingImage(true);
    }
  };

  // Wait for the tab to be initialized before rendering
  if (activeTab === null) {
    return <div>Loading...</div>; // Or a spinner/loading indicator
  }
  return (
    <div
      className={`p-2 bg-gray-200 rounded-1xl ${
        collapsed ? "w-20" : "w-1/1 sm:w-1/1 md:w-1/4 lg:w-1/5"
      } ${isUploading ? 'cursor-not-allowed' : ""}`}
      title={isUploading ? `Navigation disabled - Upload in progress` : ""} // Add title attribute to the div
    >
      <div
        className={`p-2 ${isUploading ? "pointer-events-none opacity-50" : ""}`}
      >
        <div
          className={`ml-3 hover:cursor-pointer ${
            collapsed ? "justify-center text-center" : "flex justify-end"
          }`}
        >
          {collapsed ? (
            <FaBars className="" onClick={toggleCollapse} />
          ) : (
            <FaTimes className="" onClick={toggleCollapse} />
          )}
        </div>
        <div className="flex justify-center relative py-5">
          {loading ? (
            <ContentLoader
              speed={2}
              width={"100%"}
              height={"100%"}
              viewBox="0 0 400 400"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              className="md:ml-8 sm:ml-7 ml-auto"
            >
              <rect x="0" y="0" rx="100%" ry="100%" width="80%" height="80%" />
            </ContentLoader>
          ) : (
            <>
              <div className="dv-1 relative inline-block">
                {!collapsed && (
                  <img
                    src={profileImage?.imageUrl || defaultImage}
                    alt=""
                    className="w-24 h-24 md:w-36 md:h-36 sm:w-30 sm:h-30 p-2 sm:p-2 md:p-3 rounded-full"
                  />
                )}
                {!collapsed && (
                  <div className="img-upload-btn absolute bottom-4 right-4 bg-blue-700 p-1 rounded-full cursor-pointer">
                    <label
                      htmlFor="file-upload"
                      className={`cursor-pointer ${
                        isUploadingImage ? "pointer-events-none opacity-50" : ""
                      }`}
                    >
                      {isUploadingImage ? (
                        <div className="spinner">
                          <ImSpinner6 className="animate-spin text-2xl text-white-500" />
                        </div>
                      ) : (
                        <AiOutlineCamera
                          className="text-2xl text-white rounded active:border-transparent cursor-pointer"
                          data-tooltip-id={"imgUpload"}
                          data-tip
                          data-for="imgUploadTooltip"
                        />
                      )}
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        disabled={isUploadingImage}
                      />
                    </label>
                    <Tooltip
                      id={"imgUpload"}
                      content="Click here to update your Avatar"
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <ul>
          {filteredTabConfig.map((item) => (
            <li key={item.id}>
              <a
                href={item.href}
                className={`block text-sm p-2 flex items-center ${
                  activeTab === item.id
                    ? "bg-white text-blue-600 font-bold rounded px-2"
                    : ""
                }`}
                onClick={(e) => {
                  if (!item.disabled) {
                    handleTabClick(item.id, e);
                  }
                }}
                data-tooltip-id={item.tooltipId}
                data-tip
                data-for={`${item.tooltipId}Tooltip`}
              >
                <span
                  className={`flex items-center justify-center rounded-full p-2 ${
                    activeTab === item.id ? "bg-blue-500" : "bg-transparent"
                  }`}
                >
                  <item.icon
                    className={`text-xl ${
                      activeTab === item.id ? "text-white" : "text-blue-600"
                    }`}
                  />
                </span>
                <span className={`ml-3 ${collapsed ? "hidden" : ""}`}>
                  {item.label}
                </span>
                <Tooltip
                  id={item.tooltipId}
                  content={item.tooltip}
                  className={`z-10 ${collapsed ? "block" : "hidden"}`}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
      {selectedFile && (
        <ImageUploader
          file={selectedFile}
          versionId={userId}
          customPath={`profileImages/${userId}`}
          onSuccess={(uploadedImageUrl) => {
            setSelectedFile(null);
            setIsUploadingImage(false);
            notify("Profile Picture Successfully uploaded!", "success");
            setImageVersion((prev) => prev + 1);
          }}
          onError={() => {
            setSelectedFile(null);
            setIsUploadingImage(false);
            notify("Profile Picture Upload failed. Please try again.", "error");
          }}
        />
      )}
      <ImageDownloader key={imageVersion} versionId={userId} />
    </div>
  );
};

export default Sidebar;
