import { useState, useCallback } from 'react';
import FileDetailsTable from './FileDetails';
import UploadDetails from './AddFilesForm';
import { Modal } from '../styled/Modal';

const FileUploadModal = ({isOpen, onClose, datasetId, datasetVersionId}) => {
    const [fileValidation, setFileValidation] = useState({
        hasReadmeFiles: false,
        hasDatasetFiles: false,
        totalDatasetSize: 0,
        updateKey: 0 // Add a key for forcing re-render
    });

    const handleExistingFiles = useCallback( async (validationData) => {
        await setFileValidation(prev => ({
            hasReadmeFiles: validationData.hasReadme,
            hasDatasetFiles: validationData.hasDatasetFile,
            totalDatasetSize: validationData.totalDatasetSize,
            updateKey: prev.updateKey + 1 // Increment key to force re-render
        }));
    }, []);

    return (
        <Modal
            size='xl'
            isOpen={isOpen}
            onClose={onClose}
            title="Upload Files"
        >
            <FileDetailsTable 
                datasetId={datasetId} 
                datasetVersionId={datasetVersionId}
                onValidationChange={handleExistingFiles}
                deleteAccess={true}
            />
            <UploadDetails 
                key={fileValidation.updateKey}
                hasDatasetFiles={fileValidation.hasDatasetFiles}
                hasReadmeFiles={fileValidation.hasReadmeFiles}
                totalUploadSize={fileValidation.totalDatasetSize}
                onComplete={onClose}
            />
            <div className="flex justify-end mt-4">
                <button 
                    className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600" 
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </Modal>
    )
};

export default FileUploadModal;
