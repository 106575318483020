import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signIn, clearLoginError } from '../../redux/AuthController';
import notify from "../../packages/toastify/Notify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SessionExpiredPopup = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {    
        const handleSessionExpired = (e) => {
            setUserName(e.detail.userName);
            setIsVisible(true);
        };
        window.addEventListener('sessionExpired', handleSessionExpired);
        return () => {
            window.removeEventListener('sessionExpired', handleSessionExpired);
        };
    }, []);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value) {
            setPasswordError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!password) {
            setPasswordError("Password is required");
            return;
        }
        try {
            const resultAction = await dispatch(signIn({ userName, password, appName: "dfs" }));
            if (signIn.fulfilled.match(resultAction)) {
                dispatch(clearLoginError());
                setIsVisible(false);
                notify('Re-login successful', "success");
                window.dispatchEvent(new Event('reLoginSuccessful'));
            } else {
                setPasswordError('Incorrect Password')
                notify('Incorrect password!. Please try again.', "error");
            }
        } catch (error) {
            setPasswordError('Incorrect Password')
            notify('Incorrect password!. Please try again.', "error");
        }
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 9999 }}>
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl mb-4">Session Expired</h2>
                <p>Please re-enter your password to continue.</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm">Username</label>
                        <input
                            type="text"
                            value={userName}
                            readOnly
                            className="w-full px-3 py-2 border rounded bg-gray-200 cursor-not-allowed"
                        />
                    </div>
                    <div className="mb-4 relative">
                        <label className="block text-sm">Password</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            className={`w-full px-3 py-2 border rounded ${passwordError ? "border-red-500" : ""}`}
                        />
                        <span
                            className="absolute inset-y-0 right-0 items-center mt-8 mr-2 cursor-pointer"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
                        </span>
                        {passwordError && (
                            <p className="text-red-600 text-sm">{passwordError}</p>
                        )}
                    </div>
                    <div className="flex justify-center">
                        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
                            Re-Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SessionExpiredPopup;