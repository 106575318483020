// src/redux/formSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 1,
  datasetDetails: {},
  versionDetails: {},
  uploadDetails: {},
  completed: false
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    nextPage: (state) => {
      state.currentPage += 1;
    },
    prevPage: (state) => {
      state.currentPage -= 1;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    toggleCompleted: (state) => {
      state.completed = !state.completed
    },
    setDatasetDetails: (state, action) => {
      state.datasetDetails = action.payload;
    },
    setVersionDetails: (state, action) => {
      state.versionDetails = action.payload;
    },
    setUploadDetails: (state, action) => {
      state.uploadDetails = action.payload;
    },
    resetForm: (state) => {
      state.currentPage = 1;
      state.datasetDetails = {};
      state.versionDetails = {};
      state.uploadDetails = {};      
      state.completed = true;
    },
  },
});

export const { nextPage, prevPage, setCurrentPage, setDatasetDetails, setVersionDetails, setUploadDetails, resetForm, toggleCompleted } = formSlice.actions;
export default formSlice.reducer;