import React, { useState, useContext, useEffect } from 'react';
import UploadComponent from '../../components/upload/UploadComponent';
import { v4 as uuidv4 } from 'uuid';
import { ToastContext } from "../../App";
import { handleCreateVideo } from '../../controllers/videoController';
import { fetchVideoCountLimit, decreaseLimit } from '../../services/VideoService';
import { getUserRole } from '../../redux/AuthController';

const MyVideoCreate = () => {
  const [videoName, setVideoName] = useState('');
  const [error, setError] = useState('');
  const [videoId, setVideoId] = useState(uuidv4());
  const [uploadKey, setUploadKey] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const { addToast } = useContext(ToastContext);
  const [uploadLimit, setUploadLimit] = useState(0);
  const handleGetLimits = async () => {
    try {
      const response = await fetchVideoCountLimit();
      console.log(response);
      setUploadLimit(response.limits[0].upload_count_limit);
    } catch (err) {
      console.error(err);
    }
  }
  useEffect (() => {
    handleGetLimits();
  }, []);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!videoName.trim()) {
        setError('Video name is required');
        return;
      }
      if (!fileUploaded) {
        setError('Please upload a video file');
        return;
      }
      const videoData = {
        videoId: videoId,
        parentVideoId: videoId,
        parentVideoName: videoName
      }
      const resp = await handleCreateVideo(videoData, addToast);
      setUploadKey(prev => prev + 1);
      console.log(resp);
      setVideoId(uuidv4())
      setVideoName('');
      const limit_resp = await decreaseLimit({upload_count_limit: uploadLimit - 1});
      console.log(limit_resp);
      handleGetLimits();
    } catch (error) {
      console.error(error);
    }        // Handle form submission here
  };
  if(getUserRole().roleName !== 'Video Uploader' && getUserRole().roleName === 'Annotator') 
    return <div>You do not have video upload access. Please contact DFS support to get access.</div>
  if (uploadLimit === null) {
    return <div>Loading...</div>
  }
  if (uploadLimit === 0) {
    return <div>You have reached the upload limit</div>
  }
  return (
    <div className="space-y-12">
      {/* Header Section */}
      <div className="bg-white shadow">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h1 className="text-xl font-semibold text-gray-900">Create Video</h1>
            <p className="mt-1 text-sm text-gray-600">
              Upload and manage your video content
            </p>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Remaining Uploads:</span>
            <span className={`font-semibold ${uploadLimit > 0 ? 'text-green-600' : 'text-red-600'}`}>
              {uploadLimit}
            </span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="sm:col-span-6">
            <label htmlFor="videoName" className="block text-sm font-medium text-gray-900">
              Video Name
            </label>
            <div className="mt-2">
              <input
                id="videoName"
                type="text"
                value={videoName}
                onChange={(e) => setVideoName(e.target.value)}
                placeholder="Enter video name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
              />
              {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
            </div>
          </div>
          
          <div className="mt-6">
            <UploadComponent 
              maxFiles={1}
              onUploadComplete={() => {setFileUploaded(true)}}
              onFilesRemoved={() => {setFileUploaded(false)}}
              key={uploadKey} 
              fileCategory='VIDEO-ORIGINAL' 
              databaseId={videoId} 
              versionId={videoId} 
              allowedFileTypes={[
                'video/*',               // All video formats
                'video/mp4',            // MP4 videos
                'video/webm',           // WebM videos
                'video/quicktime',      // MOV videos
                'video/x-msvideo',      // AVI videos
                'video/x-matroska'      // MKV videos
              ]}
            />
          </div>
        </div>
      </div>

      {/* Footer/Submit Section */}
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          disabled={uploadLimit <= 0}
          className={`rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
            ${uploadLimit > 0 
              ? 'bg-indigo-600 hover:bg-indigo-500' 
              : 'bg-gray-400 cursor-not-allowed'}`}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default MyVideoCreate;
