import axios from 'axios';
import creds from "../../src/creds";

const baseURL = creds.backendCore;
const authURL = creds.backendAuth;

// Store userId and sessionId globally
let tokenDetails = {
    userId: null,
    userName: null,
    sessionId: null,
};

let failedRequestsQueue = [];

// Function to update token details
export const updateTokenDetails = (userName, userId, sessionId) => {
    tokenDetails.userName = userName;
    tokenDetails.userId = userId;
    tokenDetails.sessionId = sessionId;
};

const retryFailedRequests = () => {
    failedRequestsQueue.forEach((req) => {
        axiosInstance(req.config)
            .then(req.resolve)
            .catch(req.reject);
    });
    failedRequestsQueue = [];
};

export const subscribeRetryFailedRequests = () => {
    window.addEventListener('reLoginSuccessful', retryFailedRequests);
};

// Create Axios instance
const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const status = error.response?.status;
        const message = error.response?.data?.message;
        let isRefreshing=false
        console.log("before 498")

        if ((status === 498 || message === 'Invalid or expired access token' || (status === 401 && message === 'Access token is required')) && !error.config._retry) {
            error.config._retry = true;
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const renewTokenJSON = {
                        userId: tokenDetails.userId,
                        appName: "dfs",
                    };
                    const renewresp= await axios.post(
                        `${authURL}renew-token`,
                        renewTokenJSON,
                        {
                            headers: { 'Session-Id': tokenDetails.sessionId },
                            withCredentials: true,
                        }
                    );
                    return axiosInstance(error.config)                    
                } catch (refreshError) {
                    const event = new CustomEvent('sessionExpired', { detail: { userName: tokenDetails.userName } });
                    window.dispatchEvent(event);
                    return new Promise((resolve, reject) => {   
                        failedRequestsQueue.push({ config: error.config, resolve, reject });
                    });

                } finally {
                    isRefreshing = false;
                }
            }
        } 
        return Promise.reject(error);
    }
);

// Initialize the retry listener
subscribeRetryFailedRequests();

// Export tokenDetails and failedRequestsQueue
export { tokenDetails, failedRequestsQueue };

export default axiosInstance;
