import React, { useState, useContext } from 'react';
import Table from '../../components/styled/Table';
import Select from 'react-select';
import useFetchAllUsers from '../../components/admincontrol/useFetchAllUsers';
import useFetchAllRoles from '../../components/admincontrol/useFetchAllRoles';
import useFetchDeactivatedUsers from '../../components/admincontrol/useFetchDeactivatedUsers';
import { updateUserRole, deleteUser, activateUser } from '../../services/AdminControlService';
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from './../../packages/utils/constants';
import useFetchAllVideoLimits from '../../components/admincontrol/useFetchAllVideoLimits';
import { updateLimit } from '../../services/VideoService';

const AdminAccessControl = () => {
  const [refresh, setRefresh] = useState(false); // Track changes for both tables
  const [selectedRoles, setSelectedRoles] = useState({});
  const { addToast } = useContext(ToastContext);

  // Fetch active users and roles
  const { users, loading: usersLoading, error: usersError } = useFetchAllUsers(refresh);
  const { roles, loading: rolesLoading, error: rolesError } = useFetchAllRoles();
  const { videolimits, loading: limitsLoading, error: limitsError } = useFetchAllVideoLimits(refresh);

  // Fetch deactivated users
  const { deactivatedUsers, loading: deactivatedLoading, error: deactivatedError } = useFetchDeactivatedUsers(refresh);

  const roleOptions = roles.map((role) => ({
    value: role.role_id,
    label: role.role_name,
  }));

  const handleSelectChange = (selectedOption, item) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [item.userId]: selectedOption,
    }));
  };

  const handleSave = async (item) => {
    const selectedRole = selectedRoles[item.userId];
    if (selectedRole) {
      try {
        await updateUserRole(item.userId, selectedRole.value);
        addToast({
          message: 'User Role Updated Successfully',
          variant: TOAST_VARIANTS.SUCCESS
        });
        setRefresh((prev) => !prev); // Trigger refresh
      } catch (err) {
        addToast({
          message: `Failed to update role`,
          variant: TOAST_VARIANTS.ERROR
        });
        throw err;
      }
    }
  };

  const handleDelete = async (item) => {
    try {
      await deleteUser(item.userId);
      addToast({
        message: 'User Deleted Successfully',
        variant: TOAST_VARIANTS.SUCCESS
      });
      setRefresh((prev) => !prev);
    } catch (err) {
      addToast({
        message: `Failed to delete User: ${err.message}`,
        variant: TOAST_VARIANTS.ERROR
      });
      throw err;
    }
  };

  const handleActivate = async (item) => {
    try {
      await activateUser(item.userId);
      addToast({
        message: 'User Activated Successfully',
        variant: TOAST_VARIANTS.SUCCESS
      });
      setRefresh((prev) => !prev);
    } catch (err) {
      addToast({
        message: `Failed to activate User: ${err.message}`,
        variant: TOAST_VARIANTS.ERROR
      });
      throw err;
    }
  };

  const activeUserColumns = [
    { key: "userEmail", label: "User Email", sortable: true },
    { key: "CurrentRole", label: "Current Role", sortable: true },
    {
      key: "availableRole",
      label: "Available Role",
      sortable: false,
      render: (_, item) => (
        <Select
          options={roleOptions}
          value={selectedRoles[item.userId] || null}
          onChange={(option) => handleSelectChange(option, item)}
          placeholder="Select a role..."
          isLoading={rolesLoading}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={{
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: 'white',
              color: 'black',
              ':hover': {
                backgroundColor: '#f0f0ff',
              },
            }),
          }}
        />
      ),
    },
  ];

  const videlimitColumns = [
    {
      key: "User",
      label: "User Name",
      sortable: true,
      render: (User) => `${User?.firstname || ''} ${User?.lastname || ''}`.trim(),
    },
    {
      key: "upload_count_limit",
      label: "Video Limit",
      sortable: true,
      render: (value, row) => (
        <input 
          type="number"
          defaultValue={value}
          data-user-id={row.user_id}
          className="px-2 py-1 border rounded w-20 focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
          min="0"
        />
      )
    },
  ];

  const deactivatedUserColumns = [
    { key: "userEmail", label: "User Email", sortable: true },
    { key: "CurrentRole", label: "Role", sortable: true },
  ];

  const activeUserActions = [
    {
      label: "Save",
      className:
        "px-3 py-2 rounded-lg text-sm text-blue-500 bg-white border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: handleSave,
    },
    {
      label: "Delete",
      className: "px-3 py-2 rounded-lg text-sm text-red-500 bg-white border border-red-500 hover:bg-red-300 hover:text-red-700 focus:ring-2 focus:ring-red-500",
      handler: handleDelete,
    },
  ];

  const deactivatedUserActions = [
    {
      label: "Activate",
      className:
        "px-3 py-2 rounded-lg text-sm text-green-500 bg-white border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-green-500",
      handler: handleActivate,
    },
  ];

  const videoLimitActions = [
    {
      label: "Save",
      className: "px-3 py-2 rounded-lg text-sm text-green-500 bg-white border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-green-500",
      handler: async (item) => {
        // Get the new value from the input
        const newLimit = document.querySelector(`input[data-user-id="${item.user_id}"]`)?.value;
        // Add your save function here
        await updateLimit(item.user_id, { upload_count_limit: newLimit });
      },
    }
  ];
  if (usersLoading || rolesLoading || deactivatedLoading || limitsLoading) return <div>Loading...</div>;
  if (usersError || rolesError || deactivatedError || limitsError) return <div>Error: {usersError || rolesError || deactivatedError || limitsError}</div>;

  return (
    <div className="flex flex-col w-full bg-white mb-2">
      <Table
        data={users}
        columns={activeUserColumns}
        actions={activeUserActions}
        pageSize={5}
        title="Permission Control Management"
      />
      <br /> <br />
      <Table
        data={deactivatedUsers}
        columns={deactivatedUserColumns}
        actions={deactivatedUserActions}
        pageSize={5}
        title="Activate Users"
      />
      <br /> <br />
      <Table
        data={videolimits}
        columns={videlimitColumns}
        actions={videoLimitActions}
        pageSize={5}
        title="Video Limits"
      />
    </div>
  );
};

export default AdminAccessControl;