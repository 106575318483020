const notification_type = {
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    USER_VERIFICATION: "USER_VERIFICATION",
    PASSWORD_RESET: "PASSWORD_RESET",
    CONTACT_US: "CONTACT_US"
};

const auth_config = {
    ACCESS_TOKEN_EXPIRY: 15*60*1000,
    TOKEN_RENEW_THRESHOLD: 1*60*1000
};
const upload_config = {
    CONCURRENT_UPLOADS_LIMIT: 3,
    CHUNK_SIZE: 100 * 1024 * 1024,
    DATABASE_PROFILE_ID:"PROFILE_IMG_DB_UUID",
    FILE_CATEGORY:"PROFILE_PICTURE",
    DOMAIN_IMAGE:"DOMAIN_IMAGE",
}

const download_config = {
    DATABASE_PROFILE_ID:"PROFILE_IMG_DB_UUID",
    CHUNK_SIZE: 25 * 1024 * 1024,
    MAX_RETRIES: 3,
    RETRY_DELAY: 2000,
}

module.exports = {notification_type, auth_config, upload_config, download_config};
