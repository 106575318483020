import { useState, useEffect } from "react";
import { fetchAllRoles } from "../../services/AdminControlService";

const useFetchAllRoles = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesData = await fetchAllRoles();
        setRoles(rolesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getRoles();
  }, []);

  return { roles, loading, error };
};

export default useFetchAllRoles;