import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { useDownload } from "./UseDownload";

const DownloadComponent = forwardRef(
  ({ downloadId, fileName, fileSize, fileType, onComplete }, ref) => {
    const {
      isDownloading,
      isCompleted,
      progress,
      totalSize,
      error,
      isOnline,
      isCancelled,
      pauseToggle,
      status,
      handleDownloadClick,
      handlePauseResumeClick,
      handleCancelClick,
    } = useDownload({ fileName, onComplete });

    const extractOriginalFilename = (filename) => {
      const match = filename.match(/\d{17}_(.+)$/);
      return match ? match[1] : filename;
    };

    useImperativeHandle(ref, () => ({
      startDownload: async () => {
        try {
          await handleDownloadClick(downloadId);
        } catch (error) {
          console.error("Download failed", error);
        }
      },
      pauseDownload: handlePauseResumeClick,
      cancelDownload: handleCancelClick,
    }));

    const getStatusClasses = (status) => {
      switch (status) {
        case "Completed":
          return "px-3 py-2 w-[120px] rounded-lg text-sm text-center font-medium text-green-800 bg-green-300 border-green-500";
        case "Downloading...":
          return "px-3 py-2 w-[120px] rounded-lg text-sm text-center font-medium  text-blue-800 bg-blue-300 border-blue-500";
        default:
          return "px-3 py-2 w-[120px] rounded-lg text-sm text-center font-medium  text-gray-800 bg-gray-300 border-gray-500";
      }
    };

    useEffect(() => {
      if (status === "Completed" && isCompleted) {
        onComplete(downloadId, "Completed");
      }
    }, [status, isCompleted, onComplete, downloadId]);

    const formatBytes = (bytes) => {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    };

    return (
      <div className="flex items-center p-3 bg-white rounded-lg border border-gray-200 gap-3 transition-all duration-200 hover:border-gray-300 hover:shadow-sm">
        <div className="flex items-center flex-1 gap-3 min-w-0">
          <div className="flex flex-col flex-1 min-w-0">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-800 truncate">
                {extractOriginalFilename(fileName)}
              </span>
              <span className="text-xs text-gray-500 whitespace-nowrap">
                {isDownloading
                  ? `${formatBytes(
                      (totalSize * progress) / 100
                    )} / ${formatBytes(totalSize)}`
                  : totalSize > 0
                  ? formatBytes(totalSize)
                  : ""}
              </span>
            </div>
            {isDownloading && (
              <div className="h-1 bg-gray-200 rounded overflow-hidden w-24">
                <div
                  className="h-full bg-blue-500 transition-all duration-300"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}
          </div>
          <div className={`status-indicator ${getStatusClasses(status)}`}>
            {status}
          </div>
        </div>

        <div className="flex gap-2">
          {status !== "Completed" ? (
            <>
              {!isDownloading ? (
                <button
                  className="p-2 rounded bg-transparent text-gray-500 hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handleDownloadClick}
                  disabled={!isOnline}
                  title="Start Download"
                >
                  ↓
                </button>
              ) : (
                <>
                  <button
                    className="p-2 rounded bg-transparent text-gray-500 hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={handlePauseResumeClick}
                    title={pauseToggle ? "Resume Download" : "Pause Download"}
                  >
                    {pauseToggle ? "▶" : "⏸"}
                  </button>

                  <button
                    className="p-2 rounded bg-transparent text-gray-500 hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={handleCancelClick}
                    title="Cancel Download"
                  >
                    ✖
                  </button>
                </>
              )}
            </>
          ) : (
            <div className="p-3 rounded bg-transparent text-gray-500"></div>
          )}
        </div>
      </div>
    );
  }
);

DownloadComponent.displayName = "DownloadComponent";

export default DownloadComponent;
