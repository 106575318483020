// services/ContactUsService.js

import creds from "../../src/creds";
import axios from "axios";
import { notification_type } from "../config/config";


const backendAuth = creds.backendAuth;
const appName = "DFS";
export const sendContactUsMessageService = async (name, email, message) => {
  try {
    return axios.post(`${backendAuth}otp/send`, { userName: email,notificationType:notification_type.CONTACT_US, appName:appName, name: name, message: message });
  } catch (err) {
    throw err;
  }
};
