import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Table from '../styled/Table';
import { FaDownload, FaTrash } from 'react-icons/fa';
import { humanReadableFileSize } from '../../packages/utils/humanReadableFilesize';
import { DUUrl } from '../../creds';
import DownloadSinglefile from '../download/DownloadSinglefile';
import { deleteFile } from '../../services/DatasetService';
import Modal from 'react-modal';
import MDEditor from '@uiw/react-md-editor';

const formatDate = (date) => {
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-US', options).replace(/,/g, '');
  const [month, day, year] = formattedDate.split(' ');
  return `${day}-${month}-${year}`;
};

const FileDetailsTable = ({ datasetId: propsDatasetId, datasetVersionId: propsDatasetVersionId, onValidationChange, deleteAccess=false, isOwner, agreementText }) => {
  const location = useLocation();
  const [fileDetails, setFileDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [totalDatasetSize, setTotalDatasetSize] = useState(0);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const datasetId = propsDatasetId || location.state?.datasetId;
  const datasetVersionId = propsDatasetVersionId || location.state?.datasetVersionId;

  const loadFileDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${DUUrl}download/files/${datasetId}/${datasetVersionId}`
      );
      if (!response.ok) {
        if  (response.status === 404) {
          setError('The version has no existing files');
          onValidationChange?.({
            hasReadme: false,
            hasDatasetFile: false,
            totalDatasetSize: 0
          });
          return;
        }
        throw new Error('Failed to fetch file details');
      }
      const data = await response.json();
      const mappedData = data.files.map((file) => ({
        upfilename: file.file_name,
        filetype: file.file_type,
        filesize: file.file_size,
        createdAt: file.createdAt,
        file_id: file.file_id,
        filecategory: file.file_category
      }));

      const datasetFilesSize = mappedData
        .filter(file => file.filecategory === 'DATASET-FILE')
        .reduce((total, file) => total + file.filesize, 0);
      
      setTotalDatasetSize(datasetFilesSize);
      setFileDetails(mappedData);
      
      const hasReadme = mappedData.some(file => file.filecategory === 'README');
      const hasDatasetFile = mappedData.some(file => file.filecategory === 'DATASET-FILE');
      onValidationChange?.({
        hasReadme,
        hasDatasetFile,
        totalDatasetSize: datasetFilesSize
      });
    } catch (err) {
      setError('Failed to load file details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFileDetails();
  }, [datasetId, datasetVersionId]);

  const columns = [
    {
      key: "createdAt",
      label: "Created Date",
      sortable: true,
      render: (value) => formatDate(value)
    },
    {
      key: "upfilename",
      label: "File Name",
      sortable: true,
      render: (value) => value.split('/').pop()
    },
    {
      key: "filetype",
      label: "File Type",
      sortable: true
    },
    {
      key: "filecategory",
      label: "File Type",
      sortable: true
    },
    {
      key: "filesize",
      label: "File Size",
      sortable: true,
      render: (value) => humanReadableFileSize(value)
    },
  ];

  const handleDownload = (file) => {
    setSelectedFileName(file.upfilename);
    // setIsDownloadModalOpen(true);
    setIsModalOpen(true);
  };

  const handleDelete = async (file) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(`Are you sure you want to delete the file: ${file.upfilename?.split('/').pop()}?`);
    
    if (isConfirmed) {
      try {
        await deleteFile(file.upfilename);
        await loadFileDetails();
      } catch (error) {
        console.error('Error deleting file:', error);
        alert('Failed to delete file. Please try again.');
      }
    }
  };
      
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTermsChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };

  const handleTermsAccept = () => {
    if (isTermsAccepted) {
      setIsModalOpen(false);
      setIsDownloadModalOpen(true);
    }
  }

  const tableActions = [
    {
      icon: <FaDownload />,
      tooltip: "Download File",
      handler: handleDownload,
      className: "p-2 border rounded hover:bg-green-500 hover:text-white transition-colors duration-200"
    },
    deleteAccess && {
      icon: <FaTrash />,
      tooltip: "Delete File",
      handler: handleDelete,
      className: "p-2 border rounded hover:bg-red-500 hover:text-white transition-colors duration-200"
    }
  ];

  if (loading) return <div className="text-center py-4">Loading file details...</div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <>
      <Table
        data={fileDetails}
        columns={columns}
        actions={tableActions}
        pageSize={5}
        title="File Details"
      />
      <div>
        <DownloadSinglefile
          databaseId={datasetId}
          versionId={datasetVersionId}
          isModalOpen={isDownloadModalOpen}
          selectedFile={selectedFileName}
          onClose={() => setIsDownloadModalOpen(false)}
        />
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title="Terms and Conditions"
          style={{
            overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 },
            content: {
              maxHeight: "70vh",
              maxWidth: "60vw",
              margin: "auto",
              padding: "20px",
              zIndex: 1001,
              display: "flex",
              flexDirection: "column",
            },
          }}
          shouldCloseOnOverlayClick={false}
        >
          <div className="p-4">
            <p className="mb-4">
              Please read and accept our terms and conditions before downloading.
            </p>
            <div className="mb-4">
              <p className="font-medium">Agreement</p>
              <div
                className="agreement-box mt-2 p-4 bg-gray-100 border rounded overflow-y-auto"
                style={{ maxHeight: "200px" }}
              >
                <MDEditor.Markdown
                  source={agreementText}
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "16px",
                    lineHeight: "1.5",
                    color: "black",
                  }}
                />
              </div>
              </div>
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="terms"
                  checked={isTermsAccepted}
                  onChange={handleTermsChange}
                />
                <label htmlFor="terms" className="ml-2">
                  I accept the terms and conditions.
                </label>
              </div>

              <div className="flex space-x-4">
                <button
                  className={`bg-blue-500 text-white px-4 py-2 rounded ${!isTermsAccepted && "opacity-50 cursor-not-allowed"
                    }`}
                  onClick={handleTermsAccept}
                  disabled={!isTermsAccepted}
                >
                  Proceed
                </button>
                <button
                  className="bg-gray-300 text-black px-4 py-2 rounded"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FileDetailsTable;
