import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DatasetVersionDetails from '../../components/datasets/DatasetVersionDetails';
import FileDetails from '../../components/datasets/FileDetails';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
const VersionDetails = () => {
    const { datasetId, versionId } = useParams();
    const { isDatasetOwner, agreementText } = useSelector((state) => state.dataset);
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Goes back to the previous page in history
    };
    return (
        <div className="w-full h-full px-16">
            <div className="mx-6 my-4">
                <button onClick={handleBack} className="rounded-lg text-sm px-3 py-2 text-center cursor-pointer text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 py-2 px-3 bg-blue-800 text-white hover:underline font-semibold flex items-center justify-center">
                    <MdOutlineKeyboardArrowLeft className="text-xl"/>
                    Back
                </button> 
                </div>
            <div className="bg-white shadow-md mx-4 my-4 p-6 rounded-lg space-y-8">
                <div>
                    <DatasetVersionDetails datasetVersionId={versionId} editable={true} />
                </div>
                <div className="mt-6">
                    <FileDetails datasetId={datasetId} datasetVersionId={versionId} isOwner={isDatasetOwner} agreementText={agreementText} />
                </div>
            </div>
        </div>
    );
};

export default VersionDetails;
