import { EMAIL_REGEX, PASSWORD_REGEX, NAME_REGEX, INSTITUTION_DESIGNATION_REGEX } from "../packages/utils/regexPatterns";

export const validate = (
    emailTouched,
    emailInputField,
    passwordTouched,
    passwordInputField,
    firstNameInputField,
    firstNameTouched,
    lastNameTouched,
    lastNameInputField,
    institutionTouched,
    institutionInputField,
    designationTouched,
    designationInputField
  ) => {
    const errors = {
      email:
        emailTouched && emailInputField === ""
          ? "Email is required field ."
          : emailTouched && !EMAIL_REGEX.test(emailInputField)
            ? "Invalid email id."
            : "",
  
      password:
        passwordTouched &&
        (!passwordInputField ? (
          "Password is required field."
        ) : !PASSWORD_REGEX.test(passwordInputField) ||
          passwordInputField.length < 8 ||
          passwordInputField.length > 20 ? (
          passwordInputField.length < 8 ? (
            <>
              Must meet the following requirements: <br />
              - Minimum length: 8 characters <br />
              - Include at least 1 special character (e.g., @, #, $) <br />
              - Include at least 1 uppercase letter (A-Z) <br />
              - Include at least 1 lowercase letter (a-z) <br />
              - Include at least 1 number (0-9) <br />
            </>
          ) : passwordInputField.length > 20 ? (
            "Password cannot exceed 20 characters."
          ) : (
            <>
              Must meet the following requirements: <br />
              - Minimum length: 8 characters <br />
              - Include at least 1 special character (e.g., @, #, $) <br />
              - Include at least 1 uppercase letter (A-Z) <br />
              - Include at least 1 lowercase letter (a-z) <br />
              - Include at least 1 number (0-9) <br />
            </>
          )
        ) : (
          ""
        )),
  
      firstname:
        firstNameTouched && firstNameInputField.length > 50
          ? "First name cannot exceed 50 characters."
          : firstNameTouched && firstNameInputField.length < 1
            ? "First name is required field."
            : firstNameTouched && !NAME_REGEX.test(firstNameInputField)
              ? "First name can only contain alphabets."
              : "",
  
      lastname:
        lastNameTouched && lastNameInputField.length > 50
          ? "Last name cannot exceed 50 characters."
          : lastNameTouched && lastNameInputField.length < 1
            ? "Last name is required field."
            : lastNameTouched && !NAME_REGEX.test(lastNameInputField)
              ? "Last name can only contain alphabets."
              : "",
  
      institution: institutionTouched
        ? institutionInputField.length < 1
          ? "Institution is a required field."
          : institutionInputField.length > 100
            ? "Institution cannot exceed 100 characters."
            : !INSTITUTION_DESIGNATION_REGEX.test(institutionInputField)
              ? "Enter institution name correctly."
              : ""
        : "",
  
      designation: designationTouched
        ? designationInputField.length < 1
          ? "Designation is a required field."
          : designationInputField.length > 50
            ? "Designation cannot exceed 50 characters."
            : !INSTITUTION_DESIGNATION_REGEX.test(designationInputField)
              ? "Enter designation correctly."
              : ""
        : "",
    };
  
    return errors;
  };
    
  export const checkForNullErrors = (errors, touchedFields) => {
    const fieldLabelMap = {
      firstname: "First Name",
      lastname: "Last Name",
      email: "Email",
      password: "Password",
      institution: "Institution",
      designation: "Designation",
    };
  
    let error_message = "",
      cnt = 0;
    for (let key in errors) {
      if (errors[key] || !touchedFields[key]) {
        cnt += 1;
        cnt < 2
          ? (error_message += " " + fieldLabelMap[key])
          : (error_message += ", " + fieldLabelMap[key]);
      }
    }
  
    if (cnt === 6) {
      error_message = "all the required fields";
    }
  
    return error_message;
  };