import React, {useContext} from "react";
import { deleteDatasetFromCatalogue } from "../../services/DatasetCatalogueService"; // Import the delete service
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from "../../packages/toasts/constants";

const DeleteConfirmModal = ({ show, onCancel, selectedCatalogue, onDeleteSuccess }) => {
  const { addToast } = useContext(ToastContext);
  if (!show) return null;

  const handleDelete = async () => {
    try {
      const result = await deleteDatasetFromCatalogue(selectedCatalogue.datasetCatalogueId);

      if (result.success) {
        addToast({
          message: "Dataset deleted successfully.",
          variant: TOAST_VARIANTS.SUCCESS,
        });
        onDeleteSuccess(); // Call the success callback to notify parent
      } else {
        addToast({
          message: result.message || "Failed to delete dataset.",
          variant: TOAST_VARIANTS.ERROR,
        });
      }
    } catch (error) {
      addToast({
        message: "An error occurred while deleting the dataset.",
        variant: TOAST_VARIANTS.ERROR,
      });
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${
        show ? "flex bg-black bg-opacity-50" : "hidden"
      }`}
      role="dialog"
    >
      <div className="h-fit relative p-4 w-full max-w-md mx-auto my-20 bg-white rounded-lg shadow dark:bg-gray-700">
        {/* Modal header */}
        <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Confirm Deletion
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={onCancel}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        {/* Modal body */}
        <div className="p-4 space-y-4">
          <p>Deleting this dataset will remove it from the Dataset Catalogue. Are you sure you want to proceed?</p>

          {/* Modal footer */}
          <div className="flex justify-end p-4 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button
              className="text-btn justify-center rounded-md border border-transparent shadow-sm px-4 py-2 mb-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleDelete} // Trigger the delete when clicked
            >
              Delete
            </button>
            <button
              className="text-btn justify-center rounded-md border border-transparent shadow-sm px-4 py-2 mb-2 bg-gray-400 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onCancel} // Trigger the cancel function
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmModal;
