export const FlexSection = "flex flex-col w-full px-8 bg-white mb-2 p-8"; 
export const Heading ="mt-2 mb-3 lg:text-2xl md:text-xl sm:text-lg text-lg font-extrabold tracking-tight flex-1" 
export const SubHeading = "font-bold lg:text-3xl md:text-2xl sm:text-xl text-xl tracking-tighter mx-2 text-regal-blue";
export const ParaFont = "lg:text-lg md:text-lg sm:text-base text-base font-sans pt-2 font-var text-justify mx-2";
export const GradientHeading = "text-regal-blue text-[3rem] font-extrabold tracking-tight flex-1 text-white py-2 mt-2";
export const GradientFont = "font-var text-[1rem] pt-0 md:text-sm xs:text-sm text-blue-200 text-justify";
export const ParaHeading = "text-3xl font-bold tracking-tighter mt-5 text-regal-blue mx-2 pr-4";
export const HomeParaHeading = "";
export const HomeParaFont = "font-var text-[1rem] pt-8 md:text-sm xs:text-sm text-blue-200 text-justify";
export const DatasetFont = "mb-6 text-1xl text-sky-900 text-justify mx-2";
export const AboutSubsection = "flex flex-1 justify-center items-start flex-col lg:mx-0 lg:mt-0 lg:mb-12";
export const ContactInputField = "border-black outline-none rounded-xl w-80 h-8 mb-4 px-1.5 border-1";
export const FuInputField = "min-w-full border border-solid border-slate-150 pl-1 pr-1 pb-2.5 pt-2.5";
export const OverviewFont = "lg:text-lg md:text-lg sm:text-base text-base font-sans font-var text-justify pt-2";
export const DataFont = "font-var  text-[1rem] md:text-sm xs:text-xs text-justify";
export const MisVis = "flex flex-1 justify-center items-start flex-col mr-20 lg:mb-8 lg:mr-0 lg:mt-0 lg:ml-12";
export const NavbarClass = "";
export const NavItems = "text-white font-medium text-base capitalize mx-3.5 my-1 cursor-pointer";
export const SingUpLink = "pl-3 pr-3 pt-2 pb-2 text-blue-500 bg-red-600 font-medium text-base border-none outline-none cursor-pointer rounded";
// Home page css
export const HomeCardHeading = "text-center font-sans font-semibold text-sm p-2";
export const HomePageSubHeading = "lg:text-4xl md:text-3xl sm:text-2xl text-2xl text-blue-600 font-sans text-center font-bold mb-3 pt-8";
// Internal page 
export const InternalHeading = "text-lg sm:text-lg md:text-lg lg:text-xl font-bold text-gray-800 mt-2 mb-3";
export const InternalTableHeading = "text-lg sm:text-lg md:text-lg lg:text-xl font-bold text-gray-800";