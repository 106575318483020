import React, { useEffect, useState, useContext } from "react";
import Table from "../styled/Table";
import defaultImage from "../../assets/images/profileImages/0-defaultProfile.webp";
import ImageUploader from "../upload/ImageUploader";
import useImageDownloader from "../download/ImageDownloaderComponent"; // Custom Hook
import { upload_config } from "../../config/config";
import { ToastContext } from "../../App";
import { TOAST_VARIANTS } from '../../packages/utils/constants';

const DomainTable = ({ domains, onEdit, onDelete }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [imageVersion, setImageVersion] = useState(0);
  const [selectedDomainId, setSelectedDomainId] = useState();
  const { addToast } = useContext(ToastContext);
  
  const { fetchImage, imageUrls } = useImageDownloader();

  useEffect(() => {
    domains.forEach((domain) => {
      fetchImage(domain.domain_id, "1192ur934tegdflbmcvbnvkckblfnb", "domain");
    });
  }, [domains, imageVersion, fetchImage, selectedFile]); // Include fetchImage in dependencies since it's a function from useCallback

  const handleFileChange = (event, domainId) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsUploadingImage(true);
      setSelectedDomainId(domainId);
    }
  };

  const columns = [
    {
      key: "updatedAt",
      label: "Created Date",
      sortable: true,
      render: (value) => new Date(value).toLocaleDateString("en-GB"),
    },
    {
      key: "domain_name",
      label: "Domain Name",
      sortable: true,
    },
    {
      key: "domain_description",
      label: "Domain Description",
      sortable: false,
    },
    {
      key: "DomainImage",
      label: "Image",
      sortable: false,
      width: 100,
      render: (_, row) => (
        <div className="flex flex-col items-center w-[100px]">
          <label htmlFor={`file-upload-${row.domain_id}`} className="cursor-pointer">
            <img
              src={imageUrls[row.domain_id] || defaultImage}
              alt="Domain"
              className="w-20 h-20 md:w-18 md:h-18 sm:w-18 sm:h-18 p-2 sm:p-2 md:p-3 rounded-full"
            />
          </label>
          <input
            id={`file-upload-${row.domain_id}`}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event) => handleFileChange(event, row.domain_id)}
            disabled={isUploadingImage}
          />
        </div>
      ),
    },
  ];

  const actions = [
    {
      label: "Edit",
      handler: onEdit,
      className: "px-6 py-2 rounded-lg text-sm text-blue-500 bg-white border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
    },
    {
      label: "Delete",
      handler: onDelete,
      className: "mx-3 px-6 py-2 rounded-lg text-sm text-red-500 bg-white border border-red-500 hover:bg-red-300 hover:text-red-700 focus:ring-2 focus:ring-red-500",
    },
  ];

  return (
    <div className="flex flex-col w-full px-8 bg-gray-200 mb-2 rounded-lg">
      <Table
        key={imageVersion}
        data={domains}
        columns={columns}
        actions={actions}
        pageSize={5}
        expandableField={"domain_description"}
        title="Available Domains"
      />
      {selectedFile && (
        <ImageUploader
          file={selectedFile}
          versionId={selectedDomainId}
          databaseId={"1192ur934tegdflbmcvbnvkckblfnb"}
          fileCategory={upload_config.DOMAIN_IMAGE}
          customPath={`domainImages/${selectedDomainId}`}
          onSuccess={() => {
            addToast({ 
              message: 'Domain Image Uploaded Successfully', 
              variant: TOAST_VARIANTS.SUCCESS 
            });
            setSelectedFile(null);
            setIsUploadingImage(false);
            setImageVersion((prev) => prev + 1);
          }}
          onError={() => {
            setSelectedFile(null);
            setIsUploadingImage(false);
          }}
        />
      )}
    </div>
  );
};

export default DomainTable;