import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import DisplayDetails from '../styled/DisplayDetails';
import { fetchDatasetDetails, editDatasetDetails, getDomains } from '../../services/DatasetService';
import { getUserRole } from "./../../services/AuthService";
import { selectIsLoggedIn } from "../../redux/AuthController";
import QueryUsers from './QueryUsers';
import { FaEdit, FaSave } from 'react-icons/fa';
import { handleFetchDuas } from '../../controllers/datasetController';
import AlertBox from '../styled/AlertBox';

const CustomEditField = ({ value, onChange, editing, onSave, onEdit, editable }) => {
  if (!editing) {
    return (
      <div className="flex items-center w-full">
        <input
          value={value || ''}
          readOnly
          className="w-full text-sm font-sans border border-gray-100 rounded py-2 px-3 shadow-sm text-gray-700 leading-tight focus:outline-none focus:bg-white"
        />
        {editable && (
          <button onClick={onEdit} className="ml-4 text-gray-500" title="Edit">
            <FaEdit />
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center w-full">
      <QueryUsers
        initialValue={{ name: value }}
        onSelect={(newValue) => {
          onChange(newValue.id);
        }}
      />
      <button onClick={onSave} className="ml-4 text-gray-500" title="Save">
        <FaSave />
      </button>
    </div>
  );
};

const DatasetDetails = ({ datasetId, editable, open = false }) => {
  const [dataset, setDataset] = useState({});
  const [error, setError] = useState(null);
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingField, setEditingField] = useState(null);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [duas, setDuas] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const userId = useSelector((state) => state.auth.userId);

  const cleanValue = (value) => {
    if (value === null || value === undefined) return null;
    if (typeof value === 'string' && value.trim() === '') return null;
    return value;
  };

  const formatDateToDDMMMYYYY = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return null;
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
  };

  
const getDatasetStatus = (dataset) => ({
  ...dataset,
  createdAt: formatDateToDDMMMYYYY(dataset.createdAt),
  accessType: determineAccessType(dataset.public, dataset.is_paid),
  dataset_verified: dataset.dataset_verified === 1 ? 'Verified' : 'Unverified',
});

  const fetchDataset = async () => {
    try {
      const data = await fetchDatasetDetails({ datasetId, verified: false, open });
      if (data.error) {
        setError('Error fetching dataset details');
      } else {
        const cleanedDataset = Object.fromEntries(
          Object.entries(data.datasets[0]).map(([key, value]) => [
            key,
            cleanValue(value)
          ])
        );
        await setDataset(cleanedDataset);
      }
    } catch (err) {
      setError('Error fetching dataset details');
    }
  };
  
  const fetchDuas = async () => {
    if (!dataset.domain_id) return;

    try {
      const duaList = await handleFetchDuas(dataset.domain_id);
      if (!duaList?.duas) return;

      const duaData = [
        { value: 'Select DUA', label: 'Select DUA' },
        ...duaList.duas.map(dua => ({
          value: dua.dua_id,
          label: dua.dua_title
        }))
      ];

      setDuas(duaData);
      // Find the matching DUA for the current dataset
      const currentDua = await duaData.find(dua => (dua.value === dataset.dua_id));
      
      await setDataset(prevDataset => ({
        ...prevDataset,
        dua_name: currentDua?.label || 'Select a DUA from the current domain',
        dua_id: currentDua?.value || '',
      }));

    } catch (error) {
      console.error('Error fetching DUAs:', error);
    }
  };
  
  useEffect(() => {
    isLoggedIn && fetchDuas();
  }, [dataset.domain_id, dataset.dua_id, isLoggedIn]);


  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const domainData = await getDomains();
        if (domainData && domainData.domains) {
          const domainNames = domainData.domains.map(domain => ({
            value: domain.domain_id,
            label: domain.domain_name,
          }));
          setDomains(domainNames);
        }
        setLoading(false);
      } catch (err) {
        setError('Error fetching domain list');
        setLoading(false);
      }
    };

    fetchDataset();
    fetchDomains();
  }, [datasetId, open]);

  const isEditable = (field) => {
    if (!isLoggedIn) return false;
    const roleId = getUserRole().roleName;
    const isOwner = dataset.owner_id === userId;
    const isCoordinator = dataset.coordinator_id === userId;
    const isUploader = dataset.uploader_id === userId;
    const verified = dataset.dataset_verified;
    const uploaderEditableFields = verified ? ['dataset_usage', 'content_type', 'dataset_overview', 'dataset_description'] 
    : ['dataset_description', 'domain_name', 'dataset_name', 'content_type', 'dataset_usage', 'dataset_overview', 'coordinator_name', 'accessType','dua_name'];
    const coordinatorEditableFields = verified ? ['dataset_name', 'dataset_usage', 'content_type', 'dataset_overview', 'dataset_description', 'domain_name'] 
    : ['dataset_description', 'domain_name', 'dataset_name', 'content_type', 'dataset_usage', 'dataset_overview', 'accessType','dua_name'];
    const ownerEditableFields = verified ? ['dataset_name', 'dataset_usage', 'content_type', 'dataset_overview', 'dataset_description', 'domain_name', 'coordinator_name', 'owner_name'] 
    : ['dataset_description', 'domain_name', 'dataset_name', 'content_type', 'dataset_usage', 'dataset_overview', 'accessType','coordinator_name', 'owner_name', 'dua_name'];
    const adminEditableFields = verified ? ['dataset_description', 'domain_name', 'dataset_name', 'content_type', 'dataset_usage', 'dataset_overview', 'accessType','coordinator_name', 'owner_name', 'accessType', 'uploader_name', 'dua_name']
    : []

    const editableFields = Array.from(new Set([
      ...(isUploader ? uploaderEditableFields : []),
      ...(isCoordinator ? coordinatorEditableFields : []),
      ...(isOwner ? ownerEditableFields : []),
      ...(roleId === 'Admin' ? adminEditableFields : [])
    ]));

    return editableFields.includes(field)
  };

  const handleEdit = (key, value) => {
    setEditingField(key);
    if (key === 'dataset_verified') {
      setDataset(prevData => ({
        ...prevData,
        dataset_verified: value,
        dataset_verified_raw: value === 'Verified' ? 1 : 0
      }));
    } else if (key === 'accessType') {
      let publicValue, isPaidValue;
      switch (value) {
        case 'Public':
          publicValue = 1;
          isPaidValue = 0;
          break;
        case 'Private':
          publicValue = 0;
          isPaidValue = 0;
          break;
        case 'Paid':
          publicValue = 1;
          isPaidValue = 1;
          break;
        default:
          console.error("Unexpected accessType value:", value);
          publicValue = 1;
          isPaidValue = 0;
          break;
      } 
      setDataset(prevData => ({
        ...prevData,
        accessType: value,
        public: publicValue,
        is_paid: isPaidValue
      }));
    } else {
      // if (key === 'domain_name') {
      //   setShowAlert(true);
      //   setAlertMessage('Respective Dua also changes');
      // }
      setDataset(prevData => ({ ...prevData, [key]: value }));
    }
  };
  

  const handleSave = async (key) => {
    try {
      let updatedField = {};
      
      if (key === 'dataset_verified') {
        const verifiedValue = dataset.dataset_verified === 'Verified' ? 1 : 0;
        updatedField = { dataset_verified: verifiedValue };
      } else if (key === 'accessType') {
        let publicValue, isPaidValue;
        switch (dataset.accessType) {
          case 'Public':
            publicValue = 1;
            isPaidValue = 0;
            break;
          case 'Private':
            publicValue = 0;
            isPaidValue = 0;
            break;
          case 'Paid':
            publicValue = 1;
            isPaidValue = 1;
            break;
          default:
            throw new Error('Invalid access type');
        }
        updatedField = { public: publicValue, is_paid: isPaidValue };
      } else {
        const fieldKey = key === "domain_name" ? "domain_id" :
                        key === "coordinator_name" ? "coordinator_id" :
                        key === "owner_name" ? "owner_id" :
                        key === "uploader_name" ? "uploader_id" :   
                        key === "dua_name" ? "dua_id" : key;
        updatedField = { [fieldKey]: dataset[key] };

        if (key === 'domain_name') {
          setShowAlert(true);
          setAlertMessage('Please update the relevant Dua associated with the Domain in the DUA Field');
        }
      }

      await editDatasetDetails(dataset.dataset_id, updatedField);
      await fetchDataset(); // Refresh the dataset after successful update
      if(key === "domain_name")
        await fetchDuas();
      setEditingField(null);
      setError(null);
    } catch (err) {
      console.error("Error editing dataset: ", err);
      // setError("Error updating dataset details");
    }
  };

  const determineAccessType = (publicValue, isPaidValue) => {
    if (publicValue === 0 && isPaidValue === 0) return 'Private';
    if ((publicValue === 1 && isPaidValue === 1) || (publicValue === 0 && isPaidValue === 1)) return 'Paid';
    if (publicValue === 1 && isPaidValue === 0) return 'Public';
    return 'Public'; // Default fallback
  };

  const inputTypes = {
    domain_name: {
      type: 'select',
      options: domains.map(domain => ({
        value: domain.value,
        label: domain.label,
      })),
    },
    dua_name: {
      type: 'select',
      options: duas.map(dua => ({
        value: dua.value,
        label: dua.label,
      })),
    },
    datasetVisibility: {
      type: 'select',
      options: [
        { value: 'Listed', label: 'Listed' },
        { value: 'Unlisted', label: 'Unlisted' },
      ],
    },
    accessType: {
      type: 'select',
      options: [
        { value: 'Public', label: 'Public' },
        { value: 'Private', label: 'Private' },
        { value: 'Paid', label: 'Paid' },
      ],
    },
    dataset_usage: { type: 'textarea' },
    dataset_overview: { type: 'textarea' },
    dataset_description: { type: 'textarea' },
  };

  const fieldsToDisplay = [
    { key: 'createdAt', tag: 'Creation Date' },
    { key: 'domain_name', tag: 'Domain Name' },
    { key: 'dataset_name', tag: 'Dataset Name' },
    { key: 'accessType', tag: 'Access Type' },
    { key: 'dataset_verified', tag: 'Status' },
    { key: 'content_type', tag: 'Content Type' },
    { key: 'uploader_name', tag: 'Uploader Name' },
    { key: 'coordinator_name', tag: 'Coordinator Name' },
    { key: 'owner_name', tag: 'Owner Name' },
    ...(isLoggedIn ? [{ key: 'dua_name', tag: 'DUA' }] : []),
    { key: 'dataset_usage', tag: 'Dataset Usage' },
    { key: 'dataset_overview', tag: 'Overview' },
    { key: 'dataset_description', tag: 'Description' },
  ];

  if (error) return <div className="text-red-500 p-4">{error}</div>;
  if (loading) return <div className="p-4">Loading...</div>;

  const formattedDataset = getDatasetStatus(dataset);

  return (
    <>
      {showAlert && <AlertBox message={alertMessage} onClose={() => setShowAlert(false)} />}
      <DisplayDetails
        data={formattedDataset}
        isEditable={isEditable}
        onEdit={handleEdit}
        onSave={handleSave}
        inputTypes={inputTypes}
        fieldsToDisplay={fieldsToDisplay}
        title="Dataset Details"
        customComponents={{
          coordinator_name: CustomEditField,
          uploader_name: CustomEditField,
          owner_name: CustomEditField
        }}
        editingField={editingField}
        setEditingField={setEditingField}
      />
    </>
  );
};

export default DatasetDetails;