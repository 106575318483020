import { createSlice } from "@reduxjs/toolkit";

const initialState = {  
    downloadStatus: "Ready",
    databaseId: null,
    versionId: null,
};

const downloadSlice = createSlice({
    name: "download",
    initialState,
    reducers: {
        setDownloadStatus:(state, action)=> {
            state.downloadStatus = action.payload;
        },
        setDownloadIds: (state, action) => {
            state.databaseId = action.payload.databaseId;
            state.versionId = action.payload.versionId;
        },
    },
});

export const { setDownloadStatus, setDownloadIds  } = downloadSlice.actions;
export default downloadSlice.reducer;