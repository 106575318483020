import notify  from "../packages/toastify/Notify";
import { deleteDomainService } from "../services/DomainService";


export const deleteDomain = async (domainId) => {
    try {
        const response = await deleteDomainService(domainId);
        if (response.status === 200) {
            const message = response.data.message;
            notify(`${message}`, "success");
        }
    } catch (err) {
        if (err.response) {
            switch (err.status) {
                case 403:
                    notify(`${err.response.data.message}`, "error");
                    break;
                case 500:
                    notify(`${err.response.data.message}`, "error");
                    break;
                default:
                    notify(`Something went wrong. Please try again.`, "error");
            }
        }
        return err;
    }
}

