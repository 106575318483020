import React, { useState, useRef, useEffect } from 'react';
import { fetchEmailSuggestions } from '../../services/DatasetService';
import { set } from 'date-fns';

const QueryUsers = ({ initialValue, onSelect }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const [error, setError] = useState('');
  const suggestionListRef = useRef(null);
  const [inputValue, setInputValue] = useState(initialValue?.name || '');

  const fetchSuggestions = async (query, partial=true) => {
    try {    
      const filteredEmails = (await fetchEmailSuggestions(query, partial)).data.message;
      console.log("Filtered emails:", filteredEmails);
      return {
        error: false,
        message: filteredEmails
      };
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      return {
        error: true,
        message: "Error fetching suggestions"
      };
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionListRef.current && !suggestionListRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleEmailChange = async (e) => {
    const query = e.target.value;
    setInputValue(query); // Update local state immediately
    setError('');

    if (query.length > 2) {
      try {
        const response = await fetchSuggestions(query);
        if (response.error === false && response.message) {
          const emailList = response.message.map(item => item.username);
          setSuggestions(emailList);
          setShowSuggestions(true);
        } else {
          setSuggestions([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = async (email) => {
    setShowSuggestions(false);
    const response = await fetchSuggestions(email, false);
    if (response.error === false && response.message && response.message[0]) {
      const userData = response.message[0];
      const fullName = `${userData.firstname} ${userData.lastname}`;
      setInputValue(fullName);
      onSelect({ 
        id: userData.user_id,
      });
    }
    setActiveSuggestionIndex(-1);
  };


  return (
    <div>
      <div>
      <input
          value={inputValue}
          onChange={handleEmailChange}
          placeholder="Enter email"
          autoComplete="off" // prevent browser autocomplete interference
          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
            ${error ? 'ring-red-500' : 'ring-gray-300'} 
            placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6`}
        />
        {showSuggestions && suggestions.length > 0 && (
          <ul className="border border-gray-300 rounded-md mt-1 bg-white absolute z-10 w-full max-w-md" 
              ref={suggestionListRef}>
            {suggestions.map((email, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(email)}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 
                  ${index === activeSuggestionIndex ? "bg-gray-100" : ""}`}
              >
                {email}
              </li>
            ))}
          </ul>
        )}
        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      </div>
    </div>
  );
};

export default QueryUsers;