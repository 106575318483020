import { useCallback, useState, useRef, useEffect } from 'react';
import * as tus from 'tus-js-client';
import styled from 'styled-components';
import { DUUrl, authUrl } from '../../creds';
import axiosInstance, { tokenDetails, failedRequestsQueue } from '../../services/AxiosInstance';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { upload_config } from '../../config/config';

const Container = styled.div`
  width: 100%;
  margin: 10px 0;
  font-family: -apple-system, system-ui, sans-serif;
`;

const UploadBox = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fff;
`;

const FileInput = styled.input`
  width: 100%;
  margin-bottom: 8px;
  &:disabled { opacity: 0.6; }
`;

const Progress = styled.div`
  height: 4px;
  background: #f0f0f0;
  border-radius: 2px;
  overflow: hidden;
  margin: 8px 0;
`;

const ProgressBar = styled.div`
  height: 100%;
  background: #2196F3;
  transition: width 0.3s ease;
  width: ${props => props.progress}%;
`;

const Controls = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const Button = styled.button`
  padding: 4px 8px;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  background: ${props => {
    switch (props.buttonType) {
      case 'pause': return '#FFC107';
      case 'resume': return '#4CAF50';
      case 'cancel': return '#f44336';
      default: return '#2196F3';
    }
  }};
  color: ${props => props.buttonType === 'pause' ? '#000' : '#fff'};
  &:hover { opacity: 0.9; }
`;

const Status = styled.div`
  font-size: 12px;
  color: ${props => props.error ? '#f44336' : '#666'};
  margin-top: 4px;
`;

const TusUploader = ({ 
  endpoint = `${DUUrl}upload/v1/tus`,
  onSuccess,
  onError,
  onRemove,
  onProgress,
  onStart,
  databaseId,
  versionId,
  fileCategory,
  acceptedFileTypes,
}) => {
  const [status, setStatus] = useState('idle');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const uploadRef = useRef(null);
  const fileInputRef = useRef(null);
  const [uploadConfig] = useState({
    databaseId: databaseId,
    versionId: versionId,
    appName: 'DFS',
    fileCategory: fileCategory
  });
  const userId = useSelector(state => state.auth.userId);
  const sessionId = useSelector(state => state.auth.sessionId);


  useEffect(() => () => {
    if (uploadRef.current) uploadRef.current.abort();
  }, []);

  const reset = useCallback(() => {
    setProgress(0);
    setStatus('idle');
    setError('');
    setFileName('');
    setSelectedFile(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
    uploadRef.current = null;
  }, []);

  const deleteFile = async (fileUrl) => {
    try {
      uploadRef.current?.abort();
      setStatus('deleting');
      const fileId = fileUrl?.split('/').pop();
      if (fileId) {
        await axiosInstance.delete(`${endpoint}/${fileId}`, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Tus-Resumable': '1.0.0',
            ...uploadConfig
          }
        });
        console.log('File deleted successfully from MinIO');
        reset();
      }
      onRemove?.();
    } catch (error) {
      setError('Failed to delete file');
      console.error('Error deleting file:', error);
      reset();
    }
  };

  const startUpload = useCallback((file) => {
    try {
      setError('');
      setStatus('uploading');
      setFileName(file.name);

      const upload = new tus.Upload(file, {
        endpoint,
        chunkSize: upload_config.CHUNK_SIZE,
        retryDelays: [0, 3000, 5000],
        metadata: {
          filename: file.name,
          filetype: file.type
        },
        headers: uploadConfig,
        onBeforeRequest: (req) => {
          const xhr = req.getUnderlyingObject();
          xhr.withCredentials = true;
        },
        onError: async (error) => {
          if (error.originalResponse?.getStatus() === 498 &&
              error.message.includes('Invalid or expired access token')) {
            try {
              console.log("Renewing token...");
              const renewTokenJSON = {
                userId: userId,
                appName: "dfs",
              };
              await axios.post(
                `${authUrl}renew-token`,
                renewTokenJSON,
                {
                  headers: {'Session-Id': sessionId},
                  withCredentials: true,
                }
              );
              // Retry the upload after token renewal
              upload.start();
              return;
            } catch (renewError) {
              const event = new CustomEvent('sessionExpired', { detail: { userName: tokenDetails.userName } });
              window.dispatchEvent(event);
              return new Promise((resolve, reject) => {   
                  failedRequestsQueue.push({ config: error.config, resolve, reject });
              });             
            }
          }
          setError('Upload failed');
          console.log('Upload failed because: ' + error.message);
          setStatus('error');
          onError?.(error);
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1);
          setProgress(percentage);
          onProgress?.(percentage, bytesUploaded, bytesTotal);
        },
        onSuccess: () => {
          setProgress(100);
          setStatus('completed');
          onSuccess?.(upload.url);
        }
      });

      upload.start();
      uploadRef.current = upload;
    } catch (err) {
      setError(err.message);
      setStatus('error');
      onError?.(err);
    }
  }, [endpoint, onSuccess, onError, onProgress, sessionId, uploadConfig, userId]);

  const handleFile = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
      setStatus('selected');
    }
  };

  const handleUploadClick = () => {
    if (selectedFile) {
      startUpload(selectedFile);
      onStart?.(selectedFile);
    }
  };

  const toggleUpload = () => {
    if (status === 'uploading') {
      uploadRef.current?.abort();
      setStatus('paused');
    } else if (status === 'paused') {
      uploadRef.current?.start();
      setStatus('uploading');
    }
  };

  return (
    <Container>
      <UploadBox>
        <FileInput
          ref={fileInputRef}
          type="file"
          onChange={handleFile}
          disabled={['uploading', 'paused', 'deleting', 'completed'].includes(status)}
          accept={acceptedFileTypes !== "" ? acceptedFileTypes : undefined}
        />
        {acceptedFileTypes?.length > 0 && (
          <p className="mt-1 text-sm text-gray-500">
            Accepted file types: {acceptedFileTypes}
          </p>
        )}
        {status !== 'idle' && (
          <>
            {status === 'selected' && (
              <button onClick={handleUploadClick} className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Start Upload</button>
            )}
            {status !== 'selected' && (
              <Progress>
                <ProgressBar progress={progress} />
              </Progress>
            )}
            {status === 'deleting' && (
              <Status>
                Deleting file...
              </Status>
            )}
            {fileName && (
              <Status>
                {fileName} - {progress}%
              </Status>
            )}

            {error && <Status error>{error}</Status>}

            <Controls>
              {['uploading', 'paused'].includes(status) && (
                <>
                  <Button
                    buttonType={status === 'uploading' ? 'pause' : 'resume'}
                    onClick={toggleUpload}
                  >
                    {status === 'uploading' ? 'Pause' : 'Resume'}
                  </Button>
                  <Button
                    buttonType="cancel"
                    onClick={() =>  deleteFile(uploadRef.current?.url)}
                  >
                    Cancel
                  </Button>
                </>
              )}
              {status === 'completed' && (
                <>
                  <span>Upload completed</span>
                  <Button
                    buttonType="delete"
                    onClick={() => deleteFile(uploadRef.current?.url)}
                  >
                    Remove File
                  </Button>
                </>
              )}
              {status === 'error' && (
                <>Upload failed</>
              )}
            </Controls>
          </>
        )}
      </UploadBox>
    </Container>
  );
};

export default TusUploader;