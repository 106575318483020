//---- I need this code for future development -----//

// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { requestPostApiCall, requestGetApiCall } from "../services/ApiService";

// export const createOrder = createAsyncThunk(
//     'payment/createOrder',
//     async ({ amount, currency }, { rejectWithValue }) => {
//       try {
//         const response = await requestPostApiCall('/orders', { amount, currency });
//         return response;
//       } catch (error) {
//         return rejectWithValue(error);
//       }
//     }
//   );

//   export const fetchOrders = createAsyncThunk(
//     'payment/fetchOrders',
//     async (_, { rejectWithValue }) => {
//       try {
//         return await requestGetApiCall('/payments');
//       } catch (error) {
//         return rejectWithValue(error);
//       }
//     }
//   );

// const paymentSlice = createSlice({
//     name: 'payment',
//     initialState: {
//       orders: [],
//       orderStatus: null,
//       error: null,
//       loading: false,
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//       builder
//         .addCase(createOrder.pending, (state) => {
//           state.loading = true;
//           state.orderStatus = 'loading';
//         })
//         .addCase(createOrder.fulfilled, (state, action) => {
//           state.loading = false;
//           state.orderStatus = 'success';
//           state.orders.push(action.payload);
//         })
//         .addCase(createOrder.rejected, (state, action) => {
//           state.loading = false;
//           state.orderStatus = 'failed';
//           state.error = action.payload;
//         });
//     },
//   });
  
//   export default paymentSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { requestPostApiCall } from "../services/ApiService";

export const createOrder = createAsyncThunk(
  'payment/createOrder',
  async ({ amount, currency }, { rejectWithValue }) => {
    try {
      const response = await requestPostApiCall('/orders', { amount, currency });
      return response.data[0];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOrders = createAsyncThunk(
  'payment/fetchOrders',
  async (_, { rejectWithValue }) => {
    try {
      return await requestPostApiCall('/payments');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyOrderPayment = createAsyncThunk(
  'payment/verifyOrderPayment',
  async (options, { rejectWithValue }) => {
    try {
      const response = await requestPostApiCall('/payments/verification', {...options});
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const webhookPayment = createAsyncThunk(
//   ''
// )

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    orders: [],
    orderStatus: null,
    error: null,
    loading: false,
    verifyStatus: null,
    verifyError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
        state.orderStatus = 'loading';
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.orderStatus = 'success';
        state.orders.push(action.payload);
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false;
        state.orderStatus = 'failed';
        state.error = action.payload;
      })
      
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(verifyOrderPayment.pending, (state) => {
        state.verifyStatus = 'loading';
      })
      .addCase(verifyOrderPayment.fulfilled, (state, action) => {
        state.verifyStatus = 'success';
      })
      .addCase(verifyOrderPayment.rejected, (state, action) => {
        state.verifyStatus = 'failed';
        state.verifyError = action.payload;
      });
  },
});

export default paymentSlice.reducer;
