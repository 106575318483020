import React, { createContext, useState, useContext } from "react";

// Create the SelectedDomainContext
const SelectedDomainContext = createContext();

// Provider Component
export const SelectedDomainProvider = ({ children }) => {
  const [selectedDomain, setSelectedDomain] = useState(null);

  /**
   * Updates the selected domain.
   * @param {string} domain - The selected domain name.
   */
  const updateSelectedDomain = (domain) => {
    setSelectedDomain(domain);
  };

  return (
    <SelectedDomainContext.Provider value={{ selectedDomain, updateSelectedDomain }}>
      {children}
    </SelectedDomainContext.Provider>
  );
};

// Custom hook to use SelectedDomainContext
export const useSelectedDomain = () => {
  const context = useContext(SelectedDomainContext);
  if (!context) {
    throw new Error("useSelectedDomain must be used within a SelectedDomainProvider");
  }
  return context;
};
