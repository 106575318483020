import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../../redux/AuthController';
import { useNavigate } from 'react-router-dom';
import { Button }   from '../styled/Buttons'
import { unwrapResult } from '@reduxjs/toolkit';

const SignOut = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const sessionId = useSelector((state) => state.auth.sessionId);
    const userId = useSelector((state) => state.auth.userId);
    const userName = useSelector((state) => state.auth.userName);
    const handleLogout = async () => {
        try {
            const resultAction = await dispatch(logOut({ sessionId, userId, userName }));
            const originalPromiseResult = unwrapResult(resultAction);
            if (originalPromiseResult) {
                navigate('/sign-in');
                window.location.reload();
            }
        } catch (error) {
            console.error('Failed to log out: ', error);
        }
    };

    return (
        <Button.Blue
            className="pl-3 pr-3 pb-2.5 pt-2.5 bg-blue-800 text-white hover:underline dark:text-blue-500 font-semibold"
            type="button"
            onClick={handleLogout}>
            <span>Logout</span>
        </Button.Blue>
    );
};

export default SignOut;





