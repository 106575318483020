import axios from "axios";
import axiosInstance from './AxiosInstance';
import creds from "../../src/creds";
import { getUserRole } from "./AuthService";


const backendCore = creds.backendCore;

export const getDomains = async () => {
    try {
        return await axios.get(`${backendCore}domain/get-domains`);
    } catch (error) {
        throw error.message;
    }
};

export const addDomain = async (domainData) => {
    let domainJSON = {};
    domainJSON.domainName = domainData.domain;
    domainJSON.domainDescription = domainData.domain_description;
    try {
        return await axiosInstance.post(`${backendCore}domain/add-domain`, domainJSON, {
          headers: {
              'Permission-Name': `create_domain`,
              'Role-Id': getUserRole().roleId,
          },
          withCredentials:true,
        });
    } catch (error) {
        throw error;
    }
};

export const deleteDomainService = async (domain) => {    
        return await axiosInstance.delete(`${backendCore}domain/delete-domain/${domain.domain_id}`, {
          headers: {
              'Permission-Name': `delete_domain`,
              'Role-Id': getUserRole().roleId,
          },
          withCredentials:true,
        });     
};

export const editDomain = async (domainData, prevDomainId) => {
    try {
        let updatedDomainJSON = {};
        updatedDomainJSON.domain_name = domainData.domain_name;
        updatedDomainJSON.domain_description = domainData.domain_description;
        return await axiosInstance.put(`${backendCore}domain/update-domain?domainId=${prevDomainId}`, updatedDomainJSON, {
          headers: {
              'Permission-Name': `update_domain`,
              'Role-Id': getUserRole().roleId,
          },
          withCredentials:true,
        });
    } catch (error) {
        throw error.message;
    }
};

export const listDomainImages = async (fileName) => {
  try {
    const response = await axios.get(`${backendCore}files/list?prefix=${fileName}&latest=true`);
    return response.data.files;
  } catch (error) {
    throw error;
  }
};