import axios from 'axios';
import { url } from '../creds';
import axiosInstance from './AxiosInstance';

export const createVendorPayout = async (vendorPayoutData) => {
    try {
      const response = await axiosInstance.post(
        `${url}payout/create-vendor`,
        vendorPayoutData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error(`Vendor payout creation failed: ${errorMessage}`);
      }
      throw new Error(`Failed to create vendor payout: ${error.message}`);
    }
  };

  export const validateKycAccount = async () => {
    try {
      const response = await axiosInstance.get(`${url}payout/account-validation`, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      const { message, payout } = response.data;
      return { message, payout };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error(`KYC account validation failed: ${errorMessage}`);
      }
      throw new Error(`Failed to validate KYC account: ${error.message}`);
    }
  };

  export const fatchVendorDetails = async () => {
    try {
      const response = await axiosInstance.get(`${url}get-vendor-payouts'`, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      const { message, payout } = response.data;
      return { message, payout };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error(`Failed to get Vendor Payout Details: ${errorMessage}`);
      }
      throw new Error(`Failed to get Vendor Payout Details: ${error.message}`);
    }
  };
  
  