import React, { useState, useEffect } from "react";
import {
  HomeCardHeading,
  HomePageSubHeading,
} from "../../components/styled/GlobalStyles.js";
import ContentLoader from "react-content-loader";
import { FaDatabase } from "react-icons/fa";
import { Link } from "react-router-dom";
import { homeDomain } from "../../services/HomePageService";
import { listDomainImages } from "../../services/DomainService.js";
import creds from "../../../src/creds";
import others from "../../assets/images/landingPageImages/others_logo.png";

const backendCore = creds.backendCore;

const DomainCard = React.memo(({ domainName, datasetCount, pathname, imageUrl }) => {
  return (
    <div className="p-3 flex">
      <div className="rounded-xl bg-gray-50 border-1 border-gray-600 shadow-md flex-grow">
        <div className="rounded-xl border-1 border-gray-2600 p-5">
          <div className="flex justify-end">
            <div className="text-center">
              <FaDatabase size={22} className="text-indigo-700" />
              <span className="text-gray-700 font-semibold">{datasetCount}</span>
            </div>
          </div>
          <Link className="" to={pathname}>
            <div className="flex justify-center text-center">
              <img
                src={imageUrl}
                alt="domain-logo"
                className="h-20 w-20 rounded-full object-cover"
              />
            </div>
            <p className={HomeCardHeading}>{domainName}</p>
          </Link>
        </div>
      </div>
    </div>
  );
});

const HeadingContentLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={150}
    viewBox="0 10 900 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="10" rx="0" ry="10" width="100%" height="30%" />
  </ContentLoader>
);

const DesclamerContentLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={150}
    viewBox="0 0 90% 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="70%" />
  </ContentLoader>
);

const DFSDatasetDomainLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={200}
    viewBox="0 0 500 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="30" y="0" rx="3" ry="3" width="100%" height="200" />
    <rect x="70" y="240" rx="10" ry="10" width="80%" height="40" />
    <rect x="30" y="300" rx="3" ry="3" width="100%" height="30" />
  </ContentLoader>
);

const DFSDatasetDomain = () => {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [domainImages, setDomainImages] = useState({});


  const fetchDomainHomeDataNew = async () => {
    try {
      const response = await homeDomain();
      const domainData = response.data.data;

      const formattedData = domainData.map((domain) => ({
        indexNo: domain.index,
        domainName: domain.domainName,
        datasetCount: domain.datasetCount,
        domainId: domain.domainId,
        pathname: `/datasets-listing/${domain.domainName
          .toLowerCase()
          .replace(/\s+/g, "-")}`,
      }));

      setDomains(formattedData);
      return formattedData;
    } catch (error) {
      return [];
    }
  };

  const fetchDomainImages = async (domainsData) => {
    const images = {};

    for (const domain of domainsData) {
      try {
        const filePath = `domainImages/${domain.domainId}`;
        const imageFiles = await listDomainImages(filePath);
        if (imageFiles.length > 0) {
          const imageFileName = imageFiles[0].name;
          const imageUrl = `${backendCore}files//stream?fileName=${imageFileName}`;
          images[domain.domainName] = imageUrl;
        } else {
          images[domain.domainName] = others;
        }
      } catch (error) {
        images[domain.domainName] = others;
      }
    }
    setDomainImages(images);
  };


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const domainsData = await fetchDomainHomeDataNew();
        await fetchDomainImages(domainsData);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mx-auto bg-white lg:px-20 md:px-16">
      {loading ? (
        <div className="mt-6 text-3xl text-blue-600 font-sans text-center font-bold">
          <HeadingContentLoader />
        </div>
      ) : (
        <h1 className={HomePageSubHeading}>Domains</h1>
      )}
      <div className="py-2">
        {loading ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
            {Array(
              window.innerWidth < 640
                ? 2 // Small screens
                : window.innerWidth < 768
                  ? 3 // Medium screens
                  : window.innerWidth < 1024
                    ? 4 // Large screens
                    : 5 // Extra large screens
            )
              .fill()
              .map((_, idx) => (
                <DFSDatasetDomainLoader key={idx} />
              ))}
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 md:gap-4 ">
            {domains.map((domain, index) => (
              <DomainCard
                key={index}
                domainName={domain.domainName}
                datasetCount={domain.datasetCount}
                pathname={domain.pathname}
                imageUrl={domainImages[domain.domainName] || others}
              />
            ))}
          </div>
        )}
      </div>

      <div className="mt-6 px-5">
        {loading ? (
          <div className="text-3xl text-blue-600 font-sans text-center font-bold">
            <DesclamerContentLoader />
          </div>
        ) : (
          <div className="bg-gray-100 rounded p-3">
            <p className="text-base">
              India Data Org is a pioneering initiative born from the Data
              Foundation project by IIITH DATA I-HUB FOUNDATION, a Technology
              Innovation Hub under the NMICPS mission, supported by the
              Department of Science and Technology. Our platform is a
              comprehensive library of digital data, backed by cutting-edge
              technology, robust infrastructure, and a skilled team dedicated to
              collecting, creating, curating, annotating, securing, and
              deploying data.
            </p>
            <p className="text-base my-2">
              As a vital resource for the technology community, researchers, and
              application developers, India-Data.Org empowers the development of
              AI-driven solutions and advanced analytics in socially impactful
              domains. From healthcare and mobility to smart buildings and
              systems, our focus is on enabling innovative applications tailored
              to India's unique challenges and opportunities.
            </p>
            <p className="text-base">
              Our core focus is on enhancing citizen lives by driving innovation
              through the use of secure, privacy-aware datasets, ensuring
              compliance with data protection regulations and fostering
              responsible data usage.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DFSDatasetDomain;