import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import apollo from "../../assets/images/landingPageImages/my_partner/apollo.png";
import biocon from "../../assets/images/landingPageImages/my_partner/biocon.png";
import inai from "../../assets/images/landingPageImages/my_partner/inai.png";
import cpathlabs from "../../assets/images/landingPageImages/my_partner/cpathlabs.png";
import grace from "../../assets/images/landingPageImages/my_partner/grace.png";
import igb from "../../assets/images/landingPageImages/my_partner/igb.png";
import lepra from "../../assets/images/landingPageImages/my_partner/lepra.png";
import mahindrauniversity from "../../assets/images/landingPageImages/my_partner/mahindra_university.png";
import niserb from "../../assets/images/landingPageImages/my_partner/niserb.png";
import nitap from "../../assets/images/landingPageImages/my_partner/nitap.png";
import nizamsinsitute from "../../assets/images/landingPageImages/my_partner/nizams insitute.png";
import pids from "../../assets/images/landingPageImages/my_partner/pids.png";
import rajivgandhi from "../../assets/images/landingPageImages/my_partner/rajiv_gandhi.png";
import ramaiah from "../../assets/images/landingPageImages/my_partner/ramaiah.png";
import ramdeobabauni from "../../assets/images/landingPageImages/my_partner/ramdeobaba university.png";
import rourkela from "../../assets/images/landingPageImages/my_partner/rourkela.png";
import vishnu from "../../assets/images/landingPageImages/my_partner/vishnu.png";

import { HomePageSubHeading } from "../../components/styled/GlobalStyles.js";
import ContentLoader from "react-content-loader";

const partners = [
  { src: cpathlabs, alt: "cpathlabs" },
  { src: grace, alt: "grace" },
  { src: igb, alt: "igb" },
  { src: inai, alt: "Inai" },
  { src: lepra, alt: "lepra" },
  { src: pids, alt: "pids" },
  { src: mahindrauniversity, alt: "mahindrauniversity" },
  { src: niserb, alt: "niserb" },
  { src: rajivgandhi, alt: "rajivgandhi" },
  { src: nitap, alt: "nitap" },
  { src: nizamsinsitute, alt: "nizamsinsitute" },
  { src: ramaiah, alt: "ramaiah" },
  { src: ramdeobabauni, alt: "ramdeobabauni" },
  { src: rourkela, alt: "rourkela" },
  { src: vishnu, alt: "vishnu" },
];

const HeadingContentLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={150}
    viewBox="0 0 2000 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="30%" />
  </ContentLoader>
);

const DFSDatasetDomainLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={400}
    viewBox="0 0 500 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="30" y="0" rx="3" ry="3" width="100%" height="200" />
    <rect x="70" y="240" rx="10" ry="10" width="80%" height="40" />
    <rect x="30" y="300" rx="3" ry="3" width="100%" height="30" />
  </ContentLoader>
);

const DFSPartner = () => {
  // slider added
  const SliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      { breakpoint: 1500, settings: { slidesToShow: 5 } },
      { breakpoint: 1300, settings: { slidesToShow: 4 } },
      { breakpoint: 1024, settings: { slidesToShow: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 3 } },
      { breakpoint: 600, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 2 } },
    ],
  };

  const PartnerCard = ({ src, alt }) => (
    <div className="p-3">
      <div className="rounded-xl">
        <div className="flex justify-center text-center">
          <img src={src} alt={alt} className="lg:h-28 md:h-20 sm:h-20 h-20" />
        </div>
      </div>
    </div>
  );

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="mx-auto bg-white lg:px-20 md:px-16">
      {loading ? (
        <div className="text-3xl text-blue-600 font-sans text-center font-bold">
          <HeadingContentLoader />
        </div>
      ) : (
        <h1 className={HomePageSubHeading}>Our Partners</h1>
      )}
      <div className="py-2">
        {loading ? (
          <div className="grid grid-cols-3 gap-4">
            {[...Array(3)].map((_, idx) => (
              <DFSDatasetDomainLoader key={idx} />
            ))}
          </div>
        ) : (
          <Slider {...SliderSettings}>
            {partners.map((partner, index) => (
              <PartnerCard key={index} src={partner.src} alt={partner.alt} />
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default DFSPartner;
