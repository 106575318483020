import React from "react";
import { FlexSection, Heading, ParaFont } from "../../components/styled/GlobalStyles";

const cancellationRefundData = {
  title: "Cancellations or Refunds",
  content: `For any cancellations or refunds, please reach out to `,
  contact: "dfsupport@ihub-data.iiit.ac.in",
};

function CancellationsRefunds() {
  return (
    <div className="mx-auto bg-white pt-8">
      <div className={FlexSection}>
        <div className="flex flex-col gap-4 shadow-2xl hover:shadow-3xl rounded -mt-4">
          <div className="w-full mx-4 pr-8 text-justify mb-4 mt-4">
            <h1 className={Heading}>{cancellationRefundData.title}</h1>
            <p className={`${ParaFont}`}>
              {cancellationRefundData.content}
              <a
                href={`mailto:${cancellationRefundData.contact}`}
                className="text-blue-500 underline"
              >
                {cancellationRefundData.contact}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancellationsRefunds;
