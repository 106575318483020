import { toast } from 'react-toastify';

// Using a Set to keep track of displayed messages
const displayedMessages = new Set();

const notify = (message, type) => {
  // Check if the message has already been displayed
  if (displayedMessages.has(message)) {
    return;
  }

  // Add the message to the set
  displayedMessages.add(message);

  // Display the toast notification
  const toastId = toast(message, {
    type: type,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: () => {
      // Remove the message from the set when the notification is closed
      displayedMessages.delete(message);
    }
  });
};

export default notify;

