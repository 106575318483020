import React from "react"
import {FlexSection, Heading, ParaFont, OverviewFont} from "../../components/styled/GlobalStyles"
import aboutImage from "../../assets/images/aboutImages/verticals.webp"

// const aboutImages = require.context("../../assets/images/aboutImages", true);

function About() {
  return (
    <div className="mx-auto bg-white pt-8">

        <div className={FlexSection}>
            <div className="flex gap-4 shadow-2xl hover:shadow-3xl rounded flex-wrap">
              <div className="text-justify lg:w-full md:w-full xl:w-full 2xl:w-full p-4 mt-4">
                <h1 className={Heading}>India Data</h1>
                
                  <p className={`${ParaFont}`}>
                  India Data is a comprehensive digital repository powered by advanced platforms, reliable infrastructure, and a dedicated team of experts. It supports the collection, curation, annotation, and deployment of data, playing a key role in enabling researchers, developers, and technology professionals to build AI-driven solutions and analytics.
                  </p>
              <div className="w-full text-center flex justify-center">
                <img src={aboutImage} alt={"India data aboutus"} className="md:h-1/4 md:w-1/4 xl:w-2/12 xl:w-2/12 lg:w-1/5 lg:w-1/5" />
              </div>
                  <p className={`${ParaFont}`}>
                  The repository addresses critical domains such as healthcare, mobility, smart buildings, IoT, agriculture, and computer vision, with a strong focus on solving challenges unique to the Indian context. As part of IHub-Data, the Technology Innovation Hub (TIH), India Data is committed to driving innovation and fostering impactful, data-centric technologies for real-world applications.
                  </p>
                
              </div>
            </div>
          
        </div>
    </div>
  )
}

export default About