import React from 'react';
import MDEditor from '@uiw/react-md-editor';


export const MarkdownModal = ({ isOpen, onClose, title, markdownContent }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[999]">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto z-[1000]">
        <h2 className="text-2xl font-bold mb-6">{title}</h2>
        <div className="prose prose-sm max-w-none" data-color-mode="light">
        <MDEditor.Markdown source={markdownContent} />

        </div>
        <div className="flex justify-end mt-6">
          <button 
            className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};