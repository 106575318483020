import React, { useEffect, useState } from "react";
import AddDuaForm from "../../components/dua/AddDuaForm";
import DuaTable from "../../components/dua/DuaTable";
import {getduas} from "../../services/DUAService";
import { useSelector, useDispatch } from "react-redux";
import { setTabParams } from "../../redux/commonSlice";
import { InternalHeading } from "../../components/styled/GlobalStyles";
const getDua = async () => {
  const response = await getduas();
  return response;
};


const MyDownloadUserAgreement = () => {
  const [duas, setDuas] = useState([]);
  const dispatch = useDispatch();
  const domainId = useSelector((state) => state.common.tabParams?.length ? state.common.tabParams[13]?.domainId : null);
  const domainName = useSelector((state) => state.common.tabParams?.length ? state.common.tabParams[13]?.domainName : null);
  const fetchDua = async () => {
    try {
      const response = await getDua();
      setDuas(response.data.duas || []);
    } catch (error) {
      console.error("Error fetching DUAs:", error);
    }
  };

  useEffect(() => {
    fetchDua();
    return () => {
      dispatch(setTabParams({ 13: {} }));
    };
  }, []);

   return (
    <div>
      <div className="flex flex-wrap w-full px-4 bg-gray-200 mb-2 rounded-lg">
        <h1 className={InternalHeading}>
          Add New Data User Agreement
        </h1>
      </div>

      <AddDuaForm onDuaAdded={fetchDua} domainId={domainId} domainName={domainName}/>
      <DuaTable
        data={duas}
        fetchDua={fetchDua} 
      />
    </div>
  );
};

export default MyDownloadUserAgreement;
