import React from "react";
import { Progress } from "flowbite-react";
import { TOAST_VARIANTS } from "../utils/constants";

export type ProgressBarProps = {
  className: string;
  category: TOAST_VARIANTS;
  duration?: number;
  intervalDuration?: number;
  pending: number;
};

const getToastColor = (category: TOAST_VARIANTS) => {
  switch (category) {
    case TOAST_VARIANTS.WARNING:
      return "yellow";
    case TOAST_VARIANTS.ERROR:
      return "red";
    case TOAST_VARIANTS.SUCCESS:
      return "green";
    case TOAST_VARIANTS.INFO:
      return "blue";
    default:
      return "";
  }
};

const ProgressBar = ({
  category,
  duration = 5000,
  pending,
}: ProgressBarProps) => {
  //   const [filled, setFilled] = useState(100);
  const filled = Math.round(100 * (pending / duration));
  const pgColor = getToastColor(category);

  return (
    <Progress className="h-1" progress={filled} size="sm" color={pgColor} />
  );
};
export default ProgressBar;
