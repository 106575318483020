import React, { useState, useEffect } from "react";
import Table from "../styled/Table"; // Assuming this table component exists and is styled
import { getDatasetCataloguesList, deleteDatasetFromCatalogue } from "../../services/DatasetCatalogueService"; // Updated import for deleteDatasetFromCatalogue
import DeleteConfirmModal from "./DeleteConfirmModal"; // Import the modal
import EditDatasetCatalogueModal from "./EditDatasetCatalogueModal"; // Import the edit modal

const DatasetCatalogueTable = ({ datasetCatalogue, onDatasetCatalogueUpdated }) => {
  const [showModal, setShowModal] = useState(false); // Show Delete Confirmation Modal
  const [selectedItem, setSelectedItem] = useState(null); // Currently selected item (for delete or edit)
  const [showEditModal, setShowEditModal] = useState(false); // Show Edit Modal
  const [data, setData] = useState(datasetCatalogue); // Data state for dataset catalogues

  const fetchData = async () => {
    try {
      // This assumes the data is passed as a prop initially, if not, you can use an API to fetch it
      const response = await getDatasetCataloguesList(); // Actually fetch the updated data
      setData(response.data || []); 
    } catch (error) {
      console.error("Error fetching dataset catalogue list:", error);
    }
  };
  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [datasetCatalogue]); // Empty dependency array to fetch data only once

  // Define columns for the table
  const columns = [
    {
      key: "updatedAt",
      label: "Creation Date",
      sortable: true,
      render: (value) => new Date(value).toLocaleDateString("en-GB"),
    },
    {
      key: "userFullName",
      label: "Uploader Name",
      sortable: true,
    },
    {
      key: "datasetName",
      label: "Dataset Name",
      sortable: true,
    },
    {
      key: "domainName",
      label: "Domain Name",
      sortable: true,
    },
  ];

  // Define actions (buttons) for each row
  const actions = [
    {
      label: "Link",
      key: "sourceLink",
      className:
        "px-3 py-2 rounded-lg text-sm text-blue-500 bg-white border border-blue-500 hover:bg-blue-300 hover:text-blue-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => {
        window.open(item.sourceLink, "_blank"); // Open the link in a new tab
      },
    },
    {
      label: "Edit",
      className:
        "px-6 py-2 rounded-lg text-sm text-green-500 bg-white border border-green-500 hover:bg-green-300 hover:text-green-700 focus:ring-2 focus:ring-blue-500",
      handler: (item) => {
        setSelectedItem(item); // Set the item for editing
        setShowEditModal(true); // Open the edit modal
      },
    },
    {
      label: "Delete",
      className:
        "px-6 py-2 rounded-lg text-sm text-red-500 bg-white border border-red-500 hover:bg-red-300 hover:text-red-700 focus:ring-2 focus:ring-red-500",
      handler: (item) => {
        setSelectedItem(item); // Set the item to delete
        setShowModal(true); // Show the delete confirmation modal
      },
    },
  ];
  
  // Handle cancel delete
  const handleCancel = () => {
    setShowModal(false); // Close the delete confirmation modal
    setSelectedItem(null); // Reset selected item
  };

  const handleEdit = async (updatedData) => {
    try {
      await onDatasetCatalogueUpdated(); 
      setShowEditModal(false);
      setSelectedItem(null);
    } catch (error) {
      console.error("Error updating dataset catalogue:", error);
    }
  };

  return (
    <div className="flex flex-col w-full bg-white mb-2">
      {/* Table component that displays the dataset catalogue */}
      <Table
        data={data}
        columns={columns}
        pageSize={5}
        actions={actions} // Pass the actions (Edit, Delete, etc.) to the Table component
        title="Dataset Catalogue"
      />
      {/* Delete confirmation modal */}
      <DeleteConfirmModal
        show={showModal}
        onCancel={handleCancel}
        selectedCatalogue={selectedItem} 
        onDeleteSuccess={() => { 
          onDatasetCatalogueUpdated();
          setShowModal(false);
        }}
      />

      {/* Edit dataset catalogue modal */}
      <EditDatasetCatalogueModal
        show={showEditModal}
        setShow={setShowEditModal}
        onEdit={handleEdit}
        selectedCatalogue={selectedItem} // Pass selected catalogue to the edit modal
      />
    </div>
  );
};

export default DatasetCatalogueTable;
