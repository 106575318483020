import { useState, useEffect } from 'react';
import { fetchDeactivatedUsers } from '../../services/AdminControlService';

const useFetchDeactivatedUsers = (refresh) => {
  const [deactivatedUsers, setDeactivatedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchDeactivatedUsers();
        setDeactivatedUsers(data);
      } catch (err) {
        setError(err.message || 'Failed to fetch deactivated users.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh]);

  return { deactivatedUsers, loading, error };
};

export default useFetchDeactivatedUsers;