import axiosInstance from './AxiosInstance';
import creds from '../creds';

const backendCore = creds.backendCore;

export const logDownloadEvent = async (datasetId, datasetVersion, fileName) => {
  try {
    await axiosInstance.post(
      `${backendCore}dataset/download`,
      {
        datasetId,
        datasetVersion,
        fileName,
      },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
  } catch (err) {
    console.error("Failed to log download event:", err);
  }
};
