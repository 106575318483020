import React from "react";
import { FlexSection, Heading, ParaFont, OverviewFont } from "../../components/styled/GlobalStyles";

const termsData = {
  title: "Terms and Conditions for India Data",
  introduction: `Welcome to India Data. These Terms and Conditions (the "Terms") govern your access to and use of our services, including our website and any datasets hosted on our platform. By accessing or using our services, you agree to comply with these Terms. If you do not agree, please do not use our services.`,
  sections: [
    {
      heading: "Acceptance of Terms",
      content: `By using the India Data platform, you confirm that you are of legal age (18 years or older) or have parental consent if you are at least 13 years old. You agree to abide by these Terms and any applicable laws.`,
    },
    {
      heading: "Account Registration",
      content: `To access certain features, you may need to create an account:`,
      subpoints: [
        "Registration Information: You must provide accurate and complete information during registration.",
        "Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.",
      ],
    },
    {
      heading: "User Conduct",
      content: `You agree not to engage in any of the following prohibited activities:`,
      subpoints: [
        "Posting content that is unlawful, harmful, defamatory, or invasive of privacy.",
        "Using the platform for commercial purposes without explicit permission from India Data.",
        "Attempting to gain unauthorized access to any part of the platform.",
      ],
    },
    {
      heading: "User Contributions",
      content: `You retain ownership of any content you upload but grant India Data a non-exclusive, worldwide license to use, reproduce, modify, and distribute your content as necessary for providing our services.`,
    },
    {
      heading: "Intellectual Property",
      content: `All content on the India Data platform, including datasets, text, graphics, and software, is owned by India Data or its licensors. You may not reproduce or distribute any content without prior written permission.`,
    },
    {
      heading: "Disclaimers",
      content: `India Data provides its services "as is" and disclaims all warranties regarding the accuracy or reliability of the datasets hosted on the platform. We do not guarantee uninterrupted access or that our services will be free from errors.`,
    },
    {
      heading: "Limitation of Liability",
      content: `To the fullest extent permitted by law, India Data shall not be liable for any indirect, incidental, or consequential damages arising from your use of our services.`,
    },
    {
      heading: "Changes to Terms",
      content: `We may update these Terms periodically. Any changes will be posted on this page with an updated effective date. Your continued use of our services after changes are made constitutes acceptance of those changes.`,
    },
    {
      heading: "Governing Law",
      content: `These Terms shall be governed by and construed in accordance with the laws of India.`,
    },
    {
      heading: "Contact Information",
      content: `For questions about these Terms, please contact us at `,
      contact: "dfsupport@ihub-data.iiit.ac.in",
    },
  ],
};

function TermsConditions() {
  return (
    <div className="mx-auto bg-white pt-8">
      <div className={FlexSection}>
        <div className="flex flex-col gap-4 shadow-2xl hover:shadow-3xl rounded -mt-4">
          <div className="w-full mx-4 pr-8 text-justify mb-4 mt-4">
            <h1 className={Heading}>{termsData.title}</h1>
            <p className={`${ParaFont} my-4`}>{termsData.introduction}</p>
            <ul className="list-decimal pl-8">
              {termsData.sections.map((section, index) => (
                <li key={index} className={OverviewFont}>
                  <strong>{section.heading}</strong>
                  <p className={`${ParaFont}`}>{section.content}</p>
                  {section.subpoints && (
                    <ul className="list-disc pl-8">
                      {section.subpoints.map((subpoint, subIndex) => (
                        <li key={subIndex}>{subpoint}</li>
                      ))}
                    </ul>
                  )}
                  {section.contact && (
                    <a href={`mailto:${section.contact}`} className="text-blue-500">
                      {section.contact}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
