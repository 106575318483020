import React from "react";
import { CustomToast, ToastData } from "./ToastComponent";

export const ToastComponent = ({
  toastData,
  deleteToast,
}: {
  toastData: ToastData[];
  deleteToast: ({ message }: { message: string }) => void;
}) => {
  return (
    <div className="fixed right-5 top-28 z-[9999]">
      <CustomToast toastData={toastData} deleteToast={deleteToast} />
    </div>
  );
};
