import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { FaDatabase, FaTrophy, FaDownload, FaUser } from "react-icons/fa";
import { HomePageSubHeading } from "../../components/styled/GlobalStyles.js";
import {homeDownloadsCount} from "../../services/HomePageService"
const HeadingContentLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={150}
    viewBox="0 0 2000 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="30" y="25" rx="0" ry="0" width="100%" height="30%" />
  </ContentLoader>
);

const DFSAchievementsLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={400}
    viewBox="0 0 500 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="50%" y="20" rx="10" ry="10" width="70" height="70" />
    <rect x="30" y="100" rx="3" ry="3" width="100%" height="30" />
    <rect x="30" y="140" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
);

function DFSAchievements() {
  
  const [datasetsCount, setDatasetsCount] = useState("0");
  const [downloadsCount, setDownloadsCount] = useState("0");
  const [usersCount, setUsersCount] = useState("0");
  const [usersChallenges, setChallenges] = useState("0");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await homeDownloadsCount();
        const data = response.data.result;
        if (data.error) {
          console.error(data.message);
        } 
        else {
          setDatasetsCount(data.totalAvailableDatasets);
          setChallenges(data.totalChallenges);
          setUsersCount(data.totalActiveUsers);
          setDownloadsCount(data.totalDownloadedDatasets);
        }
      } catch (error) {
        console.error("Failed to fetch datasets count:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="bg-white mx-auto">
      {loading ? (
        <div className="transition-transform duration-300 ease-in-out flex justify-center items-center mb-4 mt-6">
          <HeadingContentLoader />
        </div>
      ) : (
        <h1 className={HomePageSubHeading}>
          Achievements
        </h1>
      )}
      <div className="pt-2 flex flex-wrap justify-center mt-2">
        {loading ? (
          <>
            <div className="w-1/2 sm:w-1/2 md:w-2/4 lg:w-1/4 p-4 hover:scale-105 transition-transform duration-300 ease-in-out">
              <DFSAchievementsLoader />
            </div>
            <div className="w-1/2 sm:w-1/2 md:w-2/4 lg:w-1/4 p-4 hover:scale-105 transition-transform duration-300 ease-in-out">
              <DFSAchievementsLoader />
            </div>
            <div className="w-1/2 sm:w-1/2 md:w-2/4 lg:w-1/4 p-4 hover:scale-105 transition-transform duration-300 ease-in-out">
              <DFSAchievementsLoader />
            </div>
            <div className="w-1/2 sm:w-1/2 md:w-2/4 lg:w-1/4 p-4 hover:scale-105 transition-transform duration-300 ease-in-out">
              <DFSAchievementsLoader />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center  w-2/5 sm:w-1/2 md:w-1/5 p-2">
              <div className="bg-indigo-500 w-20 h-20 rounded-full flex items-center shadow-md justify-center">
                <FaDatabase size={28} color="white" />
              </div>
              <p className="mt-2 text-xl font-bold text-black">{datasetsCount}</p>
              <p className="text-lg font-semibold font-sans text-black">
                Datasets
              </p>
            </div>
            <div className="flex flex-col items-center  w-2/5 sm:w-1/2 md:w-1/5 p-2">
              <div className="bg-indigo-500 w-20 h-20 rounded-full flex items-center shadow-md justify-center">
                <FaTrophy size={28} color="white" />
              </div>
              <p className="mt-2 text-xl font-bold text-black">{usersChallenges}</p>
              <p className="text-lg font-semibold font-sans text-black">
                Challenges
              </p>
            </div>
            <div className="flex flex-col items-center  w-2/5 sm:w-1/2 md:w-1/5 p-2">
              <div className="bg-indigo-500 w-20 h-20 rounded-full flex items-center shadow-md justify-center">
                <FaDownload size={28} color="white" />
              </div>
              <p className="mt-2 text-xl font-bold text-black">{downloadsCount}</p>
              <p className="text-lg font-semibold font-sans text-black">
                Downloads
              </p>
            </div>
            <div className="flex flex-col items-center  w-2/5 sm:w-1/2 md:w-1/5 p-2">
              <div className="bg-indigo-500 w-20 h-20 rounded-full flex items-center shadow-md justify-center">
                <FaUser size={28} color="white" />
              </div>
              <p className="mt-2 text-xl font-bold text-black">{usersCount}</p>
              <p className="text-lg font-semibold font-sans text-black">
                Users
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DFSAchievements;
